import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ConversationPaneToolbar } from '../toolbar/ConversationPaneToolbar';
import { ConversationDetail } from '../../../../../../common/components/react/messenger/conversation-detail/ConversationDetail';
import { NewMessageForm } from '../../../../../../common/components/react/messenger/new-message-form/NewMessageForm';

const MESSAGES_CHANGED_EVENT = 'MESSAGES_CHANGED';

export const MessengerConversationPane = ({
  MessengerService,
  $state,
  ChatService,
  $scope
}) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    $scope.$on(MESSAGES_CHANGED_EVENT, () => {
      setMessages(MessengerService.currentMessages);
    });
  }, []);

  return (
    <div className="WcMessengerConversationPane">
      <ConversationPaneToolbar
        MessengerService={MessengerService}
        $state={$state}
      />
      <ConversationDetail
        messages={messages}
        conversation={MessengerService.currentConversation}
        ChatService={ChatService}
      />
      <NewMessageForm
        MessengerService={MessengerService}
        ChatService={ChatService}
      />
    </div>
  );
};

MessengerConversationPane.propTypes = {
  MessengerService: PropTypes.any,
  $state: PropTypes.any,
  ChatService: PropTypes.any,
  $scope: PropTypes.any
};
