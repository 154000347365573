import STATES from '../../../services/States';

class NotificationsListController {
  /*@ngInject*/
  constructor(API, NotificationService, $scope, $state) {
    this.API = API;
    this.$state = $state;
    this.NotificationService = NotificationService;
    $scope.$watchCollection(
      () => this.NotificationService.listNotifications(),
      this.setNotifications.bind(this)
    );

    this.loading = true;
    this.hasGroup = false;

    this._getMyGroups();
    this._notificationLoaded = false;
    this._groupsLoaded = false;
  }

  _getMyGroups() {
    this.API.listMyGroups().then(groups => {
      this.hasGroup = Boolean(groups.length);
      this._groupsLoaded = true;
      this._updateLoading();
    });
  }

  setNotifications(notifications) {
    if (!notifications || notifications.length === 0) {
      this._notificationLoaded = true;
      this._updateLoading();
      return;
    }
    this.notifications = notifications;
    this._groupsLoaded = true; // No need to know if Member has groups if has notifications
    this.separateNotifications();
  }

  // separate Notifications by `notificationType`
  separateNotifications() {
    this.setRequestNotifications(this.notifications);
    this.setInviteNotifications(this.notifications);
    this.setApprovedNotifications(this.notifications);
    this.setGroupInviteApprovedNotifications(this.notifications);
    this.setGroupActivityNotifications(this.notifications);
  }

  setInviteNotifications(notifications) {
    this.groupInviteNotifications = notifications.filter(
      this.isInvitationNotification
    );
  }

  setRequestNotifications(notifications) {
    this.joinGroupNotifications = notifications.filter(
      this.isJoinRequestNotification
    );
  }

  setApprovedNotifications(notifications) {
    this.approvedNotifications = notifications.filter(
      this.isApprovedNotification
    );
  }

  setGroupInviteApprovedNotifications(notifications) {
    this.groupInviteApprovedNotifications = notifications.filter(
      this.isGroupInviteApprovedNotification
    );
  }

  setGroupActivityNotifications(notifications) {
    this.groupActivityNotifications = notifications.filter(
      this.isGroupActivityNotification
    );
    this._notificationLoaded = true;
    this._updateLoading();
  }

  isInvitationNotification(notification) {
    return notification.notificationType === 'GINV';
  }

  isJoinRequestNotification(notification) {
    return notification.notificationType === 'GJRQ';
  }

  isApprovedNotification(notification) {
    return notification.notificationType === 'GJRA';
  }

  isGroupInviteApprovedNotification(notification) {
    return notification.notificationType === 'GINA';
  }

  isGroupActivityNotification(notification) {
    return notification.notificationType === 'GACT';
  }

  /* handle Accept and Ignore actions
   * use same ignore() fn for all type of Notifications
   */
  acceptInvitation(notification) {
    this.API.GroupMembers(notification.metadata.group.id)
      .post()
      .catch(this.handleError.bind(this));
    this.removeNotification(notification);
  }

  acceptRequest(notification) {
    this.API.GroupMembers(notification.metadata.group.id)
      .post({ id: notification.metadata.otherMember.id })
      .catch(this.handleError.bind(this));
    this.removeNotification(notification);
  }

  viewGroup(notification) {
    this.$state.go(STATES.MEMBER_GROUP, { id: notification.metadata.group.id });
  }

  viewGroups() {
    this.$state.go(STATES.MEMBER_GROUPS);
  }

  viewMyGroups() {
    this.$state.go(STATES.MEMBER_GROUPS, { myGroups: true });
  }

  ignore(notification) {
    this.NotificationService.deleteAndRemoveNotification(notification);
  }

  /* Remove Notification from model optimistically until any Error didn't raised by API
   * without new API request to update Notifications
   * and prevent error if for any reason Notification object is not found in model
   */
  removeNotification(notification) {
    this.NotificationService.optimisticRemoveNotification(notification);
  }

  handleError(error) {
    this.API.handleError()(error);
  }

  _updateLoading() {
    this.loading = !this._notificationLoaded || !this._groupsLoaded;
  }
}

export default NotificationsListController;
