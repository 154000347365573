import angular from 'angular';

import clientNavbar from './clientnavbar';
import limitedClientNavbar from './limited-clientnavbar';
import iconButtons from './iconbuttons';
import memberNavbar from './membernavbar';
import messagesLink from './messagesLink';
import notificationsLink from './notificationsLink';
import navbar from './navbar';
import profilepicturemenu from './profilepicturemenu';
import searchbutton from './searchbutton';
import view from './view';

export default angular.module('wc.components.sidenav', [
  clientNavbar.name,
  limitedClientNavbar.name,
  iconButtons.name,
  memberNavbar.name,
  messagesLink.name,
  notificationsLink.name,
  navbar.name,
  profilepicturemenu.name,
  searchbutton.name,
  view.name
]);
