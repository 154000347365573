const inject = ['$state', '$localStorage', 'API'];

class ExperienceController {
  constructor($state, $localStorage, API) {
    this.$state = $state;
    this.$localStorage = $localStorage;
    this.API = API;

    this.API.Currencies.getList()
      .then(this.setCurrencies.bind(this))
      .catch(this.API.handleError());
  }

  setCurrencies(currencies) {
    this.currencies = currencies;

    if (this.$localStorage.defaultCurrency) {
      this.selectedCurrency = this.currencies.filter(
        c => c.code === this.$localStorage.defaultCurrency.code
      )[0];
    } else {
      this.selectedCurrency = this.currencies.filter(c => c.code === 'USD')[0];
    }
  }
}

ExperienceController.$inject = inject;
ExperienceController.controllerAs = 'experience';
ExperienceController.NAME = 'ExperienceController';
export default ExperienceController;
