import angular from 'angular';

import STATES from '../../../services/States';

/*@ngInject*/
function MembersInlineEditRouting($stateProvider) {
  $stateProvider
    .state(STATES.MEMBER_INLINE_EDIT.ROOT, {
      abstract: true,
      // TODO: change url?
      url: '/edit',
      template: `<ui-view/>`,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          // Only complete Members should be allowed to access Edit Profile routes.
          return RedirectService.redirectIfNotCompleteMember();
        }
      }
    })

    .state(STATES.MEMBER_INLINE_EDIT.PROFILE, {
      url: '/profile',
      template: `<react-inline-edit-profile section="ctrl.section"></react-inline-edit-profile>`,
      /* @ngInject */
      controller: function($stateParams) {
        this.section = $stateParams.section;
      },
      controllerAs: 'ctrl',
      params: {
        section: 'summary'
      }
    })

    .state(STATES.MEMBER_INLINE_EDIT.PROFESSIONAL_SUMMARY, {
      url: '/professional-summary',
      template: `<wc-inline-edit-professional-summary></wc-inline-edit-professional-summary>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.EXEC_POSITION, {
      url: '/position',
      template: `<wc-inline-edit-position position="positionCtrl.position"></wc-inline-edit-position>`,
      /* @ngInject */
      controller: function($stateParams) {
        this.position = $stateParams.position;
      },
      controllerAs: 'positionCtrl',
      params: {
        position: {}
      }
    })

    .state(STATES.MEMBER_INLINE_EDIT.NON_EXEC_POSITION, {
      url: '/ne-position',
      template: `<wc-inline-edit-non-exec-position position="positionCtrl.position"></wc-inline-edit-non-exec-position>`,
      /* @ngInject */
      controller: function($stateParams) {
        this.position = $stateParams.position;
      },
      controllerAs: 'positionCtrl',
      params: {
        position: {}
      }
    })

    .state(STATES.MEMBER_INLINE_EDIT.EXEC_POSITIONS_LIST, {
      url: '/positions',
      template: `<wc-inline-edit-positions-list></wc-inline-edit-positions-list>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.NON_EXEC_POSITIONS_LIST, {
      url: '/ne-positions',
      template: `<wc-inline-edit-positions-list non-exec="true"></wc-inline-edit-positions-list>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ELIGIBLE_REGIONS, {
      url: '/eligible-regions',
      template: `<wc-inline-edit-eligible-regions></wc-inline-edit-eligible-regions>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.REGIONS_MANAGED, {
      url: '/regions-managed',
      template: `<wc-inline-edit-regions-managed></wc-inline-edit-regions-managed>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.FINANCE_PEOPLE, {
      url: '/finance-people',
      template: `<wc-inline-edit-finance-people></wc-inline-edit-finance-people>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.ROOT, {
      url: '/aspirations',
      abstract: true,
      template: `<ui-view>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.COMPENSATION, {
      url: '/compensation',
      template: `<wc-inline-edit-aspirations-compensation></wc-inline-edit-aspirations-compensation>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.SUMMARY, {
      url: '/summary',
      template: `<wc-inline-edit-aspirations-summary></wc-inline-edit-aspirations-summary>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.NON_EXEC, {
      url: '/non-exec',
      template: `<wc-inline-edit-aspirations-non-exec></wc-inline-edit-aspirations-non-exec>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.RELOCATION, {
      url: '/relocation',
      template: `<wc-inline-edit-aspirations-relocation></wc-inline-edit-aspirations-relocation>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.JOB_ROLES, {
      url: '/job-roles',
      template: `<wc-inline-edit-aspirations-job-roles></wc-inline-edit-aspirations-job-roles>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.INDUSTRIES, {
      url: '/industries',
      template: `<wc-inline-edit-aspirations-industries></wc-inline-edit-aspirations-industries>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.CITIES, {
      url: '/cities',
      template: `<wc-inline-edit-aspirations-cities></wc-inline-edit-aspirations-cities>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.COMPANIES, {
      url: '/companies',
      template: `<wc-inline-edit-aspirations-companies></wc-inline-edit-aspirations-companies>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.INSIGHTS.ROOT, {
      url: '/insights',
      abstract: true,
      template: `<ui-view>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.INSIGHTS.HOBBIES, {
      url: '/hobbies',
      template: `<wc-inline-edit-insights-hobbies></wc-inline-edit-insights-hobbies>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.INSIGHTS.QA, {
      url: '/qa',
      template: `<wc-inline-edit-insights-q-a></wc-inline-edit-insights-q-a>`
    })

    .state(STATES.MEMBER_INLINE_EDIT.EDUCATION, {
      url: '/education',
      template: `<wc-inline-edit-education></wc-inline-edit-education>`
    });
}

export default angular
  .module('wc.members.inlineEdit', [])
  .config(MembersInlineEditRouting);
