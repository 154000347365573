import STATES from '../../../services/States';

export default class ClientProfileCtrl {
  /*@ngInject*/
  constructor($state, API) {
    this.$state = $state;
    this.API = API;
    this.editProfileState = STATES.CLIENT_EDIT_PROFILE;
    this.loading = true;

    this.retrieveUserData();
  }

  retrieveUserData() {
    this.retrieveUser()
      .then(this.setUser.bind(this))
      .then(this.retrieveProfile.bind(this))
      .then(this.setProfile.bind(this))
      .then(() => (this.loading = false));
  }

  retrieveUser() {
    return this.API.User.get();
  }

  retrieveProfile() {
    if (this.user.profile) {
      return this.API.restangularizeUrl(this.user.profile).get();
    }
    return null;
  }

  setUser(user) {
    this.user = user;
  }

  setProfile(profile) {
    this.profile = profile;
  }
}

ClientProfileCtrl.controllerAs = 'clientProfileCtrl';
