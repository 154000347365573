import groupActivity from './groupActivity';
import groupInvite from './groupInvite';
import groupInviteApproved from './groupInviteApproved';
import joinGroupRequest from './joinGroupRequest';
import joinRequestApproved from './groupRequestApproved';
import list from './list';
import view from './view';

export default angular.module('wc.components.notifications', [
  groupActivity.name,
  groupInvite.name,
  groupInviteApproved.name,
  joinGroupRequest.name,
  joinRequestApproved.name,
  list.name,
  view.name
]);
