import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ContactsToolbar } from '../../toolbar/ContactsToolbar';
import { ConversationList } from '../../conversations/list/ConversationList';

export const MessengerContacts = ({
  conversations,
  $state,
  $scope,
  ChatService,
  MessengerService
}) => {
  return (
    <div className="wcMessengerContacts">
      <ContactsToolbar />
      <ConversationList
        conversations={conversations}
        $state={$state}
        $scope={$scope}
        ChatService={ChatService}
        MessengerService={MessengerService}
      />
    </div>
  );
};

MessengerContacts.propTypes = {
  conversations: PropTypes.arrayOf(PropTypes.object),
  $state: PropTypes.any,
  $scope: PropTypes.any,
  ChatService: PropTypes.any,
  MessengerService: PropTypes.any
};
