import ImageSelectionController from './ImageSelection.controller';
import Template from './ImageSelection.html';
import './ImageSelection.scss';

function imageSelection() {
  return {
    restrict: 'E',
    scope: {
      title: '@',
      endpoint: '=',
      model: '='
    },
    controller: ImageSelectionController,
    controllerAs: ImageSelectionController.controllerAs,
    bindToController: true,
    templateUrl: Template
  };
}

imageSelection.NAME = 'imageSelection';
export default imageSelection;
