import React, { useReducer, useCallback, useEffect } from 'react';
import { Provider, useSelector, shallowEqual, useDispatch } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import angular from 'angular';
import { react2angular } from 'react2angular';

import theme from '../../../../../common/styles/theme';
import PageContainer from '../../PageContainer';
import Page from '../../Page';
import PageHeader from '../../PageHeader';
import { default as BaseLoadingSpinner } from '../../LoadingSpinner';
import FormSectionTitle from '../../FormSectionTitle';
import { initalState, reducer } from './reducer';
import NameField from '../../profile/basics/NameField';
import GenderSelect from '../../profile/GenderSelect';
import CountryOfOrigin from '../../profile/CountryOfOrigin';
import Ethnicity from '../../profile/ethnicity/Ethnicity';
import LocationSelect from '../../profile/LocationSelect';
import BaseTextField from '../../inputs/TextField/BaseTextField';
import { Actions as UserActions } from '../../../../../common/redux/user/actions';
import { Actions as ProfilesActions } from '../../../../../common/redux/profiles/actions';
import { default as BaseFormError } from '../../FormError';
import STATES from '../../../../services/States';
import { FORM_ERROR_MESSAGE } from '../../utils/constants';
import { default as BaseSolidButton } from '../../buttons/SolidButton';
import { default as BaseInverseButton } from '../../buttons/InverseButton/InverseButton';
import useProfileUpdater from '../../helpers/useProfileUpdater';
import formValid from '../../utils/formValid';

const LoadingSpinner = styled(BaseLoadingSpinner)`
  margin: 48px 64px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 48px 64px;

  ${FormSectionTitle}:not(:first-child) {
    margin-top: 36px;
  }
`;

const Row = styled.div`
  display: flex;

  & > * {
    margin-right: ${props => (props.noRightMargin ? '0' : '28px')};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const LocationSelectWrapper = styled.div`
  margin-bottom: ${props => (props.noBottomMargin ? '0' : '18px')};
`;

const SolidButton = styled(BaseSolidButton)`
  &.root {
    margin-left: 10px;
    min-width: 150px;
  }
`;

const InverseButton = styled(BaseInverseButton)`
  &.root {
    min-width: 150px;
  }
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 40px;
`;

const FormError = styled(BaseFormError)`
  margin-top: 24px;
`;

const InlineEditProfile = ({
  GoogleMapService,
  PlaceSearchService,
  API,
  $state,
  $scope,
  section
}) => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const reduxDispatch = useDispatch();
  const reduxState = useSelector(
    state => ({
      firstName: state.userReducer.user.firstName,
      lastName: state.userReducer.user.lastName,
      ethnicity: state.profilesReducer.myProfile.ethnicity,
      ethnicityConsent: state.profilesReducer.myProfile.ethnicityConsent,
      gender: state.profilesReducer.myProfile.gender,
      countryOfOrigin: state.profilesReducer.myProfile.countryOfOrigin,
      countryOptions: state.profilesReducer.countryOptions,
      address: state.profilesReducer.myProfile.address,
      eligibleRegions: state.profilesReducer.myProfile.eligibleRegions,
      profileUrl: state.profilesReducer.myProfile.url
    }),
    shallowEqual
  );
  const [
    updateUserDetails,
    updateProfileDetails,
    updateAddressDetails
  ] = useProfileUpdater(reduxDispatch, reduxState, state, PlaceSearchService);

  const updateFormFieldError = useCallback(
    (field, error) => {
      dispatch({ type: 'UPDATE_FORM_FIELD', field, payload: { error } });
    },
    [dispatch]
  );

  const updateFormFieldTouched = useCallback(
    (field, touched) => {
      dispatch({ type: 'UPDATE_FORM_FIELD', field, payload: { touched } });
    },
    [dispatch]
  );

  useEffect(() => {
    const getData = async () => {
      const [
        googleMap,
        user,
        countries,
        profile,
        ethnicities
      ] = await Promise.all([
        GoogleMapService.get(),
        API.User.get(),
        API.Countries.getList(),
        API.retrieveMyProfile(),
        API.Ethnicities.getList()
      ]);
      const {
        actions: {
          PUT: {
            gender: { choices: genderChoices }
          }
        }
      } = await API.restangularizeUrl(profile.url).options();

      if (profile.address) {
        const result = await API.restangularizeUrl(profile.address).get();
        if (result.country) {
          // This will mutate the property in result
          try {
            await API.getWithUrlAndHandle('country', result);
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }
        profile.address = result;
      }

      ProfilesActions.updateCountryOptions(reduxDispatch, countries);
      ProfilesActions.updateMyProfile(reduxDispatch, {
        ...profile,
        ethnicityConsent: !!profile.ethnicityConsent,
        gender: genderChoices.find(gender => gender.value === profile.gender)
      });
      UserActions.updateUser(reduxDispatch, {
        ...user,
        mobileNumberSharing: user.contactSharing
      });

      dispatch({
        type: 'SET_ETHNICITIES',
        payload: {
          ethnicities: ethnicities.map(ethnicity => ({
            id: ethnicity.id,
            name: ethnicity.name,
            url: ethnicity.url
          }))
        }
      });
      dispatch({ type: 'SET_GENDERS', payload: { genders: genderChoices } });
      dispatch({
        type: 'SET_MAP_SERVICE',
        payload: {
          mapService: new googleMap.maps.places.PlacesService(
            // eslint-disable-next-line angular/document-service
            document.createElement('div')
          )
        }
      });
      dispatch({ type: 'SET_LOADING', payload: { loading: false } });
    };

    getData().catch(error => {
      API.handleError()(error);
      // Fran: remove once we have a toast in react with the API.
      // Due to async, angular doesn't know when the promise has completed. Have to trigger the digest cycle manually.
      $scope.$apply();
    });
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!formValid(state.form)) {
      dispatch({
        type: 'SET_FORM_SUBMITTED',
        payload: { formSubmitted: true }
      });

      return;
    }

    dispatch({ type: 'SET_LOADING', payload: { loading: true } });
    dispatch({ type: 'SET_FORM_SUBMITTED', payload: { formSubmitted: true } });

    const userPatch = {
      firstName: reduxState.firstName,
      lastName: reduxState.lastName
    };
    const profilePatch = {
      gender: reduxState.gender ? reduxState.gender.value : null,
      countryOfOrigin: reduxState.countryOfOrigin
        ? reduxState.countryOfOrigin.id
        : null,
      ethnicity: reduxState.ethnicity ? reduxState.ethnicity.id : null,
      ethnicityConsent: reduxState.ethnicityConsent,
      eligibleRegions: API.objectListToIdsSerializerField(
        reduxState.eligibleRegions
      )
    };
    const addressData = { ...reduxState.address };

    if (addressData.country) {
      addressData.country = addressData.country.id;
    }

    try {
      await API.User.patch(userPatch);
      await API.restangularize({ url: reduxState.profileUrl }).patch(
        profilePatch
      );
      await API.restangularize(addressData).patch(addressData);
    } catch (error) {
      dispatch({ type: 'FORM_SUBMIT_ERROR' });
      API.handleError()(error);
      // Fran: remove once we have a toast in react with the API.
      // Due to async, angular doesn't know when the promise has completed. Have to trigger the digest cycle manually.
      $scope.$apply();
      return;
    }

    $state.go(STATES.MEMBER_PROFILE, { scrollToSection: section });
  };

  const handleCancel = () => {
    $state.go(STATES.MEMBER_PROFILE, { scrollToSection: section });
  };

  return (
    <PageContainer>
      <Page noPadding>
        <PageHeader heading="Your Personal Details" />
        {state.loading && <LoadingSpinner />}
        {!state.loading && (
          <Form name="profileDetails" noValidate onSubmit={handleSubmit}>
            <FormSectionTitle>Personal Details</FormSectionTitle>
            <Row>
              <NameField
                fieldName="firstName"
                label="First Name*"
                autocomplete="given-name"
                initialValue={reduxState.firstName}
                updateValue={updateUserDetails('firstName')}
                updateError={updateFormFieldError}
                updateTouched={updateFormFieldTouched}
                touched={state.form.firstName.touched}
                formSubmitted={state.formSubmitted}
                halfWidth
              />
              <NameField
                fieldName="lastName"
                label="Last Name*"
                autocomplete="family-name"
                initialValue={reduxState.lastName}
                updateValue={updateUserDetails('lastName')}
                updateError={updateFormFieldError}
                updateTouched={updateFormFieldTouched}
                touched={state.form.lastName.touched}
                formSubmitted={state.formSubmitted}
                halfWidth
              />
            </Row>
            <Row noRightMargin>
              <GenderSelect
                genders={state.genders}
                gender={reduxState.gender}
                update={updateProfileDetails('gender')}
                updateTouched={updateFormFieldTouched}
                halfWidth
              />
              <CountryOfOrigin
                countries={reduxState.countryOptions}
                country={reduxState.countryOfOrigin}
                updateCountry={updateProfileDetails('countryOfOrigin')}
                halfWidth
              />
            </Row>
            <Ethnicity
              ethnicities={state.ethnicities}
              ethnicity={reduxState.ethnicity}
              updateEthnicity={updateProfileDetails('ethnicity')}
              ethnicityConsent={reduxState.ethnicityConsent}
              updateEthnicityConsent={updateProfileDetails('ethnicityConsent')}
              ethnicityTouched={state.form.ethnicity.touched}
              ethnicityConsentTouched={state.form.ethnicityConsent.touched}
              updateTouched={updateFormFieldTouched}
              ethnicityError={state.form.ethnicity.error}
              ethnicityConsentError={state.form.ethnicityConsent.error}
              updateError={updateFormFieldError}
              formSubmitted={state.formSubmitted}
            />
            <FormSectionTitle>Location</FormSectionTitle>
            <LocationSelectWrapper
              noBottomMargin={
                reduxState.address && reduxState.address.state && true
              }
            >
              <LocationSelect
                address={reduxState.address}
                queryFunction={PlaceSearchService.autocomplete}
                updateAddress={updateAddressDetails}
                updateError={updateFormFieldError}
                updateTouched={updateFormFieldTouched}
                formSubmitted={state.formSubmitted}
              />
              {reduxState.address && (
                <Row>
                  {reduxState.address.country &&
                    reduxState.address.country.name && (
                      <BaseTextField
                        label="Country of Residence"
                        value={reduxState.address.country.name}
                        disabled
                        halfWidth
                      />
                    )}
                  {reduxState.address.state && (
                    <BaseTextField
                      label="State / Province"
                      value={reduxState.address.state}
                      disabled
                      halfWidth
                    />
                  )}
                </Row>
              )}
            </LocationSelectWrapper>
            <FormError show={state.formSubmitted && !formValid(state.form)}>
              {FORM_ERROR_MESSAGE}
            </FormError>
            <ButtonContainer>
              <InverseButton
                type="button"
                icon="ClearIcon"
                onClick={handleCancel}
              >
                CANCEL
              </InverseButton>
              <SolidButton type="submit" icon="CheckSharpIcon">
                SAVE
              </SolidButton>
            </ButtonContainer>
          </Form>
        )}
      </Page>
    </PageContainer>
  );
};

InlineEditProfile.propTypes = {
  GoogleMapService: PropTypes.object.isRequired,
  PlaceSearchService: PropTypes.object.isRequired,
  API: PropTypes.object.isRequired,
  $state: PropTypes.object.isRequired,
  $scope: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired
};

const Wrapper = ({
  $ngRedux,
  GoogleMapService,
  PlaceSearchService,
  API,
  $state,
  $scope,
  section
}) => {
  return (
    <Provider store={$ngRedux}>
      <ThemeProvider theme={theme}>
        <InlineEditProfile
          GoogleMapService={GoogleMapService}
          PlaceSearchService={PlaceSearchService}
          API={API}
          $state={$state}
          $scope={$scope}
          section={section}
        />
      </ThemeProvider>
    </Provider>
  );
};

Wrapper.propTypes = {
  $ngRedux: PropTypes.object.isRequired,
  GoogleMapService: PropTypes.object.isRequired,
  PlaceSearchService: PropTypes.object.isRequired,
  API: PropTypes.object.isRequired,
  $state: PropTypes.object.isRequired,
  $scope: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired
};

export default angular
  .module('wc.components.inlineEdit.reactProfile', [])
  .component(
    'reactInlineEditProfile',
    react2angular(
      Wrapper,
      ['section'],
      [
        '$ngRedux',
        'GoogleMapService',
        'PlaceSearchService',
        'API',
        '$state',
        '$scope'
      ]
    )
  );
