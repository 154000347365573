import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ConversationSummary } from '../../summary/ConversationSummary';

export const ConversationList = ({
  conversations,
  $state,
  $scope,
  ChatService,
  MessengerService
}) => {
  return (
    <div className="WcMessengerConversationList">
      {conversations && conversations.length
        ? conversations.map((conversation, index) => (
            <ConversationSummary
              key={index}
              conversation={conversation}
              $state={$state}
              $scope={$scope}
              ChatService={ChatService}
              MessengerService={MessengerService}
            />
          ))
        : null}
    </div>
  );
};

ConversationList.propTypes = {
  conversations: PropTypes.arrayOf(PropTypes.object),
  $state: PropTypes.any,
  $scope: PropTypes.any,
  ChatService: PropTypes.any,
  MessengerService: PropTypes.any
};
