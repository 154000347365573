import React, { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { default as SavedItemContent } from '../SavedItem';
import EducationForm from '../EducationForm';
import DeleteButton from '../../buttons/DeleteButton';
import EditButton from '../../buttons/EditButton';
import Modal from '../../Modal';

const SavedItem = styled.div`
  border-bottom: ${props =>
    props.isExpanded
      ? ''
      : `1px solid ${props.theme.palette.extraColors.inputBorder}`};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: ${props => (props.isExpanded ? 'column' : 'row')}
  padding: 18px 0;
`;

const SavedEducation = ({
  education,
  educationIndex,
  onSave,
  removeItem,
  isInitialForm,
  qualifications,
  dispatch,
  isExpanded,
  isEducationUpdating,
  showBackToProfile,
  backToProfile
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleEdit = () => {
    dispatch({ type: 'EXPAND', id: education.id });
  };

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleCancel = useCallback(() => {
    dispatch({ type: 'CANCEL', id: education.id });
  }, [dispatch, education]);

  const handleSave = async educationToSubmit => {
    await onSave(educationToSubmit);
  };

  const handleRemoveItem = () => {
    handleModalClose();
    removeItem(educationIndex);
  };

  return (
    <SavedItem isExpanded={isExpanded}>
      {!isExpanded && (
        <Fragment>
          <SavedItemContent education={education} />
          <EditButton onClick={handleEdit} />
          <DeleteButton onClick={handleModalOpen} />
        </Fragment>
      )}
      {isExpanded && (
        <EducationForm
          isEducationUpdating={isEducationUpdating}
          showBackToProfile={showBackToProfile}
          backToProfile={backToProfile}
          cancelForm={handleCancel}
          onSave={handleSave}
          isInitialForm={isInitialForm}
          qualifications={qualifications}
          educationData={education}
        />
      )}
      <Modal
        contentLabel="Delete Education"
        isOpen={modalIsOpen}
        heading="Are you sure?"
        cancelAction={{ text: 'CANCEL', onClick: handleModalClose }}
        confirmAction={{ text: 'DELETE', onClick: handleRemoveItem }}
      >
        <p>
          Deleting this item cannot be undone.
          <br />
          Do you want to proceed?
        </p>
      </Modal>
    </SavedItem>
  );
};

SavedEducation.propTypes = {
  education: PropTypes.object.isRequired,
  educationIndex: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  isInitialForm: PropTypes.bool.isRequired,
  qualifications: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isEducationUpdating: PropTypes.bool.isRequired,
  showBackToProfile: PropTypes.bool,
  backToProfile: PropTypes.func
};

export default React.memo(SavedEducation);
