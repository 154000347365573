import angular from 'angular';
import ClientCompensationDialog from './clientcompensation/ClientCompensation.module';
import LonglistModule from './longlist/Longlist.module';
import GroupDialog from './group/GroupDialog.module';
import SignoutDialog from './SignoutDialog.module';
import DialogService from './Dialog.service';
import Dialog from './dialog';
import DownloadDialog from './download';
import NotesDialog from './notes';
import MemberStatusDialog from './memberstatus/MemberStatusDialog.service';

const dependencies = [
  ClientCompensationDialog.name,
  LonglistModule.name,
  GroupDialog.name,
  SignoutDialog.name,
  Dialog.name,
  DownloadDialog.name,
  NotesDialog.name,
  MemberStatusDialog.name
];

export default angular
  .module('wc.components.dialogs', dependencies)
  .service(DialogService.NAME, DialogService);
