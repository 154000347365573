import React from 'react';
import PropTypes from 'prop-types';

export const ParagraphSeparator = ({ className, children }) => {
  return <div className={`WcParagraphSeparator ${className}`}>{children}</div>;
};

ParagraphSeparator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object
};
