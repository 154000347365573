import angular from 'angular';

import './styles.scss';
import NotificationsListController from './controller';
import templateUrl from './template.html';

export default angular
  .module('wc.components.notifications.list', [])
  .component('wcNotificationsList', {
    controller: NotificationsListController,
    templateUrl: templateUrl
  });
