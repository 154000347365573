import angular from 'angular';

import Debounce from '../../inputs/debounce';
import './IntegerRange.scss';
import templateUrl from './IntegerRange.html';

const IntegerRange = {
  NAME: 'wcIntegerRange',
  bindings: {
    minLabel: '@',
    maxLabel: '@',
    minModel: '=',
    maxModel: '=',
    title: '@'
  },
  templateUrl: templateUrl
};

export default angular
  .module('wc.inputGroups.integerRange', [Debounce.name])
  .component(IntegerRange.NAME, IntegerRange);
