import React from 'react';
import PropTypes from 'prop-types';
import angular from 'angular';
import { react2angular } from 'react2angular';

export const FixedCard = ({ children }) => {
  return (
    <div className='WcFixedCard'>{children}</div>
  )
}

FixedCard.propTypes = {
  children: PropTypes.element
};

export default angular
  .module('wc.components.cards.fixedCard', [])
  // @ts-ignore
  .component(
    'reactWcFixedCard',
    react2angular(FixedCard, ['children'])
  );
