import './Progress.scss';
import ProgressTemplateUrl from './Progress.html';

const inject = ['$log'];

let ProgressDirective = $log => {
  return {
    restrict: 'E',
    scope: {
      units: '@',
      completed: '=',
      total: '='
    },
    templateUrl: ProgressTemplateUrl,
    link: scope => {
      scope.$watch('completed', () => {
        checkValidInputs(scope.completed, scope.total, scope);

        if (scope.completed < scope.total) {
          scope.percentage = (scope.completed * 100.0) / scope.total;
        } else {
          scope.percentage = 100;
          scope.completed = scope.total;
        }
      });
    }
  };

  function checkValidInputs(completed, total, scope) {
    if (isNaN(parseFloat(completed)) || !isFinite(completed)) {
      $log.error(
        `[${ProgressDirective.NAME}] Completed must be a valid number`
      );
      scope.completed = 0;
    }
    if (completed < 0) {
      $log.error(
        `[${ProgressDirective.NAME}] Completed cannot be less than zero`
      );
      scope.completed = 0;
    }
    if (isNaN(parseFloat(total)) || !isFinite(total)) {
      $log.error(`[${ProgressDirective.NAME}] Total must be a valid number`);
      scope.total = 1;
    }
    if (total <= 0) {
      $log.error(`[${ProgressDirective.NAME}] Total cannot be zero or less`);
      scope.total = 1;
    }
  }
};

ProgressDirective.NAME = 'wcProgress';
ProgressDirective.$inject = inject;

export default ProgressDirective;
