import STATES from '../../../../services/States';
import { FORM_ERROR_MESSAGE } from '../../../../services/FormErrorMessages';
import RegistrationBaseController from '../RegistrationBase.controller';

class RemunerationController extends RegistrationBaseController {
  /*@ngInject*/
  constructor(
    $mdConstant,
    $scope,
    $state,
    $timeout,
    API,
    CurrencyService,
    PreferredCurrencyService,
    $q
  ) {
    super($state, API);
    this.$mdConstant = $mdConstant;
    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.API = API;
    this.CurrencyService = CurrencyService;
    this.PreferredCurrencyService = PreferredCurrencyService;
    this.setSeparatorKeys();
    this.remuneration = { other: [], otherFinancialAmount: null };
    this.formErrorMessage = FORM_ERROR_MESSAGE;

    this.previousState = STATES.MEMBER_REGISTRATION.CURRENT_POSITION;
    this.nextState = STATES.MEMBER_REGISTRATION.ASPIRATIONS;

    this.$scope.$watch(
      // TODO: This should live in a service
      this.getTotalCompensation.bind(this),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.totalCompensation = newValue;
        }
      },
      true
    );

    this.currencies = [];
    let currenciesPromise = API.Currencies.getList().then(currencies => {
      this.currencies = this.CurrencyService.currencyListToMap(currencies);
      this.selectedCurrency = this.PreferredCurrencyService.getPreferredCurrency(
        currencies
      );
    });

    let profilePromise = API.retrieveMyProfile().then(profile => {
      this.profile = profile;
      if (!profile || profile.noCompensation === true) {
        this.nextTab();
      }
    });

    $q.all([currenciesPromise, profilePromise])
      .then(this.handleRemuneration.bind(this))
      .catch(this.handleError.bind(this));
  }

  setSeparatorKeys() {
    this.separatorKeys = [
      this.$mdConstant.KEY_CODE.ENTER,
      this.$mdConstant.KEY_CODE.TAB,
      this.$mdConstant.KEY_CODE.COMMA
    ];
  }

  // TODO: This should live in a service
  numberOrZero(numberish) {
    let number = +numberish;
    return isNaN(number) ? 0 : number;
  }

  getTotalCompensation() {
    let amounts = [
      this.remuneration.salaryAmount,
      this.remuneration.bonusAmount,
      this.remuneration.longTermCashAmount,
      this.remuneration.stockAndOptionsAmount,
      this.remuneration.otherFinancialAmount
    ];
    return amounts.reduce(
      (a, b) => this.numberOrZero(a) + this.numberOrZero(b),
      0
    );
  }

  handleRemuneration() {
    if (this.profile.remuneration) {
      this.API.restangularize(this.profile.remuneration)
        .get()
        .then(this.setRemuneration.bind(this))
        .then(this.setLoading.bind(this, false))
        .then(this.forceCurrencySymbolToResize.bind(this))
        .catch(this.handleError.bind(this));
    } else {
      this.setLoading(false);
      this.forceCurrencySymbolToResize();
    }
  }

  //TODO: Very hacky
  forceCurrencySymbolToResize() {
    let selectedCurrency = this.selectedCurrency;
    this.selectedCurrency = '';
    this.$timeout(() => (this.selectedCurrency = selectedCurrency), 10);
  }

  setRemuneration(remuneration) {
    this.remuneration = remuneration;
    this.selectedCurrency = this.currencies[
      remuneration.totalCompensationCurrency
    ];
    this.totalCompensation = this.getTotalCompensation();
  }

  setCurrencyFields(remuneration) {
    let currencyFields = [
      'bonusCurrency',
      'buyoutCurrency',
      'longTermCashCurrency',
      'stockAndOptionsCurrency',
      'salaryCurrency',
      'otherFinancialCurrency',
      'totalCompensationCurrency'
    ];
    currencyFields.forEach(
      cf => (remuneration[cf] = this.selectedCurrency.code)
    );
    return remuneration;
  }

  submit() {
    if (this.form.$valid) {
      this.setLoading(true);
      let data = this.remuneration.plain
        ? this.remuneration.plain()
        : this.remuneration;
      let danglingOther = $('[name="otherPackageElements"] input').val();
      if (
        danglingOther &&
        this.remuneration.other.indexOf(danglingOther) === -1
      ) {
        this.remuneration.other.push(danglingOther);
      }
      data.other = this.remuneration.other;
      data.otherFinancialAmount = this.remuneration.otherFinancialAmount
        ? this.remuneration.otherFinancialAmount
        : null;
      data = this.setCurrencyFields(data);
      let save = this.remuneration.url
        ? this.API.restangularize(this.remuneration).patch
        : this.API.Remuneration.post;
      save(data)
        .then(this.nextTab.bind(this))
        .catch(this.handleError.bind(this));
    }
  }
}

RemunerationController.controllerAs = 'remunerationCtrl';
RemunerationController.NAME = 'RemunerationController';
export default RemunerationController;
