import angular from 'angular';
import Chips from '../../chips/Chips.module';
import ChipsSelectGroup from './ChipsSelectGroup.directive';
import OnBlur from '../behaviours/OnBlur.module';
import OnRequired from '../behaviours/OnRequired.module';
import BindShiftFocusFunctionToCtrl from '../behaviours/BindShiftFocusFunctionToCtrl';

const dependencies = [
  Chips.name,
  OnBlur.name,
  OnRequired.name,
  BindShiftFocusFunctionToCtrl.name
];

export default angular
  .module('dfComponents.inputs.chipsSelectGroup', dependencies)
  .directive(ChipsSelectGroup.NAME, ChipsSelectGroup);
