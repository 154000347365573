import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileOverviewProfessionalSummary } from './MemberProfileOverviewProfessionalSummary';

export default angular
  .module('wc.components.MemberProfileOverviewProfessionalSummary', [])
  // @ts-ignore
  .component(
    'reactMemberProfileOverviewProfessionalSummary',
    react2angular(MemberProfileOverviewProfessionalSummary, [
      'member',
      'onEdit',
      'stickyEdit'
    ])
  );
