import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { react2angular } from 'react2angular';
import angular from 'angular';
import { OpaqueActionBarScrollContainer } from '../../../../../components/react/action-bar-view/OpaqueActionBarScrollContainer';
import { ActionBarTableContainer } from '../../../../../components/react/action-bar-view/ActionBarTableContainer';
import { OpaqueActionBarView } from '../../../../../components/react/action-bar-view/OpaqueActionBarView';
import { MainActionBarView } from '../../../../../components/react/action-bar-view/MainActionBarView';
import { ActionBar } from '../../../../../components/react/action-bar/ActionBar';
import { ActionBarIconButton } from '../../../../../components/react/action-bar/ActionBarIconButton/IconButton';
import { Loading } from '../../../../../components/react/loading';
import { ViewTitle } from '../../../../../components/react/view-title/ViewTitle';
import { SortableTable } from '../../../../../components/react/sortable-table/SortableTable';
import { Alert } from '../../../../../components/react/alert/Alert';
import { default as SolidButton } from '../../../../../components/react/buttons/SolidButton';
import STATES from '../../../../../services/States';
import { ThemeProvider } from 'styled-components';
import theme from '../../../../../../common/styles/theme';
import './styles.scss';

const Wrapper = ({ API, LonglistDialogService, $state }) => {
  const [endpoint, setEndpoint] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [statusChoices, setStatusChoices] = useState([]);
  const [statusDefault, setStatusDefault] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    ordering: '-date'
  });
  const [ordering, setOrdering] = useState({
    field: 'date',
    reverse: true
  });

  useEffect(() => {
    readData();
  }, []);

  /* Sortable Configuration */

  const tableHeaders = [
    {
      name: 'Name',
      key: 'name',
      sortable: true,
      sortingKey: 'name',
      include: 'NameCell',
      classes: ['BoldCell', 'LonglistList__NameCell'],
      callback: id => {
        $state.go(STATES.LONGLIST_DETAIL, {
          lid: id
        });

        return;
      }
    },
    {
      name: 'Status',
      key: 'status',
      sortable: true,
      sortingKey: 'status',
      include: 'StatusCell',
      classes: ['LonglistList__StatusCell']
    },
    {
      name: 'Date',
      key: 'date',
      sortable: true,
      sortingKey: 'date',
      include: 'DateCell',
      filter: { type: 'date', args: ['MMM DD, yyyy'] },
      classes: ['LonglistList__DateCell']
    },
    {
      name: 'Profiles',
      key: 'memberCount',
      sortable: true,
      sortingKey: 'member_count',
      classes: ['LonglistList__MemberCountCell']
    },
    {
      include: 'OverflowMenuCell',
      sortable: false,
      classes: ['MenuCell']
    }
  ];

  const getExtra = () => {
    const editOptions = [
      {
        text: 'Edit',
        name: 'Edit',
        callback: showEditDialog,
        iconFirst: true,
        icon: 'zmdi-edit'
      }
    ];

    let options = [...editOptions];

    options.push({
      text: 'Share',
      name: 'Share',
      callback: showShareDialog,
      iconFirst: true,
      icon: 'zmdi-share'
    });

    options.push({
      text: 'Delete',
      name: 'Delete',
      callback: showDeleteDialog,
      iconFirst: true,
      icon: 'zmdi-delete'
    });

    return {
      statusChoices,
      overflowMenu: options,
      editOverflowMenu: editOptions,
      updateStatus
    };
  };

  const updateOrdering = (field, reverse) => {
    const reverseText = reverse ? '-' : '';
    setOrdering({
      field,
      reverse
    });

    setFilters({
      ordering: `${reverseText}${field}`
    });

    readData();
  };

  /* Dialog Implementations */

  const showAddNewDialog = async () => {
    const data = await LonglistDialogService.showNewLonglistDialog(
      null,
      statusChoices,
      statusDefault,
      []
    );

    await create(data);
  };

  const showEditDialog = async (event, item) => {
    const data = await LonglistDialogService.showEditLonglistDialog(
      event,
      item,
      statusChoices,
      []
    );

    await update(data);
  };

  const showDeleteDialog = async (event, item) => {
    try {
      await LonglistDialogService.showDeleteLonglistDialog(event, item.name);

      deleteData(item);
    } catch (error) {
      API.handleError(error);
    }
  };

  const showShareDialog = async (event, item) => {
    try {
      await LonglistDialogService.showShareLonglistDialog(event, item);
    } catch (error) {
      API.handleError(error);
    }
  };

  /* CRUDs */

  const create = async data => {
    try {
      await endpoint.post(data);
      await readData();
    } catch (error) {
      API.handleError(error);
    }
  };

  const readData = async () => {
    setIsLoading(true);

    const retrieveMyLonglistsEndpointData = await API.retrieveMyLonglistsEndpoint();
    setEndpoint(retrieveMyLonglistsEndpointData);
    if (retrieveMyLonglistsEndpointData === null) {
      setIsLoading(false);
    } else {
      const options = await retrieveMyLonglistsEndpointData.options();

      if (options) {
        setStatusChoices(options.actions.POST.status.choices);
        setStatusDefault(options.actions.POST.status.default);
      }

      const data = await retrieveMyLonglistsEndpointData.getList(filters);
      setDataSource(data);
      setIsLoading(false);
    }
  };

  const update = async data => {
    delete data.owner;

    try {
      await API.restangularize(data).patch({
        name: data.name,
        status: data.status,
        category: data.category
      });

      await readData();
    } catch (error) {
      API.handleError(error);
    }
  };

  const deleteData = async data => {
    await API.restangularize(data).remove();
    await readData();
  };

  const updateStatus = async (index, status) => {
    await API.restangularize(this.longlists[index]).patch({
      status
    });

    await readData();
  };

  return (
    <ThemeProvider theme={theme}>
      <OpaqueActionBarView>
        <Loading loading={isLoading}>
          <ActionBar className={'md-menu-toolbar'}>
            <span className="spanFlex" />
            {endpoint !== null && (
              <ActionBarIconButton
                fontSet="zmdi zmdi-playlist-plus zmdi-hc-lg"
                onClick={showAddNewDialog}
                title="Create project"
                textButton={true}
                text="Create New Project"
                ariaLabel="Create project"
              />
            )}
          </ActionBar>
          <MainActionBarView>
            <OpaqueActionBarScrollContainer className={'TableContainer'}>
              <div className="LonglistList__TabHeader">
                <ViewTitle text="Projects" />
              </div>
              <div className="LonglistList__TabContent">
                <ActionBarTableContainer>
                  <SortableTable
                    className="WcLonglistList__Table"
                    ordering={ordering}
                    headers={tableHeaders}
                    data={dataSource}
                    extra={getExtra()}
                    onUpdateOrdering={updateOrdering}
                  />
                </ActionBarTableContainer>
                {dataSource.length === 0 && (
                  <div className="WcAlert">
                    <Alert
                      iconClass="zmdi zmdi-info-outline"
                      message="There are currently no Projects"
                    />
                    {endpoint && (
                      <SolidButton
                        type="button"
                        onClick={showAddNewDialog}
                        ariaLabel="Create project"
                      >
                        Create project
                      </SolidButton>
                    )}
                  </div>
                )}
              </div>
            </OpaqueActionBarScrollContainer>
          </MainActionBarView>
        </Loading>
      </OpaqueActionBarView>
    </ThemeProvider>
  );
};

Wrapper.propTypes = {
  API: PropTypes.object,
  LonglistDialogService: PropTypes.object,
  $state: PropTypes.object
};

export default angular
  .module('wc.components.reactLonglistsList', [])
  .component(
    'reactLonglistsList',
    react2angular(Wrapper, [], ['API', 'LonglistDialogService', '$state'])
  );
