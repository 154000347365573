import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ActionBarSeparator = ({ className, children }) => {
  return <div className={`WcActionBarSeparator ${className}`}>{children}</div>;
};

ActionBarSeparator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object
};
