import STATES from '../../../../../services/States';

import AspirationsTemplate from './Aspirations.html';
import AspirationsController from './Aspirations.controller';
import PersonalityTemplate from './Personality.html';
import PersonalityController from './Personality.controller';
import UploadsTemplate from './Uploads.html';
import UploadsController from './Uploads.controller';

let MembersRegistrationRouting = /*@ngInject*/ (
  $stateProvider,
  $urlRouterProvider
) => {
  $stateProvider
    .state(STATES.MEMBER_REGISTRATION.ABOUT_YOU, {
      url: '/about-you',
      template: '<ui-view></ui-view>'
    })

    .state(STATES.MEMBER_REGISTRATION.ASPIRATIONS, {
      url: '/aspirations',
      templateUrl: AspirationsTemplate,
      controller: AspirationsController,
      controllerAs: AspirationsController.controllerAs
    })

    .state(STATES.MEMBER_REGISTRATION.PERSONALITY, {
      url: '/personality',
      templateUrl: PersonalityTemplate,
      controller: PersonalityController,
      controllerAs: PersonalityController.controllerAs
    })

    .state(STATES.MEMBER_REGISTRATION.UPLOADS, {
      url: '/uploads',
      templateUrl: UploadsTemplate,
      controller: UploadsController,
      controllerAs: UploadsController.controllerAs
    });

  $urlRouterProvider.when(
    '/members/registration/about-you',
    '/members/registration/about-you/aspirations'
  );
};

export default MembersRegistrationRouting;
