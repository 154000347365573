import STATES from '../../../../services/States';
import GroupsController from './Groups.controller';
import Groups from './Groups.html';
import './Groups.scss';

/*@ngInject*/
function routeMemberGroups($stateProvider) {
  $stateProvider.state(STATES.MEMBER_GROUPS, {
    url: '/groups',
    templateUrl: Groups,
    controller: GroupsController,
    controllerAs: GroupsController.controllerAs,
    params: {
      myGroups: false
    },
    resolve: {
      /*@ngInject*/
      redirect: function(RedirectService) {
        // Only Members should be allowed to access Groups.
        //
        // Probably this should be narrowed down to complete Members, as incomplete Members are
        // supposed to be redirected to Registration.
        //
        // Refactoring first, can tighten up later.
        return RedirectService.redirectIfNotMemberPublic();
      }
    }
  });
}

export default angular
  .module('wc.members.memberArea.groups', [])
  .controller(GroupsController.name, GroupsController)
  .config(routeMemberGroups);
