import angular from 'angular';
import { react2angular } from 'react2angular';
import PlainButton from './PlainButton';

export default angular
  .module('wc.components.ReactPlainButton', [])
  // @ts-ignore
  .component(
    'reactPlainButton',
    react2angular(PlainButton, ['label', 'className', 'onClick'])
  );
