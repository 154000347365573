import angular from 'angular';
import template from './template.html';

import STATES from '../../../../services/States';

class InsightsHobbiesController {
  /*@ngInject*/
  constructor(API, $state) {
    this.API = API;
    this.$state = $state;
    this.toResolve = 1;

    this.API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .catch(this.handleError.bind(this));
  }

  setProfile(profile) {
    this.profile = profile;
    this.toResolve--;
  }

  cancel() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'insights-hobbies'
    });
  }

  submit() {
    if (this.form.$valid) {
      this.toResolve++;

      let patchProfile = {
        hobbies: this.API.objectListToIdsSerializerField(this.profile.hobbies)
      };

      this.API.restangularize(this.profile)
        .patch(patchProfile)
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleSuccess() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'insights-hobbies'
    });
  }

  handleError(error) {
    this.toResolve = 0;
    this.API.handleError()(error);
  }

  isLoaded() {
    return this.toResolve === 0;
  }
}

const wcInlineEditInsightsHobbies = {
  controller: InsightsHobbiesController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.insights.hobbies', [])
  .component('wcInlineEditInsightsHobbies', wcInlineEditInsightsHobbies);
