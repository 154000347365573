import './styles.scss';
import templateUrl from './template.html';

class SidebarController {
  /*@ngInject*/
  constructor(wcSidebar) {
    this.wcSidebar = wcSidebar;
  }

  /* These are fired when md-is-open changes */

  get isOpen() {
    return this.wcSidebar.isOpen(this.sidebarId);
  }

  set isOpen(bool) {
    if (bool) {
      this.wcSidebar.open(this.sidebarId);
    } else {
      this.wcSidebar.close(this.sidebarId);
    }
  }
}

export default angular
  .module('wc.components.sidebars.sidebar', [])
  .component('wcSidebar', {
    bindings: {
      sidebarId: '@'
    },
    controller: SidebarController,
    templateUrl: templateUrl,
    transclude: true
  });
