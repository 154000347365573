export const initalState = {
  qualifications: [],
  genders: [],
  ethnicities: [],
  mapService: {},
  loading: true,
  formSubmitted: false,
  form: {
    ethnicity: {
      error: false,
      touched: false
    },
    ethnicityConsent: {
      error: false,
      touched: false
    },
    gender: {
      error: false,
      touched: false
    },
    firstName: {
      error: true,
      touched: false
    },
    lastName: {
      error: true,
      touched: false
    },
    email: {
      error: true,
      touched: false
    },
    mobileNumber: {
      error: true,
      touched: false
    },
    mobileNumberSharing: {
      error: false,
      touched: false
    },
    countryOfOrigin: {
      error: false,
      touched: false
    },
    address: {
      error: true,
      touched: false
    }
  }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'FORM_SUBMIT_ERROR':
      return {
        ...state,
        loading: false,
        formSubmitted: true
      };
    case 'UPDATE_FORM_FIELD':
      return {
        ...state,
        form: {
          ...state.form,
          [action.field]: {
            ...state.form[action.field],
            ...action.payload
          }
        }
      };
    case 'SET_FORM_SUBMITTED':
    case 'SET_LOADING':
    case 'SET_GENDERS':
    case 'SET_ETHNICITIES':
    case 'SET_MAP_SERVICE':
    case 'SET_QUALIFICATIONS':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
