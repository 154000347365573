import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MainActionBarView = ({ children }) => {
  return (
    <main className="WcActionBarMain">
      <div className="WcActionBarMain__View">{children}</div>
    </main>
  );
};

MainActionBarView.propTypes = {
  children: PropTypes.any
};
