import GroupDialogController from './GroupDialog.controller';
import GroupDialogTemplateUrl from './GroupDialog.html';
import InviteMembersToGroupDialogController from './InviteMembersToGroupDialog.controller';
import InviteMembersToGroupDialogTemplateUrl from './InviteMembersToGroupDialog.html';

import './InviteMembersToGroupDialog.scss';

class GroupDialog {
  /*@ngInject*/
  constructor(DialogService) {
    this.DialogService = DialogService;
  }

  showGroupDialog(locals, targetEvent) {
    let options = {
      controller: GroupDialogController,
      controllerAs: '$ctrl',
      templateUrl: GroupDialogTemplateUrl,
      clickOutsideToClose: true,
      locals,
      targetEvent
    };
    return this.DialogService.confirm(options);
  }

  showNewGroupDialog(event, initialPublic) {
    let locals = {
      title: `Create new group`,
      ariaLabel: 'Create new group',
      okAction: 'Create group',
      group: { public: initialPublic }
    };
    return this.showGroupDialog(locals, event);
  }

  leaveGroupDialog(group) {
    let privateTemplate = `<p>You will no longer be able to view this group and will have to request access to join again.</p>`;
    let commonTemplate = `<p>Are you sure you want to leave <span class="WcGroupDialog__LeaveGroupName">${
      group.name
    }</span>?</p>`;

    let locals = {
      title: `Leave Group`,
      ariaLabel: 'Leave group',
      content: group.isPrivate
        ? privateTemplate + commonTemplate
        : commonTemplate,
      okAction: 'Leave group'
    };
    return this.DialogService.showConfirmDialog(locals);
  }

  showInviteMemberToGroupsDialog(event, groups) {
    let options = {
      controller: InviteMembersToGroupDialogController,
      targetEvent: event,
      templateUrl: InviteMembersToGroupDialogTemplateUrl,
      locals: {
        selectedGroups: [],
        groups: groups,
        okAction: 'Invite',
        title: `Send invite`
      }
    };

    return this.DialogService.confirm(options);
  }

  showInviteSuccessDialog() {
    const locals = {
      title: 'Invite sent',
      content:
        'You have successfully invited this member to your group and they will receive a notification of your invite.',
      okAction: 'OK'
    };
    return this.DialogService.showAlertDialog(locals);
  }
}

GroupDialog.NAME = 'GroupDialogService';

export default GroupDialog;
