import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { MemberVisibleSetting } from '../../member-visible/MemberVisibleSetting';
import { SameCompanySetting } from '../../same-company-setting/SameCompanySetting';
import { CovisibilitySettings } from '../../covisibility/CovisibilitySettings';

export const MemberVisibilitySettingsTab = ({
  user,
  profile,
  visibility,
  covisibility,
  onCovisibilitySettingChange,
  covisibilityHanging,
  onSameCompanySettingChange,
  onMemberVisibleSettingChange,
  visibleToCurrentCompanyHanging,
  // TMP INJECT ANGULAR CONTROLLER
  angularController,
  DialogService,
  Restangular,
  API
}) => {
  return (
    <div>
      <p className="SettingsExplanation">
        You are in complete control over your visibility to both clients and
        members on Winter Circle.
      </p>
      {!visibility.toMembers && (
        <MemberVisibleSetting
          memberVisible={visibility.toMembers}
          // TMP bind angular controller to not lose this context
          onChange={onMemberVisibleSettingChange.bind(angularController)}
        />
      )}
      <SameCompanySetting
        visibleToCurrentCompany={visibility.toSamecompany}
        visibleToCurrentCompanyHanging={visibleToCurrentCompanyHanging}
        company={profile.currentPosition.company.name}
        // TMP bind angular controller to not lose this context
        onChange={onSameCompanySettingChange.bind(angularController)}
      />
      <CovisibilitySettings
        user={user}
        profile={profile}
        covisibility={covisibility}
        // TMP bind angular controller to not lose this context
        onChange={onCovisibilitySettingChange.bind(angularController)}
        covisibilityHanging={covisibilityHanging}
        DialogService={DialogService}
        Restangular={Restangular}
        API={API}
      />
    </div>
  );
};

MemberVisibilitySettingsTab.propTypes = {
  user: PropTypes.object,
  profile: PropTypes.object,
  visibility: PropTypes.object,
  covisibility: PropTypes.object,
  onCovisibilitySettingChange: PropTypes.func,
  covisibilityHanging: PropTypes.bool,
  onSameCompanySettingChange: PropTypes.func,
  onMemberVisibleSettingChange: PropTypes.func,
  visibleToCurrentCompanyHanging: PropTypes.bool,
  angularController: PropTypes.any,
  DialogService: PropTypes.any,
  Restangular: PropTypes.any,
  API: PropTypes.any
};
