import controller from './CompletePositionFieldset.controller';
import template from './CompletePositionFieldset.html';

export default {
  bindings: {
    currentAllowed: '<',
    label: '@',
    position: '<'
  },
  controller: controller,
  NAME: 'wcCompletePositionFieldset',
  require: {
    formController: '^form'
  },
  templateUrl: template,
  transclude: true
};
