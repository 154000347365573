import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Switch from '../../../profile/basics/Switch';

const tootlipContent = `<div class="SameCompanySetting__Tooltip">
  <div class="SameCompanySetting__Tooltip_Item">
    Your profile is hidden, by default,
    <br />
    from anyone in your current company.
    <br />
    <br />
    By turning this setting on,
    <br />
    you will be visible to members and clients
    <br />
    from this company.
  </div>

  <div class="SameCompanySetting__Tooltip_Item">
    <b>Please note:</b>
    <br />
    Your profile will only be visible
    <br />
    to members at this company
    <br />
    if they have also turned their visibility on.
  </div>
</div>`;

export const SameCompanySetting = ({
  visibleToCurrentCompany,
  visibleToCurrentCompanyHanging,
  company,
  onChange
}) => {
  const [
    visibleToCurrentCompanyState,
    setVisibleToCurrentCompanyState
  ] = useState(visibleToCurrentCompany);

  return (
    <div className="SameCompanySetting__Row">
      <div className="SameCompanySetting__Name">
        <div className="SameCompanySetting__Header">
          <div className="SameCompanySetting__Label">
            Make my profile visible to&#160;
            {company && <span>{company},&#160;</span>}my current employer
            <button
              className="SameCompanySettingOption__Help"
              type="button"
              id="same-company-setting-tooltip-btn"
            >
              <span className="zmdi zmdi-help SameCompanySettingOption__HelpIcon" />
            </button>
            <ReactTooltip
              anchorId="same-company-setting-tooltip-btn"
              style={{
                backgroundColor: '#e6e6e6',
                padding: 0,
                margin: 0,
                fontWeight: 500
              }}
              html={tootlipContent}
            />
          </div>
        </div>
        <div className="SameCompanySetting__Description">
          Allow members and client users working at{' '}
          {company ? <span>{company}</span> : <span>my current employer</span>}{' '}
          to see my profile.
        </div>
      </div>
      <span className="flex" />
      <Switch
        className="SameCompanySetting__Switch"
        disabled={visibleToCurrentCompanyHanging}
        checked={visibleToCurrentCompanyState}
        aria-label={`Allow members and client companies working at ${
          company ? company : 'my current employer'
        } to see my profile`}
        onChange={() => {
          onChange(!visibleToCurrentCompanyState);
          setVisibleToCurrentCompanyState(current => !current);
        }}
      />
    </div>
  );
};

SameCompanySetting.propTypes = {
  visibleToCurrentCompany: PropTypes.bool,
  visibleToCurrentCompanyHanging: PropTypes.bool,
  company: PropTypes.string,
  onChange: PropTypes.func
};
