import 'angular';
import './styles.scss';

class StickyHeaderTableController {
  /*@ngInject*/
  constructor($scope, $q, $timeout) {
    this.$timeout = $timeout;
  }
}

const StickyHeaderTable = {
  bindings: {
    headerData: '<',
    bodyData: '<'
  },
  controller: StickyHeaderTableController,
  template: `<ng-transclude class="StickyHeaderTable__Container"></ng-transclude>`,
  transclude: true
};

export default angular
  .module('wc.components.stickyHeaderTable', [])
  .component('wcStickyHeaderTable', StickyHeaderTable);
