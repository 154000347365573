import OptionsRadioGroupTemplateUrl from './OptionsRadioGroup.html';

const inject = ['API'];

let OptionsRadioGroup = API => {
  return {
    restrict: 'E',
    templateUrl: OptionsRadioGroupTemplateUrl,
    scope: {
      title: '@',
      endpoint: '=',
      model: '=',
      field: '@'
    },

    link: scope => {
      scope.options = [];

      scope.getOptions = () => {
        scope.endpoint
          .options()
          .then(scope.handleSuccess.bind(scope), scope.handleError.bind(scope));
      };

      scope.handleSuccess = options => {
        scope.options = options.actions.POST[scope.field].choices;
      };

      scope.handleError = error => {
        API.handleError(error);
      };

      scope.getOptions();
    }
  };
};

OptionsRadioGroup.$inject = inject;
OptionsRadioGroup.NAME = 'optionsRadioGroup';

export default OptionsRadioGroup;
