import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfilePersonal } from './OwnMemberProfilePersonal';

export default angular
  .module('wc.components.OwnMemberProfilePersonal', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfilePersonal',
    react2angular(OwnMemberProfilePersonal, ['member'], ['$state'])
  );
