import angular from 'angular';

import TimePeriodGroups from '../../timeperiodinputs/TimePeriodGroups';
import './styles.scss';
import templateUrl from './template.html';

class ClientSearchFiltersController {
  /*@ngInject*/
  constructor(API, CompensationCurrency, CompensationsBlockedService, $scope) {
    this.API = API;
    this.CompensationCurrency = CompensationCurrency; // Used in template
    this.CompensationsBlockedService = CompensationsBlockedService;
    this.TimePeriodGroups = TimePeriodGroups;
    this.$scope = $scope;

    this.genderOptions = [
      { value: 'M', name: 'Male' },
      { value: 'F', name: 'Female' },
      { value: 'O', name: 'Other' }
    ];

    this.interestedInNonExecOptions = [
      { value: 'True', name: 'Interested in Non Exec' }
    ];

    this.API.retrieveMyProfile()
      .then(profile => {
        this.preferredCurrency = profile ? profile.preferredCurrency : null;
        if (!this.preferredCurrency && this.currencies) {
          this.preferredCurrency = this.currencies.USD;
        }
      })
      .catch(this.API.handleError());

    this.API.Qualifications.getList()
      .then(educationOptions => {
        this.educationOptions = educationOptions;
      })
      .catch(this.API.handleError());

    this.API.CurrencyFilterValues.getList().then(filterValues => {
      this.currencyFilterValues = filterValues.map(filterValue => {
        return { value: filterValue.value };
      });
    });

    this.handleClientWithCompensationsBlocked();
  }

  $onInit() {
    this.$scope.$on(
      'compensationBlockedChanged',
      this.handleClientWithCompensationsBlocked.bind(this)
    );
  }

  handleClientWithCompensationsBlocked() {
    this.CompensationsBlockedService.isReady.then(() => {
      if (this.CompensationsBlockedService.isBlocked()) {
        this.compensationsBlocked = true;
      }
    });
  }

  updateMinTotalCompensation(value) {
    this.filters.min_total_compensation = value;
  }

  updateMaxTotalCompensation(value) {
    this.filters.max_total_compensation = value;
  }
}

export default angular
  .module('wc.components.sidebars.clientsearchfilters', [])
  .component('wcClientSearchFilters', {
    bindings: {
      filters: '='
    },
    controller: ClientSearchFiltersController,
    templateUrl: templateUrl
  });
