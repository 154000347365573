import WcChipsTemplateUrl from './WcChips.html';

const WcChips = {
  NAME: 'wcChips',
  bindings: {
    ngModel: '=',
    separatorKeys: '<',
    placeholder: '@',
    hint: '@'
  },
  templateUrl: WcChipsTemplateUrl
};

export default WcChips;
