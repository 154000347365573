import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wc.components.searchBar', [])
  .component('wcSearchBar', {
    bindings: {
      inverseTheme: '<?',
      onFilter: '&',
      searchString: '=?',
      wcPlaceholder: '@?'
    },
    templateUrl: templateUrl
  });
