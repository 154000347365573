import React from 'react';
import PropTypes from 'prop-types';
import angular from 'angular';
import { react2angular } from 'react2angular';
import styled from 'styled-components';

import Switch from './Switch';

const TextDiv = styled.div`
  width: 80%;
`;

const SwitchDiv = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`;

const SettingsMobileSharing = ({ value, updateValue, loading }) => {
  const handleChange = event => {
    updateValue(event.target.checked);
  };

  return (
    <div className="ContactSharingSettings__Row">
      <TextDiv>
        <div className="ContactSharingSettings__Name">Share Phone Number</div>
        <div className="ContactSharingSettings__Description">
          Allow client companies to view my phone number
        </div>
      </TextDiv>
      <SwitchDiv>
        <Switch checked={value} onChange={handleChange} loading={loading} />
      </SwitchDiv>
    </div>
  );
};

SettingsMobileSharing.propTypes = {
  value: PropTypes.bool,
  updateValue: PropTypes.func,
  loading: PropTypes.bool
};

export default angular
  .module('wc.components.SettingsMobileSharing', [])
  .component(
    'reactSettingsMobileSharing',
    react2angular(SettingsMobileSharing, ['value', 'updateValue', 'loading'])
  );
