import angular from 'angular';
import { react2angular } from 'react2angular';
import { SortableTable } from './SortableTable';

export default angular
  .module('wc.components.ReactSortableTable', [])
  // @ts-ignore
  .component(
    'reactSortableTable',
    react2angular(SortableTable, [
      'headers',
      'ordering',
      'data',
      'extra',
      'className',
      'onUpdateOrderingField',
      'onUpdateOrderingReverse',
      'onInfiniteScroll'
    ])
  );
