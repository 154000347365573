import angular from 'angular';

import ClientCompensationDialogService from './ClientCompensation.service';

let ClientCompensationModule = angular
  .module('wc.components.dialogs.clientcompensation', [])
  .service(
    ClientCompensationDialogService.NAME,
    ClientCompensationDialogService
  );
export default ClientCompensationModule;
