import angular from 'angular';
import IconToggleLink from '../../buttons/icontogglelink';

import templateUrl from './template.html';

class ContainerController {
  /*@ngInject*/
  constructor() {
    this.buttons = [];
  }

  activateButton(buttonToActivate) {
    this.buttons.forEach(button => {
      button.deactivate();
    });

    buttonToActivate.activate();
  }

  register(navItemCtrl) {
    this.buttons.push(navItemCtrl);
  }
}

class LinkController {
  /*@ngInject*/
  constructor($scope, $state) {
    this.$state = $state;

    $scope.$watch(
      () => this.uiSref,
      () => {
        this.stateConfig = $state.get(this.uiSref);
      }
    );

    $scope.$watch(
      () => {
        return this.$state.current;
      },
      () => {
        this.isActive = this.checkIsActive();
      }
    );
  }

  $onInit() {
    this.$containerCtrl.register(this);
  }

  checkIsActive() {
    return this.matchesState() || this.matchesUrl();
  }

  matchesState() {
    if (!this.uiSref) {
      return false;
    }

    return this.$state.includes(this.uiSref);
  }

  matchesUrl() {
    if (!this.uiSref) {
      return false;
    }

    return this.$state.current.url.startsWith(this.stateConfig.url);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  _onClick() {
    this.$containerCtrl.activateButton(this);
  }
}

const SidenavIconLinks = {
  NAME: 'wcSidenavIconLinks',
  controller: ContainerController,
  template: '<div class="WcSidenavIconLinks" ng-transclude></div>',
  transclude: true
};

const SidenavIconLink = {
  NAME: 'wcSidenavIconLink',
  bindings: {
    iconClass: '@wcIconClass',
    ariaLabel: '@',
    label: '@',
    uiSref: '@wcUiSref',
    params: '<'
  },
  controller: LinkController,
  templateUrl: templateUrl,
  transclude: true,
  require: {
    $containerCtrl: '^wcSidenavIconLinks'
  }
};

const dependencies = [IconToggleLink.name];

export default angular
  .module('wc.components.sidenav.iconbuttons', dependencies)
  .component(SidenavIconLinks.NAME, SidenavIconLinks)
  .component(SidenavIconLink.NAME, SidenavIconLink);
