import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';

const CreateButton = ({ onClick, ariaLabel, className }) => {
  return (
    <div className={className}>
      <button
        className="md-fab WcCreateButton md-button"
        type="button"
        aria-label={ariaLabel || 'Create'}
        onClick={event => {
          event.persist();
          onClick(event);
        }}
      >
        <Add />
      </button>
    </div>
  );
};

CreateButton.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default CreateButton;
