import 'angular';
import SuggestOrganizationTemplateUrl from './SuggestOrganizationDialog.html';
import SuggestOrganizationDialogController from './SuggestOrganizationDialog.controller';

/*@ngInject*/
export default function SuggestOrganization(DialogService) {
  return {
    showSuggestDialog(
      targetEvent,
      model,
      readableOrganizationType,
      organizationType
    ) {
      let options = {
        controller: SuggestOrganizationDialogController,
        templateUrl: SuggestOrganizationTemplateUrl,
        locals: {
          organizationType: organizationType,
          readableOrganizationType: readableOrganizationType,
          model: model
        },
        targetEvent
      };
      return DialogService.confirm(options);
    }
  };
}

SuggestOrganization.NAME = 'SuggestOrganization';
