import angular from 'angular';
import './InputContainer.scss';

const InputContainer = {
  NAME: 'wcInputContainer',
  template:
    '<md-input-container class="WcInputContainer" ng-transclude></md-input-container>',
  transclude: true
};

export default angular
  .module('wc.components.inputcontainer', ['ngMaterial'])
  .component(InputContainer.NAME, InputContainer);
