import React, { useCallback, useEffect, useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import angular from 'angular';
import { react2angular } from 'react2angular';
import { Provider, useSelector, shallowEqual, useDispatch } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';

import { reducer, initalState } from './reducer';
import { Actions as ProfilesActions } from '../../../../../../../common/redux/profiles/actions';
import { Actions as UserActions } from '../../../../../../../common/redux/user/actions';
import theme from '../../../../../../../common/styles/theme';
import LoadingSpinner from '../../../../../../components/react/LoadingSpinner';
import NameField from '../../../../../../components/react/profile/basics/NameField';
import GenderSelect from '../../../../../../components/react/profile/GenderSelect';
import CountryOfOrigin from '../../../../../../components/react/profile/CountryOfOrigin';
import Ethnicity from '../../../../../../components/react/profile/ethnicity/Ethnicity';
import { EmailField } from '../../../../../../components/react/profile/basics/EmailField';
import { PhoneNumberField } from '../../../../../../components/react/profile/basics/PhoneNumberField';
import RegistrationMobileSharing from '../../../../../../components/react/profile/basics/RegistrationMobileSharing';
import LocationSelect from '../../../../../../components/react/profile/LocationSelect';
import EducationAccordion from '../../../../../../components/react/EducationsAccordion';
import BaseTextField from '../../../../../../components/react/inputs/TextField/BaseTextField';
import { default as BaseFormError } from '../../../../../../components/react/FormError';
import SolidButton from '../../../../../../components/react/buttons/SolidButton';
import STATES from '../../../../../../services/States';
import { FORM_ERROR_MESSAGE } from '../../../../../../components/react/utils/constants';
import FormSectionTitle from '../../../../../../components/react/FormSectionTitle';
import useProfileUpdater from '../../../../../../components/react/helpers/useProfileUpdater';
import formValid from '../../../../../../components/react/utils/formValid';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${FormSectionTitle}:not(:first-child) {
    margin-top: 36px;
  }
`;

const Row = styled.div`
  display: flex;

  & > * {
    margin-right: ${props => (props.noRightMargin ? '0' : '28px')};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const EmailContainer = styled.div`
  width: 48%;
`;

const LocationSelectWrapper = styled.div`
  margin-bottom: ${props => (props.noBottomMargin ? '0' : '18px')};
`;

const ContinueButtonContainer = styled.div`
  margin: 24px 0;
  text-align: right;
`;

const FormError = styled(BaseFormError)`
  margin-top: 15px;
`;

const RegistrationProfile = ({
  GoogleMapService,
  PlaceSearchService,
  API,
  $state,
  $scope
}) => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const reduxDispatch = useDispatch();
  const reduxState = useSelector(
    state => ({
      educations: state.profilesReducer.myProfile.educations,
      ethnicity: state.profilesReducer.myProfile.ethnicity,
      ethnicityConsent: state.profilesReducer.myProfile.ethnicityConsent,
      gender: state.profilesReducer.myProfile.gender,
      firstName: state.userReducer.user.firstName,
      lastName: state.userReducer.user.lastName,
      email: state.userReducer.user.email,
      mobileNumber: state.userReducer.user.mobileNumber,
      mobileNumberSharing: state.userReducer.user.mobileNumberSharing,
      countryOfOrigin: state.profilesReducer.myProfile.countryOfOrigin,
      countryOptions: state.profilesReducer.countryOptions,
      address: state.profilesReducer.myProfile.address,
      profileUrl: state.profilesReducer.myProfile.url
    }),
    shallowEqual
  );
  const [
    updateUserDetails,
    updateProfileDetails,
    updateAddressDetails
  ] = useProfileUpdater(reduxDispatch, reduxState, state, PlaceSearchService);

  const updateFormFieldError = useCallback(
    (field, error) => {
      dispatch({ type: 'UPDATE_FORM_FIELD', field, payload: { error } });
    },
    [dispatch]
  );

  const updateFormFieldTouched = useCallback(
    (field, touched) => {
      dispatch({ type: 'UPDATE_FORM_FIELD', field, payload: { touched } });
    },
    [dispatch]
  );

  useEffect(() => {
    const getData = async () => {
      const [
        googleMap,
        user,
        countries,
        profile,
        qualifications,
        ethnicities
      ] = await Promise.all([
        GoogleMapService.get(),
        API.User.get(),
        API.Countries.getList(),
        API.retrieveMyProfile(),
        API.Qualifications.getList(),
        API.Ethnicities.getList()
      ]);
      const {
        actions: {
          PUT: {
            gender: { choices: genderChoices }
          }
        }
      } = await API.restangularizeUrl(profile.url).options();
      const educations = profile.educations.map(education => {
        education.qualification = qualifications.find(
          qualification => qualification.url === education.qualification
        );
        return education;
      });

      if (profile.address) {
        const result = await API.restangularizeUrl(profile.address).get();
        if (result.country) {
          // This will mutate the property in result
          try {
            await API.getWithUrlAndHandle('country', result);
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }
        profile.address = result;
      }

      ProfilesActions.updateCountryOptions(reduxDispatch, countries);
      ProfilesActions.updateMyProfile(reduxDispatch, {
        ...profile,
        educations,
        ethnicity: profile.ethnicity,
        ethnicityConsent: !!profile.ethnicityConsent,
        gender: genderChoices.find(gender => gender.value === profile.gender)
      });
      UserActions.updateUser(reduxDispatch, {
        ...user,
        mobileNumberSharing: user.contactSharing
      });

      dispatch({
        type: 'SET_ETHNICITIES',
        payload: {
          ethnicities: ethnicities.map(ethnicity => ({
            id: ethnicity.id,
            name: ethnicity.name,
            url: ethnicity.url
          }))
        }
      });
      dispatch({ type: 'SET_QUALIFICATIONS', payload: { qualifications } });
      dispatch({ type: 'SET_GENDERS', payload: { genders: genderChoices } });
      dispatch({
        type: 'SET_MAP_SERVICE',
        payload: {
          mapService: new googleMap.maps.places.PlacesService(
            // eslint-disable-next-line angular/document-service
            document.createElement('div')
          )
        }
      });
      dispatch({ type: 'SET_LOADING', payload: { loading: false } });
    };

    getData().catch(error => {
      API.handleError()(error);
      // Fran: remove once we have a toast in react with the API.
      // Due to async, angular doesn't know when the promise has completed. Have to trigger the digest cycle manually.
      $scope.$apply();
    });
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!formValid(state.form)) {
      dispatch({
        type: 'SET_FORM_SUBMITTED',
        payload: { formSubmitted: true }
      });
      return;
    }

    dispatch({ type: 'SET_LOADING', payload: { loading: true } });
    dispatch({ type: 'SET_FORM_SUBMITTED', payload: { formSubmitted: true } });

    const userPatch = {
      firstName: reduxState.firstName,
      lastName: reduxState.lastName,
      email: reduxState.email,
      mobileNumber: reduxState.mobileNumber,
      contactSharing: reduxState.mobileNumberSharing
    };
    const patchData = {
      countryOfOrigin: reduxState.countryOfOrigin
        ? reduxState.countryOfOrigin.id
        : null,
      gender: reduxState.gender ? reduxState.gender.value : null,
      ethnicity: reduxState.ethnicity ? reduxState.ethnicity.id : null,
      ethnicityConsent: reduxState.ethnicityConsent
    };
    const addressData = Object.assign({}, reduxState.address);

    if (addressData.country) {
      addressData.country = addressData.country.id;
    }

    try {
      await API.User.patch(userPatch);
      await API.restangularize({ url: reduxState.profileUrl }).patch(patchData);

      if (addressData.url) {
        await API.restangularize(addressData).patch(addressData);
      } else {
        await API.Address.post(addressData);
      }
    } catch (error) {
      dispatch({ type: 'FORM_SUBMIT_ERROR' });
      API.handleError()(error);
      // Fran: remove once we have a toast in react with the API.
      // Due to async, angular doesn't know when the promise has completed. Have to trigger the digest cycle manually.
      $scope.$apply();

      return;
    }

    $state.go(STATES.MEMBER_REGISTRATION.CURRENT_POSITION, { id: 0 });
  };

  return (
    <Fragment>
      {state.loading && <LoadingSpinner />}
      {!state.loading && (
        <Fragment>
          <FormSectionTitle>Personal Details</FormSectionTitle>
          <Form name="profileBasics" noValidate onSubmit={handleSubmit}>
            <Row>
              <NameField
                fieldName="firstName"
                label="First Name*"
                autocomplete="given-name"
                initialValue={reduxState.firstName}
                updateValue={updateUserDetails('firstName')}
                updateError={updateFormFieldError}
                updateTouched={updateFormFieldTouched}
                touched={state.form.firstName.touched}
                formSubmitted={state.formSubmitted}
                halfWidth
              />
              <NameField
                fieldName="lastName"
                label="Last Name*"
                autocomplete="family-name"
                initialValue={reduxState.lastName}
                updateValue={updateUserDetails('lastName')}
                updateError={updateFormFieldError}
                updateTouched={updateFormFieldTouched}
                touched={state.form.lastName.touched}
                formSubmitted={state.formSubmitted}
                halfWidth
              />
            </Row>
            <Row noRightMargin>
              <GenderSelect
                genders={state.genders}
                gender={reduxState.gender}
                update={updateProfileDetails('gender')}
                updateTouched={updateFormFieldTouched}
                halfWidth
              />
              <CountryOfOrigin
                countries={reduxState.countryOptions}
                country={reduxState.countryOfOrigin}
                updateCountry={updateProfileDetails('countryOfOrigin')}
                halfWidth
              />
            </Row>
            <Ethnicity
              ethnicities={state.ethnicities}
              ethnicity={reduxState.ethnicity}
              updateEthnicity={updateProfileDetails('ethnicity')}
              ethnicityConsent={reduxState.ethnicityConsent}
              updateEthnicityConsent={updateProfileDetails('ethnicityConsent')}
              ethnicityTouched={state.form.ethnicity.touched}
              ethnicityConsentTouched={state.form.ethnicityConsent.touched}
              updateTouched={updateFormFieldTouched}
              ethnicityError={state.form.ethnicity.error}
              ethnicityConsentError={state.form.ethnicityConsent.error}
              updateError={updateFormFieldError}
              formSubmitted={state.formSubmitted}
            />
            <FormSectionTitle>Contact Information</FormSectionTitle>
            <EmailContainer>
              <EmailField
                initialValue={reduxState.email}
                updateValue={updateUserDetails('email')}
                updateError={updateFormFieldError}
                updateTouched={updateFormFieldTouched}
                touched={state.form.email.touched}
                formSubmitted={state.formSubmitted}
                label="Email Address*"
              />
            </EmailContainer>
            <Row>
              <PhoneNumberField
                initialValue={reduxState.mobileNumber}
                updateValue={updateUserDetails('mobileNumber')}
                updateError={updateFormFieldError}
                updateTouched={updateFormFieldTouched}
                touched={state.form.mobileNumber.touched}
                formSubmitted={state.formSubmitted}
                label="Phone Number*"
                halfWidth
              />
              <RegistrationMobileSharing
                value={reduxState.mobileNumberSharing}
                updateValue={updateUserDetails('mobileNumberSharing')}
                halfWidth
              />
            </Row>
            <FormSectionTitle>Location</FormSectionTitle>
            <LocationSelectWrapper
              noBottomMargin={
                reduxState.address && reduxState.address.state && true
              }
            >
              <LocationSelect
                address={reduxState.address}
                queryFunction={PlaceSearchService.autocomplete}
                updateAddress={updateAddressDetails}
                updateError={updateFormFieldError}
                updateTouched={updateFormFieldTouched}
                formSubmitted={state.formSubmitted}
              />
              {reduxState.address && (
                <Row>
                  {reduxState.address.country &&
                    reduxState.address.country.name && (
                      <BaseTextField
                        label="Country of Residence"
                        value={reduxState.address.country.name}
                        disabled
                        halfWidth
                      />
                    )}
                  {reduxState.address.state && (
                    <BaseTextField
                      label="State / Province"
                      value={reduxState.address.state}
                      disabled
                      halfWidth
                    />
                  )}
                </Row>
              )}
            </LocationSelectWrapper>
            {/* Required due to nesting of forms */}
            <SolidButton type="submit" hidden>
              CONTINUE
            </SolidButton>
          </Form>
          <EducationAccordion
            qualifications={state.qualifications}
            $state={$state}
            API={API}
            $scope={$scope}
          />
          <FormError show={state.formSubmitted && !formValid(state.form)}>
            {FORM_ERROR_MESSAGE}
          </FormError>
          <ContinueButtonContainer>
            <SolidButton type="button" onClick={handleSubmit}>
              CONTINUE
            </SolidButton>
          </ContinueButtonContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

RegistrationProfile.propTypes = {
  GoogleMapService: PropTypes.object.isRequired,
  PlaceSearchService: PropTypes.object.isRequired,
  API: PropTypes.object.isRequired,
  $state: PropTypes.object.isRequired,
  $scope: PropTypes.object.isRequired
};

// Fran: To be removed once router is implemented
const Wrapper = ({
  $ngRedux,
  GoogleMapService,
  PlaceSearchService,
  API,
  $state,
  $scope
}) => {
  return (
    <Provider store={$ngRedux}>
      <ThemeProvider theme={theme}>
        <RegistrationProfile
          GoogleMapService={GoogleMapService}
          PlaceSearchService={PlaceSearchService}
          API={API}
          $state={$state}
          $scope={$scope}
        />
      </ThemeProvider>
    </Provider>
  );
};

Wrapper.propTypes = {
  $ngRedux: PropTypes.object.isRequired,
  GoogleMapService: PropTypes.object.isRequired,
  PlaceSearchService: PropTypes.object.isRequired,
  API: PropTypes.object.isRequired,
  $state: PropTypes.object.isRequired,
  $scope: PropTypes.object.isRequired
};

export default angular
  .module('wc.components.reactRegistrationProfile', [])
  .component(
    'reactRegistrationProfile',
    react2angular(
      Wrapper,
      [],
      [
        '$ngRedux',
        'GoogleMapService',
        'PlaceSearchService',
        'API',
        '$state',
        '$scope'
      ]
    )
  );
