import React from 'react';
import PropTypes from 'prop-types';
import InverseButton from '../../../../buttons/InverseButton/InverseButton';

export const OtherMemberProfileActions = ({
  member,
  groups,
  API,
  MessengerService,
  GroupInvitesService,
  GroupDialogService,
  $q
}) => {
  const showInviteToGroupDialog = event => {
    GroupDialogService.showInviteMemberToGroupsDialog(event, groups).then(
      inviteMemberToGroups
    );
  };

  const inviteMemberToGroups = groups => {
    // TO DO - test the functionality
    const invitePromises = [];
    groups.forEach(group => {
      invitePromises.push(
        GroupInvitesService.inviteMemberToGroup(member, group)
      );
    });

    $q.all(invitePromises).then(
      GroupDialogService.showInviteSuccessDialog,
      handleError
    );
  };

  const handleError = error => {
    let errorMessage;
    if (!error || !error.data || !error.data.detail) {
      errorMessage = 'Could not send all group invites';
    }
    API.handleError(errorMessage)(error); // When errorMessage is undefined, handleError() will use error.data.detail by default
  };

  return (
    <div className="WcMemberProfileContent__ActionButtons">
      <InverseButton
        aria-label="Message"
        className="WcMemberProfileContent__Button"
        type="button"
        onClick={() => MessengerService.messageUser(member.chatId)}
        icon="EmailIcon"
        label="Send a Message"
        withBorder={false}
        withMargin={false}
        withPadding={false}
      />

      {groups && groups.length > 0 && (
        <InverseButton
          aria-label="Invite to Group"
          className="WcMemberProfileContent__Button"
          type="button"
          onClick={showInviteToGroupDialog}
          icon="FileText"
          label="Invite to Group"
          withBorder={false}
          withMargin={false}
          withPadding={false}
        />
      )}
    </div>
  );
};

OtherMemberProfileActions.propTypes = {
  member: PropTypes.object,
  groups: PropTypes.any,
  API: PropTypes.any,
  MessengerService: PropTypes.any,
  GroupInvitesService: PropTypes.any,
  GroupDialogService: PropTypes.any,
  $q: PropTypes.any
};
