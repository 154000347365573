import React from 'react';
import PropTypes from 'prop-types';
import styled, {
  ThemeProvider as StyledThemeProvider
} from 'styled-components';
import { ThemeProvider as MaterialUIStylesProvider } from '@material-ui/styles';
import { RadioGroup as BaseRadioGroup } from '@material-ui/core';
import angular from 'angular';
import { react2angular } from 'react2angular';

import RadioButton from '../RadioButton';
import theme from '../../../../../common/styles/theme';

const RadioGroup = styled(({ ...props }) => (
  <BaseRadioGroup classes={{ root: 'root' }} {...props} />
))`
  &.root {
    flex-direction: row;

    & > * {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const RadioButtonGroup = ({ value, handleChange, buttons }) => {
  return (
    <RadioGroup value={value} onChange={handleChange}>
      {buttons.map(({ value, labelText }) => {
        return <RadioButton key={value} value={value} labelText={labelText} />;
      })}
    </RadioGroup>
  );
};

RadioButtonGroup.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      labelText: PropTypes.string.isRequired
    })
  ).isRequired
};

const Wrapper = ({ value, handleChange, buttons }) => {
  return (
    <StyledThemeProvider theme={theme}>
      <MaterialUIStylesProvider theme={theme}>
        <RadioButtonGroup
          value={value}
          handleChange={handleChange}
          buttons={buttons}
        />
      </MaterialUIStylesProvider>
    </StyledThemeProvider>
  );
};

Wrapper.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      labelText: PropTypes.string.isRequired
    })
  ).isRequired
};

export default angular
  .module('wc.components.react.radioButtonGroup', [])
  .component(
    'reactRadioButtonGroup',
    react2angular(Wrapper, ['value', 'handleChange', 'buttons'], [])
  );
