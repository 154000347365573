import 'angular';
import template from './template.html';
import './styles.scss';

import { MAIN_SEARCH_ID } from '../../../services/MemberLists.service';

class SearchResultsController {
  /*@ngInject*/
  constructor(MemberListUIService, wcMemberList, wcSidebar) {
    this.wcMemberList = wcMemberList;
    this.isScrolled = false;
    this.viewOptions = MemberListUIService.viewOptions;
    this.memberList = wcMemberList[MAIN_SEARCH_ID];
    this.wcSidebar = wcSidebar;
  }

  onSearchToggle() {
    this.wcSidebar.toggle(this.searchSidebarId);
  }

  isEmpty() {
    return this.wcMemberList[MAIN_SEARCH_ID].isEmpty();
  }

  onInfiniteScroll() {
    this.wcMemberList[MAIN_SEARCH_ID].loadNextMembers();
  }
}

const SearchResults = {
  NAME: 'wcSearchResults',
  templateUrl: template,
  bindings: {
    extraTableData: '<',
    onNotifyScroll: '&',
    onToggleUser: '&',
    showLonglistActions: '=',
    tableHeaders: '<',
    userProfileState: '@',
    searchSidebarId: '@'
  },
  controller: SearchResultsController
};

export default angular
  .module('wc.components.searchResults', [])
  .component(SearchResults.NAME, SearchResults);
