import angular from 'angular';
import { react2angular } from 'react2angular';
import { MessageBubblePartner } from './MessageBubblePartner';

export default angular
  .module('wc.components.ReactMessageBubblePartner', [])
  // @ts-ignore
  .component(
    'reactMessageBubblePartner',
    react2angular(MessageBubblePartner, [
      'isLast',
      'message',
      'mergeTop',
      'mergeBottom',
      'sentAt'
    ])
  );
