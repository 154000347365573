import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import BaseTextField from '../../inputs/TextField/BaseTextField';
import Select from '../../inputs/selects/Select';
import { default as BaseSolidButton } from '../../buttons/SolidButton';
import InverseButton from '../../buttons/InverseButton/InverseButton';
import { FORM_ERROR_MESSAGE, ESC } from '../../utils/constants';
import theme from '../../../../../common/styles/theme';

const Form = styled.form`
  opacity: ${props => props.isEducationUpdating && '0.5'};
`;

const SelectContainer = styled.div`
  margin: 18px 0 2px 0;
  padding: 15px 0;
`;

const FormError = styled.div`
  font-size: 14px;
  margin-bottom: 12px;
  color: ${props => props.theme.palette.error[500]};
`;

const FormButtonContainer = styled.div`
  text-align: ${props =>
    props.isInitialForm && !props.showBackToProfile ? 'left' : 'right'};
`;

// eslint-disable-next-line no-unused-vars
const SolidButton = styled(({ isInitialForm, showBackToProfile, ...props }) => (
  <BaseSolidButton {...props} />
))`
  &.root {
    margin-left: ${props =>
      props.isInitialForm && !props.showBackToProfile ? '0' : '10px'};
  }
`;

const EducationForm = ({
  showBackToProfile = false,
  backToProfile,
  cancelForm,
  onSave,
  isInitialForm,
  qualifications = [],
  educationData = {},
  isEducationUpdating = false
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formFields, setFormField] = useState({
    school: {
      error: true,
      touched: false,
      value: educationData.school || ''
    },
    qualification: {
      error: true,
      touched: false,
      value: educationData.qualification || null
    },
    fieldOfStudy: {
      error: true,
      touched: false,
      value: educationData.fieldOfStudy || ''
    },
    year: {
      error: false,
      touched: false,
      value: educationData.year || ''
    }
  });

  const validateYear = useCallback(year => {
    const currentYear = new Date().getFullYear();
    const minGraduationYear = currentYear - 50;
    const maxGraduationYear = currentYear + 5;
    return year >= minGraduationYear && year <= maxGraduationYear;
  }, []);

  const errorHandler = useCallback(
    (fieldName, value) => {
      if (fieldName === 'year') {
        const year = parseInt(value);
        const regexp = new RegExp(`^[0-9]*$`);
        const isValidNumber = regexp.test(year);

        if (!value) {
          return {
            hasError: false,
            errorText: ''
          };
        } else if (!isValidNumber) {
          return {
            hasError: true,
            errorText: 'A number is required'
          };
        } else if (!validateYear(year)) {
          return {
            hasError: true,
            errorText: ''
          };
        }
      }

      if (!value) {
        return {
          hasError: true,
          errorText: 'This field is required'
        };
      }

      return {
        hasError: false,
        errorText: ''
      };
    },
    [validateYear]
  );

  const updateFormField = useCallback((fieldName, value) => {
    setFormField(prevState => {
      const data = {
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          ...value
        }
      };
      return data;
    });
  }, []);

  const updateError = useCallback(
    (fieldName, error) => {
      updateFormField(fieldName, { error });
    },
    [updateFormField]
  );

  const updateTouched = useCallback(
    (fieldName, touched) => {
      updateFormField(fieldName, { touched });
    },
    [updateFormField]
  );

  const updateValue = useCallback(
    (fieldName, value) => {
      updateFormField(fieldName, { value });
    },
    [updateFormField]
  );

  const hasError = useCallback(
    (fieldName, value) => {
      return errorHandler(fieldName, value).hasError;
    },
    [errorHandler]
  );

  const errorText = useCallback(
    (fieldName, value) => {
      return errorHandler(fieldName, value).errorText;
    },
    [errorHandler]
  );

  const handleBlur = useCallback(
    event => {
      if (!event) {
        return;
      }

      const {
        target: { name: fieldName }
      } = event;

      if (formFields[fieldName].touched) {
        return;
      }

      updateTouched(fieldName, true);
    },
    [formFields, updateTouched]
  );

  const handleChange = useCallback(
    ({ target: { value, name: fieldName } }) => {
      updateValue(fieldName, value);
      updateError(fieldName, hasError(fieldName, value));
    },
    [updateValue, updateError, hasError]
  );

  const shouldRenderError = fieldName => {
    return (
      (formSubmitted || formFields[fieldName].touched) &&
      formFields[fieldName].error
    );
  };

  const valueToItem = useCallback(value => {
    return {
      value: value.name,
      display: value.name,
      key: value.id
    };
  }, []);

  const selectHandleChange = useCallback(
    value => {
      updateValue('qualification', value);
      updateError(
        'qualification',
        hasError('qualification', (value && value.name) || value)
      );

      if (!formFields['qualification'].touched) {
        updateTouched('qualification', true);
      }
    },
    [formFields, updateValue, updateError, hasError, updateTouched]
  );

  const isFormValid = () => {
    let isValid = true;
    const formFieldValues = Object.values(formFields);

    for (const value of formFieldValues) {
      if (!isValid) {
        break;
      }
      isValid = !value.error;
    }

    return isValid;
  };

  const handleSubmit = event => {
    event.preventDefault();
    const isValid = isFormValid();

    setFormSubmitted(true);

    if (!isValid) {
      return;
    }

    const formFieldEntries = Object.entries(formFields);
    const dataToSubmit = { ...educationData };

    for (const [key, entry] of formFieldEntries) {
      dataToSubmit[key] = entry.value;
    }

    onSave(dataToSubmit);
  };

  const handleCancelFormKey = event => {
    if (event.keyCode === ESC) {
      cancelForm();
    }
  };

  const handleCancelFormClick = () => {
    cancelForm();
  };

  const selectValue = formFields.qualification.value;
  const selectHasError = hasError(
    'qualification',
    (selectValue && selectValue.name) || selectValue
  );
  const selectErrorText = errorText(
    'qualification',
    (selectValue && selectValue.name) || selectValue
  );

  // Run after setting default values for an exiting item
  useEffect(() => {
    // if it is not an item to be edited
    if (!educationData.id) {
      return;
    }

    const formFieldEntries = Object.entries(formFields);

    for (const [fieldName, entry] of formFieldEntries) {
      updateError(fieldName, hasError(fieldName, entry.value));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Form
        isEducationUpdating={isEducationUpdating}
        onSubmit={handleSubmit}
        onKeyDown={handleCancelFormKey}
        noValidate
      >
        <BaseTextField
          label="University / School"
          value={formFields.school.value}
          inputProps={{ name: 'school' }}
          onChange={handleChange}
          onBlur={handleBlur}
          shouldRenderError={shouldRenderError('school')}
          errorText={errorText('school', formFields.school.value)}
          required={formFields.school.error}
        />
        <SelectContainer>
          <Select
            options={qualifications}
            value={selectValue}
            label="Qualification"
            onChange={selectHandleChange}
            hasError={selectHasError}
            onBlur={handleBlur}
            checkForError={shouldRenderError('qualification')}
            errorText={selectErrorText}
            valueToItem={valueToItem}
            fullWidth
            errorMessageLink={false}
            selectName="qualification"
          />
        </SelectContainer>
        <BaseTextField
          label="Field of study"
          value={formFields.fieldOfStudy.value}
          fieldName="fieldOfStudy"
          inputProps={{ name: 'fieldOfStudy' }}
          onChange={handleChange}
          onBlur={handleBlur}
          shouldRenderError={shouldRenderError('fieldOfStudy')}
          errorText={errorText('fieldOfStudy', formFields.fieldOfStudy.value)}
          required={formFields.fieldOfStudy.error}
        />
        <BaseTextField
          label="Year of graduation"
          value={formFields.year.value}
          fieldName="year"
          inputProps={{ name: 'year' }}
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          shouldRenderError={shouldRenderError('year')}
          errorText={errorText('year', formFields.year.value)}
          autocomplete="off"
        />
        {formSubmitted && !isFormValid() && (
          <FormError>{FORM_ERROR_MESSAGE}</FormError>
        )}
        <FormButtonContainer
          isInitialForm={isInitialForm}
          showBackToProfile={showBackToProfile}
        >
          {!isInitialForm && (
            <InverseButton
              onClick={handleCancelFormClick}
              disabled={isEducationUpdating}
            >
              CANCEL
            </InverseButton>
          )}
          {isInitialForm && showBackToProfile && (
            <InverseButton onClick={backToProfile} icon="PersonIcon">
              BACK TO PROFILE
            </InverseButton>
          )}
          <SolidButton
            type="submit"
            isInitialForm={isInitialForm}
            showBackToProfile={showBackToProfile}
            icon="CheckSharpIcon"
            disabled={isEducationUpdating}
          >
            SAVE
          </SolidButton>
        </FormButtonContainer>
      </Form>
    </ThemeProvider>
  );
};

EducationForm.propTypes = {
  showBackToProfile: PropTypes.bool,
  backToProfile: PropTypes.func,
  cancelForm: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isInitialForm: PropTypes.bool.isRequired,
  qualifications: PropTypes.array.isRequired,
  educationData: PropTypes.object,
  isEducationUpdating: PropTypes.bool
};

export default EducationForm;
