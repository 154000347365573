import angular from 'angular';
import './SectionHeader.scss';

const SectionHeader = {
  NAME: 'wcSectionHeader',
  template: `
    <h2 class="WcSectionHeader" ng-transclude></h2>
  `,
  transclude: true
};

// MIGRATED TO REACT - src/desktop/components/react/typography/section-header/SectionHeader
export default angular
  .module('wc.components.typography.sectionHeader', [])
  .component(SectionHeader.NAME, SectionHeader);
