import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import Switch from './Switch';
import theme from '../../../../../common/styles/theme';

const SwitchContainer = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  color: ${props => props.theme.palette.extraColors.textWeak};
  font: normal 400 14px/20px ProximaNova, sans-serif;
  width: ${props => props.halfWidth && '50%'};
`;

const RegistrationMobileSharing = ({
  value,
  updateValue,
  halfWidth = false
}) => {
  const handleChange = event => {
    updateValue(event.target.checked);
  };

  return (
    // Fran: remove provider once profile component completed
    <ThemeProvider theme={theme}>
      <SwitchContainer halfWidth={halfWidth}>
        Allow client companies to view my phone number
        <Switch checked={value} onChange={handleChange} />
      </SwitchContainer>
    </ThemeProvider>
  );
};

RegistrationMobileSharing.propTypes = {
  value: PropTypes.bool,
  updateValue: PropTypes.func,
  halfWidth: PropTypes.bool
};

export default RegistrationMobileSharing;
