import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import theme from '../../../../../../common/styles/theme';
import FormControl from '../../FormControl';
import { InputError } from '../../../../../../common/components';

const StyledLabel = styled.div`
  position: relative;
`;

const AutoSuggestContainer = styled.div`
  font-size: 16px;
  width: ${props => props.halfWidth && '50%'};
`;

const inputComponent = ({ inputRef, ...otherProps }) => {
  return <div ref={inputRef} {...otherProps} />;
};

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ])
};

const Control = ({ children, innerProps, innerRef, selectProps }) => {
  const inputProps = {
    inputComponent,
    inputProps: {
      ref: innerRef,
      children,
      ...innerProps
    }
  };

  const labelStyle = selectProps.value
    ? {
        transform: 'translate(0, 9px) scale(0.875)'
      }
    : {};

  const validationError = !!selectProps.validationError;

  return (
    <Fragment>
      <FormControl fullWidth validationerror={validationError}>
        <InputLabel htmlFor="react-select" style={labelStyle}>
          <StyledLabel>{selectProps.labelText}</StyledLabel>
        </InputLabel>
        <Input {...inputProps} />
      </FormControl>
      {validationError && <InputError>{selectProps.errorMessage}</InputError>}
    </Fragment>
  );
};

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]).isRequired,
  selectProps: PropTypes.object.isRequired
};

class AutoSuggest extends React.Component {
  components = {
    Control,
    Placeholder: () => null,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    LoadingIndicator: () => null
  };

  getSelectStyles = (component, props) => {
    return this.props.selectStyles[component]
      ? this.props.selectStyles[component](undefined, props)
      : {};
  };

  selectStyles = {
    valueContainer: (styles, props) => ({
      ...styles,
      padding: 0,
      ...this.getSelectStyles('valueContainer', props)
    }),
    menu: (styles, props) => ({
      ...styles,
      maxHeight: '240px',
      marginTop: '0px',
      marginBottom: '0px',
      borderRadius: '0px',
      zIndex: 2,
      ...this.getSelectStyles('menu', props)
    }),
    menuList: (styles, props) => ({
      ...styles,
      maxHeight: '240px',
      paddingTop: '0px',
      paddingBottom: '0px',
      ...this.getSelectStyles('menuList', props)
    }),
    menuPortal: (styles, props) => ({
      ...styles,
      maxHeight: '240px',
      ...this.getSelectStyles('menuPortal', props)
    }),
    option: (styles, { isFocused, ...otherProps }) => ({
      ...styles,
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '0px',
      paddingBottom: '0px',
      lineHeight: '48px',
      fontSize: '14px',
      fontFamily: "'ProximaNova', sans-serif",
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: isFocused ? '#f7f7f7' : 'transparent',
      maxHeight: '48px',
      ...this.getSelectStyles('option', { isFocused, ...otherProps })
    }),
    noOptionsMessage: (styles, props) => ({
      ...styles,
      minHeight: '48px',
      paddingLeft: '15px',
      paddingRight: '15px',
      fontFamily: "'ProximaNova', sans-serif",
      lineHeight: '48px',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.87)',
      textAlign: 'left',
      ...this.getSelectStyles('noOptionsMessage', props)
    }),
    loadingMessage: (styles, props) => ({
      ...styles,
      minHeight: '48px',
      paddingLeft: '15px',
      paddingRight: '15px',
      fontFamily: "'ProximaNova', sans-serif",
      lineHeight: '48px',
      fontSize: '14px',
      textAlign: 'left',
      ...this.getSelectStyles('loadingMessage', props)
    })
  };

  render() {
    return (
      <ThemeProvider theme={theme.palette}>
        <AutoSuggestContainer halfWidth={this.props.halfWidth}>
          <div style={this.props.style}>
            {this.props.render({
              inputId: 'react-select',
              styles: this.selectStyles,
              components: this.components
            })}
          </div>
        </AutoSuggestContainer>
      </ThemeProvider>
    );
  }
}

AutoSuggest.propTypes = {
  render: PropTypes.func.isRequired,
  style: PropTypes.object,
  selectStyles: PropTypes.object,
  halfWidth: PropTypes.bool
};

AutoSuggest.defaultProps = {
  selectStyles: {}
};

export default AutoSuggest;
