import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ActionBarIconButton = ({
  ariaLabel,
  onClick,
  title,
  textButton,
  text,
  fontSet,
  className
}) => {
  return (
    <button
      aria-label={ariaLabel}
      className={
        'react-action-bar-icon-button ' +
        className +
        ' ' +
        (!textButton ? 'WcActionBarIconButton md-icon-button' : 'md-button')
      }
      onClick={onClick}
      title={title}
    >
      {text}
      <i className={'WcActionBarIconButton__Icon md-accent ' + fontSet} />
    </button>
  );
};

ActionBarIconButton.propTypes = {
  ariaLabel: PropTypes.string,
  fontSet: PropTypes.string,
  className: PropTypes.string,
  textButton: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.any
};
