import PhoneNumberInputUrl from './PhoneNumberInput.html';
import log from 'loglevel';

import { parse, format, isValidNumber } from 'libphonenumber-js';

let PhoneNumberInput = () => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: PhoneNumberInputUrl,
    scope: {
      blurred: '=?',
      fieldName: '@?',
      form: '=?',
      ngModel: '=',
      placeholder: '@?',
      tooltip: '@?'
    },
    require: ['ngModel'],
    link: (scope, elem, attrs, ctrls) => {
      let ctrl = ctrls[0];

      attrs.$observe('readonly', () => {
        scope.readonly = 'readonly' in attrs;
      });

      scope.required = attrs.hasOwnProperty('required');

      if (!scope.placeholder) {
        scope.placeholder = 'Phone Number (including country code)';
      }

      function setInternalModel() {
        scope._model = format(parse(scope.ngModel), 'International');
      }

      setInternalModel();

      scope.format = () => {
        try {
          let parsedNumber = parse(scope._model);
          if (isValidNumber(parsedNumber)) {
            scope._model = format(parsedNumber, 'International');
            scope.ngModel = scope._model.replace(/\s+/g, '');
          }
        } catch (error) {
          log.warn(
            `[PhoneNumberInput directive] ${
              error.message
            }. Internal model value: ${scope._model}`
          );
          ctrl.$setValidity('phoneNumber', true);
        }
      };
    }
  };
};

PhoneNumberInput.NAME = 'phoneNumberInput';

export default PhoneNumberInput;
