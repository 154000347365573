import STATES from '../../../services/States';
import SettingsController from './Settings.controller';
import SettingsTemplate from './Settings.html';

/*@ngInject*/
function SettingsRouting($stateProvider) {
  $stateProvider.state(STATES.CLIENT_SETTINGS, {
    url: '/settings?tab',
    templateUrl: SettingsTemplate,
    controller: SettingsController,
    controllerAs: SettingsController.controllerAs,
    resolve: {
      /*@ngInject*/
      redirect: function(RedirectService) {
        // Only Clients are allowed to access Client Settings.
        //
        // This potentially wants tightened up, since Incomplete Clients should really be redirected
        // to Registration.
        //
        // Today I'm trying to refactor, not improve, and don't want to risk breaking changes, so
        // leaving as-is.
        return RedirectService.redirectIfNotClient();
      }
    }
  });
}

export default SettingsRouting;
