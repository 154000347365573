import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import placeholderImage from './placeholder.svg';

const placeholderImageSrc =
  'data:image/svg+xml;base64,' + btoa(placeholderImage);

export const CardContent = ({ group }) => {
  return (
    <React.Fragment>
      <img
        className="WcGroupCardContent__Image"
        src={group.imageUrl || placeholderImageSrc}
      />
      <div className="WcGroupCardContent__TextContainer">
        <h2 className="WcGroupCardContent__Header">{group.name}</h2>
        <p
          className={`WcGroupCardContent__Description ${
            group.isPrivate ? 'WcGroupCardContent__Description--Private' : ''
          }`}
        >
          {group.description}
        </p>
        {group.isPrivate && (
          <p className="WcGroupCardContent__PrivateLabel">Private group</p>
        )}
      </div>
    </React.Fragment>
  );
};

CardContent.propTypes = {
  group: PropTypes.object
};
