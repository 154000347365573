import angular from 'angular';

import STATES from '../../../services/States';
import './styles.scss';
import template from './template.html';

class joinGroupRequestController {
  /*@ngInject*/
  constructor() {
    this.GROUP_STATE = STATES.MEMBER_GROUP;
    this.MEMBER_PROFILE_STATE = STATES.OTHER_MEMBER_PROFILE;
  }
}

const joinGroupRequestNotificationComponent = {
  bindings: {
    notification: '<',
    onAccept: '&',
    onIgnore: '&'
  },
  controller: joinGroupRequestController,
  templateUrl: template
};

export default angular
  .module('wc.components.notifications.joinGroupRequest', [])
  .component(
    'wcJoinGroupRequestNotification',
    joinGroupRequestNotificationComponent
  );
