import React from 'react';
import PropTypes from 'prop-types';

export const DocumentNameCell = ({ name, extra, rowItem }) => {
  return (
    <span
      className="DocumentNameCell"
      onClick={() => {
        extra.downloadDocument(rowItem);
      }}
    >
      {name}
    </span>
  );
};

DocumentNameCell.propTypes = {
  name: PropTypes.string,
  extra: PropTypes.any,
  rowItem: PropTypes.any
};
