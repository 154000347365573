import angular from 'angular';

import templateUrl from './View.html';
import './View.scss';

const View = {
  NAME: 'wcSidenavView',
  templateUrl: templateUrl,
  transclude: {
    sidenav: 'wcSidenavNavbarSlot',
    main: 'wcSidenavMainSlot'
  }
};

export default angular
  .module('wc.components.sidenav.view', [])
  .component(View.NAME, View);
