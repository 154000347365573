import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { CircularImage } from '../../circular-image/CircularImage';
import { MISSING_PROFILE_PICTURE_WITH_ACCENT_BACKGROUND_BASE64 } from '../../../../services/ImageUrls';

export const HighlightedImageBubble = ({ src, label, missingImageUrl }) => {
  return (
    <CircularImage
      className="WcHighlightedImageBubble"
      src={src}
      alt={label}
      missingImageUrl={
        missingImageUrl || MISSING_PROFILE_PICTURE_WITH_ACCENT_BACKGROUND_BASE64
      }
    />
  );
};

HighlightedImageBubble.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  missingImageUrl: PropTypes.string
};
