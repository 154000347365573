import STATES from '../../../services/States';
import MemberAreaTemplateUrl from './MemberArea.html';

/*@ngInject*/
function MembersRouting($stateProvider) {
  $stateProvider.state(STATES.MEMBER_AREA, {
    abstract: true,
    templateUrl: MemberAreaTemplateUrl,
    resolve: {
      /*@ngInject*/
      redirect: function(RedirectService) {
        return RedirectService.redirectIfNotMember();
      }
    }
  });
}

export default MembersRouting;
