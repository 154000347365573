import angular from 'angular';

export default angular
  .module('wc.components.buttons.wcSolidLink', [])
  .component('wcSolidLink', {
    template: /*@ngInject*/ function($element, $attrs) {
      // This workaround is required for IE11.
      // Normal template file + $ctrl interpolation results in invalid type texts (e.g.: type="submit submit")
      $attrs.type = $attrs.type || 'submit';
      return `
        <md-button
          class="WcSolidButton"
          ng-attr-type="${$attrs.type}"
          ui-sref="{{ $ctrl.wcSref }}">

          <ng-transclude></ng-transclude>

        </md-button>
      `;
    },
    transclude: true,
    bindings: {
      type: '@',
      wcSref: '@'
    }
  });
