import STATES from '../../../../services/States';

import GroupController from './Group.controller';
import Group from './Group.html';
import './Group.scss';

/*@ngInject*/
function routeMemberGroup($stateProvider) {
  $stateProvider.state(STATES.MEMBER_GROUP, {
    url: '/group/:id',
    templateUrl: Group,
    controller: GroupController,
    controllerAs: GroupController.controllerAs,
    resolve: {
      /*@ngInject*/
      redirect: function(RedirectService) {
        // Only Members should be allowed to access Groups.
        //
        // Probably this should be narrowed down to complete Members, as incomplete Members are
        // supposed to be redirected to Registration.
        //
        // Refactoring first, can tighten up later.
        return RedirectService.redirectIfNotMemberPublic();
      }
    }
  });
}

export default angular
  .module('wc.members.memberArea.group', [])
  .controller(GroupController.name, GroupController)
  .config(routeMemberGroup);
