import angular from 'angular';

export default class GroupedChips {
  constructor(
    chips,
    groupProperty = 'group',
    groupName = 'name',
    defaultGroup = 'Other',
    minSelection = 0,
    maxSelection
  ) {
    this.groupProperty = groupProperty;
    this.groupName = groupName;
    this.defaultGroup = defaultGroup;
    this.minSelection = minSelection;
    this.maxSelection = maxSelection;

    this.setChips(chips);
  }

  setMinSelection(minSelection) {
    this.minSelection = minSelection;
  }

  setChips(chips) {
    this.chips = chips;
    this.groups = this.groupOptions(chips);
  }

  groupOptions() {
    /*
     *  Takes a flat list of options and groups them. Assumes the `options` argument looks like this:
     *
     *  [
     *    {name: 'Option 1', group: {name: 'Group A'}},
     *    ...
     *  ]
     *
     *  The `group` and `groupName` parameters can be used to define the properties
     *  to check for group metadata. For example, if the input looks like this...
     *
     *  [
     *    {name: 'Option 1', team: {teamName: 'Group A'}},
     *    ...
     *  ]
     *
     *  We can set group = 'team' and groupName = 'teamName'.
     */

    if (!angular.isArray(this.chips)) {
      return this.chips;
    }

    let groupedOptions = {};
    this.chips.forEach(option => {
      let destinationGroup;

      if (!option[this.groupProperty]) {
        destinationGroup = this.defaultGroup;
      } else {
        destinationGroup =
          option[this.groupProperty][this.groupName] || this.defaultGroup;
      }

      if (angular.isUndefined(groupedOptions[destinationGroup])) {
        groupedOptions[destinationGroup] = [];
      }

      groupedOptions[destinationGroup].push(option);
    });

    return groupedOptions;
  }

  getStatus() {
    const selectedCount = this.chips.filter(chip => chip.isSelected).length;
    const minDiff = Math.min(0, selectedCount - this.minSelection);
    const maxDiff = Math.min(0, this.maxSelection - selectedCount);
    const minValid = this.minSelection ? minDiff : 0;
    const maxValid = this.maxSelection ? maxDiff : 0;
    return minValid - maxValid;
  }

  getStatusText() {
    const status = this.getStatus();

    if (status === 0) {
      return;
    }

    const addOrRemove = status > 0 ? 'remove' : 'select';
    const absStatus = Math.abs(status);

    let statusText;
    if (status > 0) {
      statusText = absStatus === 1 ? 'an option' : `${absStatus} options`;
    } else {
      statusText =
        absStatus === 1 ? 'one more option' : `${absStatus} more options`;
    }
    const atLeast = this.minSelection !== this.maxSelection ? ' at least' : '';

    return `Please ${addOrRemove}${atLeast} ${statusText}`;
  }

  checkIsValid() {
    return this.getStatus() === 0;
  }
}
