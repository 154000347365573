import angular from 'angular';
import { react2angular } from 'react2angular';
import { ClientMemberProfileActions } from './ClientMemberProfileActions';

export default angular
  .module('wc.components.ClientMemberProfileActions', [])
  // @ts-ignore
  .component(
    'reactClientMemberProfileActions',
    react2angular(
      ClientMemberProfileActions,
      ['member'],
      ['Resume', 'MessengerService', 'AuthenticationService']
    )
  );
