import STATES from '../../services/States';

/*@ngInject*/
export default function redirectIfUnsupportedDevice(
  $state,
  DetectDeviceService
) {
  const deviceUnsupported = DetectDeviceService.isMobile();
  if (deviceUnsupported) {
    $state.go(STATES.DEVICE_NOT_SUPPORTED);
  }
}
