import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Delete as BaseDelete } from '@material-ui/icons';

const DeleteIcon = styled(({ ...props }) => (
  <BaseDelete classes={{ root: 'root' }} {...props} />
))`
  &.root {
    font-size: 21px;
    color: ${props => props.theme.palette.extraColors.textWeaker}

    &:hover {
      color: ${props => props.theme.palette.secondary.A200}
    }
  }
`;
const DeleteButton = ({ onClick }) => {
  return (
    <button aria-label="Delete" type="button" onClick={onClick}>
      <DeleteIcon />
    </button>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default DeleteButton;
