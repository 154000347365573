import angular from 'angular';
import Navbar from '../navbar';
import IconButtons from '../iconbuttons';
import ProfilePictureMenu from '../profilepicturemenu';

import './LimitedClientNavbar.scss';
import templateUrl from './LimitedClientNavbar.html';

class LimitedClientNavbarCtrl {
  /*@ngInject*/
  constructor(AuthenticationService) {
    this.AuthenticationService = AuthenticationService;
  }

  signOut() {
    this.AuthenticationService.showLogoutDialog();
  }
}

const LimitedClientNavbar = {
  NAME: 'wcSidenavLimitedClientNavbar',
  controller: LimitedClientNavbarCtrl,
  templateUrl: templateUrl
};

const dependencies = [Navbar.name, IconButtons.name, ProfilePictureMenu.name];

export default angular
  .module('wc.components.sidenav.limitedClientNavbar', dependencies)
  .component(LimitedClientNavbar.NAME, LimitedClientNavbar);
