import angular from 'angular';
import { react2angular } from 'react2angular';
import { OrderingDropdown } from './OrderingDropdown';

export default angular
  .module('wc.components.react.memberList.orderingDropdown', [])
  // @ts-ignore
  .component(
    'reactMemberListOrderingDropdown',
    react2angular(
      OrderingDropdown,
      [
        'sortOptionDefaultValue',
        'sortOptions',
        'sortOptionChange',
        'reversible',
        'reverseOrdering',
        'onFlipOrdering'
      ],
      ['$scope']
    )
  );
