import angular from 'angular';

/* @ngInject */
function bindShiftFocusFunctionToCtrl($timeout) {
  return (ctrl, targetElementForFocus) => {
    ctrl.shiftFocus = function shiftFocus() {
      $timeout(() => {
        $(targetElementForFocus).focus();
      });
    };
  };
}

bindShiftFocusFunctionToCtrl.NAME = 'dfBindShiftFocusFunctionToCtrl';

export default angular
  .module('dfComponents.inputs.behaviours.dfBindShiftFocusFunctionToCtrl', [])
  .factory(bindShiftFocusFunctionToCtrl.NAME, bindShiftFocusFunctionToCtrl);
