/*@ngInject*/
export default function blurInputsOnSubmitDirective() {
  /*
  Mobile devices are a bit retarded and they do not blur the inputs on submit.
  This causes the virtual keyboards to stay active.

  Add this directive to forms which on submit do not redirect to avoid keyboard issues
  on mobile devices.

  Inputs are blurred on state transition, so if submission results in state change,
  this directive is not required.
  */
  return {
    restrict: 'A',
    link: (scope, element) => {
      $(element).submit(() => {
        $('input').blur();
      });
    }
  };
}

blurInputsOnSubmitDirective.NAME = 'blurInputsOnSubmit';
