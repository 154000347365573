import angular from 'angular';
import { react2angular } from 'react2angular';
import { MessengerSendButton } from './MessengerSendButton';

export default angular
  .module('wcCommon.components.reactMessengerSendButton', [])
  // @ts-ignore
  .component(
    'reactMessengerSendButton',
    react2angular(MessengerSendButton, [])
  );
