/**
 * Use together with wcActionBar and wcOpaqueActionBarScrollContainer like this. wcActionBar
 * will turn opaque on content scroll.
 *
 * <wc-opaque-action-bar-view>
 *     <wc-action-bar wc-opaque-action-bar>
 *         <!-- Usual actionBar content here -->
 *     </wc-action-bar>
 *
 *     <wc-opaque-action-bar-scroll-container>
 *         <!-- View content here -->
 *     </wc-opaque-action-bar-scroll-container>
 * </wc-opaque-action-bar-view>
 */

import angular from 'angular';

class OpaqueActionBarViewController {
  /*@ngInject*/
  constructor($document, $element) {
    this.$element = $element;

    const scrollParent = $document.find('#wc-body-scroll-container');
    scrollParent.bind('scroll', this.onScroll.bind(this));
  }

  onScroll(event) {
    const wasScrolled = this.isScrolled;
    this.isScrolled = event.target.scrollTop > 0;

    // Use conditional to cut down on # digest triggers
    if (wasScrolled !== this.isScrolled) {
      this.setOpaqueState(this.isScrolled);
    }
  }

  setOpaqueState(isOpaque) {
    if (isOpaque) {
      this.$element.addClass('WcActionBar--Opaque');
    } else {
      this.$element.removeClass('WcActionBar--Opaque');
    }
  }
}

export default angular
  .module('wc.components.actionBar.opaqueActionBarView', [])
  .component('wcOpaqueActionBarView', {
    controller: OpaqueActionBarViewController
  });
