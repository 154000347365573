import CommonAuthenticationService from '../../../common/services/CommonAuthentication';
import SolidButton from '../../components/buttons/solidbutton';
import Card from '../../components/cards/fixedcard';
import Dialogs from '../../components/dialogs/Dialogs.module';
import FormErrorMessage from '../../components/typography/formerror';
import FormInfoMessage from '../../components/typography/forminfo';
import RequestNewToken from './requestNewToken';
import services from '../../services/Services.module';

import './styles.scss';
import AuthenticationService from './Authentication.service';
import configureRoutes from './routes';

export default angular
  .module('wc.auth', [
    AuthenticationService.name,
    Card.name,
    CommonAuthenticationService.name,
    Dialogs.name,
    FormErrorMessage.name,
    FormInfoMessage.name,
    SolidButton.name,
    RequestNewToken.name,
    services.name
  ])
  .config(configureRoutes);
