import angular from 'angular';

import './styles.scss';

import ReactOwnMemberProfileAspirations from './../../../react/profile/profile-content/own-edit/aspirations';
import ReactOwnMemberProfileFinancePeople from './../../../react/profile/profile-content/own-edit/finance-people';
import ReactOwnMemberProfileCareerHistory from './../../../react/profile/profile-content/own-edit/career-history';
import ReactOwnMemberProfileNonExecPositions from './../../../react/profile/profile-content/own-edit/non-exec-positions';
import ReactOwnMemberProfileExperience from './../../../react/profile/profile-content/own-edit/experience';
import ReactOwnMemberProfileInsights from './../../../react/profile/profile-content/own-edit/insights';

export default angular.module('wc.components.profile.content.ownEdit', [
  ReactOwnMemberProfileAspirations.name,
  ReactOwnMemberProfileFinancePeople.name,
  ReactOwnMemberProfileCareerHistory.name,
  ReactOwnMemberProfileNonExecPositions.name,
  ReactOwnMemberProfileExperience.name,
  ReactOwnMemberProfileInsights.name
]);
