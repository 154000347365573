let RequiredChips = () => {
  return {
    require: 'ngModel',
    link: function(scope) {
      if (scope.required === true) {
        scope.$watch(
          'model',
          () => {
            if (scope.touched) {
              if (scope.model.length === 0) {
                scope.field.$setValidity('requiredChips', false);
              } else {
                scope.field.$setValidity('requiredChips', true);
              }
            } else {
              scope.field.$setValidity('requiredChips', true);
            }
          },
          true
        );
      }
    }
  };
};

RequiredChips.NAME = 'requiredChips';

export default RequiredChips;
