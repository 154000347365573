import angular from 'angular';
import template from './template.html';

import STATES from '../../../../services/States';

class AspirationsNonExecController {
  /*@ngInject*/
  constructor(API, $state) {
    this.API = API;
    this.$state = $state;
    this.toResolve = 1;

    this.API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .catch(this.handleError.bind(this));
  }

  setProfile(profile) {
    this.profile = profile;
    this.toResolve--;
  }

  cancel() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'aspiration-non-execs'
    });
  }

  submit() {
    if (this.form.$valid) {
      this.toResolve++;
      let profilePatch = {
        interestedInNonExec: this.profile.interestedInNonExec
      };
      this.API.restangularize(this.profile)
        .patch(profilePatch)
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleSuccess() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'aspiration-non-execs'
    });
  }

  handleError(error) {
    this.toResolve = 0;
    this.API.handleError()(error);
  }

  isLoaded() {
    return this.toResolve === 0;
  }
}

const wcInlineEditAspirationsNonExec = {
  controller: AspirationsNonExecController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.aspirations.nonExec', [])
  .component('wcInlineEditAspirationsNonExec', wcInlineEditAspirationsNonExec);
