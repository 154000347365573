import STATES from '../../../../services/States';
import SettingsTemplate from './Settings.html';

/*@ngInject*/
function SettingsRouting($stateProvider) {
  $stateProvider.state(STATES.MEMBER_SETTINGS, {
    url: '/settings?tab',
    templateUrl: SettingsTemplate,
    controller: 'MemberSettingsController',
    controllerAs: 'settingsCtrl',
    resolve: {
      /*@ngInject*/
      redirect: function(RedirectService) {
        // Only Members should be allowed to access Member Settings.
        //
        // Probably this should be narrowed down to complete Members, as incomplete Members are
        // supposed to be redirected to Registration.
        //
        // Refactoring first, can tighten up later.
        return RedirectService.redirectIfNotMember();
      }
    }
  });
}

export default SettingsRouting;
