import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ActionBarTableContainer = ({ children }) => {
  return <div className="wc-action-bar-table-container">{children}</div>;
};

ActionBarTableContainer.propTypes = {
  children: PropTypes.any
};
