import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import STATES from '../../../../services/States';
import './styles.scss';
import { CircularImage } from '../../../../../common/components/react/circular-image/CircularImage';
import { ConditionalProfileLink } from '../../conditional-profile-link/ConditionalProfileLink';
import { ExpandableTextarea } from '../../../../../common/components/react/expandable-textarea/ExpandableTextarea';
import { filterDate } from '../../../../../common/services/DateUtils';
import { locationSummary } from '../../../../../common/services/Regions';

export const Post = ({ group, canComment, post, member, API, $state }) => {
  const memberProfileState = STATES.OTHER_MEMBER_PROFILE;

  const [displayCommentBoxOverride, setDisplayCommentBoxOverride] = useState(
    false
  );
  const [newCommentText, setNewCommentText] = useState('');
  const [comments, setComments] = useState(post.comments);
  const textarea = useRef(null);

  useEffect(() => {
    if (displayCommentBoxOverride) {
      textarea.current && textarea.current.focus();
    }
  }, [displayCommentBoxOverride]);

  const onClickCommentButton = () => {
    setDisplayCommentBoxOverride(true);
  };

  const submitComment = () => {
    if (!newCommentText) {
      return;
    }

    API.PostComments(group.id, post.id)
      .post({
        content: newCommentText
      })
      .then(response => {
        setComments([...comments, response]);
        setNewCommentText('');
      });
  };

  const updateComment = str => {
    setNewCommentText(str);
  };

  const shouldDisplayNewCommentBox = () => {
    return (
      group.isMember && (post.comments.length > 0 || displayCommentBoxOverride)
    );
  };

  return (
    <React.Fragment>
      <div className="WcPost__RootContainer">
        <div className="WcPost__RootUserContainer">
          <ConditionalProfileLink
            wcCondition={!post.user.isTestAccount}
            profileId={post.user.id}
            userProfileState={memberProfileState}
            wcClassName="WcPost__RootUser"
            url={$state.href(memberProfileState, { id: post.user.id })}
          >
            <CircularImage
              className="WcPost__RootUserImage"
              wcAlt="Profile picture"
              wcSrc={post.user.profilePicture}
            />

            <div className="WcPost__RootUserInfo">
              <p className="WcPost__RootUserName">{post.user.fullName}</p>
              <p className="WcPost__RootUserInfo">{`${
                post.user.currentInternalJobTitle
              }, ${post.user.currentCompany}`}</p>
              <p className="WcPost__RootUserInfo">
                <span className="WcPost__RootLocationSummary">
                  {locationSummary(post.user)}
                </span>
                <span className="WcPost__RootDate">
                  {filterDate(post.dateCreated)}
                </span>
              </p>
            </div>
          </ConditionalProfileLink>
          <div className="flex" />
        </div>

        <p
          className="WcPost__RootText"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </div>

      {canComment && (
        <div className="WcPost__CommentButtonContainer">
          <button
            onClick={onClickCommentButton}
            className="WcPost__CommentButton"
          >
            <span className="zmdi zmdi-comment-alt-text WcPost__CommentButtonIcon" />
            <span className="WcPost__CommentButtonLabel">Comment</span>
          </button>
        </div>
      )}

      {(comments.length > 0 || shouldDisplayNewCommentBox()) && (
        <div className="WcPost__RepliesContainer">
          {comments.map((reply, idx) => (
            <div key={idx} className="WcPost__ReplyContainer">
              <ConditionalProfileLink
                wcCondition={!reply.user.isTestAccount}
                profileId={reply.user.id}
                userProfileState={memberProfileState}
                wcClassName="WcPost__ReplyImageContainer"
                url={$state.href(memberProfileState, { id: reply.user.id })}
              >
                <CircularImage
                  className="WcPost__ReplyUserImage"
                  wcAlt="Profile picture"
                  wcSrc={reply.user.profilePicture}
                />
              </ConditionalProfileLink>

              <div className="WcPost__ReplyTextContainer">
                <ConditionalProfileLink
                  wcCondition={!reply.user.isTestAccount}
                  profileId={reply.user.id}
                  userProfileState={memberProfileState}
                  wcClassName="WcPost__ReplyUserName"
                  url={$state.href(memberProfileState, { id: reply.user.id })}
                >
                  {reply.user.fullName}
                </ConditionalProfileLink>
                <p
                  className="WcPost__ReplyText"
                  dangerouslySetInnerHTML={{ __html: reply.content }}
                />
              </div>

              {/* TODO add sent x days ago label here */}
              <div className="WcPost__ReplyDateContainer">
                <p className="WcPost__ReplyDate">
                  {filterDate(reply.dateCreated)}
                </p>
              </div>
            </div>
          ))}

          {shouldDisplayNewCommentBox() && (
            <div className="WcPost__AddCommentContainer">
              <div className="WcPost__AddCommentUserImageContainer">
                <CircularImage
                  className="WcPost__AddCommentUserImage"
                  wcAlt="Profile picture"
                  wcSrc={member.profilePicture}
                />
              </div>
              <form className="WcPost__NewCommentForm">
                <ExpandableTextarea
                  innerRef={textarea}
                  className="WcPost__TextArea"
                  model={newCommentText}
                  sendOnEnter={true}
                  onSubmit={submitComment}
                  onChange={updateComment}
                  placeholder="Add comment..."
                />
              </form>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

Post.propTypes = {
  group: PropTypes.object,
  canComment: PropTypes.bool,
  post: PropTypes.object,
  member: PropTypes.object,
  API: PropTypes.any,
  $state: PropTypes.any
};
