import controller from './MinimalPositionFieldset.controller';
import template from './MinimalPositionFieldset.html';
import './styles.scss';

export default {
  bindings: {
    position: '<',
    relatedFieldsGroup: '<',
    currentAllowed: '<',
    updateFormFieldError: '<',
    reactForm: '<'
  },
  controller: controller,
  NAME: 'wcMinimalPositionFieldset',
  require: {
    formController: '^form'
  },
  templateUrl: template,
  transclude: true
};
