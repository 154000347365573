import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { HighlightedImageBubble } from '../../../../../../common/components/react/highlighted-image-bubble/HighlightedImageBubble';

export const MessengerRecipientInfo = ({
  profilePictureSrc,
  name,
  jobTitle
}) => {
  return (
    <div className="WcMessengerRecipientInfo__Container">
      <div className="WcMessengerRecipientInfo__Avatar">
        <HighlightedImageBubble src={profilePictureSrc} label="profileImage" />
      </div>

      <div className="WcMessengerRecipientInfo__TitleContainer">
        <h2 className="WcMessengerRecipientInfo__Name">{name}</h2>
        <p className="WcMessengerRecipientInfo__JobTitle">{jobTitle}</p>
      </div>
    </div>
  );
};

MessengerRecipientInfo.propTypes = {
  profilePictureSrc: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string
};
