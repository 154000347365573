import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const Toolbar = ({ children }) => {
  return <div className="WcMessengerToolbar">{children}</div>;
};

Toolbar.propTypes = {
  children: PropTypes.node
};
