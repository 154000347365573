let SortableHeaderDirective = () => {
  return {
    restrict: 'A',
    link: scope => {
      scope.sortBy = header => {
        if (!header.sortable) {
          return;
        }
        let key = header.sortingKey;

        // The 3 header state transitions
        // 1. none -> asc
        // 2. asc -> desc
        // 3. desc -> none

        if (scope.ordering.field !== key) {
          scope.onUpdateOrderingField({ field: key }); // none -> asc
          scope.onUpdateOrderingReverse({ bool: false });
        } else if (scope.ordering.field === key && !scope.ordering.reverse) {
          scope.onUpdateOrderingField({ field: key }); // asc -> desc
          scope.onUpdateOrderingReverse({ bool: true });
        } else {
          scope.onUpdateOrderingField({ field: undefined }); // desc -> none
          scope.onUpdateOrderingReverse({ bool: false });
        }
      };
    }
  };
};

SortableHeaderDirective.$inject = [];
SortableHeaderDirective.NAME = 'sortableHeader';

export default SortableHeaderDirective;
