import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { CircularImage } from '../../../../../../common/components/react/circular-image/CircularImage';
import { MemberProfileOverviewPersonal } from '../personal/MemberProfileOverviewPersonal';
import { MemberProfileOverviewPosition } from '../position/MemberProfileOverviewPosition';
import { ProfessionalSummary } from '../../../../../../common/components/react/professional-summary/ProfessionalSummary';

export const MemberProfileOverview = ({ member, AuthenticationService }) => {
  const [canContact, setCanContact] = useState(false);

  useEffect(() => {
    AuthenticationService.getProfile().then(profile => {
      setCanContact(profile && !profile.hideContact);
    });
  }, []);

  return (
    <React.Fragment>
      {member.isShadow && canContact && (
        <div className="WcMemberProfileNotActive">
          This candidate is not a member of the Winter Circle network
        </div>
      )}
      <div className="WcMemberProfileOverview">
        <div className="WcMemberProfileOverview__PictureContainer">
          <CircularImage
            className="WcMemberProfileOverview__ProfilePicture"
            alt="Profile picture"
            src={member.profilePicture}
          />
        </div>
        <div className="WcMemberProfileOverview__PersonalContainer">
          <MemberProfileOverviewPersonal member={member} />
          {member.currentPosition.company.name &&
            member.currentPosition.internalJobTitle && (
              <MemberProfileOverviewPosition member={member} />
            )}
          <ProfessionalSummary
            className="WcCommonMemberProfileOverview__ProfessionalSummaryContainer"
            member={member}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

MemberProfileOverview.propTypes = {
  member: PropTypes.object,
  AuthenticationService: PropTypes.any
};
