export default function bindValidationBehaviour(
  scope,
  attributes,
  componentCtrl,
  modelCtrl,
  formCtrl
) {
  function setValidity() {
    const isRequired = attributes.hasOwnProperty('required');
    if (formCtrl.$submitted) {
      componentCtrl.isTouched = true;
    }

    modelCtrl.$setValidity(
      'validChipsSelection',
      componentCtrl.checkIsValid() || !componentCtrl.isTouched || !isRequired
    );

    if (!isRequired || componentCtrl.checkIsValid()) {
      modelCtrl.$setValidity('required', true);
    } else if (isRequired && componentCtrl.isTouched) {
      modelCtrl.$setValidity('required', false);
    }
  }

  function setTouched() {
    modelCtrl.$setTouched(componentCtrl.isTouched);
  }

  scope.$watch(() => componentCtrl.isValid, setValidity);
  scope.$watch(() => componentCtrl.model, setValidity);
  scope.$watch(() => formCtrl.$submitted, setValidity, true);

  scope.$watch(
    () => componentCtrl.isTouched,
    () => {
      setTouched();
      setValidity();
    }
  );
}
