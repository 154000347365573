import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

// This component migration is prepared (src/desktop/components/react/profile/edit-container/index.js)
// But can't be used everywhere, because ng-transclude/props.children is not supported by react2angular
export default angular
  .module('wc.components.profile.editContainer', [])
  .component('wcMemberProfileEditContainer', {
    bindings: {
      onEdit: '&',
      onDelete: '&?',
      stickyEdit: '=?'
    },
    templateUrl: templateUrl,
    transclude: true
  });
