import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class controller {
  /*@ngInject*/
  constructor(CompensationCurrency) {
    this.CompensationCurrency = CompensationCurrency; // Used in template
  }
}

export default angular
  .module('wc.components.inputs.selects.limited-options-currency-dropdown', [])
  .component('limitedOptionsCurrencyDropdown', {
    controller: controller,
    templateUrl: templateUrl
  });
