import angular from 'angular';
import template from './template.html';

import STATES from '../../../../services/States';
import getFormattedDateString from '../../../../../common/services/DateUtils';

class NonExecPositionController {
  /*@ngInject*/
  constructor(API, $state, $mdConstant, $timeout, $q, $scope) {
    this.API = API;
    this.$state = $state;
    this.$mdConstant = $mdConstant;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$scope = $scope;
  }

  $onInit() {
    this.current = this.position ? !this.position.endDate : true;
    if (this.position.url) {
      // Check if object is not empty
      // Restangularize object in the meantime, no need to wait for it
      this.API.restangularize(this.position)
        .get()
        .then(restangularized => (this.position = restangularized))
        .catch(this.handleError.bind(this));
    }
  }

  submit() {
    if (this.form.$valid) {
      this.loading = true;
      let data = {
        company: this.position.company,
        positionTitle: this.position.positionTitle,
        startDate: getFormattedDateString(this.position.startDate),
        endDate: this.current
          ? null
          : getFormattedDateString(this.position.endDate)
      };
      let saveMethod = this.position.url
        ? this.API.restangularize(this.position).patch
        : this.API.NonExecPositions.post;
      saveMethod(data)
        .then(this.handleNavigation.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleNavigation() {
    if (this.position.url) {
      let positionElementId = 'non-exec-' + this.position.id;
      return this.$state.go(STATES.MEMBER_PROFILE, {
        scrollToSection: positionElementId
      });
    }
    this.$state.go(STATES.MEMBER_INLINE_EDIT.NON_EXEC_POSITIONS_LIST);
  }

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

const wcInlineEditNonExecPosition = {
  bindings: {
    position: '<'
  },
  controller: NonExecPositionController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.nonExecPosition', [])
  .component('wcInlineEditNonExecPosition', wcInlineEditNonExecPosition);
