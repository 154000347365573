import angular from 'angular';
import './Layout.scss';
import templateUrl from './Layout.html';

const Layout = {
  NAME: 'wcSidebarLayout',
  templateUrl: templateUrl,
  transclude: {
    sidebar: 'wcSidebarLayoutSidebarSlot',
    content: 'wcSidebarLayoutContentSlot'
  }
};

export default angular
  .module('wc.components.sidebars.layout', [])
  .component(Layout.NAME, Layout);
