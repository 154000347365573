export default function bindValidationBehaviour(
  scope,
  attributes,
  controllers
) {
  const chipsDrawerCtrl = controllers.wcSuggestibleChipsDrawerController;
  const modelCtrl = controllers.ngModelController;
  const formCtrl = controllers.formController;

  function validChipsSelection(isRequired) {
    return (
      chipsDrawerCtrl.checkIsValid() ||
      !chipsDrawerCtrl.isTouched ||
      !isRequired ||
      chipsDrawerCtrl.isDisabled
    );
  }

  function setValidity() {
    const isRequired = attributes.hasOwnProperty('required');
    if (formCtrl.$submitted) {
      chipsDrawerCtrl.isTouched = true;
    }

    modelCtrl.$setValidity(
      'validChipsSelection',
      validChipsSelection(isRequired)
    );

    if (validChipsSelection(isRequired)) {
      modelCtrl.$setValidity('required', true);
    } else if (isRequired && chipsDrawerCtrl.isTouched) {
      modelCtrl.$setValidity('required', false);
    }

    if (chipsDrawerCtrl.checkIsValid()) {
      chipsDrawerCtrl.onValid({
        hasSuggestion: chipsDrawerCtrl.hasSuggestion()
      });
    } else {
      chipsDrawerCtrl.onInvalid();
    }
  }

  function setTouched() {
    modelCtrl.$setTouched(chipsDrawerCtrl.isTouched);
  }

  scope.$watch(() => chipsDrawerCtrl.isValid, setValidity);
  scope.$watch(() => chipsDrawerCtrl.suggestedChipsModel, setValidity, true);
  scope.$watch(() => chipsDrawerCtrl.model, setValidity);
  scope.$watch(() => formCtrl.$submitted, setValidity, true);
  scope.$watch(() => chipsDrawerCtrl.isDisabled, setValidity);

  scope.$watch(
    () => chipsDrawerCtrl.isTouched,
    () => {
      setTouched();
      setValidity();
    }
  );
}
