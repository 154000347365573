import React from 'react';
import PropTypes from 'prop-types';

export const FormErrorMessage = ({ children, className }) => {
  return (
    <div className={`WcFormErrorMessage ${className}`}>{children}</div>
  )
}

FormErrorMessage.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string
};
