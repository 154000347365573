import extractDomain from 'extract-domain';

const DefaultConfig = {
  apiVersion: '1',
  rewriteURL: false
};

function _getAPIUrl(
  host = 'localhost',
  protocol = 'https',
  path = '/v2/',
  api = 'api'
) {
  let apiUrl = protocol + '://' + host + path;
  // NOTE: Change to allow staging API to be used with local. Don't use on staging or production.
  //host = 'api.stg.wintercircle.co';
  if (window && window.location && window.location.hostname) {
    host = window.location.hostname;
  }
  let domain = extractDomain(host);
  // Check if we have a domain, as this is be non-local environment
  if (domain) {
    // Find the API url from the hostname (change [app.] and [m.] with [api.])
    let parts = host.split('.'); // decompose hostname
    let sub = parts.shift(); // remove [app.] or [m.]
    // Use api-beta if app is on app-beta or m-beta
    if (sub.indexOf('-beta') !== -1) {
      api = api + '-beta';
    }
    parts.unshift(api); // add [api.] at the beginning
    host = parts.join('.'); // recompose hostname

    apiUrl = protocol + '://' + host + path;
    return apiUrl;
  }
  // The rest is for local config
  let port = '';
  if (window && window.location && window.location.port) {
    port = window.location.port;
  }
  if (port) {
    apiUrl = protocol + '://' + host + ':' + port + path;
  }
  return apiUrl;
}
const PlatformConfig = {
  featureFlags: [
    { key: 'messaging', active: true },
    { key: 'memberGroups', active: true },
    { key: 'memberSearch', active: true },
    { key: 'clientSearch', active: true }
  ]
};
const EnvConfig = {
  apiURL: _getAPIUrl(),
  configName: 'prod',
  googleMapsKey: 'AIzaSyAd6laOW5AbCgE_bZEbBrVCLSHof6wOYJI',
  googleAnalyticsAccountID: 'UA-71572102-2'
};
export default Object.assign(DefaultConfig, PlatformConfig, EnvConfig);
