import angular from 'angular';
import { react2angular } from 'react2angular';
import { AnchorNavigationContainer } from './AnchorNavigationContainer';

export default angular
  .module('wc.components.AnchorNavigationContainer', [])
  // @ts-ignore
  .component(
    'reactAnchorNavigationContainer',
    react2angular(AnchorNavigationContainer, ['tabs'], [])
  );
