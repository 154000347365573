class AcceptContractController {
  /*@ngInject*/
  constructor(API, AuthenticationService) {
    this.loading = true;
    API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .catch(this.handleError.bind(this));

    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.loading = true;
  }

  setProfile(profile) {
    this.profile = profile;
    this.loading = false;
  }

  submit() {
    if (!this.accepted) {
      return;
    }
    this.loading = true;
    let acceptData = {
      termsAccepted: true
    };
    this.API.restangularize(this.profile)
      .patch(acceptData)
      .then(this.handleSuccess.bind(this))
      .catch(this.handleError.bind(this));
  }

  handleSuccess() {
    this.AuthenticationService.refresh()
      .then(this.navigate.bind(this))
      .catch(this.handleError.bind(this));
  }

  navigate() {
    this.AuthenticationService.navigateToDefaultState();
  }

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

AcceptContractController.NAME = 'AcceptContractController';
AcceptContractController.controllerAs = 'acceptCtrl';

export default AcceptContractController;
