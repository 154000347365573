import React from 'react';
import styled from 'styled-components';
import BaseFormControl from '@material-ui/core/FormControl';

// eslint-disable-next-line no-unused-vars
const FormControl = styled(({ validationerror, ...props }) => (
  <BaseFormControl {...props} />
))`
  width: 100%;
  display: flex;

  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: solid 2px
      ${props =>
        props.validationerror ? props.theme.error.A700 : 'rgba(0, 0, 0, 0.05)'};
  }

  .MuiInput-underline:after {
    border-bottom: solid 2px ${props => props.theme.secondary.A400};
  }

  .MuiSelect-select:focus {
    background-color: inherit;
  }

  .MuiInputLabel-root {
    color: #8c8c8c;
    font-family: 'ProximaNova', sans-serif;
    font-size: 16px;
    overflow: visible;
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    color: ${props =>
      props.validationerror ? props.theme.error.A700 : '#8c8c8c'};
  }

  .MuiSvgIcon-root {
    width: 18px;
  }

  .MuiFormLabel {
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .MuiInputBase-input {
    color: rgb(44, 44, 52);
    font-family: 'ProximaNova', sans-serif;
    font-size: 16px;
    line-height: 19px;
  }
`;

export default FormControl;
