import angular from 'angular';

const WeakParagraph = {
  NAME: 'wcWeakParagraph',
  template: '<p class="WcWeakParagraph" ng-transclude></p>',
  transclude: true
};

export default angular
  .module('wc.components.typography.weakParagraph', [])
  .component(WeakParagraph.NAME, WeakParagraph);
