import STATES from '../../../services/States';
import { MAIN_SEARCH_ID } from '../../../services/MemberLists.service';
import InputWithTimePeriodSelector from '../../timeperiodinputs/input';
import TimePeriodGroups from '../../timeperiodinputs/TimePeriodGroups';
import SearchSidebar from '../searchsidebar';
import templateUrl from './template.html';

export const MEMBER_SEARCH_SIDEBAR_ID = 'wcMemberSearchSidebar';

class MemberSearchSidebarCtrl {
  /*@ngInject*/
  constructor($state, API, wcMemberList, wcSidebar) {
    this.API = API;
    this.$state = $state;
    this.wcMemberList = wcMemberList;
    this.wcSidebar = wcSidebar;

    this.searchSidebarId = MEMBER_SEARCH_SIDEBAR_ID;
    this.memberListId = MAIN_SEARCH_ID;
    this.TimePeriodGroups = TimePeriodGroups;

    this.wcMemberList.initMainSearch();
  }

  onInit() {
    this.wcSidebar.register(MEMBER_SEARCH_SIDEBAR_ID);
  }

  onSearch() {
    this.$state.go(STATES.MEMBER_SEARCH_RESULTS);
  }
}

const MemberSearchSidebar = {
  NAME: 'wcMemberSearchSidebar',
  controller: MemberSearchSidebarCtrl,
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.memberSearchSidebar', [
    InputWithTimePeriodSelector.name,
    SearchSidebar.name
  ])
  .component(MemberSearchSidebar.NAME, MemberSearchSidebar);
