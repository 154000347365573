import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select from '../../inputs/selects/Select';

const SelectContainer = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 65px;
  width: ${props => (props.halfWidth ? 50 : 100)}%;
`;

const GenderSelect = ({
  genders,
  gender,
  update,
  updateTouched,
  halfWidth = false
}) => {
  const handleBlur = () => {
    updateTouched('gender', true);
  };

  const valueToItem = value => ({
    value: value.value,
    display: value.displayName,
    key: value.value
  });

  return (
    <SelectContainer halfWidth={halfWidth}>
      <Select
        options={genders}
        value={gender}
        label="Gender"
        onChange={update}
        hasError={false}
        onBlur={handleBlur}
        checkForError={false}
        errorText=""
        valueToItem={valueToItem}
      />
    </SelectContainer>
  );
};

GenderSelect.propTypes = {
  genders: PropTypes.array,
  gender: PropTypes.object,
  update: PropTypes.func,
  updateTouched: PropTypes.func,
  halfWidth: PropTypes.bool
};

export default GenderSelect;
