import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ActiveFilterCount = ({ count }) => {
  return (
    <React.Fragment>
      {count && <div className="WcMemberListActiveFilterCount">{count}</div>}
    </React.Fragment>
  );
};

ActiveFilterCount.propTypes = {
  count: PropTypes.number
};
