import angular from 'angular';

import longlistDropdown from './longlistDropdown';
import resultsCount from './resultsCount';
import searchResults from './searchResults';

export default angular.module('wc.components.search', [
  longlistDropdown.name,
  resultsCount.name,
  searchResults.name
]);
