import angular from 'angular';
import uiRouter from 'angular-ui-router';
require('imports-loader?angular=angular!angular-feature-flags');

import 'angular-elastic';

import ChatService from './Chat.service';
import MessengerService from './Messenger.service';
import Toolbar from './toolbar/Toolbar.component';

import ConversationDetail from './conversationDetail/ConversationDetail.component';
import NewMessageForm from './newMessageForm/NewMessageForm.component';

// react
import ReactStatusIndicator from './../react/messenger/status-indicator';
import ReactMessengerContacts from '../react/messenger/contacts/container';
import ReactMessageBubbleUser from '../react/messenger/message-bubble-user';
import ReactMessageBubblePartner from '../react/messenger/message-bubble-partner';
import ReactConversationDetail from '../react/messenger/conversation-detail';
import ReactNewMessageForm from '../react/messenger/new-message-form';

export default angular
  .module('wc.messenger', [
    uiRouter,
    'monospaced.elastic',
    'feature-flags',
    ReactStatusIndicator.name,
    ReactMessengerContacts.name,
    ReactMessageBubbleUser.name,
    ReactMessageBubblePartner.name,
    ReactConversationDetail.name,
    ReactMessageBubblePartner.name,
    ReactNewMessageForm.name
  ])
  .component(ConversationDetail.NAME, ConversationDetail)
  .component(NewMessageForm.NAME, NewMessageForm)
  .component(Toolbar.NAME, Toolbar)
  .service(ChatService.NAME, ChatService)
  .service(MessengerService.NAME, MessengerService);
