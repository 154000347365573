import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wc.components.actionBar.main', [])
  .component('wcActionBarMain', {
    templateUrl: templateUrl,
    transclude: true
  });
