import STATES from '../../services/States';

export default class MembersController {
  /*@ngInject*/
  constructor(
    $state,
    API,
    AuthenticationService,
    featureFlags,
    MessengerService
  ) {
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.MessengerService = MessengerService;
    this.$state = $state;
    this.STATES = STATES;

    if (featureFlags.isOn('messaging')) {
      this._initMessenger();
    }
  }

  showProfileButton() {
    return (
      this.AuthenticationService.isProfileComplete() &&
      this.$state.current.name !== STATES.MEMBER_PROFILE
    );
  }

  _initMessenger() {
    this.API.User.get().then(user =>
      this.MessengerService.init(user, {
        conversation: this.STATES.MEMBER_CONVERSATION,
        list: this.STATES.MEMBER_MESSAGES,
        redirectListState: true
      })
    );
  }
}

MembersController.controllerAs = 'membersCtrl';
