import AllowSuggestionsTemplateUrl from './AllowSuggestions.html';
import './AllowSuggestions.scss';

const inject = ['API'];

let AllowSuggestions = API => {
  return {
    restrict: 'E',
    templateUrl: AllowSuggestionsTemplateUrl,
    scope: {
      endpoint: '=',
      results: '=',
      suggestion: '=',
      labelField: '@',
      keyEvent: '=',
      inputContainer: '=',
      successCallback: '='
    },
    link: scope => {
      scope.$watch('results', (currResults, prevResults) => {
        scope.currResultsEmpty = currResults ? currResults.length === 0 : false;
        scope.prevResultsEmpty = prevResults ? prevResults.length === 0 : false;
      });

      scope.$watch('keyEvent', () => {
        if (scope.keyEvent && scope.keyEvent.keyCode === 13) {
          scope.suggest(scope.keyEvent);
        }
      });

      scope.isSuggestionEmpty = () => {
        return !scope.suggestion;
      };

      scope.areResultsEmpty = () => {
        return scope.prevResultsEmpty || scope.currResultsEmpty;
      };

      scope.isSuggestionInResults = () => {
        let val = false;
        if (scope.results) {
          scope.results.forEach(item => {
            if (item[scope.labelField] === scope.suggestion) {
              val = true;
            }
          });
        }
        return val;
      };

      scope.show = () => {
        return (
          !scope.isSuggestionEmpty() &&
          scope.areResultsEmpty() &&
          !scope.isSuggestionInResults()
        );
      };

      scope.suggest = event => {
        if (scope.show()) {
          let input = scope.inputContainer
            .find('md-autocomplete')
            .find('md-input-container');

          if (!event || input.hasClass('md-input-focused')) {
            let postData = {};
            postData[scope.labelField] = scope.suggestion;
            scope.endpoint
              .post(postData)
              .then(
                scope.handleSuccess.bind(scope),
                scope.handleError.bind(scope)
              );
          }
        }
      };

      scope.handleSuccess = response => {
        let successMessage = `Suggestion ${
          response.data[scope.labelField]
        } has been added`;
        scope.successCallback(response);
        API.handleSuccess(successMessage);
      };

      scope.handleError = error => {
        let errorMessage =
          'Sorry we could not add your suggestion. Please contact our support team.';
        let errorHandler = API.handleError(errorMessage);
        errorHandler(error);
      };
    }
  };
};

AllowSuggestions.$inject = inject;
AllowSuggestions.NAME = 'allowSuggestions';

export default AllowSuggestions;
