import React from 'react';
import PropTypes from 'prop-types';

export const AnchorNavigationContainer = ({ tabs, children }) => {
  return (
    <div className="WcMemberProfileContent">
      <div id="profileBoundingBox">
        <tabs
          style={{
            position: 'sticky',
            top: '0px',
            zIndex: 100
          }}
        >
          {tabs}
        </tabs>

        {children}
      </div>
    </div>
  );
};

AnchorNavigationContainer.propTypes = {
  tabs: PropTypes.node,
  children: PropTypes.node
};
