import React from 'react';
import PropTypes from 'prop-types';
import angular from 'angular';
import { react2angular } from 'react2angular';

import TextField from '../../inputs/TextField';

export const EmailField = ({
  initialValue,
  updateValue,
  updateError,
  updateTouched,
  touched,
  formSubmitted,
  label,
  halfWidth = false
}) => {
  const emailRegExp = new RegExp(
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+/.source +
      /(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*/.source +
      /@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+/.source +
      /[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.source
  );

  const errorHandler = value => {
    if (!value) {
      return {
        hasError: true,
        errorText: 'This field is required'
      };
    }
    if (!emailRegExp.test(value)) {
      return {
        hasError: true,
        errorText: 'The email address is not valid'
      };
    }
    return {
      hasError: false,
      errorText: ''
    };
  };

  const hasError = value => errorHandler(value).hasError;

  const errorText = value => errorHandler(value).errorText;

  return (
    <TextField
      fieldName="email"
      label={label}
      initialValue={initialValue}
      updateValue={updateValue}
      hasError={hasError}
      updateError={updateError}
      errorText={errorText}
      updateTouched={updateTouched}
      touched={touched}
      formSubmitted={formSubmitted}
      autocomplete="email"
      halfWidth={halfWidth}
    />
  );
};

EmailField.propTypes = {
  initialValue: PropTypes.string,
  updateValue: PropTypes.func.isRequired,
  updateError: PropTypes.func.isRequired,
  updateTouched: PropTypes.func,
  touched: PropTypes.bool,
  formSubmitted: PropTypes.bool.isRequired,
  label: PropTypes.string,
  halfWidth: PropTypes.bool
};

EmailField.defaultProps = {
  label: 'Email Address'
};

export default angular
  .module('wc.components.EmailField', [])
  .component(
    'reactEmailField',
    react2angular(EmailField, [
      'initialValue',
      'updateValue',
      'updateError',
      'updateTouched',
      'touched',
      'formSubmitted',
      'label'
    ])
  );
