const DEBOUNCE_TIME_MILLISECONDS = 500;
const NAME = 'wcDebounce';

function Debounce() {
  return {
    link: (scope, element, attributes, ngModelController) => {
      function setOptions() {
        if (ngModelController.$options) {
          ngModelController.$options.debounce = DEBOUNCE_TIME_MILLISECONDS;
        } else {
          ngModelController.$options = {
            updateOnDefault: true,
            debounce: DEBOUNCE_TIME_MILLISECONDS
          };
        }
      }

      if (NAME in attributes && scope.$eval(attributes[NAME]) !== false) {
        scope.$watch(() => ngModelController.$options, setOptions);
      }
    },
    restrict: 'A',
    require: 'ngModel'
  };
}

Debounce.NAME = NAME;

export default angular
  .module('wc.inputs.debounce', [])
  .directive(Debounce.NAME, Debounce);
