class SignoutDialog {
  /*@ngInject*/
  constructor(DialogService) {
    this.DialogService = DialogService;
  }

  showLogoutDialog() {
    let locals = {
      title: 'Are you sure you want to sign out?',
      ariaLabel: 'Sign out',
      okAction: 'Yes',
      cancel: 'No'
    };
    return this.DialogService.showConfirmDialog(locals);
  }
}

SignoutDialog.NAME = 'SignoutDialogService';

export default SignoutDialog;
