import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const CardList = ({ children }) => {
  return <ul className="CardList">{children}</ul>;
};

CardList.propTypes = {
  children: PropTypes.node
};
