import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ConditionalProfileLink = ({
  wcCondition,
  profileId = '',
  lid = '',
  userProfileState,
  wcClass = '',
  url = '',
  children,
  $state
}) => {
  const href = $state
    ? $state.href(userProfileState, { id: profileId, lid: lid })
    : url;
  return (
    <React.Fragment>
      {wcCondition ? (
        <a className={wcClass} href={href}>
          {children}
        </a>
      ) : (
        <span className={wcClass}>{children}</span>
      )}
    </React.Fragment>
  );
};

ConditionalProfileLink.propTypes = {
  wcCondition: PropTypes.bool,
  profileId: PropTypes.number,
  lid: PropTypes.string,
  userProfileState: PropTypes.string,
  wcClass: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.node,
  $state: PropTypes.any
};
