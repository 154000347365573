import './CardList.scss';
import templateUrl from './CardList.html';

class CardListController {}

/*@ngInject*/
export default {
  NAME: 'cardList',
  transclude: true,
  bindings: {
    wcInfiniteScrollContainer: '@',
    wcInfiniteScroll: '&'
  },
  controller: CardListController,
  templateUrl: templateUrl
};
