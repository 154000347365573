import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import alertCircle from './assets/alertCircle.svg';
import chatBubblesIcon from './assets/chatBubbles.svg';
import chatIcon from './assets/chat.svg';
import checkIcon from './assets/check.svg';
import cross from './assets/cross.svg';
import cvIcon from './assets/cv.svg';
import leftArrowIcon from './assets/leftArrow.svg';
import padlockIcon from './assets/padlock.svg';
import profileIcon from './assets/profile.svg';
import profilePictureIcon from './assets/profilePicture.svg';
import searchIcon from './assets/search.svg';
import sendIcon from './assets/send.svg';
import settingsIcon from './assets/settings.svg';

const attributeToSrcMap = {
  alertCircle: alertCircle,
  padlock: padlockIcon,
  profile: profileIcon,
  profilePicture: profilePictureIcon,
  settings: settingsIcon,
  chat: chatIcon,
  chatBubbles: chatBubblesIcon,
  check: checkIcon,
  cross: cross,
  cv: cvIcon,
  leftArrow: leftArrowIcon,
  search: searchIcon,
  send: sendIcon
};

export const Icon = ({ icon, accent }) => {
  return (
    <div className="WcIcon">
      <span
        dangerouslySetInnerHTML={{ __html: attributeToSrcMap[icon] }}
        className={`${accent ? 'WcIconAccent' : ''}`}
      />
    </div>
  );
};

Icon.propTypes = { icon: PropTypes.string, accent: PropTypes.bool };
