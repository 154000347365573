import angular from 'angular';
import { react2angular } from 'react2angular';
import { OtherMemberProfileActions } from './OtherMemberProfileActions';

export default angular
  .module('wc.components.OtherMemberProfileActions', [])
  // @ts-ignore
  .component(
    'reactOtherMemberProfileActions',
    react2angular(
      OtherMemberProfileActions,
      ['member', 'groups'],
      [
        'API',
        'MessengerService',
        'GroupInvitesService',
        'GroupDialogService',
        '$q'
      ]
    )
  );
