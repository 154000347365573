import angular from 'angular';
import { react2angular } from 'react2angular';
import { ViewTitle } from './ViewTitle';

export default angular
  .module('wc.components.ReactViewTitle', [])
  // @ts-ignore
  .component(
    'reactViewTitle',
    react2angular(ViewTitle, ['topMargin', 'text', 'className'])
  );
