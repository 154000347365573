import angular from 'angular';

const wcFormErrorMessage = {
  NAME: 'wcFormErrorMessage',
  template:
    '<div class="WcFormErrorMessage"><ng-transclude></ng-transclude></div>',
  transclude: true
};

export default angular
  .module('wcComponents.typography.formErrorMessage', [])
  .component(wcFormErrorMessage.NAME, wcFormErrorMessage);
