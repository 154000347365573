import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Section = styled.section`
  box-shadow: 0 3px 25px 1px rgba(0, 0, 0, 0.08);
  padding-bottom: 0;
  background-color: white;
  margin-bottom: 50px;
  width: 100%;
  padding: ${props => {
    if (props.noPadding) {
      return '0';
    }
    if (props.noTopPadding) {
      return '0 65px 75px';
    }
    return '65px 75px';
  }};
`;

const Page = ({ noPadding, noTopPadding, children, id }) => {
  return (
    <Section id={id} noPadding={noPadding} noTopPadding={noTopPadding}>
      {children}
    </Section>
  );
};

Page.propTypes = {
  noTopPadding: PropTypes.bool,
  noPadding: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string
};

export default Page;
