let scroll = /*@ngInject*/ $timeout => {
  return {
    restrict: 'A',
    scope: {
      wcScrollTransfer: '@'
    },
    link: {
      post: (scope, element) => {
        $timeout(() => {
          let target = $(scope.wcScrollTransfer);
          $timeout(() => {
            element.on('wheel', event => {
              if (event.target === event.currentTarget) {
                target.scrollTop(target.scrollTop() + event.deltaY);
              }
            });
          });
        });
      }
    }
  };
};

scroll.NAME = 'wcScrollTransfer';

let scrollModule = angular
  .module('wc.components.scrollTransfer', [])
  .directive(scroll.NAME, scroll);

export default scrollModule;
