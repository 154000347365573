import ListInputUrl from './ListInput.html';

function guidGenerator() {
  let S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
}

let ListInputDirective = () => {
  return {
    restrict: 'E',
    templateUrl: ListInputUrl,
    scope: {
      model: '=',
      placeholder: '@?'
    },
    link: scope => {
      scope.id = guidGenerator();
      function add() {
        if (!scope.new) {
          return;
        }
        let index = scope.model.indexOf(scope.new);
        if (index === -1) {
          scope.model.push(scope.new);
          scope.new = '';
        } else {
          $(`ul#${scope.id}`)
            .find(`li:nth-child(${index + 1})`)
            .fadeIn(100)
            .fadeOut(150)
            .fadeIn(100)
            .fadeOut(150)
            .fadeIn(100);
        }
      }

      function remove(index) {
        scope.model.splice(index, 1);
      }

      scope.add = add;
      scope.remove = remove;
    }
  };
};

ListInputDirective.NAME = 'listInput';
ListInputDirective.$inject = [];
export default ListInputDirective;
