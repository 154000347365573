import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

const IconToggleLink = {
  NAME: 'wcIconToggleLink',
  bindings: {
    ariaLabel: '@',
    label: '@',
    iconClass: '@wcIconClass',
    isActive: '<wcIsActive',
    onClick: '&wcOnClick',
    uiSref: '@wcUiSref',
    params: '<?'
  },
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.buttons.icontogglelink', [])
  .component(IconToggleLink.NAME, IconToggleLink);
