import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wc.components.conditional-profile-link', [])
  .component('wcConditionalProfileLink', {
    bindings: {
      wcCondition: '<',
      profileId: '<',
      lid: '<',
      userProfileState: '<',
      wcClass: '@?'
    },
    templateUrl: templateUrl,
    transclude: true
  });
