import templateUrl from './RemovableChipsList.html';

const wcRemovableChipsList = {
  NAME: 'wcRemovableChipsList',
  controllerAs: 'removableChipsListCtrl',
  templateUrl: templateUrl,
  bindings: {
    model: '<',
    suggestedChipsModel: '<',
    onChipRemove: '&',
    onSuggestedChipRemove: '&',
    onChipFocus: '&',
    onChipClick: '&',
    onChipBlur: '&'
  }
};

export default wcRemovableChipsList;
