import ChangeContactDialogController from './dialogs/contact/ChangeContactDialog.controller';
import ChangeEmailDialogTemplate from './dialogs/contact/ChangeEmailDialog.html';
import ChangeMobileDialogTemplate from './dialogs/contact/ChangeMobileDialog.html';
import ChangePasswordDialogController from './dialogs/password/ChangePasswordDialog.controller';
import ChangePasswordDialogTemplate from './dialogs/password/ChangePasswordDialog.html';

import { Actions as UserActions } from '../../../../common/redux/user/actions';
import { Actions as ChangeContactActions } from '../../../../common/redux/changeContact/actions';

const MapStateToProps = state => ({
  email: state.userReducer.user.email,
  mobileNumber: state.userReducer.user.mobileNumber,
  mobileNumberSharing: state.userReducer.user.mobileNumberSharing,
  newEmail: state.changeContactReducer.newEmail,
  newMobileNumber: state.changeContactReducer.newMobileNumber
});

const MapDispatchToProps = dispatch => ({
  updateMobileNumberSharing: mobileNumberSharing => {
    UserActions.updateUser(dispatch, {
      mobileNumberSharing
    });
  },
  updateUser: user => {
    UserActions.updateUser(dispatch, {
      ...user
    });
  },
  updateNewEmail: email => {
    ChangeContactActions.updateEmail(dispatch, email);
  },
  updateNewMobileNumber: mobileNumber => {
    ChangeContactActions.updateMobileNumber(dispatch, mobileNumber);
  }
});

export default class SensitiveSettingsController {
  /*@ngInject*/
  constructor($ngRedux, API, DialogService, ngToast, AuthenticationService) {
    this.$ngRedux = $ngRedux;
    this.DialogService = DialogService;
    this.AuthenticationService = AuthenticationService;
    this.API = API;
    this.ngToast = ngToast;
    this.isMember = this.AuthenticationService.isMember();

    this.reactForm = {
      email: {
        error: false
      },
      mobileNumber: {
        error: false
      },
      mobileNumberSharing: {
        error: false
      }
    };
  }

  reactFormValid = () => {
    for (let field in this.reactForm) {
      if (this.reactForm[field].error) {
        return false;
      }
    }
    return true;
  };

  reactFormUpdateError = (field, error) => {
    if (!this.reactForm[field]) {
      return;
    }
    this.reactForm[field].error = error;
  };

  resetReactForm = () => {
    for (let field in this.reactForm) {
      this.reactForm[field].error = false;
    }
  };

  resetFields = () => {
    this.resetEmailField();
    this.resetMobileNumberField();
  };

  resetEmailField = () => this.updateNewEmail('');

  resetMobileNumberField = () => this.updateNewMobileNumber('');

  resetFormState = () => {
    this.resetReactForm();
    this.resetFields();
  };

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(
      MapStateToProps,
      MapDispatchToProps
    )(this);

    this.updateUser({
      email: this.user.email,
      mobileNumber: this.user.mobileNumber,
      mobileNumberSharing: this.user.contactSharing
    });

    delete this.user.email;
    delete this.user.mobileNumber;
    delete this.user.contactSharing;
  }

  $onDestroy() {
    this.unsubscribe();
  }

  editEmail = () => {
    this.showEmailDialog()
      .then(this.saveUser.bind(this))
      .finally(this.resetFormState);
  };

  editMobileNumber = () => {
    this.showMobileDialog()
      .then(this.saveUser.bind(this))
      .finally(this.resetFormState);
  };

  editPassword = () => {
    this.showPasswordDialog().then(this.saveUser.bind(this));
  };

  changeMobileNumberSharing = mobileNumberSharing => {
    this.updateMobileNumberSharing(mobileNumberSharing);
    this.onSharingSettingChange({ sharingSetting: mobileNumberSharing });
  };

  showEmailDialog() {
    return this.DialogService.show({
      locals: {
        user: this.user,
        newEmail: this.newEmail,
        updateNewEmail: this.updateNewEmail,
        reactFormUpdateError: this.reactFormUpdateError,
        reactFormValid: this.reactFormValid
      },
      templateUrl: ChangeEmailDialogTemplate,
      controller: ChangeContactDialogController
    });
  }

  showMobileDialog() {
    return this.DialogService.show({
      locals: {
        user: this.user,
        newMobileNumber: this.newMobileNumber,
        updateNewMobileNumber: this.updateNewMobileNumber,
        reactFormUpdateError: this.reactFormUpdateError,
        reactFormValid: this.reactFormValid
      },
      templateUrl: ChangeMobileDialogTemplate,
      controller: ChangeContactDialogController
    });
  }

  showPasswordDialog() {
    return this.DialogService.show({
      locals: {
        user: this.user
      },
      templateUrl: ChangePasswordDialogTemplate,
      controller: ChangePasswordDialogController
    });
  }

  saveUser({ password, currentPassword }) {
    const email = this.newEmail ? this.newEmail : this.email;
    const mobileNumber = this.newMobileNumber
      ? this.newMobileNumber
      : this.mobileNumber;

    let patchData = {
      email,
      mobileNumber,
      password: password,
      currentPassword: currentPassword
    };
    this.API.User.patch(patchData)
      .then(this.setUser.bind(this))
      .catch(this.API.handleError('Incorrect password'));
  }

  setUser(user) {
    this.updateUser({
      email: user.email,
      mobileNumber: user.mobileNumber
    });

    this.onUserChange(user);
    this.ngToast.success('Update successful');
  }
}
