import React, { Fragment, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import SavedEducation from './SavedEducation';
import EducationForm from './EducationForm';
import InverseButton from '../buttons/InverseButton/InverseButton';
import { default as BaseFormSectionTitle } from '../FormSectionTitle';
import { default as BaseSolidButton } from '../buttons/SolidButton';
import { Actions as ProfilesActions } from '../../../../common/redux/profiles/actions';
import { reducer, initialState } from './reducer';
import STATES from '../../../services/States';

const SavedItemsContainer = styled.div`
  margin-bottom: 12px;
`;

const SolidButton = styled(BaseSolidButton)`
  &.root {
    margin-left: 10px;
  }
`;

const ButtonContainer = styled.div`
  text-align: ${props => (props.showBackToProfile ? 'right' : 'initial')};
  margin-top: ${props => (props.showBackToProfile ? '40px' : '0')};
`;

const FormSectionTitle = styled(BaseFormSectionTitle)`
  margin: 32px 0 24px;
`;

const EducationAccordion = ({
  qualifications,
  showBackToProfile = false,
  profileSection = '',
  $state,
  API,
  $scope
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const educations = useSelector(
    state => state.profilesReducer.myProfile.educations,
    shallowEqual
  );
  const reduxDispatch = useDispatch();

  // FRAN: ROUTER to be removed later
  const backToProfile = useCallback(() => {
    let scrollToSection = profileSection ? profileSection : false;
    $state.go(STATES.MEMBER_PROFILE, { scrollToSection: scrollToSection });
  }, [profileSection]);

  const onDelete = education => {
    return API.restangularize(education).remove();
  };

  const onSave = useCallback(
    async education => {
      const educationData = {
        school: education.school,
        qualification: education.qualification
          ? education.qualification.id
          : null,
        fieldOfStudy: education.fieldOfStudy,
        year: education.year || null
      };

      if (education.id) {
        dispatch({ type: 'UPDATING', id: education.id });
        try {
          const result = await API.restangularize(education).patch(
            educationData
          );
          const newEducations = educations.map(education => {
            if (education.id === result.id) {
              const qualification = qualifications.find(
                qualification => qualification.id === result.qualification
              );
              return {
                ...result,
                qualification: qualification
              };
            }
            return education;
          });

          ProfilesActions.updateEducations(reduxDispatch, newEducations);
          dispatch({ type: 'UPDATING_SUCCESSFUL', id: education.id });
        } catch (error) {
          dispatch({ type: 'UPDATING_ERROR', id: education.id });
          API.handleError()(error);
          // Fran: remove once we have a toast in react with the API.
          // Due to async, angular doesn't know when the promise has completed. Have to trigger the digest cycle manually.
          $scope.$apply();
        }

        return;
      }

      try {
        dispatch({ type: 'ADDING_NEW_EDUCATION' });
        const result = await API.Educations.post(educationData);
        const qualification = qualifications.find(
          qualification => qualification.id === result.qualification
        );
        const newEducations = [
          ...educations,
          {
            ...result,
            qualification
          }
        ];
        ProfilesActions.updateEducations(reduxDispatch, newEducations);
        dispatch({ type: 'ADDING_NEW_EDUCATION_SUCCESSFUL' });
      } catch (error) {
        dispatch({ type: 'ADDING_NEW_EDUCATION_ERROR' });
        API.handleError()(error);
        // Fran: remove once we have a toast in react with the API.
        // Due to async, angular doesn't know when the promise has completed. Have to trigger the digest cycle manually.
        $scope.$apply();
      }
    },
    [reduxDispatch, dispatch, educations, qualifications, API, $scope]
  );

  const removeItem = useCallback(
    async educationIndex => {
      const newEducations = [...educations];

      newEducations.splice(educationIndex, 1);
      ProfilesActions.updateEducations(reduxDispatch, newEducations);

      try {
        await onDelete(educations[educationIndex]);
      } catch (error) {
        ProfilesActions.updateEducations(reduxDispatch, educations);
        API.handleError()(error);
        // Fran: remove once we have a toast in react with the API.
        // Due to async, angular doesn't know when the promise has completed. Have to trigger the digest cycle manually.
        $scope.$apply();
      }
    },
    [reduxDispatch, educations, API, $scope]
  );

  const isInitialForm = useCallback(() => {
    return !educations.length;
  }, [educations]);

  const handleShowNewForm = () => {
    dispatch({ type: 'SHOW_NEW_FORM', payload: !state.showNewForm });
  };

  const handleSaveNewItem = async educationToSubmit => {
    await onSave(educationToSubmit);
  };

  const shouldShowButtons = () => {
    const educationsExpanded = Object.values(state).some(
      education => education.isExpanded
    );

    return !state.showNewForm && !isInitialForm() && !educationsExpanded;
  };

  const getStateItemPropertyValue = (itemId, property) => {
    return (state[itemId] && state[itemId][property]) || false;
  };

  return (
    <Fragment>
      <FormSectionTitle>Education</FormSectionTitle>
      {educations.map((education, arrayIndex) => (
        <SavedItemsContainer key={education.id}>
          <SavedEducation
            education={education}
            educationIndex={arrayIndex}
            onSave={onSave}
            removeItem={removeItem}
            isInitialForm={isInitialForm()}
            qualifications={qualifications}
            showBackToProfile={showBackToProfile}
            backToProfile={backToProfile}
            dispatch={dispatch}
            isExpanded={getStateItemPropertyValue(education.id, 'isExpanded')}
            isEducationUpdating={getStateItemPropertyValue(
              education.id,
              'updating'
            )}
          />
        </SavedItemsContainer>
      ))}
      {(state.showNewForm || isInitialForm()) && (
        <EducationForm
          isEducationUpdating={state.isAddingEducation}
          showBackToProfile={showBackToProfile}
          backToProfile={backToProfile}
          cancelForm={handleShowNewForm}
          onSave={handleSaveNewItem}
          isInitialForm={isInitialForm()}
          qualifications={qualifications}
        />
      )}
      {shouldShowButtons() && (
        <ButtonContainer showBackToProfile={showBackToProfile}>
          {showBackToProfile && (
            <Fragment>
              <InverseButton
                type="button"
                onClick={backToProfile}
                icon="PersonIcon"
              >
                BACK TO PROFILE
              </InverseButton>
              <SolidButton
                type="submit"
                onClick={handleShowNewForm}
                icon="AddIcon"
              >
                ADD ANOTHER EDUCATION
              </SolidButton>
            </Fragment>
          )}
          {!showBackToProfile && (
            <InverseButton
              type="button"
              onClick={handleShowNewForm}
              icon="AddIcon"
            >
              ADD ANOTHER EDUCATION
            </InverseButton>
          )}
        </ButtonContainer>
      )}
    </Fragment>
  );
};

EducationAccordion.propTypes = {
  qualifications: PropTypes.array.isRequired,
  showBackToProfile: PropTypes.bool,
  profileSection: PropTypes.string,
  $state: PropTypes.object.isRequired,
  API: PropTypes.object.isRequired,
  $scope: PropTypes.object.isRequired
};

export default EducationAccordion;
