import SelectAllLonglistsCheckbox from './cells/SelectAllLonglistsCheckbox.html';
import CheckboxCell from './cells/CheckboxCell.html';

import {
  checkAllSelected,
  toggleSelectAll
} from '../../services/MultipleSelection';

export let sortableTableLinkFunction = (
  scope,
  elem,
  attr,
  SortableTableController
) => {
  scope.$watch(
    () => SortableTableController.data,
    () => {
      SortableTableController.extra.allSelected = checkAllSelected(
        SortableTableController.data
      );
    },
    true
  );

  let checkboxHeader = {
    headerInclude: SelectAllLonglistsCheckbox,
    include: CheckboxCell,
    sortable: false,
    classes: ['CheckboxCell']
  };

  // Add a column to table headers for checkboxes
  if (SortableTableController.headers[0].include !== checkboxHeader.include) {
    SortableTableController.headers.unshift(checkboxHeader);
  }

  SortableTableController.extra.selectAll = () => {
    toggleSelectAll(SortableTableController.data);
  };
};

/**
 * @ngdoc directive
 * @restrict A
 *
 * @description
 * Adds a checkboxes column to the sortable-table. Selection info then becomes available via.
 * the isSelected property of each data item (row) in the table.
 *
 * Usage: <sortable-table sortable-table-checkboxes ...></sortable-table>
 */
let sortableTableCheckboxes = () => {
  return {
    restrict: 'A',
    require: '^sortableTable',
    link: sortableTableLinkFunction
  };
};

sortableTableCheckboxes.NAME = 'sortableTableCheckboxes';

export default sortableTableCheckboxes;
