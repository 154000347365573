import { cloneDeep } from 'lodash';

class RelatedFormController {
  constructor($scope) {
    this.idField = '_relatedFormId';
    this.counter = 0;

    this.buttonLabels = this.buttonLabels
      ? this.buttonLabels
      : {
          addAnother: 'Add another',
          addNew: 'Add new',
          save: 'Save'
        };

    this.showForm = !this.model.length;

    this.removeCallback = this.removeCallback ? this.removeCallback : () => {};

    $scope.$watch(
      () => this.model,
      () => {
        this.model.map(this.ensureIdFieldExists.bind(this));
        this.showForm = !this.model.length;
        this.addButtonLabel = this.model.length
          ? this.buttonLabels.addAnother
          : this.buttonLabels.addNew;
      },
      true
    );

    $scope.$watch(() => this.hideButtons, this.setButtonVisibility.bind(this));
  }

  setButtonVisibility() {
    this.showAddButton = !this.editing;
    this.showSaveButton = this.editing;
    this.addButtonLabel = this.model.length
      ? this.buttonLabels.addAnother
      : this.buttonLabels.addNew;
  }

  edit(item) {
    this.showForm = true;
    this.editing = true;
    this.new = cloneDeep(item);
    this.setButtonVisibility();
  }

  ensureIdFieldExists(item) {
    if (angular.isUndefined(item[this.idField])) {
      item[this.idField] = this.counter++;
    }
    return item;
  }

  save() {
    if (
      this.beforeSaveCallback &&
      !this.beforeSaveCallback({ item: this.new })
    ) {
      return;
    }
    if (!this.editing) {
      this.addNew();
    }
    for (let i = 0; i < this.model.length; i++) {
      if (this.model[i][this.idField] === this.new[this.idField]) {
        this.model[i] = Object.assign(this.model[i], this.new);
        this.new = {};
        this.editing = false;
        this.showForm = false;
        this.setButtonVisibility();
        return;
      }
    }
  }

  remove(item) {
    let matchingId = this.model.indexOf(
      this.model.find(i => i[this.idField] === item[this.idField])
    );
    if (matchingId > -1) {
      this.removeCallback({ item: this.model[matchingId] });
      this.model.splice(matchingId, 1);
    }
  }

  addNew() {
    this.model.push(Object.assign({}, this.ensureIdFieldExists(this.new)));
    this.new = {};
    this.showForm = false;
  }
}

RelatedFormController.$inject = ['$scope'];
RelatedFormController.controllerAs = 'relatedFormCtrl';
RelatedFormController.NAME = 'RelatedFormController';
export default RelatedFormController;
