import angular from 'angular';
import template from './MemberAccountSettingsTab.html';

import SensitiveSettings from '../sensitive/SensitiveSettings.module';
import ContactSharingSetting from '../contact-sharing';

const dependencies = [SensitiveSettings.name, ContactSharingSetting.name];

const MemberAccountSettingsTab = {
  NAME: 'wcMemberAccountSettingsTab',
  bindings: {
    user: '<',
    onSharingSettingChange: '&',
    sharingSettingHanging: '<'
  },
  templateUrl: template
};

export default angular
  .module('wc.settings.MemberAccount', dependencies)
  .component(MemberAccountSettingsTab.NAME, MemberAccountSettingsTab);
