import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class RestrictedCurrencyRangeController {
  $onChanges(changes) {
    if (changes.minModel) {
      this._innerMinModel = {
        value: this._removeCurrencyFromValue(changes.minModel.currentValue),
        symbol: this.currency.symbol
      };
    }
    if (changes.maxModel) {
      this._innerMaxModel = {
        value: this._removeCurrencyFromValue(changes.maxModel.currentValue),
        symbol: this.currency.symbol
      };
    }
    if (changes.currency) {
      if (this._innerMinModel) {
        this.updateMinModel();
      }
      if (this._innerMaxModel) {
        this.updateMaxModel();
      }
    }
  }

  updateMinModel() {
    this.onMinChanged({
      value: this._addCurrencyToValue(this._innerMinModel.value)
    });
  }

  updateMaxModel() {
    this.onMaxChanged({
      value: this._addCurrencyToValue(this._innerMaxModel.value)
    });
  }

  _addCurrencyToValue(value) {
    if (!value) {
      return;
    }
    return `${value}_${this.currency.code}`;
  }

  _removeCurrencyFromValue(value) {
    if (!value) {
      return;
    }
    return value.split('_')[0];
  }
}

export default angular
  .module('wc.inputGroups.restrictedCurrencyRange', [])
  .component('wcRestrictedCurrencyRange', {
    bindings: {
      currency: '<',
      maxLabel: '@',
      maxModel: '<',
      minLabel: '@',
      minModel: '<',
      onMaxChanged: '&',
      onMinChanged: '&',
      options: '<',
      title: '@'
    },
    controller: RestrictedCurrencyRangeController,
    templateUrl: templateUrl
  });
