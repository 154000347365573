import NameCell from '../components/table/cells/NameCell.html';
import LocationCell from '../components/table/cells/LocationCell.html';
import CompensationCell from '../components/table/cells/CompensationCell.html';

import '../components/table/cells/Style.scss';

class MemberListUIService {
  /*@ngInject*/
  constructor(
    $state,
    $localStorage,
    CompensationsBlockedService,
    $rootScope,
    $q
  ) {
    this.$state = $state;

    this.$localStorage = $localStorage.$default({ gridLayout: true });
    this.CompensationsBlockedService = CompensationsBlockedService;
    this.viewOptions = { gridLayout: this.$localStorage.gridLayout };

    this.isReadyDeferred = $q.defer();
    this.isReady = this.isReadyDeferred.promise;

    this._setUpTableHeaders();
    this._setUpUI();

    $rootScope.$on('compensationBlockedChanged', this._setUpUI.bind(this));
  }

  getDefaultTableHeaders() {
    return angular.copy(this.defaultTableHeaders);
  }

  getDefaultSortOptions() {
    return angular.copy(this.defaultSortOptions);
  }

  toggleLayout = () => {
    this.viewOptions.gridLayout = !this.viewOptions.gridLayout;
    this.$localStorage.gridLayout = this.viewOptions.gridLayout;
  };

  _setUpTableHeaders() {
    this.defaultTableHeaders = [
      {
        name: 'Name',
        classes: ['LinkCell'],
        sortable: true,
        sortingKey: 'last_name',
        include: NameCell,
        priority: 0
      },
      {
        name: 'Job Title',
        key: 'currentInternalJobTitle',
        sortable: true,
        sortingKey: 'current_internal_job_title',
        classes: ['LightFontCell'],
        priority: 0
      },
      {
        name: 'Company',
        key: 'currentCompany',
        sortable: true,
        sortingKey: 'current_company',
        classes: ['LightFontCell'],
        priority: 0
      },
      {
        name: 'Compensation',
        include: CompensationCell,
        sortable: true,
        sortingKey: 'total_compensation_amount',
        classes: ['LightFontCell', 'CompensationCell'],
        priority: 1
      },
      {
        name: 'Location',
        key: 'location',
        sortable: true,
        sortingKey: 'address_city',
        include: LocationCell,
        classes: ['LightFontCell', 'LocationCell'],
        priority: 2
      }
    ];
  }

  _setUpUI() {
    this.CompensationsBlockedService.isReady
      .then(this._handleCompensationsBlocked.bind(this))
      .then(this._setUpSortOptions.bind(this));
  }

  _handleCompensationsBlocked() {
    if (this.CompensationsBlockedService.isBlocked()) {
      // Remove compensation column
      let newDefaultTableHeaders = this.defaultTableHeaders.filter(
        x => x.name !== 'Compensation'
      );
      this.defaultTableHeaders = newDefaultTableHeaders;
    }
  }

  _setUpSortOptions() {
    this.updateSortOptions(this.defaultTableHeaders);
    this.isReadyDeferred.resolve();
  }

  updateSortOptions(tableHeaders) {
    this.defaultSortOptions = tableHeaders.filter(
      x => x.sortable && x.sortingKey
    );
  }
}

MemberListUIService.NAME = 'MemberListUIService';
export default MemberListUIService;
