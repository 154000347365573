import angular from 'angular';
import template from './ClientAccountSettingsTab.html';
import SensitiveSettings from '../sensitive/SensitiveSettings.module';

const dependencies = [SensitiveSettings.name];

const ClientAccountSettingsTab = {
  NAME: 'wcClientAccountSettingsTab',
  bindings: {
    user: '<'
  },
  templateUrl: template
};

export default angular
  .module('wc.settings.clientAccount', dependencies)
  .component(ClientAccountSettingsTab.NAME, ClientAccountSettingsTab);
