import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import {
  CheckSharp as BaseCheckSharp,
  Add as BaseAdd
} from '@material-ui/icons';

const Button = styled(({ ...props }) => (
  <ButtonBase classes={{ root: 'root' }} {...props} />
))`
  &.root {
    background-color: ${props => props.theme.palette.secondary[500]};
    border: 1px solid ${props => props.theme.palette.secondary[500]};
    color: ${props => props.theme.palette.common.white};
    border-radius: 2px;
    font-weight: 700;
    font-size: 14px;
    height: 42px;
    margin: 0;
    padding: 0 24px;
    display: ${props => props.hidden && 'none'};

    &:hover,
    &:focus {
      background-color: ${props => props.theme.palette.secondary[700]};
      border-color: ${props => props.theme.palette.secondary[700]};
    }
  }
`;

const iconStyles = css`
  margin-right: 12px;
  font-size: 15px;
`;

const getStyledIcon = Component => {
  return styled(({ ...props }) => (
    <Component classes={{ root: 'root' }} {...props} />
  ))`
    &.root {
      ${iconStyles}
    }
  `;
};

const CheckSharpIcon = getStyledIcon(BaseCheckSharp);
const AddIcon = getStyledIcon(BaseAdd);
const icons = {
  CheckSharpIcon: <CheckSharpIcon />,
  AddIcon: <AddIcon />
};

// To restyle this component after importing, className is given into the component by styled-components which is then passed on
const SolidButton = ({
  type,
  onClick,
  icon = null,
  children,
  className,
  disabled,
  hidden
}) => {
  return (
    <Button
      type={type || 'button'}
      onClick={onClick}
      hidden={hidden}
      disabled={disabled}
      className={className}
    >
      {icon && icons[icon]}
      {children}
    </Button>
  );
};

SolidButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool
};

export default SolidButton;
