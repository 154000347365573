import Immutable from 'immutable';

export const CURRENT = Immutable.Map({ text: 'Current', value: 'current' });
export const PAST = Immutable.Map({ text: 'Past', value: 'past' });
export const ASPIRATIONAL = Immutable.Map({
  text: 'Aspirational',
  value: 'aspirational'
});

export default { CURRENT, PAST, ASPIRATIONAL };
