import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { CompanyLogo } from '../../../../../common/components/react/company-logo/CompanyLogo';
import Switch from '../../profile/basics/Switch';

export const CompanyPrivacySettings = ({
  onChange,
  onDefaultChanged,
  remunerationBlockedDefault,
  companiesWithCompensationBlocked,
  companies
}) => {
  const [
    remunerationBlockedDefaultState,
    setRemunerationBlockedDefaultState
  ] = useState(remunerationBlockedDefault);
  const [companiesState, setCompaniesState] = useState(companies);
  const changeCompanyAllowedToViewCompensation = useCallback(
    index => {
      const companiesTmp = [...companiesState];
      companiesTmp[index].allowed = !companiesState[index].allowed;
      setCompaniesState(companiesTmp);
      onChange(companiesTmp);
    },
    [companiesState]
  );

  return (
    <React.Fragment>
      <div>
        <div>
          <div className="NotificationSettings__Name">Default settings</div>
          <p>
            As a member of Winter Circle, your compensation forms part of your
            professional profile. Here, you can easily control which companies
            can see your compensation.
          </p>

          <p>
            Note that displaying your compensation details can help ensure that
            you are found for the right opportunities. These details cannot be
            viewed by other members.
          </p>
        </div>
      </div>

      <div className="CompanyGenericRow">
        <div>
          <div className="NotificationSettings__Name">
            When a new company joins Winter Circle, allow them to view my
            compensation data by default
          </div>
        </div>
        <span className="flex" />
        <Switch
          checked={remunerationBlockedDefaultState}
          aria-label="When a new company joins Winter Circle, allow them to view my compensation data, by default"
          onChange={() => {
            setRemunerationBlockedDefaultState(
              !remunerationBlockedDefaultState
            );
            onDefaultChanged(!remunerationBlockedDefaultState);
          }}
        />
      </div>
      {companies.length > 0 && (
        <div className="CompanyGenericRow">
          <div>
            <div className="NotificationSettings__Name">Company settings</div>
            <p>
              The following list of companies are Winter Circle clients. Choose
              which of these companies can view your compensation details.
            </p>
          </div>
        </div>
      )}

      {companies.map((company, index) => (
        <React.Fragment key={company.id}>
          <div className="CompanyGenericRow CompanyPrivacySettings__Row">
            <div>
              {!company.hideImage && company.image && (
                <CompanyLogo alt={company.name} src={company.image} />
              )}
              {(company.hideImage || !company.image) && (
                <div>{company.name}</div>
              )}
            </div>
            <div className="flex" />
            <Switch
              checked={company.allowed}
              aria-label={`${company.name} is allowed to view remuneration`}
              onChange={() => changeCompanyAllowedToViewCompensation(index)}
            />
          </div>

          {companiesWithCompensationBlocked.length > 0 && (
            <div className="CompanyGenericRow">
              <div>
                <p>
                  The following list of companies have opted to disable
                  compensation visibility for all members. You will be notified
                  if this setting changes.
                </p>
              </div>
            </div>
          )}

          {companiesWithCompensationBlocked.map(company => (
            <div
              className="CompanyGenericRow CompanyPrivacySettings__Row"
              key={company.id}
            >
              <span>
                {!company.hideImage && company.image && (
                  <CompanyLogo alt={company.name} src={company.image} />
                )}
                {(company.hideImage || !company.image) && (
                  <div>{company.name}</div>
                )}
              </span>
            </div>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

CompanyPrivacySettings.propTypes = {
  onChange: PropTypes.func,
  onDefaultChanged: PropTypes.func,
  remunerationBlockedDefault: PropTypes.bool,
  companiesWithCompensationBlocked: PropTypes.arrayOf(PropTypes.object),
  companies: PropTypes.arrayOf(PropTypes.object)
};
