import angular from 'angular';
import { react2angular } from 'react2angular';
import { LayoutToggleSwitch } from './LayoutToggleSwitch';

export default angular
  .module('wc.components.react.memberList.layoutToggleSwitch', [])
  // @ts-ignore
  .component(
    'reactMemberListLayoutToggleSwitch',
    react2angular(LayoutToggleSwitch, [], ['MemberListUIService', '$scope'])
  );
