import AcceptLegislation from './accept-legislation/Accept.module';
import AcceptContract from './accept-contract/Accept.module';
import ClientProfile from './client-profile';
import ClientSearchSidebar from '../../components/sidebars/clientsearchsidebar';
import DashboardModule from './dashboard/Dashboard.module';
import EditProfile from './edit-profile';
import LonglistFilterSidebar from '../../components/sidebars/longlistfiltersidebar';
import LonglistsModule from './longlists/Longlists.module';
import Messenger from '../../../common/components/messenger/Messenger.module';
import Registration from './registration';
import SearchModule from './search/Search.module';
import Settings from './settings/Settings.module';
import SidebarLayout from '../../components/sidebars/layout';
import SidenavNavbar from '../../components/sidenav/clientnavbar';

import ClientsRouting from './Clients.routing';

let ClientsModule = angular
  .module('wc.clients', [
    AcceptLegislation.name,
    AcceptContract.name,
    ClientProfile.name,
    ClientSearchSidebar.name,
    DashboardModule.name,
    EditProfile.name,
    LonglistFilterSidebar.name,
    LonglistsModule.name,
    Messenger.name,
    Registration.name,
    SearchModule.name,
    Settings.name,
    SidebarLayout.name,
    SidenavNavbar.name
  ])

  .config(ClientsRouting);

export default ClientsModule;
