import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wc.components.settings.contactSharing', [])
  .component('wcContactSharing', {
    bindings: {
      onChange: '&',
      sharingSetting: '<',
      sharingSettingHanging: '<'
    },
    templateUrl: templateUrl
  });
