import STATES from '../../../services/States';

import AddButtonsCell from '../../../components/table/cells/AddButtonsCell.html';

import { CLIENT_SEARCH_SIDEBAR_ID } from '../../../components/sidebars/clientsearchsidebar';
import { MAIN_SEARCH_ID } from '../../../services/MemberLists.service';

class SearchController {
  /*@ngInject*/
  constructor(
    $document,
    $log,
    $scope,
    $state,
    $stateParams,
    API,
    LonglistDialogService,
    MemberListUIService,
    wcMemberList
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$document = $document;
    this.$log = $log;
    this.API = API;
    this.LonglistDialogService = LonglistDialogService;
    this.$stateParams = $stateParams;

    this.longlistsLoaded = false;
    this.showLonglistActions = false;

    // This legacy service is only kept around to set up ordering widget + table headers.
    // See this.setUpMemberList()
    //
    // Should be replaced by componentizing table + ordering widget, as the concern here is UI
    // reuse.
    //
    // Also provides grid/list functionality
    this.MemberListUIService = MemberListUIService;

    // This is the "real" member list service which handles filtering etc.
    this.wcMemberList = wcMemberList;

    this.searchSidebarId = CLIENT_SEARCH_SIDEBAR_ID;
    this.userProfileState = STATES.CLIENTS_MEMBER_PROFILE;
    this.longlistDetailState = STATES.LONGLIST_DETAIL;

    this.API.retrieveMyLonglistsEndpoint().then(
      this.setLonglistsEndpoint.bind(this)
    );

    this.setUp();
  }

  setUp() {
    this.API.listMyLonglists().then(this.setLonglists.bind(this));
    this.setUpMemberListUIService()
      .then(this._setUpTableData.bind(this))
      .then(this._setUpMemberList.bind(this))
      .then(this._setUpLonglist.bind(this));
  }

  _setUpTableData() {
    this.extraTableData = {
      toggleUserCallback: this.addOrRemoveMember.bind(this)
    };
    this.tableHeaders.push({
      include: AddButtonsCell,
      sortable: false,
      classes: ['AddButtonsCell']
    });
    this.sortOptions.unshift({ name: 'Relevance', sortable: false });
  }

  _setUpMemberList() {
    return this.wcMemberList.isReady.then(() => {
      this.memberList = this.wcMemberList[MAIN_SEARCH_ID];
      this.memberList.refresh();
      this.$scope.$watch(
        () => this.memberList.ordering.field,
        field =>
          (this.currentSortOption = this.sortOptions.filter(
            opt => opt.sortingKey === field
          )[0])
      );
    });
  }

  _setUpLonglist() {
    this.longlistSelectButtons = [
      { name: 'Add project', callback: this.addLonglist.bind(this) }
    ];

    this.$scope.$watch(
      () => {
        if (this.currentLonglist) {
          this.showLonglistActions = !!this.currentLonglist;
          return this.currentLonglist.id;
        }
      },
      (longlistId, oldLonglistId) => {
        if (longlistId && longlistId !== oldLonglistId) {
          this.updateLonglist();
        }
      }
    );

    this.$scope.$on('membersUpdated', this.markAlreadyAdded.bind(this));
  }

  _markMemberAlreadyAdded(member) {
    member.isAddedNow = false;
    member.isAlreadyAdded =
      this.currentLonglist.members.indexOf(member.userId) !== -1;
  }

  markAlreadyAdded() {
    if (this.currentLonglist) {
      this.memberList.members.forEach(this._markMemberAlreadyAdded.bind(this));
    }
  }

  setUpMemberListUIService() {
    return this.MemberListUIService.isReady.then(() => {
      this.tableHeaders = this.MemberListUIService.getDefaultTableHeaders();
      this.MemberListUIService.updateSortOptions(this.tableHeaders);
      this.sortOptions = this.MemberListUIService.getDefaultSortOptions();
    });
  }

  allLonglistWithCategory(longlists) {
    return longlists.filter(item => item.category);
  }

  setLonglists(longlists) {
    this.longlists = longlists;
    this.longlistsLoaded = true;

    if (longlists.length > 0) {
      const defaultLonglistId = parseInt(this.$stateParams.longlistId);

      if (defaultLonglistId) {
        this.setCurrentLonglistById(defaultLonglistId);
      } else {
        this.setCurrentLonglist(longlists[0]);
      }
    }
  }

  setLonglistsEndpoint(longlistsEndpoint) {
    this.longlistsEndpoint = longlistsEndpoint;
    if (this.longlistsEndpoint === null) {
      return;
    }
    this.longlistsEndpoint.options().then(options => {
      if (options.actions.POST.category) {
        this.categoryChoices = options.actions.POST.category.choices;
      }
      this.statusChoices = options.actions.POST.status.choices;
      this.statusDefault = options.actions.POST.status.default;
    });
  }

  setCurrentLonglist(longlist) {
    this.currentLonglist = longlist;
  }

  setCurrentLonglistById(longlistId) {
    this.currentLonglist = this.longlists.find(longlist => {
      return longlist.id === longlistId;
    });
  }

  goToCurrentLonglistUrl = () => {
    return this.$state.go(this.longlistDetailState, {
      lid: this.currentLonglist.id
    });
  };

  updateLonglist() {
    this.API.Longlists.get(this.currentLonglist.id)
      .then(this.setCurrentLonglist.bind(this))
      .then(this.updateMembersList.bind(this))
      .then(this.markAlreadyAdded.bind(this));
  }

  addOrRemoveMember(member) {
    if (this.currentLonglist) {
      let longlistMemberEndpoint = this.API.Longlist(this.currentLonglist.id);
      let index = this.memberList.members.indexOf(member);

      if (member.isAlreadyAdded) {
        longlistMemberEndpoint
          .one('members', member.userId)
          .remove()
          .then(() => {
            this.API.handleSuccess(
              `${member.fullName} removed from ${this.currentLonglist.name}`
            );
            let updatedMember = member;
            updatedMember.isAlreadyAdded = false;
            updatedMember.isAddedNow = false;
            this.memberList.members[index] = updatedMember;
          }, this.API.handleError(`Could not remove ${member.fullName} from ${this.currentLonglist.name}. Please try again.`));
      } else if (member.isAddedNow) {
        longlistMemberEndpoint
          .one('members', member.userId)
          .remove()
          .then(() => {
            this.API.handleSuccess(
              `${member.fullName} removed from ${this.currentLonglist.name}`
            );
            let updatedMember = member;
            updatedMember.isAddedNow = false;
            this.memberList.members[index] = updatedMember;
          }, this.API.handleError(`Could not remove ${member.fullName} from ${this.currentLonglist.name}. Please try again.`));
      } else {
        member.isAddedNow = true;
        longlistMemberEndpoint
          .one('members', member.userId)
          .put(member.userId)
          .then(() => {
            this.API.handleSuccess(
              `${member.fullName} added to ${this.currentLonglist.name}`
            );
            let updatedMember = member;
            updatedMember.isAddedNow = false;
            updatedMember.isAlreadyAdded = true;
            this.memberList.members[index] = updatedMember;
          }, this.API.handleError(`Could not add ${member.fullName} to ${this.currentLonglist.name}. Please try again.`));
      }
    }
  }

  updateMembersList() {
    if (this.currentLonglist) {
      this.memberList.members.forEach(this._markMemberAlreadyAdded.bind(this));
    }
  }

  getCategoryChoices() {
    if (
      this.longlists &&
      this.allLonglistWithCategory(this.longlists).length !== 0
    ) {
      return this.categoryChoices;
    }
    return [];
  }

  addLonglist = event => {
    this.LonglistDialogService.showNewLonglistDialog(
      event,
      this.statusChoices,
      this.statusDefault,
      this.getCategoryChoices()
    ).then(
      this.createLonglist.bind(this),
      this.cancelled.bind(this, 'Adding new project')
    );
  };

  createLonglist(data) {
    return this.longlistsEndpoint
      .post(data)
      .then(this.unsetStateDefaultLonglist.bind(this))
      .then(this.getLonglists.bind(this), this.API.handleError());
  }

  unsetStateDefaultLonglist() {
    delete this.$stateParams.longlistId;
  }

  getLonglists() {
    this.longlistsEndpoint
      .getList(this.filters)
      .then(this.setLonglists.bind(this), this.API.handleError());
  }

  cancelled(action) {
    this.$log.info(`${action} action cancelled`);
  }

  onFlipOrdering = () => {
    this.memberList.flipOrdering();
  };
}

SearchController.controllerAs = '$ctrl';
export default SearchController;
