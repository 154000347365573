import angular from 'angular';

import EditLink from './editlink';
import WcButtonNext from './wcbuttonnext/WcButtonNext.directive';
import RemoveButton from './remove';

import SolidLink from './solidlink';
import WcBlockButton from './wcblockbutton/WcBlockButton.module';
import WcTransluscentButton from './transluscentbutton';

const dependencies = [
  SolidLink.name,
  WcBlockButton.name,
  WcTransluscentButton.name
];

let ButtonsModule = angular
  .module('wc.components.buttons', dependencies)
  .component(EditLink.NAME, EditLink)
  .directive(WcButtonNext.NAME, WcButtonNext)
  .component(RemoveButton.NAME, RemoveButton);

export default ButtonsModule;
