import angular from 'angular';
import template from './template.html';

import STATES from '../../../../services/States';

class InsightsQAController {
  /*@ngInject*/
  constructor(API, $state, $q) {
    this.API = API;
    this.$state = $state;
    this.toResolve = 1;

    let questionsPromise = API.Questions.getList().then(
      questions => (this.questions = questions)
    );

    let profilePromise = API.retrieveMyProfile().then(
      profile => (this.profile = profile)
    );

    $q.all([questionsPromise, profilePromise])
      .then(this.setAnswers.bind(this))
      .catch(this.handleError.bind(this));
  }

  setAnswers() {
    this.answers = {};
    this.profile.answers.map(a => {
      this.answers[a.question] = a;
    });
    this.questions.map(q => {
      if (this.answers[q.id] === undefined) {
        this.answers[q.id] = { text: '', question: q.id, questionText: q.text };
      }
    });
    this.toResolve--;
  }

  cancel() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'insights-q-and-a'
    });
  }

  submit() {
    if (this.form.$valid) {
      this.toResolve++;
      Object.keys(this.answers).map(a => {
        let answersSave;
        if (this.answers[a].id) {
          answersSave = this.API.restangularize(this.answers[a]).patch;
        } else {
          answersSave = this.API.Answer.post;
        }
        answersSave(this.answers[a])
          .then(this.handleSuccess())
          .catch(this.handleError.bind(this));
      });
    }
  }

  handleSuccess() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'insights-q-and-a'
    });
  }

  handleError(error) {
    this.toResolve = 0;
    this.API.handleError()(error);
  }

  isLoaded() {
    return this.toResolve === 0;
  }
}

const wcInlineEditInsightsQA = {
  controller: InsightsQAController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.insights.qA', [])
  .component('wcInlineEditInsightsQA', wcInlineEditInsightsQA);
