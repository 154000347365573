import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileNonExecPositions } from './OwnMemberProfileNonExecPositions';

export default angular
  .module('wc.components.OwnMemberProfileNonExecPositions', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileNonExecPositions',
    react2angular(
      OwnMemberProfileNonExecPositions,
      ['member'],
      ['API', '$state', 'DialogService']
    )
  );
