export default class ChangePasswordController {
  /*@ngInject*/
  constructor($mdDialog, AuthenticationConfig) {
    this.passwordStrengthOptions =
      AuthenticationConfig.PASSWORD_STRENGTH_OPTIONS;
    this.$mdDialog = $mdDialog;
  }

  submit() {
    this.form.$submitted = true;
    if (this.form.$valid) {
      this.user.currentPassword = this.currentPassword;
      this.user.password = this.newPassword;
      this.$mdDialog.hide(this.user);
    }
  }
}
