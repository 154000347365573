import EditLonglistDialogController from './LonglistDialogEditForm.controller';
import SimpleDialogController from '../SimpleDialog.controller';
import ShareLonglistDialogController from './ShareLonglistDialog.controller';

import ShareLonglistTemplateUrl from './ShareLonglist.html';
import CopyMemberDialogTemplateUrl from './CopyMemberDialog.html';
import EditLonglistDialogTemplateUrl from './LonglistDialogEditForm.html';

import './CopyMemberDialog.scss';
import './ShareLonglist.scss';

class LonglistDialog {
  /*@ngInject*/
  constructor(DialogService) {
    this.DialogService = DialogService;
  }

  showShareLonglistDialog(event, longlist) {
    let options = {
      controller: ShareLonglistDialogController,
      targetEvent: event,
      templateUrl: ShareLonglistTemplateUrl,
      locals: {
        ariaLabel: `Longlist ${longlist.name}`,
        longlist: longlist,
        title: `${longlist.name}`
      }
    };

    return this.DialogService.confirm(options);
  }

  showLonglistFormDialog(locals, targetEvent) {
    let options = {
      controller: EditLonglistDialogController,
      controllerAs: '$ctrl',
      templateUrl: EditLonglistDialogTemplateUrl,
      clickOutsideToClose: true,
      locals,
      targetEvent
    };
    return this.DialogService.confirm(options);
  }

  showCopyToLonglistDialog(event, longlists, currentLonglist) {
    let options = {
      controller: SimpleDialogController,
      targetEvent: event,
      templateUrl: CopyMemberDialogTemplateUrl,
      locals: {
        currentLonglist: currentLonglist,
        destinationLonglists: [],
        longlists: longlists,
        okAction: 'Add',
        title: 'Select the target projects'
      }
    };

    return this.DialogService.confirm(options);
  }

  showRemoveMemberDialog(event, deletedMember) {
    let locals = {
      ariaLabel: 'Remove member',
      content: `Are you sure you want to remove <b>${
        deletedMember.fullName
      }</b>?`,
      item: deletedMember,
      okAction: 'Delete',
      title: `Remove ${deletedMember.fullName}?`
    };

    return this.DialogService.showConfirmDialog(locals, event);
  }

  showRemoveSelectedMembersDialog(event) {
    let locals = {
      ariaLabel: 'Remove selected members',
      content: `Are you sure you want to remove the selected members from this project?`,
      okAction: 'Delete',
      title: `Remove selected members?`
    };

    return this.DialogService.showConfirmDialog(locals, event);
  }

  showDeleteLonglistDialog(event, listName) {
    let locals = {
      ariaLabel: 'Delete project',
      content:
        `Deleting a project cannot be undone.<br>` +
        `Are you sure you want to delete this project?`,
      okAction: 'Delete',
      title: `Delete ${listName}?`
    };

    return this.DialogService.showConfirmDialog(locals, event);
  }

  showEditLonglistDialog(event, longlist, statusChoices, categoryChoices) {
    let locals = {
      title: `Edit project ${longlist.name}`,
      ariaLabel: `Edit project ${longlist.name}`,
      okAction: 'Save',
      longlist: longlist,
      statusChoices: statusChoices,
      categoryChoices: categoryChoices
    };
    return this.showLonglistFormDialog(locals, event);
  }

  showNewLonglistDialog(event, statusChoices, initialStatus, categoryChoices) {
    let locals = {
      title: `New Project`,
      ariaLabel: 'Create new Project',
      okAction: 'Create',
      longlist: { status: initialStatus },
      statusChoices: statusChoices,
      categoryChoices: categoryChoices
    };
    return this.showLonglistFormDialog(locals, event);
  }
}

LonglistDialog.NAME = 'LonglistDialogService';

export default LonglistDialog;
