import Immutable from 'immutable';
import TimePeriodGroups from './TimePeriodGroups';

export default class TimePeriodModel {
  constructor(timePeriodOptions, value, timePeriods) {
    this.timePeriodOptions = timePeriodOptions;
    this.value = value;
    this.timePeriods = timePeriods;

    this.setValue = this.setValue.bind(this);
    this.setTimePeriods = this.setTimePeriods.bind(this);

    // lol this is horrible
    this.isTimePeriodModel = true;
  }

  setValue(value) {
    this.value = value;
  }

  setTimePeriods(timePeriods) {
    this.timePeriods = timePeriods;
  }

  stringifyTimePeriod() {
    let selectedTimePeriodSet;

    if (this.timePeriods && this.timePeriods.length) {
      selectedTimePeriodSet = new Immutable.Set(
        this.timePeriods.map(timePeriod => timePeriod.value)
      );
    }

    return TimePeriodGroups.convertTimePeriodSetToString(
      selectedTimePeriodSet,
      this.timePeriodOptions
    );
  }

  static isTimePeriodModel(instance) {
    return !!instance && !!instance.isTimePeriodModel;
  }
}
