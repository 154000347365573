import React from 'react';
import './styles.scss';
import { Toolbar } from '../../../toolbar/Toolbar';

export const ContactsToolbar = () => {
  return (
    <Toolbar>
      <h2 className="WcContactsToolbar__Header">All Messages</h2>
    </Toolbar>
  );
};
