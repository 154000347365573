import angular from 'angular';

import template from './template.html';
import './styles.scss';

class DownloadDialogController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  download() {
    this.$mdDialog.hide();
  }
}

class DownloadDialogService {
  /*@ngInject*/
  constructor(DialogService) {
    this.DialogService = DialogService;
  }

  show(file) {
    return this.DialogService.show({
      locals: {
        file: file
      },
      bindToController: true,
      templateUrl: template,
      controller: DownloadDialogController,
      controllerAs: '$ctrl',
      clickOutsideToClose: true,
      escapeToClose: true
    });
  }
}

export default angular
  .module('wc.components.dialogs.downloadDialog', [])
  .service('DownloadDialogService', DownloadDialogService);
