import angular from 'angular';
import EditMemberStatusDialogController from './MemberStatusDialogEditForm.controller';
import EditMemberStatusDialogTemplateUrl from './MemberStatusDialogEditForm.html';

class MemberStatusDialog {
  /*@ngInject*/
  constructor(DialogService) {
    this.DialogService = DialogService;
  }

  showMemberStatusFormDialog(locals, targetEvent) {
    let options = {
      controller: EditMemberStatusDialogController,
      controllerAs: '$ctrl',
      templateUrl: EditMemberStatusDialogTemplateUrl,
      clickOutsideToClose: true,
      locals,
      targetEvent
    };
    return this.DialogService.confirm(options);
  }

  showEditMemberStatusDialog(event, member, statusChoices) {
    let locals = {
      title: `Edit ${member.fullName}`,
      ariaLabel: `Edit ${member.fullName}`,
      okAction: 'Save',
      statusChoices: statusChoices,
      member: member
    };
    return this.showMemberStatusFormDialog(locals, event);
  }
}

export default angular
  .module('wc.components.dialogs.memberStatusDialog', [])
  .service('MemberStatusDialogService', MemberStatusDialog);
