import angular from 'angular';

import AspirationsController from './Aspirations.controller';
import AboutYouRouting from './AboutYou.routing';

import Textarea from '../../../../../components/inputs/textarea';

const moduleDependencies = [Textarea.name];

let AboutYouModule = angular
  .module('wc.members.registration.aspirations', moduleDependencies)
  .controller(AspirationsController.NAME, AspirationsController)
  .config(AboutYouRouting);

export default AboutYouModule;
