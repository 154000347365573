import TimePeriodGroups from '../TimePeriodGroups';
import TimePeriodModel from '../TimePeriodModel';

export default class MultiValueTypeaheadCtrl {
  /*@ngInject*/
  constructor($scope) {
    $scope.$watch(
      () => this.model,
      () => {
        if (!this.model) {
          this.initializeModel();
        }
      }
    );

    $scope.$watch(
      () => this._model,
      () => {
        this.updateExternalModel();
      },
      true
    );
  }

  $onInit() {
    this.initializeModel();
  }

  initializeModel() {
    this._model = new TimePeriodModel(
      TimePeriodGroups.convertTimePeriodListToSet(
        this.timePeriodSelectorOptions
      )
    );
  }

  updateExternalModel() {
    this.model = this._model;
  }
}
