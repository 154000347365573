import angular from 'angular';
import { react2angular } from 'react2angular';
import { StatusIndicator } from './StatusIndicator';

export default angular
  .module('wc.components.ReactMessengerStatusIndicator', [])
  // @ts-ignore
  .component(
    'reactMessengerStatusIndicator',
    react2angular(StatusIndicator, [])
  );
