import STATES from '../../services/States';

class UnsupportedDeviceController {
  /*@ngInject*/
  constructor($state) {
    this.$state = $state;
  }

  goToLogin() {
    this.$state.go(STATES.LOGIN);
  }
}

UnsupportedDeviceController.controllerAs = 'UnsupportedDeviceCtrl';

export default UnsupportedDeviceController;
