import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../inputs/selects/Select';

const EthnicitySelect = ({
  ethnicities,
  ethnicity,
  update,
  hasError,
  updateTouched,
  checkForError
}) => {
  const errorText = `
    Please select your ethnicity if you agree to provide this
    information. If you would prefer not to provide this
    information, please untick the box.
  `;

  const handleBlur = () => {
    updateTouched(true);
  };

  const valueToItem = value => ({
    value: value.id,
    display: value.name,
    key: value.id
  });

  return (
    <Select
      options={ethnicities}
      value={ethnicity}
      label="Ethnicity"
      onChange={update}
      hasError={hasError}
      onBlur={handleBlur}
      checkForError={checkForError}
      errorText={errorText}
      valueToItem={valueToItem}
    />
  );
};

EthnicitySelect.propTypes = {
  ethnicities: PropTypes.array,
  ethnicity: PropTypes.object,
  update: PropTypes.func,
  hasError: PropTypes.bool,
  updateTouched: PropTypes.func,
  checkForError: PropTypes.bool
};

export default EthnicitySelect;
