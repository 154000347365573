import React from 'react';
import PropTypes from 'prop-types';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import { ReactChips } from '../../../../../ReactChips/ReactChips';
import STATES from '../../../../../../../services/States';
import { removeChildRegions } from '../../../../../../../../common/services/Regions';

export const OwnMemberProfileWorkEligibility = ({ member, $state }) => {
  const onEdit = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ELIGIBLE_REGIONS);
  };

  return (
    <article className="Separator">
      <div className="WcEditArticle">
        <MemberProfileEditContainer onEdit={onEdit}>
          <div className="flex-grow">
            <h2 className="WcMemberProfileContent__Subheading">
              Work Eligibility
            </h2>
            <ReactChips
              readOnly
              data={removeChildRegions(member.eligibleRegions)}
              accent={true}
            />
          </div>
        </MemberProfileEditContainer>
      </div>
    </article>
  );
};

OwnMemberProfileWorkEligibility.propTypes = {
  member: PropTypes.object,
  $state: PropTypes.any
};
