import angular from 'angular';
import template from './template.html';

import './styles.scss';

const scrollOffset = 80;

class MemberProfileController {
  /*@ngInject*/
  constructor($stateParams, $timeout, $document, $element) {
    this.loaded = false;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$document = $document;
    this.$element = $element;
  }

  updateLoaded() {
    this.loaded = !!(this.member && this.user);
    if (this.loaded && this.$stateParams.scrollToSection) {
      this.$timeout(() => {
        this._scroll(this.$stateParams.scrollToSection);
      });
    }
  }

  _scroll(target) {
    let el = this.$document[0].getElementById(target);
    if (!el) {
      // No element matched, cannot scroll
      return;
    }
    let targetElement = $(el);
    this.$element.animate(
      {
        scrollTop:
          targetElement.offset().top -
          this.$element.offset().top +
          this.$element.scrollTop() -
          scrollOffset
      },
      0
    );
  }

  setMember(profile) {
    this.member = profile;
    this.updateLoaded();
  }

  setUser(user) {
    this.user = user;
    this.updateLoaded();
  }

  setGroups(groups) {
    this.groups = groups;
  }

  areAspirationsFetched() {
    return typeof this.member.aspirations !== 'string';
  }
}

export default angular
  .module('wc.components.profile.memberProfile', [])
  .component('wcMemberProfile', {
    bindings: {
      isOwn: '=?',
      isClient: '<?'
    },
    controller: MemberProfileController,
    transclude: true,
    templateUrl: template
  });
