import RequiredMessageTemplateUrl from './RequiredMessage.html';

// THIS DOESN'T WORK YET

let RequiredMessage = $compile => {
  return {
    require: '^form',
    templateUrl: RequiredMessageTemplateUrl,
    link: (scope, elem, attr, ctrl) => {
      scope.ctrl = ctrl;
      scope.attr = attr;
      angular.element(elem).after(
        $compile(
          `<div class="errors md-input-messages-animation md-auto-hide" ng-messages="ctrl[attr.name].$error">
    <div ng-message="required" class="md-input-message-animation">This field is required</div>
</div>`
        )(scope)
      );
    }
  };
};

RequiredMessage.NAME = 'requiredMessage';
RequiredMessage.$inject = ['$compile'];
export default RequiredMessage;
