import { PathWell } from 'pathwell';

let RequireDigits = () => {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.requireDigits = function(modelValue, viewValue) {
        const classCount = PathWell.getClassCounts(viewValue);
        const expected = parseInt(attrs.requireDigits) || 0;
        return expected <= classCount.d;
      };
    }
  };
};

RequireDigits.NAME = 'requireDigits';

export default RequireDigits;
