import angular from 'angular';

import Buttons from '../buttons/Buttons.module';

import wcRemovableChipsList from './removablechipslist/RemovableChipsList.component';
import wcRemovableChip from './RemovableChip.component';
import wcSuggestedChip from './SuggestedChip.component';

const dependencies = [Buttons.name];

let ChipsModule = angular
  .module('wc.components.chips', dependencies)
  .component(wcRemovableChipsList.NAME, wcRemovableChipsList)
  .component(wcRemovableChip.NAME, wcRemovableChip)
  .component(wcSuggestedChip.NAME, wcSuggestedChip);

export default ChipsModule;
