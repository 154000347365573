import angular from 'angular';
import './Page.scss';

const Page = {
  NAME: 'wcPage',
  bindings: {
    noPadding: '<',
    noTopPadding: '<?'
  },
  template:
    '<section class="WcPage" ng-class="{\'WcPage--NoPadding\': $ctrl.noPadding, \'WcPage--NoTopPadding\' : $ctrl.noTopPadding}" ng-transclude></section>',
  transclude: true
};

export default angular
  .module('wc.components.page', [])
  .component(Page.NAME, Page);
