import React from 'react';
import PropTypes from 'prop-types';

const PageContainer = ({ children }) => {
  return (
    <div className="WcPages" style={{ margin: 'auto' }}>
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ])
};

export default PageContainer;
