import 'angular';

const previousButton = {
  NAME: 'wcPreviousButton',
  template: `<wc-inverse-button class="Form__ActionButton" type="button">Previous</wc-inverse-button>`
};

export default angular
  .module('wc.components.buttons.previousButton', [])
  .component(previousButton.NAME, previousButton);
