export const initialState = {
  showNewForm: false,
  isAddingEducation: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'ADDING_NEW_EDUCATION':
      return {
        ...state,
        showNewForm: true,
        isAddingEducation: true
      };
    case 'ADDING_NEW_EDUCATION_SUCCESSFUL':
      return {
        ...state,
        showNewForm: false,
        isAddingEducation: false
      };
    case 'ADDING_NEW_EDUCATION_ERROR':
      return {
        ...state,
        showNewForm: true,
        isAddingEducation: false
      };
    case 'SHOW_NEW_FORM':
      return {
        ...state,
        showNewForm: action.payload
      };
    case 'EXPAND':
      return {
        ...state,
        [action.id]: {
          updating: false,
          isExpanded: true
        }
      };
    case 'UPDATING':
      return {
        ...state,
        [action.id]: {
          updating: true,
          isExpanded: true
        }
      };
    case 'CANCEL':
    case 'UPDATING_SUCCESSFUL':
      return {
        ...state,
        [action.id]: {
          updating: false,
          isExpanded: false
        }
      };
    case 'UPDATING_ERROR':
      return {
        ...state,
        [action.id]: {
          updating: false,
          isExpanded: true
        }
      };
    default:
      return state;
  }
};
