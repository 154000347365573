import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import { StatsWidget } from '../../../../../../../../common/components/react/stats-widget/StatsWidget';
import STATES from '../../../../../../../services/States';

export const OwnMemberProfileFinancePeople = ({
  member,
  $state,
  CurrencyService
}) => {
  const [managementStats, setManagementStats] = useState([]);

  useEffect(() => {
    const managementStatsTmp = [
      {
        label: 'Capital Expenditure',
        value: member.capexAmount !== null ? parseFloat(member.capexAmount) : 0,
        currencySymbol: CurrencyService.getCurrencySymbol(member.capexCurrency)
      },
      {
        label: 'Operational Expenditure',
        value: member.opexAmount !== null ? parseFloat(member.opexAmount) : 0,
        currencySymbol: CurrencyService.getCurrencySymbol(member.opexCurrency)
      },
      {
        label: 'Profit and Loss',
        value:
          member.profitAndLossAmount !== null
            ? parseFloat(member.profitAndLossAmount)
            : 0,
        currencySymbol: CurrencyService.getCurrencySymbol(
          member.profitAndLossCurrency
        )
      },
      {
        label: 'Revenue Target',
        value:
          member.revenueTargetAmount !== null
            ? parseFloat(member.revenueTargetAmount)
            : 0,
        currencySymbol: CurrencyService.getCurrencySymbol(
          member.revenueTargetCurrency
        )
      },
      { label: 'Number of FTEs', value: member.fullTimeEmployees },
      { label: 'Number of Direct Reports', value: member.directReports },
      { label: 'Extended Team', value: member.extendedTeamSize }
    ];
    setManagementStats(managementStatsTmp);
  }, []);

  const onEdit = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.FINANCE_PEOPLE);
  };

  return (
    <article className="Separator">
      <div className="WcEditArticle">
        <MemberProfileEditContainer onEdit={onEdit}>
          <div className="flex-grow">
            <h2 className="WcMemberProfileContent__Subheading">
              Finance &amp; People Management
            </h2>
            <div className="WcMemberProfileContent__InfoText">
              Details of the largest budgets and team sizes managed over the
              past 10 years
            </div>
            {managementStats && <StatsWidget stats={managementStats} />}
          </div>
        </MemberProfileEditContainer>
      </div>
    </article>
  );
};

OwnMemberProfileFinancePeople.propTypes = {
  member: PropTypes.object,
  $state: PropTypes.any,
  CurrencyService: PropTypes.any
};
