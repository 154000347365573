import STATES from '../../../../../services/States';
import { FORM_ERROR_MESSAGE } from '../../../../../services/FormErrorMessages';
import { POSITION_TYPES } from '../../../../../../common/services/Positions';
import RegistrationBaseController from '../../RegistrationBase.controller';
import getFormattedDateString from '../../../../../../common/services/DateUtils';
import formValid from '../../../../../components/react/utils/formValid';

class PositionController extends RegistrationBaseController {
  /*@ngInject*/
  constructor(
    $mdConstant,
    $scope,
    $state,
    $timeout,
    API,
    RelatedFieldsGroup,
    Resume,
    CurrencyService,
    PreferredCurrencyService,
    $q
  ) {
    super($state, API);
    this.$mdConstant = $mdConstant;
    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.API = API;
    this.Resume = Resume;
    this.CurrencyService = CurrencyService;
    this.PreferredCurrencyService = PreferredCurrencyService;
    this.formValid = formValid;

    this.formErrorMessage = FORM_ERROR_MESSAGE;

    this.relatedFieldsMap = {
      company: ['businessLines'],
      businessLines: []
    };

    this.position = {
      current: true,
      positionType: {
        code: POSITION_TYPES.FULL_TIME
      }
    };

    this.resumeCaptions = {
      READY: 'Drag & Drop your CV here to upload',
      UPLOADING: 'Uploading...',
      UPLOADED: 'Drag & Drop your CV here to upload'
    };

    this.currentAllowed = true;
    this.POSITION_TYPES = POSITION_TYPES;

    this.setSeparatorKeys();

    this.relatedFieldsGroup = new RelatedFieldsGroup(this.relatedFieldsMap);

    $scope.$watch(
      () => this.position,
      position => {
        this.relatedFieldsGroup.setModel(position);
        this.relatedFieldsGroup.setSuggestionsModel(
          position.organizationSuggestions
        );
      },
      true
    );

    this.nextState = STATES.MEMBER_REGISTRATION.REMUNERATION;
    this.previousState = STATES.MEMBER_REGISTRATION.PROFILE;

    this.currencies = [];
    let currenciesPromise = API.Currencies.getList().then(currencies => {
      this.currencies = this.CurrencyService.currencyListToMap(currencies);
      this.selectedCurrency = this.PreferredCurrencyService.getPreferredCurrency(
        currencies
      );
    });

    let profilePromise = API.retrieveMyProfile().then(profile => {
      this.profile = profile;
      if (!this.profile || this.profile.noCompensation) {
        this.nextState = STATES.MEMBER_REGISTRATION.ASPIRATIONS;
      }
    });

    this.reactForm = {
      company: {
        error: true
      }
    };

    $q.all([currenciesPromise, profilePromise])
      .then(this.handleProfile.bind(this))
      .catch(this.handleError.bind(this));
  }

  updateFormFieldError = (fieldName, error) => {
    this.reactForm[fieldName].error = error;
    this.$scope.$apply();
  };

  //TODO: Very hacky -- only works in fast browsers
  forceCurrencySymbolToResize() {
    this.selectedCurrency.symbol += ' ';
    this.$timeout(() => {
      this.selectedCurrency.symbol = this.selectedCurrency.symbol.trim();
    });
  }

  setSeparatorKeys() {
    this.separatorKeys = [
      this.$mdConstant.KEY_CODE.ENTER,
      this.$mdConstant.KEY_CODE.TAB,
      this.$mdConstant.KEY_CODE.COMMA
    ];
  }

  handleProfile() {
    this.nonExecCompanies = this.profile.nonExecCompanies;
    if (this.profile.opexCurrency) {
      this.selectedCurrency = this.currencies[this.profile.opexCurrency];
    }
    const position = this.profile.positions.find(position => position.current);
    if (position) {
      this.API.restangularizeUrl(position.url)
        .get()
        .then(this.handlePosition.bind(this))
        .then(this.setLoading.bind(this, false))
        .then(this.forceCurrencySymbolToResize.bind(this))
        .catch(this.handleError.bind(this));
    } else {
      this.setLoading(false);
      this.forceCurrencySymbolToResize();
    }
  }

  handlePosition(position) {
    this.updateReactFormComponentsError(position);

    this.position = position;

    if (this.position.organizationSuggestions.length) {
      this.transformOrganizationSuggestions();
    }
  }

  transformOrganizationSuggestions() {
    let suggestions = {};
    this.position.organizationSuggestions.forEach(suggestion => {
      let key = suggestion.organizationType;
      if (!suggestions[key]) {
        suggestions[key] = [];
      }

      suggestions[key].push(suggestion);
    });

    this.position.organizationSuggestions = suggestions;
  }

  hasSuggestions() {
    if (!this.position.organizationSuggestions) {
      return false;
    }

    const keys = Object.keys(this.position.organizationSuggestions);
    for (let i = 0; i < keys.length; i++) {
      const suggestion = this.position.organizationSuggestions[keys[i]];

      if (suggestion && suggestion.length > 0) {
        return true;
      }
    }

    return false;
  }

  /**
   * Commit Suggestion data (eg. from Departments, Business Lines etc) to the API
   */
  saveSuggestions(position) {
    if (!this.position.organizationSuggestions) {
      return;
    }

    // Sometimes, there might be existing Suggestions. Two things are needed...
    //
    // 1. DELETE any Suggestions that used to be there, but are now gone.
    // 2. POST any new Suggestions.
    this._deleteRemovedSuggestionsFromAPI(position);
    this._postNewSuggestionsToAPI(position);
  }

  getQueryParams() {
    if (this.hasSuggestions()) {
      return { suggestion: true };
    }
  }

  addDanglingNonExecCompany() {
    let danglingNonExecCompany = $('[name="nonExecCompanies"] input').val();
    if (
      danglingNonExecCompany &&
      this.nonExecCompanies.indexOf(danglingNonExecCompany) === -1
    ) {
      this.nonExecCompanies.push(danglingNonExecCompany);
    }
  }

  saveNonExec() {
    let profilePatch = {
      nonExecCompanies: this.nonExecCompanies,
      regionsManaged: this.profile.regionsManaged.map(region => region.id),
      opexAmount: this.profile.opexAmount ? this.profile.opexAmount : null,
      opexCurrency: this.selectedCurrency.code,
      capexAmount: this.profile.capexAmount ? this.profile.capexAmount : null,
      capexCurrency: this.selectedCurrency.code,
      profitAndLossAmount: this.profile.profitAndLossAmount
        ? this.profile.profitAndLossAmount
        : null,
      profitAndLossCurrency: this.selectedCurrency.code,
      revenueTargetAmount: this.profile.revenueTargetAmount
        ? this.profile.revenueTargetAmount
        : null,
      revenueTargetCurrency: this.selectedCurrency.code,
      extendedTeamSize: this.profile.extendedTeamSize
        ? this.profile.extendedTeamSize
        : null,
      directReports: this.profile.directReports
        ? this.profile.directReports
        : null
    };
    return this.API.restangularize(this.profile).patch(profilePatch);
  }

  uploadResume(file) {
    let fd = new FormData();
    fd.append('file', file);

    return this.API.Resumes.withHttpConfig({
      transformRequest: angular.identity
    })
      .customPOST(fd, '', {}, { 'Content-Type': undefined })
      .then(response => {
        this.profile.resume = response.url;
      });
  }

  downloadResume() {
    this.Resume.download(this.profile.resume, this.profile.fullName);
  }

  /**
   * This is needed as I cannot update this.reactForm on inital render due to angular digest errors. So I need to update the error if a value already exists for a required field.
   * Scenario: Select a company and fill in the required fields. Next click continue. Then click on the previous button. next click Continue to move to next step.
   * The above will fail without this.
   */
  updateReactFormComponentsError = position => {
    // The object is composed as {keyInThePositionObjectToCheck: valueToCheckForThatKeyInThePositionObject}
    const requiredFieldKeys = { company: 'id' };

    Object.keys(requiredFieldKeys).forEach(key => {
      const hasError =
        position[key] && position[key][requiredFieldKeys[key]] ? false : true;
      this.reactForm[key].error = hasError;
    });
  };

  submit() {
    if (!this.form.$valid || !this.formValid(this.reactForm)) {
      return;
    }
    this.addDanglingNonExecCompany();
    this.setLoading(true);

    const queryParams = this.getQueryParams();

    let saveMethod;
    if (this.position.id) {
      saveMethod = this.API.restangularize(this.position).patch;
    } else {
      saveMethod = this.API.Positions.post;
    }

    const position = {
      positionType: this.position.positionType.code,
      city: this.position.city ? this.position.city : null,
      company: this.position.company ? this.position.company.id : null,
      startDate: getFormattedDateString(this.position.startDate),
      endDate: this.position.current
        ? null
        : getFormattedDateString(this.position.endDate),
      internalJobTitle: this.position.internalJobTitle,
      directReportingLine: this.position.directReportingLine,
      noticePeriod: this.position.noticePeriod,
      current: this.position.current,
      roleSummary: this.position.roleSummary,
      businessLines: this.API.objectListToIdsSerializerField(
        this.position.businessLines
      ),
      departments: this.API.objectListToIdsSerializerField(
        this.position.departments
      ),
      functions: this.API.objectListToIdsSerializerField(
        this.position.functions
      ),
      industryStandardJobTitles: this.API.objectListToIdsSerializerField(
        this.position.industryStandardJobTitles
      ),
      areasOfResponsibility: this.API.objectListToIdsSerializerField(
        this.position.areasOfResponsibility
      ),
      roleObjectives: this.API.objectListToIdsSerializerField(
        this.position.roleObjectives
      )
    };

    // Only update cityCoords if set
    if (this.position.cityCoords) {
      position.cityCoords = this.position.cityCoords;
    } else if (position.city === null) {
      // Clear coords if city is empty
      position.cityCoords = null;
    }

    saveMethod(position, queryParams)
      .then(this.saveSuggestions.bind(this))
      .then(this.saveNonExec.bind(this))
      .then(this.nextTab.bind(this))
      .catch(this.handleError.bind(this));
  }

  /*
   * Auxiliary helper.
   *
   * In some cases, the data might already contain some Suggestions when we GET the Position from
   * the API. If any of them are removed from the form data after GET, ask the API to DELETE them.
   */
  _deleteRemovedSuggestionsFromAPI(position) {
    if (position.organizationSuggestions) {
      position.organizationSuggestions.forEach(fetchedSuggestion => {
        // If the suggestion is for a Department, constrain the search to any new Department
        // suggestions that have been added ie. this.position.organizationSuggestions.departments
        let searchBucket = this.position.organizationSuggestions[
          fetchedSuggestion.organizationType
        ];

        // If there aren't any new suggestions for the field, we're done...
        if (searchBucket) {
          // If the suggestion is still there in the updated suggestions, we're done...
          for (let i = 0; i < searchBucket.length; i++) {
            if (searchBucket[i].name === fetchedSuggestion.name) {
              return;
            }
          }

          // If the suggestion was in the fetched data but is missing from the current form data,
          // DELETE it!
          this.API.restangularize(fetchedSuggestion)
            .remove()
            .catch(this.API.handleError());
        }
      });
    }
  }

  /*
   * Auxiliary helper.
   *
   * Compare Suggestions data in the form to existing Suggestions data from API. POST all new
   * Suggestions.
   */
  _postNewSuggestionsToAPI(position) {
    // Two nested loops:
    // - for every field (Department, Business Lines, ...)
    // - for every Suggestion within each field
    Object.keys(this.position.organizationSuggestions).forEach(field => {
      this.position.organizationSuggestions[field].forEach(suggestion => {
        let shouldPost = true;

        // If the User is updating an existing Position, ensure that this Suggestion hasn't already
        // been POSTed.
        for (let i = 0; i < position.organizationSuggestions.length; i++) {
          if (position.organizationSuggestions[i].name === suggestion.name) {
            shouldPost = false;
          }
        }

        if (shouldPost) {
          let saveData = {
            readableOrganizationType: suggestion.readableOrganizationType,
            organizationType: suggestion.organizationType,
            position: position.id,
            name: suggestion.name
          };

          this.API.OrganizationSuggestions.post(saveData).catch(
            this.API.handleError()
          );
        }
      });
    });
  }

  shouldDisplayFieldError(fieldName) {
    if (!this.form[fieldName]) {
      return false;
    }

    return (
      Object.keys(this.form[fieldName].$error).length > 0 &&
      (this.form[fieldName].$touched || this.form.$submitted)
    );
  }
}

PositionController.NAME = 'PositionController';
PositionController.controllerAs = 'positionCtrl';

export default PositionController;
