class ShareLonglistDialogController {
  /*@ngInject*/
  constructor($mdDialog, API) {
    this.API = API;
    this.$mdDialog = $mdDialog;
    this.clients = [];
    this.getAccounts();
  }

  getAccounts() {
    this.API.LonglistAccounts(this.longlist.id)
      .getList()
      .then(accounts => {
        this.clients = accounts;
      });
  }

  onSelectedItemChange(account) {
    if (!account) {
      return;
    }
    this.shareLonglist(account.user.id).then(() => {
      this.clients.push(account);
      this.accountSelected = null;
    });
  }

  removeItem(account) {
    this.unshareLonglist(account.user.id).then(() => {
      this.clients.splice(this.clients.indexOf(account), 1);
    });
  }

  shareLonglist(userId) {
    return this.API.LonglistSharing(this.longlist.id, userId)
      .put()
      .catch(this.API.handleError());
  }

  unshareLonglist(userId) {
    return this.API.LonglistSharing(this.longlist.id, userId)
      .remove()
      .catch(this.API.handleError());
  }

  validate() {
    this.$mdDialog.hide(this.clients);
  }
}

export default ShareLonglistDialogController;
