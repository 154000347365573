import angular from 'angular';

import Common from '../../common/components';

import ActionBar from './actionbar';
import ActionBarView from './actionBarView';
import BackButton from './buttons/backbutton';
import ButtonsModule from './buttons/Buttons.module';
import Card from './card';
import CardList from './cardlist';
import CheckboxOverflowMenu from './checkboxoverflowmenu/CheckboxOverflowMenu.module';
import ConditionalProfileLink from './conditional-profile-link';
import ConditionalServiceLink from './conditional-service-link';
import Chips from './chips/Chips.module';
import ClientDocuments from './client-documents/documents';
import ClientDocumentsList from './client-documents/list';
import ClientDocumentDownload from './client-document-download';
import Crop from './crop/Crop.module';
import Dialogs from './dialogs/Dialogs.module';
import DisplayField from './displayfield/DisplayField.module';
import ReactEducationAccordion from './react/EducationsAccordion/Wrapper';
import Groups from './groups';
import ImageSelection from './imageselection/ImageSelection.module';
import InlineEdit from './inline-edit';
import InlineEditProfile from './react/inlineEdit/Profile';
import InputsModule from './inputs/Inputs.module';
import MemberReferralForm from './memberReferralForm';
import Messenger from './messenger';
import Notifications from './notifications';
import OverflowMenu from './overflowmenu/OverflowMenu.module';
import Post from './post';
import Profile from './profile';
import Progress from './progress/Progress.module';
import progressloader from './progressloader/ProgressLoader.module';
import ProgressTabs from './progresstabs/ProgressTabs.module';
import RelatedForm from './relatedform/RelatedForm.module';
import RequiredMessage from './requiredmessage/RequiredMessage.module';
import Scroll from './scroll';
import Scrollable from './scrollable/Scrollable.module';
import Search from './search';
import SearchBar from './search-bar';
import Sidenav from './sidenav';
import SingleLocation from './singlelocation';
import Tab from './tabs/tab';
import Table from './table/Table.module';
import Tabs from './tabs';
import TimePeriodInputs from './timeperiodinputs';
import UserCardList from './usercardlist';
import Validators from './validators/Validators.module';
import WcBackdrop from './wcbackdrop/WcBackdrop.module';
import wcLoading from './wcloading/WcLoading.module';

// TODO(mihail): better organization
import RegistrationProfile from '../apps/react/members/registration/tabs/Profile';
import EmailField from './react/profile/basics/EmailField';
import PhoneNumberField from './react/profile/basics/PhoneNumberField';
import SettingsMobileSharing from './react/profile/basics/SettingsMobileSharing';
import RadioButtonGroup from './react/inputs/RadioButtonGroup';

// for Migration
// src/desktop/components/react/MyComponent/index.js
import MyComponent from './react/MyComponent';
import ReactChips from './react/ReactChips';
import Login from './react/auth/login';
import FixedCard from './react/cards/FixedCard';
import Loading from './react/loading';
import ReactMemberProfileOverviewPersonal from './react/profile/profile-overview/personal';
import ReactMemberProfileOverviewPosition from './react/profile/profile-overview/position';
import ReactMemberProfileOverviewProfessionalSummary from './react/profile/profile-overview/own-edit/professional-summary';
import ReactMemberProfileEditContainer from './react/profile/edit-container';
import ReactOwnMemberProfileRegionalManagement from './react/profile/profile-content/own-edit/regional-management';
import ReactOwnMemberProfileWorkEligibility from './react/profile/profile-content/own-edit/work-eligibility';
import ReactOwnMemberProfileEducation from './react/profile/profile-content/own-edit/education';
import ReactOwnMemberProfilePersonal from './react/profile/profile-content/own-edit/personal';
import ReactOwnMemberProfileContacts from './react/profile/profile-content/own-edit/contact-details';
import ReactSensitiveSettingOption from './react/settings/sensitive/options/sensitive';
import ReactPasswordSettingOption from './react/settings/sensitive/options/password';
import ReactCompanyPrivacySettings from './react/company-privacy-settings';
import ReactNotificationSettingsTab from './react/settings/notifications';
import ReactClientCompensationSettingsTab from './react/settings/client-compensation';
import ReactConditionalProfileLink from './react/conditional-profile-link';
import ReactPost from './react/post';
import ReactDropdownMenu from './react/dropdown-menu';
import ReactAnchorNavigationTabsSlot from './react/profile/profile-content/navigation/anchor-navigation-tabs-slot';
import ReactAnchorNavigationContainer from './react/profile/profile-content/navigation/anchor-navigation-container';
import ReactActionBarIconButton from './react/action-bar/ActionBarIconButton';
import ReactAlert from './react/alert';
import ReactViewTitle from './react/view-title';
import ReactSortableTable from './react/sortable-table';

import ReactLonglistList from '../apps/react/clients/longlists/lists';
import ReactEmailPreferences from './react/email-preferences';
import ReactCard from './react/card';
import ReactCardList from './react/card-list';
import ReactMemberListLayoutToggleSwitch from './react/member-list/layout-toggle-switch';
import ReactMemberListOrderingDropdown from './react/member-list/ordering-dropdown';
import ReactMemberListActiveFilterCount from './react/member-list/active-filter-count';
import ReactMemberListAddToLonglistSwitch from './react/member-list/add-to-longlist-switch';
import ReactActionBarSeparator from './react/action-bar/separator';
import ReactCreateButton from './react/buttons/CreateButton';
import ReactPlainButton from './react/buttons/PlainButton';
import ReactParagraphSepator from './react/separator/paragraph-separator';

let dependencies = [
  ActionBar.name,
  ActionBarView.name,
  BackButton.name,
  ButtonsModule.name,
  Card.name,
  CardList.name,
  CheckboxOverflowMenu.name,
  Chips.name,
  ClientDocuments.name,
  ClientDocumentsList.name,
  ClientDocumentDownload.name,
  Common.name,
  ConditionalProfileLink.name,
  ConditionalServiceLink.name,
  Crop.name,
  Dialogs.name,
  DisplayField.name,
  ReactEducationAccordion.name,
  Groups.name,
  ImageSelection.name,
  InlineEdit.name,
  InlineEditProfile.name,
  InputsModule.name,
  MemberReferralForm.name,
  Messenger.name,
  Notifications.name,
  OverflowMenu.name,
  Post.name,
  Profile.name,
  Progress.name,
  progressloader.name,
  ProgressTabs.name,
  RelatedForm.name,
  RequiredMessage.name,
  Scroll.name,
  Scrollable.name,
  Search.name,
  SearchBar.name,
  Sidenav.name,
  SingleLocation.name,
  Tab.name,
  Table.name,
  Tabs.name,
  TimePeriodInputs.name,
  UserCardList.name,
  UserCardList.name,
  Validators.name,
  WcBackdrop.name,
  wcLoading.name,
  RegistrationProfile.name,
  EmailField.name,
  PhoneNumberField.name,
  SettingsMobileSharing.name,
  RadioButtonGroup.name,
  MyComponent.name,
  ReactChips.name,
  Login.name,
  FixedCard.name,
  Loading.name,
  ReactMemberProfileOverviewPersonal.name,
  ReactMemberProfileOverviewPosition.name,
  ReactMemberProfileOverviewProfessionalSummary.name,
  ReactMemberProfileEditContainer.name,
  ReactOwnMemberProfileRegionalManagement.name,
  ReactOwnMemberProfileWorkEligibility.name,
  ReactOwnMemberProfileEducation.name,
  ReactOwnMemberProfilePersonal.name,
  ReactOwnMemberProfileContacts.name,
  ReactSensitiveSettingOption.name,
  ReactPasswordSettingOption.name,
  ReactCompanyPrivacySettings.name,
  ReactNotificationSettingsTab.name,
  ReactClientCompensationSettingsTab.name,
  ReactConditionalProfileLink.name,
  ReactPost.name,
  ReactDropdownMenu.name,
  ReactAnchorNavigationTabsSlot.name,
  ReactAnchorNavigationContainer.name,
  ReactActionBarIconButton.name,
  ReactAlert.name,
  ReactViewTitle.name,
  ReactSortableTable.name,
  ReactLonglistList.name,
  ReactEmailPreferences.name,
  ReactCard.name,
  ReactCardList.name,
  ReactMemberListLayoutToggleSwitch.name,
  ReactMemberListOrderingDropdown.name,
  ReactMemberListActiveFilterCount.name,
  ReactMemberListAddToLonglistSwitch.name,
  ReactActionBarSeparator.name,
  ReactCreateButton.name,
  ReactPlainButton.name,
  ReactParagraphSepator.name
];

export default angular.module('wc.components', dependencies);
