import RegistrationController from '../registration/Registration.controller';
import STATES from '../../../services/States';

class EditClientUploadController extends RegistrationController {
  /*ngInject*/
  goToNextState() {
    this.$state.go(STATES.CLIENT_EDIT_UPLOAD);
  }
}

export default EditClientUploadController;
