class ImageSelectionController {
  constructor($scope, API) {
    this.endpoint.getList().then(this.setItems.bind(this), API.handleError());
    this.modelWatcher = $scope.$watch(
      () => this.model,
      (newValue, oldValue) => {
        if (newValue && newValue !== oldValue) {
          this.updateItems();
        }
      }
    );
  }

  setItems(items) {
    this.items = items;
    this.updateItems();
  }

  updateItems() {
    if (this.model) {
      let ids = this.model.map(m => m.id);
      let urls = this.model.map(m => m.url);
      this.items.map(
        i => (i.isSelected = ids.indexOf(i.id) > -1 || urls.indexOf(i.url) > -1)
      );
    }
  }

  toggleItem(item) {
    item.isSelected = !item.isSelected;
    this.model = this.items.filter(i => i.isSelected);
  }
}

ImageSelectionController.$inject = ['$scope', 'API'];
ImageSelectionController.controllerAs = 'imageSelectionCtrl';
export default ImageSelectionController;
