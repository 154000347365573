import STATES from '../../services/States';

import PasswordCreationController from './PasswordCreation.controller';
import PasswordCreationTemplateUrl from './PasswordCreation.html';

/*@ngInject*/
function PasswordCreationRouting($stateProvider) {
  $stateProvider.state(STATES.PASSWORD_CREATION, {
    url: '/password-creation',
    controller: PasswordCreationController,
    controllerAs: PasswordCreationController.controllerAs,
    templateUrl: PasswordCreationTemplateUrl,
    resolve: {
      /*@ngInject*/
      redirect: function(RedirectService) {
        // Only Users without passwords should be able to view Password Creation.
        return RedirectService.redirectIfAlreadyChosenPassword();
      }
    }
  });
}

export default PasswordCreationRouting;
