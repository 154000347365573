import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ViewTitle = ({ topMargin, text, className }) => {
  return (
    <h1
      className={
        'WcViewTitle ' +
        className +
        (topMargin ? ' WcViewTitle__TopMargin' : '')
      }
    >
      {text}
    </h1>
  );
};

ViewTitle.propTypes = {
  topMargin: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string
};
