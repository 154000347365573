import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 200px;
`;
const CIRCLE_FILL_COLOR = 'rgb(164,148,125)';

const LoadingSpinner = ({ className }) => {
  return (
    <LoadingContainer className={className}>
      <CircularProgress
        thickness={4}
        size={50}
        style={{ color: CIRCLE_FILL_COLOR }}
      />
    </LoadingContainer>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string
};

export default LoadingSpinner;
