const SCROLL_OFFSET = 140;

class AnchorNavigation {
  scrollTo(target) {
    document.querySelector(`#${target}`).scrollIntoView({
      behavior: 'smooth'
    });
  }

  checkIfItemActive(target, scrollTop) {
    const firstVisibleAnchorPage = Array.from(
      document.querySelectorAll('.anchorPage')
    )
      .reverse()
      .find(el => {
        return el.offsetTop <= scrollTop + SCROLL_OFFSET;
      });
    return firstVisibleAnchorPage && firstVisibleAnchorPage.id
      ? firstVisibleAnchorPage.id === target
      : false;
  }
}

export default new AnchorNavigation();
