import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberVisibilitySettingsTab } from './MemberVisibilitySettingsTab';

export default angular
  .module('wc.components.ReactMemberVisibilitySettingsTab', [])
  // @ts-ignore
  .component(
    'reactMemberVisibilitySettingsTab',
    react2angular(
      MemberVisibilitySettingsTab,
      [
        'user',
        'profile',
        'isMember',
        'visibility',
        'covisibility',
        'onCovisibilitySettingChange',
        'covisibilityHanging',
        'onSameCompanySettingChange',
        'memberVisibleSettingHanging',
        'onMemberVisibleSettingChange',
        'visibleToCurrentCompanyHanging',
        'angularController'
      ],
      ['DialogService', 'Restangular', 'API']
    )
  );
