import DropdownController from '../Expandable.controller';
import RequestHelper from '../../../../services/RequestHelper';

class ChipsDrawerController extends DropdownController {
  /*@ngInject*/
  constructor($scope, $timeout, $q, API) {
    super();

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$q = $q;
    this.API = API;

    this.doCallEndpoint = this.$q.defer();

    this._initializeModel();

    if (this.onChange) {
      this.$scope.$watch(
        () => this.model,
        () => {
          this.$timeout(this.onChange);
        },
        true
      );
    }
    this._fetchOptions();

    this.$scope.$watch(
      () => this.endpoint,
      () => {
        this.doCallEndpoint.resolve(this.endpoint);
      },
      true
    );

    this.outstandingAPICall = this.$q.defer();

    this.outstandingAPICall.promise
      .then(this._setOptions.bind(this))
      .catch(this.API.handleError());
  }

  _initializeModel() {
    this.model = this.model || [];
  }

  _fetchOptions() {
    if (this.outstandingAPICall) {
      this.outstandingAPICall.resolve();
      this.outstandingAPICall = this.$q.defer();
      this.outstandingAPICall.promise
        .then(this._setOptions.bind(this))
        .catch(this.API.handleError());
    }

    this.doCallEndpoint.promise.then(endpoint => {
      if (endpoint) {
        let filterOptions = {};
        if (this.filterOptions) {
          filterOptions = RequestHelper.getFilterDict(this.filterOptions);
        }

        endpoint({ filterOptions: filterOptions }).then(
          this.outstandingAPICall.resolve
        );
      }
    });
  }

  _setOptions(options) {
    if (options) {
      this._options = options;
    }
  }

  _catchEvent(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  _onChipRemove(event, chip) {
    this._catchEvent(event);
    this.removeChip(chip);
    this.isTouched = true;

    if (this._isExpanded) {
      this._onChipBlur(event);
    }
  }

  checkIsValid() {
    return this.isValid;
  }

  shouldDisplayError() {
    return (
      Object.keys(this.ngModelController.$error).length > 0 && !this.isDisabled
    );
  }

  removeChip(chip) {
    this.model.splice(this.model.indexOf(chip), 1);
  }
}

ChipsDrawerController.controllerAs = 'chipsDrawerCtrl';
export default ChipsDrawerController;
