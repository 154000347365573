class SuggestOrganizationDialogController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
    this.suggestion = undefined;
  }

  submit() {
    if (this.form.$valid) {
      this.model = {
        readableOrganizationType: this.readableOrganizationType,
        organizationType: this.organizationType,
        name: this.suggestion
      };
      this.$mdDialog.hide(this.model);
    }
  }
}

SuggestOrganizationDialogController.controllerAs = '$ctrl';

export default SuggestOrganizationDialogController;
