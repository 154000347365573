import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Edit as BaseEdit } from '@material-ui/icons';

const EditIcon = styled(({ ...props }) => (
  <BaseEdit classes={{ root: 'root' }} {...props} />
))`
  &.root {
    font-size: 21px;
    color: ${props => props.theme.palette.extraColors.textWeaker}

    &:hover {
      color: ${props => props.theme.palette.secondary.A200}
    }
  }
`;

const EditButton = ({ onClick }) => {
  return (
    <button type="button" aria-label="Edit" onClick={onClick}>
      <EditIcon />
    </button>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EditButton;
