import states from '../../services/States';

class ClientsController {
  /*@ngInject*/
  constructor(API, featureFlags, MessengerService, AuthenticationService) {
    this.API = API;
    this.MessengerService = MessengerService;
    this.AuthenticationService = AuthenticationService;
    this.messagingEnabled = false;
    this.AuthenticationService.getProfile().then(profile => {
      this.messagingEnabled = profile && !profile.disableMessaging;
      if (this.messagingEnabled && featureFlags.isOn('messaging')) {
        this._initMessenger();
      }
    });
  }

  setUser(user) {
    this.user = user;
  }

  _initMessenger() {
    const userPromise = this.API.User.get();
    userPromise.then(this.setUser.bind(this), this.API.handleError());
    userPromise.then(user =>
      this.MessengerService.init(user, {
        conversation: states.CLIENT_CONVERSATION,
        list: states.CLIENT_MESSAGES,
        redirectListState: true
      })
    );
  }
}

ClientsController.controllerAs = 'clients';

export default ClientsController;
