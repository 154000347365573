import STATES from '../../../services/States';
import { tabNames } from '../settings/constants';

class AcceptController {
  /*@ngInject*/
  constructor(API, $state, AuthenticationService, $sessionStorage) {
    this.loading = true;
    API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .catch(this.handleError.bind(this));

    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.$sessionStorage = $sessionStorage;
    this.$state = $state;
    this.loading = true;
  }

  setProfile(profile) {
    this.profile = profile;
    this.loading = false;
  }

  continueToSettings() {
    this.loading = true;
    if (this.accepted) {
      let acceptData = {
        compensationLegislationAccepted: true
      };
      this.API.restangularize(this.profile)
        .patch(acceptData)
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
    } else {
      this.$sessionStorage.hasSeenLegislationAlert = true;
      this.goToCompensationSettings();
    }
  }

  handleSuccess() {
    this.AuthenticationService.refresh()
      .then(this.goToCompensationSettings.bind(this))
      .catch(this.handleError.bind(this));
  }

  goToCompensationSettings() {
    this.$state.go(STATES.CLIENT_SETTINGS, { tab: tabNames.COMPENSATION });
  }

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

AcceptController.NAME = 'AcceptController';
AcceptController.controllerAs = 'acceptCtrl';

export default AcceptController;
