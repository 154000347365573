import './styles.scss';
import templateUrl from './template.html';

class CurrencyDropdownController {
  /*@ngInject*/
  constructor($scope, API, CurrencyService, PreferredCurrencyService) {
    this.API = API;
    this.formatCurrency = CurrencyService.formatCurrency;

    $scope.$watch(
      () => this.model,
      () => {
        if (this.model) {
          PreferredCurrencyService.setPreferredCurrency(this.model);
        }
      }
    );
  }
}

const currencyDropdown = {
  NAME: 'currencyDropdown',
  bindings: {
    model: '='
  },
  controller: CurrencyDropdownController,
  templateUrl: templateUrl
};

export default currencyDropdown;
