import angular from 'angular';

import STATES from '../../../services/States';
import './styles.scss';
import template from './template.html';

class groupInviteApprovedController {
  /*@ngInject*/
  constructor() {
    this.GROUP_STATE = STATES.MEMBER_GROUP;
    this.MEMBER_PROFILE_STATE = STATES.OTHER_MEMBER_PROFILE;
  }
}

const groupInviteApprovedNotificationComponent = {
  bindings: {
    notification: '<',
    onAccept: '&',
    onIgnore: '&'
  },
  controller: groupInviteApprovedController,
  templateUrl: template
};

export default angular
  .module('wc.components.notifications.groupInviteApproved', [])
  .component(
    'wcGroupInviteApprovedNotification',
    groupInviteApprovedNotificationComponent
  );
