import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileRegionalManagement } from './OwnMemberProfileRegionalManagement';

export default angular
  .module('wc.components.OwnMemberProfileRegionalManagement', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileRegionalManagement',
    react2angular(OwnMemberProfileRegionalManagement, ['member'], ['$state'])
  );
