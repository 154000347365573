import angular from 'angular';
import templateUrl from './template.html';
import './styles.scss';

const dialog = {
  bindings: {
    ariaLabel: '@'
  },
  transclude: {
    title: 'wcDialogTitleSlot',
    content: 'wcDialogContentSlot',
    actions: 'wcDialogActionsSlot'
  },
  templateUrl: templateUrl
};

export default angular
  .module('wc.components.dialogs.dialog', [])
  .component('wcDialog', dialog);
