import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ChangeCovisibilityDialogController from '../../../../settings/covisibility/dialogs/ChangeCovisibilityDialog.controller';
import ChangeCovisibilityDialogTemplate from '../../../../settings/covisibility/dialogs/ChangeCovisibilityDialog.html';

const tootlipContent = `
  <div class="CovisibilitySetting__Tooltip">
    <div class="CovisibilitySetting__Tooltip_Item">
      Your profile is visible by default
      <br />
      to every member and client user
      <br />
      outside of your current employer.
    </div>

    <div class="CovisibilitySetting__Tooltip_Item">
      Please list other companies you would
      <br />
      like to hide your profile from.
      <br />
      This will hide your profile
      <br />
      from both members and clients
      <br />
      in these organisations.
    </div>

    <div class="CovisibilitySetting__Tooltip_Item">
      <b>Please note:</b>
      <br />
      By hiding your profile from certain companies,
      <br />
      you will also reduce the size of your member network.
    </div>
  </div>
`;

export const CovisibilitySettings = ({
  user,
  covisibility,
  onChange,
  covisibilityHanging,
  DialogService,
  Restangular,
  API
}) => {
  const showCovisibilityEditDialog = () => {
    return DialogService.show({
      locals: {
        user: user,
        covisibility: Restangular.copy(covisibility),
        API: API,
        onChange: () => onChange({ covisibility: covisibility })
      },
      templateUrl: ChangeCovisibilityDialogTemplate,
      controller: ChangeCovisibilityDialogController
    });
  };

  return (
    <div className="CovisibilitySetting__Row">
      <div className="CovisibilitySetting__Name">
        <div className="CovisibilitySetting__Header">
          <div className="CovisibilitySetting__Label">
            Hide my profile from the following companies
            <button
              className="CovisibilitySettingOption__Help"
              type="button"
              id="sensitive-setting-tooltip-btn"
            >
              <span className="zmdi zmdi-help CovisibilitySettingOption__HelpIcon" />
            </button>
            <ReactTooltip
              anchorId="sensitive-setting-tooltip-btn"
              style={{
                backgroundColor: '#e6e6e6',
                padding: 0,
                margin: 0,
                fontWeight: 500
              }}
              html={tootlipContent}
            />
          </div>

          {!covisibilityHanging && (
            <button
              className="SensitiveSettingOption__Edit"
              onClick={showCovisibilityEditDialog}
              type="button"
            >
              <span className="zmdi zmdi-edit SensitiveSettingOption__EditIcon" />
            </button>
          )}
        </div>
      </div>
      <div className="CovisibilitySetting__Description">
        Please list other companies you would like to hide your profile from.
        This will hide your profile from both members and clients in these
        organisations. <br />
      </div>
    </div>
  );
};

CovisibilitySettings.propTypes = {
  user: PropTypes.object,
  covisibility: PropTypes.object,
  onChange: PropTypes.func,
  covisibilityHanging: PropTypes.bool,
  DialogService: PropTypes.any,
  Restangular: PropTypes.any,
  API: PropTypes.any
};
