import './RequiredDropdownSelect.scss';
import DropdownSelectTemplateUrl from './RequiredDropdownSelect.html';

const inject = ['$log'];

let DropdownSelect = $log => {
  return {
    restrict: 'E',
    templateUrl: DropdownSelectTemplateUrl,
    scope: {
      endpoint: '=',
      fieldName: '@',
      forceShowError: '<',
      form: '=?',
      labelField: '@?',
      labelFunction: '&?',
      model: '=',
      title: '@',
      tooltip: '@?'
    },
    link: (scope, elem, attrs) => {
      scope.required = 'required' in attrs || undefined;

      if (!scope.labelField) {
        scope.labelField = 'name';
      }

      elem.find('md-select').on('blur', () => {
        scope.touched = true;
      });

      scope.getOptions = () => {
        scope.endpoint
          .getList()
          .then(scope.handleSuccess.bind(scope), scope.handleError.bind(scope));
      };

      scope.handleSuccess = options => {
        scope.options = options;
        if (options.length === 0) {
          $log.warn(`Empty list returned`);
        }
      };

      scope.handleError = error => {
        if (
          error &&
          error.status &&
          error.statusText &&
          error.data &&
          error.data.detail &&
          error.config &&
          error.config.url
        ) {
          $log.error(
            `API error: ${error.data.detail} (${error.config.url} ${
              error.status
            } ${error.statusText})`
          );
        } else if (error && error.status && error.statusText) {
          $log.error(`API error: ${error.status} ${error.statusText}`);
        } else {
          $log.error(`Unexpected API error ${error}`, error);
        }
      };

      scope.getOptions();
    }
  };
};

DropdownSelect.NAME = 'requiredDropdownSelect';
DropdownSelect.$inject = inject;

export default DropdownSelect;
