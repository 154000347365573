import { POSITION_TYPES } from '../../../../../common/services/Positions';

export default class {
  /*@ngInject*/
  constructor($scope, $timeout, API, RelatedFieldsGroup) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.API = API;
    this.RelatedFieldsGroup = RelatedFieldsGroup;
    this.POSITION_TYPES = POSITION_TYPES;

    this.cachedData = {
      company: { url: '' }
    };

    this.tooltipOptions = {
      current: {
        jobTitle: 'Your job title, as it appears on your business card',
        directReportingLine: 'The job title of the person you report to',
        businessLines:
          'Select the lines of business which the area you lead supports or is active in',
        departments:
          'Select the departments in which the area you lead belongs to',
        functions: 'Select the functions in which the area you lead belongs to',
        industryStandardJobTitles:
          'Select the Industry Standard Job Titles you recognize as best matching the role you currently perform',
        areasOfResponsibility:
          'Select the areas that currently fall within the areas you manage',
        roleObjectives:
          'Select all of the objectives or missions you deliver in this role'
      },
      past: {
        jobTitle: 'Your job title, as it appeared on your business card',
        directReportingLine: 'The job title of the person you reported to',
        businessLines:
          'Select the lines of business which the area you led supported or was active in',
        departments:
          'Select the departments in which the area you lead belonged to',
        functions:
          'Select the functions in which the area you lead belonged to',
        industryStandardJobTitles:
          'Select the Industry Standard Job Titles you recognize as best matching the role you performed',
        areasOfResponsibility:
          'Select the areas of that fell within the areas you managed',
        roleObjectives:
          'Select all of the objectives or missions you delivered in this role'
      }
    };

    this.relatedFieldsMap = {
      company: ['businessLines'],
      businessLines: []
    };
    this.relatedFieldsGroup = new this.RelatedFieldsGroup(
      this.relatedFieldsMap
    );

    this.reactRadioButtons = [
      {
        value: this.POSITION_TYPES.FULL_TIME,
        labelText: 'Full Time'
      },
      {
        value: this.POSITION_TYPES.INTERIM,
        labelText: 'Interim'
      }
    ];
  }

  $onInit() {
    this.tooltips = this.position.current
      ? this.tooltipOptions.current
      : this.tooltipOptions.past;

    this.$scope.$watch(
      () => this.position,
      () => {
        this.relatedFieldsGroup.setModel(this.position);
        this.relatedFieldsGroup.setSuggestionsModel(
          this.position.organizationSuggestions
        );
      },
      true
    );

    this.updateIndustryOptions();

    this.$timeout(() => {
      this.loading = false;
    });
  }

  handleCheckboxChange = e => {
    this.position.current = e.target.checked;
    this.$scope.$apply();
  };

  handleRadioButtonGroupChange = e => {
    this.position.positionType.code = e.target.value;
    this.$scope.$apply();
  };

  handleCompanyChange = value => {
    this.position.company = value;
    this.$scope.$apply();
    this.updateIndustryOptions();
  };

  updateIndustryOptions() {
    if (this.position.company) {
      let company = this.API.restangularizeUrl(this.position.company.url);
      if (company && company.url !== this.cachedData.company.url) {
        this.relatedFieldsGroup.onValid('company');
        company.get().then(c => {
          this.industriesFilters = { companies: c };
        });
        this.cachedData.company.url = company.url;
      }
    }
  }

  filterParentChanges(name, extra = {}) {
    this.$timeout(() => {
      this[`${name}Filters`] = extra;
      this[`${name}Filters`][name] = this.position[name];
    });
  }

  shouldDisplayRequiredFieldError(field) {
    return (
      this.formController[field].$touched &&
      Object.keys(this.formController[field].$error).length > 0
    );
  }
}
