import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileWorkEligibility } from './OwnMemberProfileWorkEligibility';

export default angular
  .module('wc.components.OwnMemberProfileWorkEligibility', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileWorkEligibility',
    react2angular(OwnMemberProfileWorkEligibility, ['member'], ['$state'])
  );
