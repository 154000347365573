import angular from 'angular';
import states from '../../services/States';

import template from './template.html';
import './styles.scss';

import conversationPane from './conversationPane';
import reactRecipientInfo from '../react/messenger/recipient-info';

class MessengerController {
  /*@ngInject*/
  constructor($scope, $state, MessengerService, AuthenticationService) {
    this.$scope = $scope;
    this.MessengerService = MessengerService;
    this.$state = $state;

    this.MessengerService.conversationsPromise.then(conversations => {
      if ($state.params.id) {
        this.MessengerService.setCurrentConversation($state.params.id);
      } else {
        this.MessengerService.redirectToLatestConversation(conversations);
      }
    });

    let isMember = $state.current.name.slice(0, 8) === 'members.';
    this.searchState = isMember ? states.MEMBER_SEARCH_RESULTS : states.SEARCH;
    this.searchEnabled = false;
    this.AuthenticationService = AuthenticationService;
    this.profilePromise = this.AuthenticationService.getProfile().then(
      profile => {
        this.searchEnabled = profile && !profile.limitedClient;
      }
    );

    this.MessengerService.serviceReadyPromise.then(() => {
      this.MessengerService.loadConversations();
      // This is necessary to trigger a digest, for some reasons
      this.MessengerService.conversationsPromise.then(() => {});
    });
  }

  goToSearch() {
    this.$state.go(this.searchState);
  }
}

export default angular
  .module('wc.components.messenger', [
    conversationPane.name,
    reactRecipientInfo.name
  ])
  .component('wcMessenger', {
    controller: MessengerController,
    templateUrl: template
  });
