import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import InverseButton from '../../buttons/InverseButton/InverseButton';
import { Menu, MenuItem } from '@material-ui/core';

const clickInput = inputName => {
  document.querySelector(inputName).click();
};

const getMenuButton = (menuItem, handleClose) => {
  if (menuItem.callback) {
    return (
      <MenuItem onClick={handleClose} key={menuItem.name}>
        <button
          className="WcDropdownMenu__Button"
          aria-label={menuItem.name}
          onClick={(event, element) => menuItem.callback(event, element)}
        >
          {menuItem.iconFirst && (
            <React.Fragment>
              {menuItem.icon && <span className={`zmdi ${menuItem.icon}`} />}
              {menuItem.name}
            </React.Fragment>
          )}

          {!menuItem.iconFirst && (
            <React.Fragment>
              {menuItem.name}
              {menuItem.icon && <span className={`zmdi ${menuItem.icon}`} />}
            </React.Fragment>
          )}
        </button>
      </MenuItem>
    );
  } else if (menuItem.clickInput) {
    return (
      <MenuItem
        onClick={handleClose}
        key={menuItem.name}
        className="WcDropcownMenuItem"
      >
        <button
          className="WcDropdownMenu__Button"
          aria-label={menuItem.name}
          onClick={() => clickInput(menuItem.clickInput)}
        >
          {menuItem.iconFirst && (
            <React.Fragment>
              {menuItem.icon && <span className={`zmdi ${menuItem.icon}`} />}
              {menuItem.name}
            </React.Fragment>
          )}

          {!menuItem.iconFirst && (
            <React.Fragment>
              {menuItem.name}
              {menuItem.icon && <span className={`zmdi ${menuItem.icon}`} />}
            </React.Fragment>
          )}
        </button>
      </MenuItem>
    );
  } else if (!menuItem.callback && !menuItem.clickInput) {
    return (
      <MenuItem
        onClick={handleClose}
        key={menuItem.name}
        className="WcDropcownMenuItem"
      >
        <button
          className="WcDropdownMenu__Button--inactive"
          aria-label={menuItem.name}
        >
          <span>{menuItem.name}</span>
        </button>
      </MenuItem>
    );
  } else {
    return <React.Fragment />;
  }
};

export const DropdownMenu = ({ menuObject }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <InverseButton
        ariaLabel={menuObject.button.name}
        className="WcDropdownMenu__Button"
        type="button"
        onClick={handleClick}
        label={menuObject.button.name}
        icon={menuObject.button.icon}
        withBorder={false}
        withMargin={true}
      />
      <Menu
        className={'WcDropdownMenu__Container ' + menuObject.className}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuObject.menu.map(menuItem => getMenuButton(menuItem, handleClose))}
      </Menu>
    </React.Fragment>
  );
};

DropdownMenu.propTypes = {
  menuObject: PropTypes.object
};
