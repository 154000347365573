import angular from 'angular';
import './TimePeriodSelector.scss';
import controller from './TimePeriodSelector.controller';
import templateUrl from './TimePeriodSelector.html';

const TimePeriodSelector = {
  NAME: 'wcTimePeriodSelector',
  bindings: {
    model: '=wcModel',
    timePeriodSelectorOptions: '<wcTimePeriodSelectorOptions'
  },
  controller: controller,
  templateUrl: templateUrl
};

export default angular
  .module('wc.components.timePeriodSelector', [])
  .component(TimePeriodSelector.NAME, TimePeriodSelector);
