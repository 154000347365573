import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MemberProfileEditContainer = ({
  children,
  stickyEdit,
  onEdit,
  onDelete
}) => {
  return (
    <div className="WcMemberProfileEditContainer">
      <div className="WcMemberProfileEditContainer__PushBox" />

      {children}

      <div
        className={`WcMemberProfileEditContainer__EditBox ${
          stickyEdit ? 'WcMemberProfileEditContainer__EditBox--StickyEdit' : ''
        }`}
      >
        <button
          className="WcMemberProfileEditContainer__EditButton"
          aria-label="Edit"
          onClick={event => onEdit(event)}
        >
          <span className="WcMemberProfileEditContainer__EditIcon zmdi zmdi-edit" />
        </button>
        {onDelete && (
          <button
            className="WcMemberProfileEditContainer__EditButton"
            aria-label="Delete"
            onClick={event => onDelete(event)}
          >
            <span className="WcMemberProfileEditContainer__EditIcon zmdi zmdi-delete" />
          </button>
        )}
      </div>
    </div>
  );
};

MemberProfileEditContainer.propTypes = {
  children: PropTypes.node,
  stickyEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};
