import angular from 'angular';

const wcFormInfoMessage = {
  NAME: 'wcFormInfoMessage',
  template:
    '<div class="WcFormInfoMessage"><ng-transclude></ng-transclude></div>',
  transclude: true
};

export default angular
  .module('wcComponents.typography.formInfoMessage', [])
  .component(wcFormInfoMessage.NAME, wcFormInfoMessage);
