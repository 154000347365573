import angular from 'angular';
import './PlainButton.scss';

const PlainButton = {
  NAME: 'wcPlainButton',
  bindings: {
    onClick: '&wcOnClick'
  },
  template:
    '<button class="WcPlainButton" ng-click="$ctrl.onClick($event)"><div ng-transclude></div></button>',
  transclude: true
};

export default angular
  .module('wc.components.buttons.plainButton', [])
  .component(PlainButton.NAME, PlainButton);
