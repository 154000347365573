import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InverseButton from '../../../../buttons/InverseButton/InverseButton';

export const ClientMemberProfileActions = ({
  member,
  Resume,
  MessengerService,
  AuthenticationService
}) => {
  const [messagingEnabled, setMessagingEnabled] = useState(false);

  useEffect(() => {
    if (AuthenticationService.isClient()) {
      AuthenticationService.getProfile().then(profile => {
        setMessagingEnabled(profile && !profile.disableMessaging);
      });
    }
  }, []);

  const downloadResume = () => {
    if (member.resume) {
      Resume.download(member.resume, member.fullName);
    }
  };
  return (
    <div className="WcMemberProfileContent__ActionButtons">
      {messagingEnabled && member.chatId && (
        <InverseButton
          featureFlag="messaging"
          aria-label="Message"
          className="WcMemberProfileContent__Button"
          type="button"
          onClick={() => MessengerService.messageUser(member.chatId)}
          icon="EmailIcon"
          label="Send a Message"
          withBorder={false}
          withMargin={true}
        />
      )}

      {member.resume && (
        <InverseButton
          aria-label="CV Resume"
          className="WcMemberProfileContent__Button"
          type="button"
          onClick={downloadResume}
          icon="FileText"
          label="CV / Resume"
          withBorder={false}
          withMargin={true}
        />
      )}
    </div>
  );
};

ClientMemberProfileActions.propTypes = {
  member: PropTypes.object,
  Resume: PropTypes.any,
  MessengerService: PropTypes.any,
  AuthenticationService: PropTypes.any
};
