const formValid = form => {
  for (let field in form) {
    if (form[field].error) {
      return false;
    }
  }

  return true;
};

export default formValid;
