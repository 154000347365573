import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const SensitiveSettingOption = ({ label, name, onEdit, value }) => {
  return (
    <div className="SensitiveSettingOption">
      <div className="SensitiveSettingOption__Header">
        <div className="SensitiveSettingOption__Label">{name}</div>
        <button
          className="SensitiveSettingOption__Edit"
          onClick={onEdit}
          type="button"
        >
          <span className="zmdi zmdi-edit SensitiveSettingOption__EditIcon" />
        </button>
      </div>
      <div className="SensitiveSettingOption__Current">
        <label className="SensitiveSettingOption__CurrentLabel">{label}</label>
        <div className="SensitiveSettingOption__CurrentValue">{value}</div>
      </div>
    </div>
  );
};

SensitiveSettingOption.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onEdit: PropTypes.func,
  value: PropTypes.string
};
