import STATES from '../../../services/States';

class MembersRegistrationController {
  /*@ngInject*/
  constructor(API, AuthenticationService) {
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.tabs = [
      {
        title: 'Basics',
        redirectTo: {
          state: STATES.MEMBER_REGISTRATION.PROFILE
        }
      },
      {
        title: 'Experience',
        redirectTo: {
          state: STATES.MEMBER_REGISTRATION.EXPERIENCE
        }
      },
      {
        title: 'Compensation',
        redirectTo: {
          state: STATES.MEMBER_REGISTRATION.REMUNERATION
        }
      },
      {
        title: 'About You',
        redirectTo: {
          state: STATES.MEMBER_REGISTRATION.ABOUT_YOU
        }
      }
    ];
    this.AuthenticationService.getProfile().then(profile => {
      if (!profile || profile.noCompensation) {
        this.removeCompensationTab();
      }
    });
  }

  removeCompensationTab() {
    this.tabs = this.tabs.filter(
      e => e.redirectTo.state !== STATES.MEMBER_REGISTRATION.REMUNERATION
    );
  }
}

MembersRegistrationController.controllerAs = 'registrationCtrl';
MembersRegistrationController.NAME = 'MembersRegistrationController';
export default MembersRegistrationController;
