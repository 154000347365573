import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import styled from 'styled-components';

import theme from '../../../../../common/styles/theme';
import { InputError, InputHint } from '../../../../../common/components';
import { CheckboxQuestion as BaseCheckboxQuestion } from '../../inputs/CheckboxQuestion';

const StyledCheckbox = styled.div`
  margin-top: -9px;
`;

const CheckboxQuestion = styled(BaseCheckboxQuestion)`
  margin: 9px 0;
`;

class EthnicityConsent extends React.Component {
  handleClick = () => {
    const { checked, update } = this.props;
    update(!checked);
  };

  renderError = () => {
    const { checkForError, hasError } = this.props;
    if (checkForError) {
      if (hasError) {
        return (
          <InputError>
            Please tick the box if you agree to provide this information. If you
            would prefer not to provide this information, please deselect your
            ethnicity from the list.{` `}
            <a
              target="_blank"
              href="https://www.wintercircle.co/gdpr.html"
              rel="noopener noreferrer"
            >
              Learn more.
            </a>
          </InputError>
        );
      }
    }
    return null;
  };

  setTouched = () => {
    this.props.updateTouched(true);
  };

  render() {
    const { checked, label } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <StyledCheckbox>
          <CheckboxQuestion
            onBlur={this.setTouched}
            checked={checked}
            onChange={this.handleClick}
            labelText={label}
          />
          {this.renderError()}
        </StyledCheckbox>
        <InputHint>
          We gather this data point, among others, to help ensure we are
          building a demographically diverse network.
        </InputHint>
      </ThemeProvider>
    );
  }
}

EthnicityConsent.propTypes = {
  checked: PropTypes.bool,
  update: PropTypes.func,
  label: PropTypes.string,
  hasError: PropTypes.bool,
  updateTouched: PropTypes.func,
  checkForError: PropTypes.bool
};

export default EthnicityConsent;
