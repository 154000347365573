import angular from 'angular';
import './Input.scss';
import controller from './Input.controller';
import templateUrl from './Input.html';

import TimePeriodSelector from '../../timeperiodinputs/timeperiodselector';

const Input = {
  NAME: 'wcInputWithTimePeriodSelector',
  bindings: {
    model: '=ngModel',
    timePeriodSelectorOptions: '<wcTimePeriodSelectorOptions'
  },
  controller: controller,
  templateUrl: templateUrl
};

const dependencies = [TimePeriodSelector.name];

export default angular
  .module('wc.components.timePeriodInputs.input', dependencies)
  .component(Input.NAME, Input);
