import Immutable from 'immutable';
import TIME_PERIODS from './TimePeriods';

const current = TIME_PERIODS.CURRENT.get('value');
const past = TIME_PERIODS.PAST.get('value');
const aspirational = TIME_PERIODS.ASPIRATIONAL.get('value');

/* These must correspond to the strings expected by API in filter query params. */
const CURRENT_STRING = 'current';
const PAST_STRING = 'past';
const ASPIRATIONAL_STRING = 'aspiration';
const CURRENT_PAST_STRING = 'current_past';
const CURRENT_ASPIRATIONAL_STRING = 'current_aspiration';
const PAST_ASPIRATIONAL_STRING = 'past_aspiration';
const ALL_STRING = 'all';

const CURRENT_SET = new Immutable.Set([current]);
const PAST_SET = new Immutable.Set([past]);
const ASPIRATIONAL_SET = new Immutable.Set([aspirational]);
const CURRENT_PAST_SET = new Immutable.Set([current, past]);
const CURRENT_ASPIRATIONAL_SET = new Immutable.Set([current, aspirational]);
const PAST_ASPIRATIONAL_SET = new Immutable.Set([past, aspirational]);
const ALL_SET = new Immutable.Set([current, past, aspirational]);

const CURRENT_LIST = new Immutable.List([TIME_PERIODS.CURRENT]);
const PAST_LIST = new Immutable.List([TIME_PERIODS.PAST]);
const ASPIRATIONAL_LIST = new Immutable.List([TIME_PERIODS.ASPIRATIONAL]);
const CURRENT_PAST_LIST = new Immutable.List([
  TIME_PERIODS.CURRENT,
  TIME_PERIODS.PAST
]);
const CURRENT_ASPIRATIONAL_LIST = new Immutable.List([
  TIME_PERIODS.CURRENT,
  TIME_PERIODS.ASPIRATIONAL
]);
const PAST_ASPIRATIONAL_LIST = new Immutable.List([
  TIME_PERIODS.PAST,
  TIME_PERIODS.ASPIRATIONAL
]);
const ALL_LIST = new Immutable.List([
  TIME_PERIODS.CURRENT,
  TIME_PERIODS.PAST,
  TIME_PERIODS.ASPIRATIONAL
]);

export default class TimePeriodGroups {
  static convertTimePeriodSetToString(
    selectedTimePeriodSet,
    timePeriodOptionsSet
  ) {
    if (
      !selectedTimePeriodSet ||
      selectedTimePeriodSet.equals(timePeriodOptionsSet)
    ) {
      return this.STRINGS.get('ALL');
    }

    const key =
      this.SETS.findKey(value => selectedTimePeriodSet.equals(value)) || 'ALL';
    return this.STRINGS.get(key);
  }

  static convertTimePeriodStringToSet(timePeriodString) {
    if (!timePeriodString) {
      return this.SETS.get('ALL');
    }

    const key =
      this.STRINGS.findKey(value => timePeriodString.equals(value)) || 'ALL';
    return this.SETS.get(key);
  }

  static convertTimePeriodListToSet(timePeriodList) {
    const key =
      this.LISTS.findKey(value => timePeriodList.equals(value)) || 'ALL';
    return this.SETS.get(key);
  }
}

TimePeriodGroups.STRINGS = Immutable.fromJS({
  CURRENT: CURRENT_STRING,
  PAST: PAST_STRING,
  ASPIRATIONAL: ASPIRATIONAL_STRING,
  CURRENT_PAST: CURRENT_PAST_STRING,
  CURRENT_ASPIRATIONAL: CURRENT_ASPIRATIONAL_STRING,
  PAST_ASPIRATIONAL: PAST_ASPIRATIONAL_STRING,
  ALL: ALL_STRING
});

TimePeriodGroups.SETS = Immutable.fromJS({
  CURRENT: CURRENT_SET,
  PAST: PAST_SET,
  ASPIRATIONAL: ASPIRATIONAL_SET,
  CURRENT_PAST: CURRENT_PAST_SET,
  CURRENT_ASPIRATIONAL: CURRENT_ASPIRATIONAL_SET,
  PAST_ASPIRATIONAL: PAST_ASPIRATIONAL_SET,
  ALL: ALL_SET
});

TimePeriodGroups.LISTS = Immutable.fromJS({
  CURRENT: CURRENT_LIST,
  PAST: PAST_LIST,
  ASPIRATIONAL: ASPIRATIONAL_LIST,
  CURRENT_PAST: CURRENT_PAST_LIST,
  CURRENT_ASPIRATIONAL: CURRENT_ASPIRATIONAL_LIST,
  PAST_ASPIRATIONAL: PAST_ASPIRATIONAL_LIST,
  ALL: ALL_LIST
});
