import { cloneDeep } from 'lodash';
import STATES from '../../../services/States';
import { FORM_ERROR_MESSAGE } from '../../../services/FormErrorMessages';
import cityDisplayValue from '../../../services/CityDisplayValue';

class RegistrationController {
  /*@ngInject*/
  constructor($q, $scope, $state, API, AuthenticationService, CurrencyService) {
    this.$q = $q;
    this.$scope = $scope;
    this.$state = $state;
    this.API = API;
    this.CurrencyService = CurrencyService;
    this.setLoading(true);
    this.formErrorMessage = FORM_ERROR_MESSAGE;
    this.cityDisplayValue = cityDisplayValue;
    this.AuthenticationService = AuthenticationService;

    this.API.User.get()
      .then(this.handleUser.bind(this))
      .then(this.handleProfile.bind(this))
      .then(this.setLoading.bind(this, false))
      .catch(this.API.handleError());

    this.API.Currencies.getList()
      .then(this.setCurrencies.bind(this))
      .catch(this.API.handleError());
  }

  handleUser(user) {
    this.user = user;

    if (user.profile) {
      return this.API.restangularizeUrl(user.profile).get();
    }

    return null;
  }

  handleProfile(profile) {
    this.clientProfile = profile;
  }

  setCurrencies(currencies) {
    this.currencies = this.CurrencyService.currencyListToMap(currencies);
  }

  setLoading(loading) {
    this.loading = !!loading;
  }

  submit() {
    if (this.form.$valid) {
      let patchData = cloneDeep(this.user);
      delete patchData.mobileNumber;
      delete patchData.email;
      this.API.User.patch(patchData)
        .then(this.patchProfile.bind(this))
        .then(this.goToNextState.bind(this))
        .catch(this.API.handleError());
    }
  }

  patchProfile() {
    let patchData = {
      city: this.clientProfile.city ? this.clientProfile.city.id : null,
      country: this.clientProfile.country
        ? this.clientProfile.country.id
        : null,
      directDial: this.clientProfile.directDial,
      internalJobTitle: this.clientProfile.internalJobTitle,
      preferredCurrency: this.clientProfile.preferredCurrency
        ? this.clientProfile.preferredCurrency.id
        : null,
      state: this.clientProfile.state
    };
    return this.API.restangularize(this.clientProfile).patch(patchData);
  }

  goToNextState() {
    this.$state.go(STATES.CLIENT_REGISTRATION_UPLOAD);
  }
}

RegistrationController.controllerAs = 'registrationCtrl';
export default RegistrationController;
