import angular from 'angular';

/* @ngInject */
function onDisabled() {
  return (attributes, onDisabled, onEnabled) => {
    function callDisabledHandlers() {
      if ('disabled' in attributes) {
        if (angular.isFunction(onDisabled)) {
          onDisabled();
        }
      } else {
        if (angular.isFunction(onEnabled)) {
          onEnabled();
        }
      }
    }

    attributes.$observe('disabled', callDisabledHandlers);
  };
}

onDisabled.NAME = 'dfOnDisabled';

export default angular
  .module('dfComponents.inputs.behaviours.dfOnDisabled', [])
  .factory(onDisabled.NAME, onDisabled);
