import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileOverviewPersonal } from './MemberProfileOverviewPersonal';

export default angular
  .module('wc.components.MemberProfileOverviewPersonal', [])
  // @ts-ignore
  .component(
    'reactWcMemberProfileOverviewPersonal',
    react2angular(MemberProfileOverviewPersonal, ['member'])
  );
