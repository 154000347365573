import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Toolbar } from '../../../../../../../common/components/react/messenger/toolbar/Toolbar';
import { ConditionalProfileLink } from '../../../../../react/conditional-profile-link/ConditionalProfileLink';
import STATES from '../../../../../../services/States';
import { USER_TYPES } from '../../../../../../../common/services/api/APIConstants';
import { MessengerRecipientInfo } from '../../../../../react/messenger/recipient-info/MessengerRecipientInfo';

export const ConversationPaneToolbar = ({ MessengerService, $state }) => {
  const [recipient, setRecipient] = useState();
  const [title, setTitle] = useState();

  useEffect(() => {
    if (MessengerService && MessengerService.currentConversation) {
      updateRecipient();
    }
  }, [MessengerService]);

  const updateRecipient = async () => {
    if (!MessengerService.currentConversation) {
      return;
    }

    const recipientTmp = await MessengerService.getRecipient(
      MessengerService.currentConversation
    );
    setRecipient(recipientTmp);
    updateTitleFromRecipientMetadata(recipientTmp);
  };

  const updateTitleFromRecipientMetadata = recipient => {
    if (recipient && recipient.attributes) {
      const titleTmp = [
        recipient.attributes.job_title,
        recipient.attributes.company
      ].join(', ');
      setTitle(titleTmp);
    }
  };

  const canNavigateToProfile = () => {
    return (
      MessengerService.getUser() &&
      recipient &&
      recipient.attributes &&
      recipient.attributes.profile_type === USER_TYPES.MEMBER
    );
  };

  const getProfileState = () => {
    const userType = MessengerService.getUser().type;

    let state;
    if (userType === USER_TYPES.CLIENT) {
      state = STATES.CLIENTS_MEMBER_PROFILE;
    } else if (userType === USER_TYPES.MEMBER) {
      state = STATES.OTHER_MEMBER_PROFILE;
    } else {
      return;
    }

    return state;
  };

  const isTestUser = () => {
    return recipient && recipient.attributes && recipient.attributes.test_user;
  };

  return (
    <Toolbar className="WcConversationPaneToolbar">
      {recipient && (
        <ConditionalProfileLink
          wcCondition={!isTestUser() && canNavigateToProfile()}
          profileId={recipient.attributes.profile_id}
          userProfileState={getProfileState()}
          wcClass="WcConversationPane__ProfileLink"
          $state={$state}
        >
          <MessengerRecipientInfo
            profilePictureSrc={recipient.attributes.avatar_url}
            name={recipient.attributes.display_name}
            jobTitle={title}
          />
        </ConditionalProfileLink>
      )}
    </Toolbar>
  );
};

ConversationPaneToolbar.propTypes = {
  MessengerService: PropTypes.any,
  $state: PropTypes.any
};
