import angular from 'angular';
import { react2angular } from 'react2angular';

import { MessengerRecipientInfo } from './MessengerRecipientInfo';

export default angular
  .module('wc.components.ReactMessengerRecipientInfo', [])
  .component(
    'reactMessengerRecipientInfo',
    react2angular(MessengerRecipientInfo, [
      'profilePictureSrc',
      'name',
      'jobTitle'
    ])
  );
