import WcLoadingUrl from './WcLoading.html';

/*
 'hide': Use ng-hide instead of ng-if
 */

/*@ngInject*/
let LoadingDirective = () => {
  return {
    restrict: 'A',
    scope: {
      loading: '<wcLoading',
      centered: '<centered',
      transparent: '<transparent',
      wcHide: '<?'
    },
    templateUrl: WcLoadingUrl,
    transclude: true
  };
};

LoadingDirective.NAME = 'wcLoading';

export default LoadingDirective;
