import STATES from '../../../services/States';
import { PASSWORD_CHANGE_SUCCESS } from '../LoginMessages';

class ChangePasswordController {
  /*@ngInject*/
  constructor($state, $stateParams, API, AuthenticationService) {
    this.$state = $state;
    this.API = API;
    this.AuthenticationService = AuthenticationService;

    if (!$stateParams.token) {
      $state.go(STATES.LOGIN);
      return;
    }

    this.AuthenticationService.loginWithOneTimeToken(
      $stateParams,
      STATES.FORGOTTEN_PASSWORD
    );
  }

  submit() {
    if (this.form.$valid) {
      this.API.User.all('password')
        .customPUT({ password: this.password })
        // Refresh the AuthenticationService, because hasChosenPassword might have changed now.
        .then(() => this.AuthenticationService.refresh())
        .then(this.handlePasswordChangeSuccess.bind(this))
        .catch(this.API.handleError());
    }
  }

  handlePasswordChangeSuccess() {
    this.$state.go(STATES.LOGIN, { status: PASSWORD_CHANGE_SUCCESS });
  }
}

ChangePasswordController.controllerAs = 'changePasswordCtrl';
export default ChangePasswordController;
