/**
 * @param {Object[]} list
 * @returns {Boolean} - True if one or more items in the list are selected.
 */
export let checkAnySelected = list => {
  if (!list) {
    return false;
  }

  for (let i = 0; i < list.length; i++) {
    if (list[i].isSelected) {
      return true;
    }
  }

  return false;
};

/**
 * @param {Object[]} list
 * @returns {Boolean} - True if every item in the list is selected.
 */
export let checkAllSelected = list => {
  if (!list) {
    return true;
  }

  for (let i = 0; i < list.length; i++) {
    if (list[i].isSelected !== true) {
      return false;
    }
  }

  return true;
};

/**
 * Select all items in the list, or deselect all if they are already all selected.
 *
 * @param {Object[]} list
 */
export let toggleSelectAll = list => {
  if (checkAllSelected(list)) {
    list.forEach(item => {
      item.isSelected = false;
    });
  } else {
    list.forEach(item => {
      item.isSelected = true;
    });
  }
};

/**
 * Get a list of all items in the provided list.
 *
 * @param {Object[]} list
 * @returns {Array}
 */
export let getSelectedList = list => {
  let selected = [];
  list.forEach(item => {
    if (item.isSelected === true) {
      selected.push(item);
    }
  });

  return selected;
};

/**
 * Deslect all items in list.
 *
 * @param {Object[]} list
 */
export let deselectAll = list => {
  list.forEach(item => {
    item.isSelected = false;
  });
};
