class EditLonglistDialogController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
  }

  validate() {
    this.dialogForm.$submitted = true;
    if (this.dialogForm.$valid) {
      this.$mdDialog.hide(this.longlist);
    }
  }
}

export default EditLonglistDialogController;
