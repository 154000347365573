import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileEditContainer } from './MemberProfileEditContainer';

export default angular
  .module('wc.components.MemberProfileEditContainer', [])
  // @ts-ignore
  .component(
    'reactMemberProfileEditContainer',
    react2angular(MemberProfileEditContainer, [
      'stickyEdit',
      'onEdit',
      'onDelete',
      'children'
    ])
  );
