import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MemberProfileCandidateSummary = ({ summary }) => {
  const summaryArray = summary ? summary.split('\r\n') : [];

  return summaryArray.length > 0 ? (
    <React.Fragment>
      <h2 className="WcMemberProfileContent__Subheading WcMemberProfileContent__Subheading__SummaryTitle">
        Candidate Summary
      </h2>
      <div className="WcMemberProfileContent__Summary">
        {summaryArray.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
    </React.Fragment>
  ) : null;
};

MemberProfileCandidateSummary.propTypes = {
  summary: PropTypes.string
};
