import angular from 'angular';

function defaultCheckInternalBlur(rootElement, activeElement) {
  return rootElement.contains(activeElement);
}

/* @ngInject */
function onBlur($document, $timeout) {
  return (
    scope,
    element,
    onBlur,
    checkInternalBlur = defaultCheckInternalBlur
  ) => {
    element.addEventListener(
      'blur',
      event => {
        // We need to wait a browser tick before document.activeElement is assigned the correct
        // value.
        $timeout(() => {
          if (checkInternalBlur(element, $document[0].activeElement, event)) {
            return;
          }

          onBlur();
          scope.$apply();
        });
      },
      true
    );
  };
}

onBlur.NAME = 'dfOnBlur';

export default angular
  .module('dfComponents.inputs.behaviours.dfOnBlur', [])
  .factory(onBlur.NAME, onBlur);
