import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const Card = ({ children }) => {
  return <div className="cardElement">{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node
};
