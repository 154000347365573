import STATES from '../../../services/States';
import LonglistDetailTemplateUrl from './detail/LonglistDetail.html';
import LonglistDetailController from './detail/LonglistDetail.controller';

let LonglistsRouting = $stateProvider => {
  $stateProvider
    .state(STATES.LONGLISTS, {
      url: '/longlists?tab',
      template: `<react-longlists-list></react-longlists-list>`
    })

    .state(STATES.LONGLIST_DETAIL, {
      url: '/longlists/:lid',
      templateUrl: LonglistDetailTemplateUrl,
      controller: LonglistDetailController,
      controllerAs: LonglistDetailController.controllerAs
    })

    .state(STATES.STANDALONE_PREVIEW_LONGLIST, {
      url: '/preview/longlists/:lid',
      templateUrl: LonglistDetailTemplateUrl,
      controller: LonglistDetailController,
      controllerAs: LonglistDetailController.controllerAs
    })

    .state(STATES.LONGLIST_DETAIL_MEMBER, {
      url: '/longlists/:lid/members/:id',
      template: `<wc-member-profile is-client="true" id="profileScrollContainer" wc-sticky-element-container>
      </wc-member-profile>`
    });
};

LonglistsRouting.$inject = ['$stateProvider'];

export default LonglistsRouting;
