/*eslint angular/definedundefined:0*/
/*eslint angular/typecheck-array:0*/

function getFilterDict(value) {
  if (!value) {
    return {};
  }
  let filterOptions = {};
  Object.keys(value).forEach(k => {
    let filterOption = value[k];
    if (typeof filterOption !== 'undefined') {
      if (filterOption.map) {
        // Transform a list of objects into a list of ids
        filterOptions[k] = filterOption.map(o => o.id);
      } else if (filterOption.id) {
        // Transform a single object into an id
        filterOptions[k] = filterOption.id;
      } else {
        // Leave primitive filters untouched
        filterOptions[k] = filterOption;
      }
    }
  });
  return camelCaseToSnakeCase(filterOptions);
}

function camelCaseToSnakeCase(obj) {
  let underscored = {};
  Object.keys(obj).map(k => {
    let key = k.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
    underscored[key] = obj[k];
  });
  return underscored;
}

export default {
  getFilterDict,
  camelCaseToSnakeCase
};
