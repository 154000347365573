import './styles.scss';
import templateUrl from './template.html';

export default {
  NAME: 'wcEditLink',
  bindings: {
    ariaLabel: '@',
    uiSref: '@'
  },
  templateUrl: templateUrl
};
