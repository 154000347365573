import STATES from '../../../services/States';

import ClientRegistrationController from './Registration.controller';
import ClientRegistrationTemplateUrl from './Registration.html';
import ClientsRegistrationUploadTemplateUrl from './Upload.html';
import ClientsRegistrationUploadController from './Upload.controller';

/*@ngInject*/
function redirect(RedirectService) {
  // Only Clients who haven't completed registration yet should be allowed to view the registration.
  return RedirectService.redirectIfNotClientOrAlreadyComplete();
}

/*@ngInject*/
export default function ClientRegistrationRouting(
  $stateProvider,
  $urlRouterProvider
) {
  $stateProvider.state(STATES.CLIENT_REGISTRATION, {
    url: '/clients/registration',
    abstract: true,
    template: '<ui-view class="MainView"></ui-view>'
  });

  $stateProvider.state(STATES.CLIENT_REGISTRATION_PROFILE, {
    url: '/profile',
    templateUrl: ClientRegistrationTemplateUrl,
    controller: ClientRegistrationController,
    controllerAs: ClientRegistrationController.controllerAs,
    resolve: { redirect: redirect }
  });

  $stateProvider.state(STATES.CLIENT_REGISTRATION_UPLOAD, {
    url: '/upload',
    templateUrl: ClientsRegistrationUploadTemplateUrl,
    controller: ClientsRegistrationUploadController,
    controllerAs: ClientsRegistrationUploadController.controllerAs,
    resolve: { redirect: redirect }
  });

  $urlRouterProvider.when(
    '/clients/registration',
    '/clients/registration/profile'
  );
}
