import 'angular';

import './actionsContainer';
import Form from './form';
import MinimalPositionFieldset from './position/minimal-position-fieldset/MinimalPositionFieldset.component';
import CompletePositionFieldset from './position/complete-position-fieldset/CompletePositionFieldset.component';
import blurInputsOnSubmitDirective from './BlurInputsOnSubmit.directive';
import RelatedFieldsGroup from './RealtedFieldsGroup';

export default angular
  .module('wc.forms', [RelatedFieldsGroup.name, Form.name])
  .component(MinimalPositionFieldset.NAME, MinimalPositionFieldset)
  .component(CompletePositionFieldset.NAME, CompletePositionFieldset)
  .directive(blurInputsOnSubmitDirective.NAME, blurInputsOnSubmitDirective);
