import React from 'react';
import PropTypes from 'prop-types';

export const FormInfoMessage = ({ children, className }) => {
  return (
    <div className={`WcFormInfoMessage ${className}`}>{children}</div>
  )
}

FormInfoMessage.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string
};
