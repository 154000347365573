import angular from 'angular';

const ParagraphSeparator = {
  NAME: 'wcParagraphSeparator',
  template: '<div class="WcParagraphSeparator"></div>'
};

export default angular
  .module('wc.components.separators.paragraphSeparator', [])
  .component(ParagraphSeparator.NAME, ParagraphSeparator);
