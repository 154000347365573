class ForgottenPasswordController {
  constructor($state, API) {
    this.$state = $state;
    this.API = API;

    this.clearMessages();
  }

  clearMessages() {
    this.APIMessages = {};
  }

  submit() {
    if (this.form.$valid) {
      this.clearMessages();
      this.API.PasswordRecovery.post({
        email: this.email
      })
        .then(this.handleEmailResponse.bind(this))
        .catch(this.handleEmailResponseError.bind(this));
    }
  }

  handleEmailResponse() {
    this.APIMessages.sent = true;
  }

  handleEmailResponseError() {
    this.APIMessages.genericError = true;
  }
}

ForgottenPasswordController.$inject = ['$state', 'API'];
ForgottenPasswordController.NAME = 'ForgottenPasswordController';
ForgottenPasswordController.controllerAs = 'forgottenPasswordCtrl';
export default ForgottenPasswordController;
