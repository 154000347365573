import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  NameWithImageCell,
  StatusCell,
  DateCell,
  LocationCell,
  OverflowMenuCell,
  DocumentNameCell,
  DocumentDownloadCell
} from './Cells';
import './styles.scss';

export const SortableTable = ({
  headers,
  ordering,
  data,
  extra,
  onUpdateOrderingField,
  onUpdateOrderingReverse,
  onUpdateOrdering,
  onInfiniteScroll,
  className
}) => {
  const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  useEffect(() => {
    const bodyScrollContainer = document.getElementById(
      'wc-body-scroll-container'
    );
    const tableContainer = document.getElementById('SortableTableContainer');
    const onScroll = async () => {
      if (
        bodyScrollContainer.scrollTop > tableContainer.clientHeight * 0.7 &&
        onInfiniteScroll
      ) {
        const response = await onInfiniteScroll();
        if (response) {
          setDataSource([...response]);
        }
      }
    };

    bodyScrollContainer.addEventListener('scroll', onScroll);

    return () => {
      bodyScrollContainer.removeEventListener('scroll', onScroll);
    };
  }, []);

  const sortBy = header => {
    if (!header.sortable) {
      return;
    }

    let key = header.sortingKey;

    if (ordering.field !== key) {
      if (onUpdateOrderingField && onUpdateOrderingReverse) {
        onUpdateOrderingField(key); // none -> asc
        onUpdateOrderingReverse(false);
      }
      onUpdateOrdering(key, false);
    } else if (ordering.field === key && !ordering.reverse) {
      if (onUpdateOrderingField && onUpdateOrderingReverse) {
        onUpdateOrderingField(key); // asc -> desc
        onUpdateOrderingReverse(true);
      }
      onUpdateOrdering(key, true);
    } else {
      if (onUpdateOrderingField && onUpdateOrderingReverse) {
        onUpdateOrderingField(undefined); // desc -> none
        onUpdateOrderingReverse(false);
      }
      onUpdateOrdering(undefined, false);
    }
  };

  const cellClick = (header, id) => {
    if (header.callback) {
      header.callback(id);
    }
  };

  return (
    <div className={className}>
      <div id="SortableTableContainer" className="SortableTable__Container">
        <div className="StickyHeaderTable__Container">
          <div className="SortableTable">
            <table className="StickyHeaderTable__Data">
              <thead className="SortableTableHeader">
                <tr className="SortableTableHeaderSticky">
                  {headers &&
                    headers.map((header, i) => {
                      return (
                        <th
                          key={i}
                          className={
                            'SortableTableHeaderCell' +
                            (header.sortable && ' CursorPointer') +
                            (ordering.field &&
                            ordering.field === header.sortingKey
                              ? ' SortedHeaderCell'
                              : '')
                          }
                          onClick={() => {
                            sortBy(header);
                          }}
                        >
                          {!header.headerInclude && (
                            <span className="SortableTable__HeaderCellContent">
                              {!header.hideName && (
                                <span className="SortableTable__HeaderCellContent__Text">
                                  {header.name}
                                </span>
                              )}
                              {header.sortable &&
                                ordering.field === header.sortingKey &&
                                !ordering.reverse && (
                                  <md-icon
                                    aria-label="Ascending"
                                    class="zmdi zmdi-caret-up"
                                  />
                                )}

                              {header.sortable &&
                                ordering.field === header.sortingKey &&
                                ordering.reverse && (
                                  <md-icon
                                    aria-label="Descending"
                                    class="zmdi zmdi-caret-down"
                                  />
                                )}
                            </span>
                          )}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody className="SortableTableBody">
                {dataSource &&
                  dataSource.map((row, dataIndex) => {
                    return (
                      <tr
                        key={dataIndex}
                        className={
                          'SortableTableRow ' +
                          (row.isShadow && 'SortableTableRowHighlight')
                        }
                      >
                        {headers &&
                          headers.map((header, headerIndex) => {
                            return (
                              <td
                                key={`${dataIndex}-${headerIndex}`}
                                className={
                                  'SortableTableCell ' +
                                  (header.classes && header.classes.join(' '))
                                }
                              >
                                <div
                                  className={header.callback ? 'clickable' : ''}
                                  onClick={() => {
                                    cellClick(header, row.id);
                                  }}
                                >
                                  {!header.include && (
                                    <span>{row[header.key]}</span>
                                  )}
                                  {header.include === 'NameCell' && (
                                    <span>{row[header.key]}</span>
                                  )}
                                  {header.include === 'NameWithImageCell' && (
                                    <NameWithImageCell
                                      image={row.profilePicture}
                                      name={row.fullName}
                                    />
                                  )}
                                  {header.include === 'StatusCell' && (
                                    <StatusCell
                                      choices={extra.statusChoices}
                                      defaultValue={row[header.key]}
                                      onChange={value => {
                                        extra.updateStatus(dataIndex, value);
                                      }}
                                      ariaLabel="Select status"
                                    />
                                  )}
                                  {header.include === 'DateCell' && (
                                    <DateCell
                                      date={row[header.key]}
                                      format={header.filter.args[0]}
                                    />
                                  )}
                                  {header.include === 'LocationCell' && (
                                    <LocationCell
                                      city={row.addressCity}
                                      country={row.addressCountry}
                                    />
                                  )}
                                  {header.include === 'OverflowMenuCell' && (
                                    <OverflowMenuCell
                                      extra={extra}
                                      rowItem={row}
                                    />
                                  )}
                                  {header.include === 'DocumentNameCell' && (
                                    <DocumentNameCell
                                      extra={extra}
                                      rowItem={row}
                                      name={row.name}
                                    />
                                  )}
                                  {header.include ===
                                    'DocumentDownloadCell' && (
                                    <DocumentDownloadCell
                                      extra={extra}
                                      rowItem={row}
                                    />
                                  )}
                                </div>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

SortableTable.propTypes = {
  headers: PropTypes.any,
  ordering: PropTypes.any,
  data: PropTypes.any,
  extra: PropTypes.any,
  onUpdateOrderingField: PropTypes.any,
  onUpdateOrderingReverse: PropTypes.any,
  onUpdateOrdering: PropTypes.any,
  onInfiniteScroll: PropTypes.any,
  className: PropTypes.string
};
