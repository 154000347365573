export default {
  NAME: 'wcTransluscentButton',
  template: /*@ngInject*/ function($element, $attrs) {
    // This workaround is required for IE11.
    // Normal template file + $ctrl interpolation results in invalid type texts (e.g.: type="submit submit"
    $attrs.type = $attrs.type || 'submit';
    return `
      <md-button
        class="WcTransluscentButton"
        ng-attr-type="${$attrs.type}"
        ng-click="$ctrl.onClick({$event: $event})">

        <ng-transclude></ng-transclude>

      </md-button>
    `;
  },
  transclude: true,
  bindings: {
    type: '@',
    onClick: '&'
  }
};
