import { PathWell } from 'pathwell';

let RequireLowercaseCharacters = () => {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.requireLowercaseCharacters = function(
        modelValue,
        viewValue
      ) {
        const classCount = PathWell.getClassCounts(viewValue);
        const expected = parseInt(attrs.requireLowercaseCharacters) || 0;
        return expected <= classCount.l;
      };
    }
  };
};

RequireLowercaseCharacters.NAME = 'requireLowercaseCharacters';

export default RequireLowercaseCharacters;
