import angular from 'angular';
import template from './template.html';
import STATES from '../../../services/States';

class EligibleRegionsController {
  /*@ngInject*/
  constructor(API, $state) {
    this.API = API;
    this.$state = $state;
    this.toResolve = 1;

    this.API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .catch(this.handleError.bind(this));
  }

  setProfile(profile) {
    this.profile = profile;
    this.toResolve--;
  }

  cancel() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'work-eligibility'
    });
  }

  submit() {
    if (this.form.$valid) {
      this.toResolve++;
      let regionsPatch = {
        eligibleRegions: this.API.objectListToIdsSerializerField(
          this.profile.eligibleRegions
        )
      };
      this.API.restangularize(this.profile)
        .patch(regionsPatch)
        .then(this.handleSucces.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleSucces() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'work-eligibility'
    });
  }

  handleError(error) {
    this.toResolve = 0;
    this.API.handleError()(error);
  }

  isLoaded() {
    return this.toResolve === 0;
  }
}

const wcInlineEditEligibleRegions = {
  controller: EligibleRegionsController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.eligibleRegions', [])
  .component('wcInlineEditEligibleRegions', wcInlineEditEligibleRegions);
