import AlertDialogTemplateUrl from './AlertDialog.html';
import ConfirmDialogTemplateUrl from './ConfirmDialog.html';
import SimpleDialogController from './SimpleDialog.controller';

/**
 * DialogService
 *
 * Display dialogs with sensible defaults.
 *
 */
export default class DialogService {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
    this.defaultSettings = {
      bindToController: true,
      clickOutsideToClose: true,
      controllerAs: '$ctrl'
    };
  }

  show(settings) {
    return this.$mdDialog.show(this._getCombinedSettings(settings));
  }

  alert(settings) {
    return this.show(this.$mdDialog.alert(this._getCombinedSettings(settings)));
  }

  confirm(settings) {
    return this.show(
      this.$mdDialog.confirm(this._getCombinedSettings(settings))
    );
  }

  _getCombinedSettings(settings) {
    return Object.assign({}, this.defaultSettings, settings);
  }

  showConfirmDialog(locals, targetEvent) {
    let options = {
      controller: SimpleDialogController,
      templateUrl: ConfirmDialogTemplateUrl,
      locals,
      targetEvent
    };

    return this.confirm(options);
  }

  showAlertDialog(locals, targetEvent) {
    let options = {
      controller: SimpleDialogController,
      templateUrl: AlertDialogTemplateUrl,
      clickOutsideToClose: false,
      locals,
      targetEvent
    };

    return this.alert(options);
  }
}

DialogService.NAME = 'DialogService';
