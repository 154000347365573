import { PathWell } from 'pathwell';

let RequireUppercaseCharacters = () => {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.requireUppercaseCharacters = function(
        modelValue,
        viewValue
      ) {
        const classCount = PathWell.getClassCounts(viewValue);
        const expected = parseInt(attrs.requireUppercaseCharacters) || 0;
        return expected <= classCount.u;
      };
    }
  };
};

RequireUppercaseCharacters.NAME = 'requireUppercaseCharacters';

export default RequireUppercaseCharacters;
