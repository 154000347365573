import ChipsSelect from './ChipsSelect';
import ChipsSelectTemplate from './ChipsSelect.html';
import RequestHelper from '../../../services/RequestHelper';

function chipsSelect($timeout) {
  return {
    restrict: 'E',
    templateUrl: ChipsSelectTemplate,
    scope: {
      min: '@?',
      max: '@?',
      model: '=',
      options: '=?',
      title: '@?',
      titleKey: '@?',
      idKey: '@?',
      endpoint: '=',
      valid: '=?',
      touched: '=?',
      status: '=?',
      fieldName: '@?',
      form: '=?',
      filterOptions: '=?',
      onChange: '&?',
      hideMessages: '<'
    },
    link: (scope, elem, attrs) => {
      scope.idKey = scope.idKey ? scope.idKey : 'id';
      scope.titleKey = scope.titleKey ? scope.titleKey : 'name';
      scope.fieldName = scope.fieldName || 'chipsSelect';
      scope.filterOptions = scope.filterOptions ? scope.filterOptions : {};

      $timeout(() => {
        scope.required = attrs.hasOwnProperty('required');
        scope.preventSelectAll = attrs.hasOwnProperty('preventSelectAll');

        if (scope.required && attrs.required !== false && !scope.min) {
          scope.min = 1;
        }

        const chipsSelect = new ChipsSelect(scope.min, scope.max);
        chipsSelect.setIdField(scope.idKey);

        const updateModel = () => {
          scope.model = chipsSelect.getModel();
          scope.inputModel = chipsSelect.getInputModel();
        };

        if (scope.endpoint) {
          getOptionsFromEndpoint();
        } else if (scope.options) {
          chipsSelect.setElements(scope.options);
          scope.options = chipsSelect.updateElements(scope.model);
          updateModel();
          scope.dirty = false;
        }

        scope.$watch(
          'model',
          (newValue, oldValue) => {
            if (newValue !== oldValue) {
              chipsSelect.setElements(scope.options);
              scope.options = chipsSelect.updateElements(scope.model);
              scope.valid = chipsSelect.validate();
              scope.status = chipsSelect.getStatusText();

              if (scope.onChange) {
                scope.onChange();
              }
            }
          },
          true
        );

        // Quite hacky. If an external component updates options, we need to transform them again.
        // Should really separate internal representation from external to avoid the
        // `processingOptions` stuff.

        let processingOptions = false;
        scope.$watch('options', (newValue, oldValue) => {
          if (processingOptions) {
            processingOptions = false;
            return;
          }

          if (newValue !== oldValue) {
            processingOptions = true;
            chipsSelect.setElements(scope.options);
            scope.options = chipsSelect.updateElements(scope.model);
            scope.valid = chipsSelect.validate();
            scope.status = chipsSelect.getStatusText();
          }
        });

        scope.$watch('touched', () => {
          scope.status = chipsSelect.getStatusText();
        });

        scope.$watch(
          'filterOptions',
          (newValue, oldValue) => {
            if (newValue !== oldValue) {
              getOptionsFromEndpoint();
            }
          },
          true
        );

        scope.toggle = function chipSelectToggle(item) {
          scope.touched = true;
          chipsSelect.toggleById(item[scope.idKey]);
          updateModel();
        };

        function getOptionsFromEndpoint() {
          let filterOptions = RequestHelper.getFilterDict(scope.filterOptions);
          scope.endpoint.getList(filterOptions).then(data => {
            chipsSelect.setElements(data);
            scope.options = chipsSelect.updateElements(scope.model);
            updateModel();
            scope.dirty = false;
          });
        }

        scope.selectAll = () => {
          scope.options.map(o => (o.isSelected = true));
          updateModel();
        };

        scope.unselectAll = () => {
          scope.options.map(o => (o.isSelected = false));
          updateModel();
        };
      });
    }
  };
}

chipsSelect.$inject = ['$timeout'];
chipsSelect.NAME = 'chipsSelect';

export default chipsSelect;
