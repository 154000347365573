import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileActions } from './OwnMemberProfileActions';

export default angular
  .module('wc.components.OwnMemberProfileActions', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileActions',
    react2angular(
      OwnMemberProfileActions,
      ['member'],
      ['$state', 'Resume', 'API']
    )
  );
