import { calculatePasswordStrength } from '../../../services/PasswordStrength';

let StrongPassword = () => {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.strongPassword = function(modelValue, viewValue) {
        scope.strength = calculatePasswordStrength(viewValue);
        return scope.strength >= 50;
      };
    }
  };
};

StrongPassword.NAME = 'strongPassword';

export default StrongPassword;
