import './CheckboxOverflowMenu.scss';
import CheckboxOverflowMenuTemplate from './CheckboxOverflowMenu.html';

let checkboxOverflowMenu = () => {
  return {
    scope: {
      menuItems: '=',
      menuItemKey: '@?',
      element: '=',
      selectedItems: '=',
      callback: '&?'
    },
    restrict: 'E',
    templateUrl: CheckboxOverflowMenuTemplate,
    link(scope) {
      scope.menuItemKey = scope.menuItemKey ? scope.menuItemKey : 'text';
      scope.changeMenuItem = ($event, menuItem) => {
        menuItem.selected = !menuItem.selected;
        scope.selectedItems = scope.menuItems.filter(i => i.selected);

        if (scope.callback) {
          scope.callback();
        }
      };

      scope.$watch('selectedItems', () => {
        if (!scope.selectedItems) {
          scope.menuItems.forEach(menuItem => {
            menuItem.selected = false;
          });
          return;
        }
      });
    }
  };
};

checkboxOverflowMenu.NAME = 'checkboxOverflowMenu';

export default checkboxOverflowMenu;
