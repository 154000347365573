function wcBackdrop($document) {
  return {
    restrict: 'A',
    link: scope => {
      let htmlRootElement = $($document[0])
        .children()
        .eq(0);
      htmlRootElement.addClass('WcRegularScroll');

      scope.$on('$destroy', () => {
        htmlRootElement.removeClass('WcRegularScroll');
      });
    }
  };
}
wcBackdrop.$inject = ['$document'];
wcBackdrop.NAME = 'wcBackdrop';
export default wcBackdrop;
