import '../raven.init';
/* Third party */
import 'checklist-model';
import 'mobile-detect';
import 'ngstorage';
import 'restangular';
import './services/ChromeAutofillFix';
import 'angular-elastic';
import angular from 'angular';
import 'redux';
import ngRedux from 'ng-redux';
import log from 'loglevel';
import ngMessages from 'angular-messages';
import ngSanitize from 'angular-sanitize';
import uiRouter from 'angular-ui-router';
import ngInfiniteScroll from 'ng-infinite-scroll';
import 'sticky-header';

/* Config */
// import ENV_CONFIG from './config/EnvConfig.constant';
import AppRouting from './app.routing';
import RoutingRedirects from './tools/Routing.redirects';
import StateTransition from './StateTransition.run';
import ToastConfig from './config/Toast.config';
import GoogleAnalyticsConfig from '../common/config/GoogleAnalytics.config';

import redirectIfUnsupportedDevice from './apps/unsupported-device/UnsupportedDevice.run';

/* App modules */
import ApiErrorHandler from './services/ApiErrorHandler';
import APIModule from '../common/services/api/API.module';
import ClientsModule from './apps/clients/Clients.module';
import CommonComponentsModule from '../common/components';
import ComponentsModule from './components';
import ConfigModule from './config/Config.module';
import AuthModule from './apps/auth';
import MembersModule from './apps/members/Members.module';
import ServicesModule from './services/Services.module';
import UnsupportedDeviceModule from './apps/unsupported-device';
import PreviewModule from './apps/preview';
import MdChipsRemoveOverride from './components/chips/MdChipRemove.directive';

/* Filters */
import Filters from '../common/filters';

/* Globally imported styles */
import 'ng-toast/dist/ngToast.min.css';
import 'normalize.css';
import '../common/styles/AngularMaterialOverrides.scss';
import '../common/styles/FontFaces.scss';
import './main.scss';

/* React globally imported styles */
import 'react-tooltip/dist/react-tooltip.css';

log.setLevel('info');

// angular-feature-flags expects 'angular' to be available on global scope
// Use Webpack's imports-loader to pass the local `angular` variable
// See: https://webpack.github.io/docs/shimming-modules.html
require('imports-loader?angular=angular!angular-feature-flags');

const deps = [
  /* Third party */
  'angular-google-analytics',
  ngRedux,
  'feature-flags',
  uiRouter,
  ngInfiniteScroll,
  ngMessages,
  ngSanitize,
  'restangular',
  'ngStorage',
  'checklist-model',
  'ngMaterial',
  MdChipsRemoveOverride.name, // Must be loaded after ngMaterial. Let's just do it here.
  'ngToast',
  'chrome-autofill-fix',
  'fsm',

  /* App modules */
  ApiErrorHandler.name,
  APIModule.name,
  ClientsModule.name,
  CommonComponentsModule.name,
  ComponentsModule.name,
  ConfigModule.name,
  AuthModule.name,
  MembersModule.name,
  ServicesModule.name,
  UnsupportedDeviceModule.name,
  PreviewModule.name,

  Filters.name
];

// if (ENV_CONFIG.configName !== 'local') {
//   deps.push('ngRaven');
// }

export default angular
  .module('wc', deps)

  .config(AppRouting)
  .config(ToastConfig)
  .config(GoogleAnalyticsConfig)

  .run(StateTransition)
  .run(RoutingRedirects)
  .run(
    /*@ngInject*/ ($log, EnvConfig) => {
      if (EnvConfig.configName) {
        $log.debug(`Loaded env configuration: ${EnvConfig.configName}`);
      } else {
        $log.error(`No env configuration is loaded.`);
      }
    }
  )
  .run(['Analytics', function(Analytics) {}]) // eslint-disable-line no-unused-vars
  .run(redirectIfUnsupportedDevice);
