import angular from 'angular';

import './IconToggleButton.scss';
import templateUrl from './IconToggleButton.html';

const IconToggleButton = {
  NAME: 'wcIconToggleButton',
  bindings: {
    ariaLabel: '@ariaLabel',
    label: '@',
    iconClass: '@wcIconClass',
    isActive: '<wcIsActive',
    onClick: '&wcOnClick'
  },
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.buttons.icontogglebutton', [])
  .component(IconToggleButton.NAME, IconToggleButton);
