import angular from 'angular';

import './FilterSwitch.scss';
import templateUrl from './FilterSwitch.html';

import SidebarService from '../../../services/Sidebar.service';
import PlainButton from '../../buttons/plainbutton';

const dependencies = [SidebarService.name, PlainButton.name];

class FilterSwitchCtrl {
  /*@ngInject*/
  constructor(DetectDeviceService, wcSidebar) {
    this.DetectDeviceService = DetectDeviceService;
    this.wcSidebar = wcSidebar;

    this.label = this.label || this._getLabel();
  }

  toggle() {
    this.wcSidebar.toggle(this.searchSidebarId);
  }

  _getLabel() {
    if (this.DetectDeviceService.isDesktop()) {
      return 'Search';
    } else {
      return 'Filter';
    }
  }
}

const FilterSwitch = {
  NAME: 'wcFilterSwitch',
  bindings: {
    searchSidebarId: '@',
    label: '@wcLabel'
  },
  controller: FilterSwitchCtrl,
  templateUrl: templateUrl
};

export default angular
  .module('wc.components.buttons.filterSwitch', dependencies)
  .component(FilterSwitch.NAME, FilterSwitch);
