import angular from 'angular';

import SortableHeader from './SortableHeader.directive';
import SortableTable from './SortableTable.directive';
import SortableTableCheckboxes from './SortableTableCheckboxes.directive';
import StickyHeaderTable from './sticky-header-table';

import './SortableTable.scss';
import './cells/Styles.scss';

let TableModule = angular
  .module('wc.components.table', [StickyHeaderTable.name])
  .directive(SortableHeader.NAME, SortableHeader)
  .directive(SortableTable.NAME, SortableTable)
  .directive(SortableTableCheckboxes.NAME, SortableTableCheckboxes);

export default TableModule;
