import angular from 'angular';
import { react2angular } from 'react2angular';
import { ConversationDetail } from './ConversationDetail';

export default angular
  .module('wc.components.ReactConversationDetail', [])
  // @ts-ignore
  .component(
    'reactConversationDetail',
    react2angular(
      ConversationDetail,
      ['conversation', 'messages'],
      ['ChatService']
    )
  );
