/*
 * Fix issue where chips cannot be removed in Safari.
 * See https://github.com/angular/material/issues/8108#issuecomment-223216150
 *
 * TODO: use a fork of Angular Material instead?
 */

export default angular
  .module('material.components.chips')
  .directive('mdChipRemove', MdChipRemove);

/**
 * @ngdoc directive
 * @name mdChipRemove
 * @module material.components.chips
 *
 * @description
 * `<md-chip-remove>`
 * Designates an element to be used as the delete button for a chip. This
 * element is passed as a child of the `md-chips` element.
 *
 * @usage
 * <hljs lang="html">
 *   <md-chips><button md-chip-remove>DEL</button></md-chips>
 * </hljs>
 */

/**
 * MdChipRemove Directive Definition.
 *
 * @param $compile
 * @param $timeout
 * @returns {{restrict: string, require: string[], link: Function, scope: boolean}}
 * @constructor
 */

/*@ngInject*/
function MdChipRemove($timeout) {
  return {
    restrict: 'A',
    require: '^mdChips',
    scope: false,
    link: postLink
  };

  function postLink(scope, element, attr, ctrl) {
    element.on('click mousedown', event => {
      if (event.which !== 1) {
        return;
      }

      scope.$apply(() => {
        ctrl.removeChip(scope.$$replacedScope.$index); // eslint-disable-line angular/no-private-call
      });
    });

    // Child elements aren't available until after a $timeout tick as they are hidden by an
    // `ng-if`. see http://goo.gl/zIWfuw
    $timeout(function() {
      element.attr({ tabindex: -1, 'aria-hidden': true });
      element.find('button').attr('tabindex', '-1');
    });
  }
}
