import React from 'react';
import PropTypes from 'prop-types';
import { CircularImage } from '../../../../../../common/components/react/circular-image/CircularImage';

export const NameWithImageCell = ({ image, name }) => {
  return (
    <div className="ImageNameCell">
      <CircularImage
        src={image}
        alt={name}
        className="SortableTable__ImageBubble"
      />
      <span>{name}</span>
    </div>
  );
};

NameWithImageCell.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string
};
