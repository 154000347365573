import 'angular';
import wcPreviousButton from '../previousbutton';
import ButtonCtrl from '../previousbuttonwithconfirmation/PreviousButtonWithConfirmation.controller';

const previousButtonWithConfirmation = {
  NAME: 'wcPreviousButtonWithConfirmation',
  bindings: {
    onConfirm: '&',
    skipConfirmationIf: '<' // bool
  },
  template: `<wc-previous-button ng-click="$ctrl.showConfirmationDialog()"></wc-previous-button>`,
  controller: ButtonCtrl
};

const dependencies = [wcPreviousButton.name];

export default angular
  .module('wc.components.buttons.previousButtonWithConfirmation', dependencies)
  .component(
    previousButtonWithConfirmation.NAME,
    previousButtonWithConfirmation
  );
