import angular from 'angular';
import template from './template.html';
import './styles.scss';

import { FORM_ERROR_MESSAGE } from '../../services/FormErrorMessages';
import STATES from '../../services/States';

import ProfessionalSummary from './professional-summary';
import ExecPosition from './position/exec/index';
import NonExecPosition from './position/non-exec/index';
import PositionsList from './positions-list';
import EligibleRegions from './eligible-regions';
import RegionsManaged from './regions-managed';
import FinancePeople from './finance-people';
import Aspirations from './aspirations';
import Insights from './insights';
import Education from './education';
import NotificationBar from './notification-bar';

class InlineEditController {
  /*@ngInject*/
  constructor($state) {
    this.$state = $state;
    this.formErrorMessage = FORM_ERROR_MESSAGE;
  }

  backToProfile() {
    let scrollToSection = this.profileSection ? this.profileSection : false;
    this.$state.go(STATES.MEMBER_PROFILE, { scrollToSection: scrollToSection });
  }
}

const wcInlineEdit = {
  bindings: {
    headerTitle: '@',
    name: '=',
    loading: '<',
    onAdd: '&?',
    addLabel: '<?',
    onSubmit: '&?',
    onCancel: '&?',
    hideBackToProfile: '<?',
    profileSection: '@?',
    reactFormValid: '&?'
  },
  controller: InlineEditController,
  controllerAs: 'inlineCtrl',
  templateUrl: template,
  transclude: true
};

const dependencies = [
  ProfessionalSummary.name,
  ExecPosition.name,
  NonExecPosition.name,
  PositionsList.name,
  EligibleRegions.name,
  RegionsManaged.name,
  FinancePeople.name,
  Aspirations.name,
  Insights.name,
  Education.name,
  NotificationBar.name
];

export default angular
  .module('wc.components.inlineEdit', dependencies)
  .component('wcInlineEdit', wcInlineEdit);
