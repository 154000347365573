import angular from 'angular';

import './SearchSidebar.scss';
import templateUrl from './SearchSidebar.html';

import FilterSwitch from '../../../components/buttons/filterswitch';
import PlainButton from '../../../components/buttons/plainbutton';
import { MiniLabel, HeavyLabel } from '../../../components/typography';
import { MAIN_SEARCH_ID } from '../../../services/MemberLists.service';
import SidebarService from '../../../services/Sidebar.service';
import Sidebar from '../sidebar';

const dependencies = [
  'ngMaterial',
  FilterSwitch.name,
  PlainButton.name,
  MiniLabel.name,
  HeavyLabel.name,
  SidebarService.name,
  Sidebar.name
];

class SearchSidebarCtrl {
  /*@ngInject*/
  constructor(wcMemberList, wcSidebar) {
    this.wcMemberList = wcMemberList;
    this.wcSidebar = wcSidebar;
  }

  $onInit() {
    this.onInit();
  }

  _onSearch() {
    this.onSearch();
    this.wcSidebar.close(this.searchSidebarId);
  }

  onClearFilters() {
    this.wcMemberList[MAIN_SEARCH_ID].clearFilters();
  }
}

const SearchSidebar = {
  NAME: 'wcSearchSidebar',
  bindings: {
    onInit: '&',
    onSearch: '&',
    searchSidebarId: '@'
  },
  controller: SearchSidebarCtrl,
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.searchSidebar', dependencies)
  .component(SearchSidebar.NAME, SearchSidebar);
