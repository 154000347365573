import angular from 'angular';

import apiModule from '../../common/services/api/API.module';
import memberListFactoryModule from './MemberList.factory';

export const MAIN_SEARCH_ID = 'MAIN_SEARCH';

class MemberLists {
  /*@ngInject*/
  constructor($log, $q, $localStorage, API, createMemberList, $rootScope) {
    this.$log = $log;
    this.$q = $q;
    this.$localStorage = $localStorage;
    this.API = API;
    this.createMemberList = createMemberList;

    this.isReadyDeferred = this.$q.defer();
    this.isReady = this.isReadyDeferred.promise;

    $rootScope.$on(
      'compensationBlockedChanged',
      this.removeCompensations.bind(this)
    );
  }

  initMainSearch() {
    if (angular.isUndefined(this[MAIN_SEARCH_ID])) {
      this[MAIN_SEARCH_ID] = this.createMemberList(this.API.Members);
      this.isReadyDeferred.resolve();
    } else {
      this[MAIN_SEARCH_ID].refresh();
    }
  }

  removeCompensations() {
    let members = this[MAIN_SEARCH_ID].members;
    members.forEach(member => {
      member.totalCompensationAmount = null;
      member.totalCompensationCurrency = 'USD';
    });
  }

  /*
   * Register a new longlist with the service. Initializes a new instance of the filters object, the members array and
   * other associated state.
   *
   * Note: does not re-initialize existing longlist state.
   */
  registerLonglist(id) {
    const memberListId = this.getMemberListId(id);

    if (!this.hasOwnProperty(memberListId)) {
      this[memberListId] = this.createMemberList(
        this.API.LonglistMembersFull(id)
      );
      this[memberListId].longlistId = id;
      this._handleOrdering(id, memberListId);
      this.$log.info(
        this._formatLogMessage('Registered new longlist'),
        Object.assign({}, this)
      );
    }
  }

  _handleOrdering(id, memberListId) {
    if (!this.$localStorage.longlistOrdering) {
      // Init parent object if not exist. TODO: Use Immmutable.js?
      this.$localStorage.longlistOrdering = {};
    }
    if (!this.$localStorage.longlistOrdering[id]) {
      // Set and store ordering to default if not set
      this[memberListId].setOrderingField('last_name');
      this.$localStorage.longlistOrdering[id] = this[memberListId].ordering;
    } else {
      /* Set ordering from stored value, otherwise factory would overwrite it.
       * TODO: Factory initializes list as it would be a search list. /
       * For longlists we have to manually set the ordering. Remove the asymmetry.
       */
      let ordering = this.$localStorage.longlistOrdering[id];
      this[memberListId].setOrdering(ordering);
    }
  }

  getMemberListId(longlistId) {
    return `LONGLIST_${longlistId}`;
  }

  _formatLogMessage(logMessage) {
    return `[MemberLists] ${logMessage}`;
  }
}

export default angular
  .module('wc.services.memberLists', [
    apiModule.name,
    memberListFactoryModule.name
  ])
  .service('wcMemberList', MemberLists);
