class StickySidebarController {
  /*@ngInject*/
  constructor($element, $attrs) {
    this.$element = $element;
    this.paddingOffset = parseInt($attrs.wcPaddingOffset);
  }

  $onInit() {
    this.containerCtrl.registerListener(this.stickElement.bind(this));
  }

  /**
   * This is a pretty tricky method. Could be a good refactoring opportunity.
   *
   * The child element recieves info about the scroll position and height of it's
   * container. The child element uses this information to decide how to position itself.
   *
   * There are 3 cases...
   *
   * 1. The child is taller than the container.
   *    The container's scroll position is lower than the bottom of the child.
   *    The child should stick to the bottom of the container.
   *
   *              ------
   *              |    |
   *              |    |
   *         -----|----|-----
   *         |    |    |    |
   *         |    ------    |
   *         ----------------
   *
   * 2. The child is shorter than the container.
   *    The container's scroll position is lower than the bottom of the child.
   *    The child should stick to the bottom of the container.
   *
   *         ----------------
   *         |    ------    |
   *         |    |    |    |
   *         |    ------    |
   *         |              |
   *         |              |
   *         ----------------
   *
   * 3. The container's scroll position is not as low as the bottom of the child.
   *    The child should scroll with the container until we reach case 1a.
   *
   *              ------
   *         -----|----|-----
   *         |    |    |    |
   *         |    |    |    |
   *         -----|----|-----
   *              ------
   *
   */
  stickElement(containerHeight, containerScrollTop) {
    const containerScrollBottom = containerHeight + containerScrollTop;
    const fullElementHeight =
      this.$element[0].clientHeight + 2 * this.paddingOffset;

    if (containerScrollBottom >= fullElementHeight) {
      if (!this.isSticky) {
        const dummyElement = `<div id="dummy" style="width: ${$(
          this.$element
        ).outerWidth(true)}px;"></div>`;
        this.isSticky = true;
        this.$element.after(dummyElement);
      }

      this.$element.css({
        position: 'absolute',
        'pointer-events': 'none'
      });

      if (fullElementHeight > containerHeight) {
        // Case 1: tall child in a short container, scrolled beyond child height
        //      => should stick to bottom
        this.$element.css({ bottom: `${this.paddingOffset}px` });
        this.$element.css({ top: '' });
      } else {
        // Case 2: short child in a tall container
        //      => should stick to top
        this.$element.css({ bottom: '' });
        this.$element.css({ top: `${this.paddingOffset}px` });
      }
    } else {
      // Case 3: tall child in a short container, but we haven't scrolled to bottom of child yet.
      //      => element should not be sticky
      if (!this.isSticky) {
        return;
      }

      $('#dummy').detach();
      this.isSticky = false;
      this.$element.css({ position: 'static' });
    }
  }
}

export default function StickySidebar() {
  return {
    bindToController: true,
    controller: StickySidebarController,
    restrict: 'A',
    require: {
      containerCtrl: '^^wcStickyElementContainer'
    }
  };
}

StickySidebar.NAME = 'wcStickySidebar';
