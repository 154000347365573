import CropDialogController from './CropDialog.controller';
import DialogTemplate from './CropDialog.html';
import './CropDialog.scss';
import { ImageDataConverter } from '../../../common/services/ImageUtil';

class CropDialogService {
  /*@ngInject*/
  constructor($q, DialogService) {
    this.$q = $q;
    this.DialogService = DialogService;
  }

  show(imageData) {
    let deferredCroppedImage = this.$q.defer();
    let fileReader = new FileReader();

    fileReader.onload = fileLoadedEvent => {
      const options = {
        clickOutsideToClose: false,
        controller: CropDialogController,
        locals: {
          imageData: fileLoadedEvent.target.result
        },
        templateUrl: DialogTemplate
      };
      this.DialogService.confirm(options)
        .then(image =>
          deferredCroppedImage.resolve(
            new ImageDataConverter(image).dataURItoBlob()
          )
        )
        .catch(() => deferredCroppedImage.reject());
    };

    fileReader.readAsDataURL(imageData);

    return deferredCroppedImage.promise;
  }
}

CropDialogService.NAME = 'CropDialogService';
export default CropDialogService;
