/**
 * Just a BaseTextField with some extra logic and assumptions implemented
 * to avoid code duplication
 */
import React from 'react';
import PropTypes from 'prop-types';

import BaseTextField from './BaseTextField';

class TextField extends React.Component {
  constructor(props) {
    super(props);
    const { initialValue, updateError, fieldName, hasError } = props;
    const error = hasError(initialValue);

    this.state = {
      value: initialValue,
      valueChanged: false,
      touched: false,
      error
    };
    updateError(fieldName, error);
  }

  getTouched = () => {
    // this is to allow for undefined touched prop
    if (typeof this.props.touched !== 'undefined') {
      return this.props.touched;
    }
    return this.state.touched;
  };

  handleTouched = () => {
    // this is to allow for undefined updateTouched prop
    if (typeof this.props.updateTouched !== 'undefined') {
      this.props.updateTouched(this.props.fieldName, true);
    } else {
      this.setState({ touched: true });
    }
  };

  handleChange = event => {
    const value = event.target.value;
    const error = this.props.hasError(value);

    if (error !== this.state.error) {
      this.props.updateError(this.props.fieldName, error);
    }

    this.setState({
      value,
      valueChanged: true,
      error
    });
  };

  handleBlur = () => {
    if (this.state.valueChanged) {
      const formatedInput =
        this.props.formatInput && this.props.formatInput(this.state.value);
      this.props.updateValue(formatedInput || this.state.value);
      this.handleTouched();
      this.setState({
        valueChanged: false,
        value: formatedInput || this.state.value
      });
    }
  };

  checkForError = () => {
    const touched = this.getTouched();
    const { formSubmitted } = this.props;
    return formSubmitted || touched;
  };

  shouldRenderError = () => {
    return this.checkForError() && this.state.error;
  };

  render() {
    const { value } = this.state;
    const { label, errorText, autocomplete, halfWidth = false } = this.props;
    return (
      <BaseTextField
        value={value}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        shouldRenderError={this.shouldRenderError()}
        errorText={errorText(value)}
        autocomplete={autocomplete}
        halfWidth={halfWidth}
      />
    );
  }
}

TextField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  updateValue: PropTypes.func,
  hasError: PropTypes.func,
  updateError: PropTypes.func,
  errorText: PropTypes.func,
  updateTouched: PropTypes.func,
  touched: PropTypes.bool,
  formSubmitted: PropTypes.bool,
  autocomplete: PropTypes.string,
  formatInput: PropTypes.func,
  halfWidth: PropTypes.bool
};

export default TextField;
