import STATES from '../../../../../services/States';

import ExperienceTemplate from './Experience.html';
import ExperienceController from './Experience.controller';
import PositionTemplate from './Position.html';
import PositionController from './Position.controller';

let MembersRegistrationRouting = /*@ngInject*/ (
  $stateProvider,
  $urlRouterProvider
) => {
  $stateProvider
    .state(STATES.MEMBER_REGISTRATION.EXPERIENCE, {
      url: '/experience',
      templateUrl: ExperienceTemplate,
      controller: ExperienceController,
      controllerAs: ExperienceController.controllerAs
    })

    .state(STATES.MEMBER_REGISTRATION.CURRENT_POSITION, {
      url: '/current-position',
      templateUrl: PositionTemplate,
      controller: PositionController,
      controllerAs: PositionController.controllerAs
    });

  $urlRouterProvider.when(
    '/members/registration/experience',
    '/members/registration/experience/current-position'
  );
};

export default MembersRegistrationRouting;
