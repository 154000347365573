import React from 'react';
import PropTypes from 'prop-types';

import EthnicitySelect from './EthnicitySelect';
import EthnicityConsent from './EthnicityConsent';

class Ethnicity extends React.Component {
  componentDidMount() {
    const { ethnicity, ethnicityConsent } = this.props;
    this.updateEthnicityErrors(ethnicity, ethnicityConsent);
  }

  updateEthnicityErrors = (ethnicity, ethnicityConsent) => {
    const ethnicityId = ethnicity ? ethnicity.id : undefined;

    const ethnicityConsentError = this.ethnicityConsentError({
      ethnicityConsent,
      ethnicityId
    });

    const ethnicityError = this.ethnicityError({
      ethnicityConsent,
      ethnicityId
    });

    this.props.updateError('ethnicityConsent', ethnicityConsentError);
    this.props.updateError('ethnicity', ethnicityError);
  };

  ethnicityError = ({
    ethnicityConsent = this.props.ethnicityConsent,
    ethnicityId
  }) => {
    const hasEthnicity = ethnicityId ? true : false;
    return ethnicityConsent && !hasEthnicity;
  };

  ethnicityConsentError = ({
    ethnicityConsent = this.props.ethnicityConsent,
    ethnicityId
  }) => {
    const hasEthnicity = ethnicityId ? true : false;
    return hasEthnicity && !ethnicityConsent;
  };

  ethnicityUpdate = ethnicity => {
    this.updateEthnicityErrors(ethnicity, this.props.ethnicityConsent);
    this.ethnicityUpdateTouched(true);
    this.props.updateEthnicity(ethnicity);
  };

  ethnicityConsentUpdate = ethnicityConsent => {
    this.updateEthnicityErrors(this.props.ethnicity, ethnicityConsent);
    this.ethnicityConsentUpdateTouched(true);
    this.props.updateEthnicityConsent(ethnicityConsent);
  };

  ethnicityErrorCheck = () => {
    const {
      ethnicityTouched,
      ethnicityConsentTouched,
      formSubmitted
    } = this.props;

    return formSubmitted || ethnicityTouched || ethnicityConsentTouched;
  };

  ethnicityUpdateTouched = touched => {
    const { ethnicityTouched } = this.props;
    if (ethnicityTouched) {
      return;
    }

    this.props.updateTouched('ethnicity', touched);
  };

  ethnicityConsentUpdateTouched = touched => {
    const { ethnicityConsentTouched } = this.props;
    if (ethnicityConsentTouched) {
      return;
    }
    this.props.updateTouched('ethnicityConsent', touched);
  };

  render() {
    const {
      ethnicities,
      ethnicity,
      ethnicityConsent,
      ethnicityError,
      ethnicityTouched,
      ethnicityConsentError
    } = this.props;
    const checkForError = this.ethnicityErrorCheck();
    const wrapperStyle = {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      width: '50%'
    };

    if (ethnicityError && ethnicityTouched) {
      wrapperStyle.minHeight = '140px';
    } else {
      wrapperStyle.minHeight = '71px';
    }

    return (
      <div>
        <div style={wrapperStyle}>
          <EthnicitySelect
            ethnicities={ethnicities}
            ethnicity={ethnicity}
            update={this.ethnicityUpdate}
            hasError={ethnicityError}
            updateTouched={this.ethnicityUpdateTouched}
            checkForError={checkForError}
          />
        </div>
        <div>
          <EthnicityConsent
            checked={ethnicityConsent}
            update={this.ethnicityConsentUpdate}
            label="I agree to share my ethnicity"
            hasError={ethnicityConsentError}
            updateTouched={this.ethnicityConsentUpdateTouched}
            checkForError={checkForError}
          />
        </div>
      </div>
    );
  }
}

Ethnicity.propTypes = {
  ethnicities: PropTypes.array,
  ethnicity: PropTypes.object,
  updateEthnicity: PropTypes.func,
  ethnicityConsent: PropTypes.bool,
  updateEthnicityConsent: PropTypes.func,
  ethnicityTouched: PropTypes.bool,
  ethnicityConsentTouched: PropTypes.bool,
  updateTouched: PropTypes.func,
  ethnicityError: PropTypes.bool,
  ethnicityConsentError: PropTypes.bool,
  updateError: PropTypes.func,
  formSubmitted: PropTypes.bool
};

export default Ethnicity;
