import React from 'react';
import PropTypes from 'prop-types';

export const StatusCell = ({ choices, defaultValue, onChange, ariaLabel }) => {
  return (
    <select
      className="SortableTable__Dropdown"
      defaultValue={defaultValue}
      onChange={event => {
        onChange(event.target.value);
      }}
      aria-label={ariaLabel}
    >
      {choices.map((option, optionIndex) => {
        return (
          <option key={optionIndex} value={option.value}>
            {option.displayName}
          </option>
        );
      })}
    </select>
  );
};

StatusCell.propTypes = {
  choices: PropTypes.any,
  defaultValue: PropTypes.string,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func
};
