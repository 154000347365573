/*eslint angular/window-service:0*/
import MobileDetect from 'mobile-detect';

class DetectDeviceService {
  /*@ngInject*/
  constructor() {
    this.mobileDetect = new MobileDetect(window.navigator.userAgent);
  }

  isDesktop() {
    return !this.isTablet() && !this.isMobile();
  }

  isTablet() {
    return this.mobileDetect.tablet() !== null;
  }

  isMobile() {
    return this.mobileDetect.phone() !== null;
  }
}

DetectDeviceService.NAME = 'DetectDeviceService';
export default DetectDeviceService;
