import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const AddToLonglistSwitch = ({ user, onToggleUser, $scope }) => {
  return (
    <div className="WcAddToLonglistSwitch">
      <button
        className={`md-button ${
          user.isAlreadyAdded
            ? 'WcAddToLonglistSwitch__Tick'
            : 'WcAddToLonglistSwitch__Plus'
        }`}
        onClick={() => {
          onToggleUser({ user: user });
          $scope.$apply();
        }}
        aria-label={`${user.isAlreadyAdded ? 'Remove member' : 'Add member'}`}
      >
        <i
          className={`WcAddToLonglistSwitch__Icon ${
            user.isAlreadyAdded ? 'zmdi zmdi-check' : 'zmdi zmdi-plus'
          }`}
        />
      </button>
    </div>
  );
};

AddToLonglistSwitch.propTypes = {
  user: PropTypes.object.isRequired,
  onToggleUser: PropTypes.func,
  $scope: PropTypes.object
};
