import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileOverviewPosition } from './MemberProfileOverviewPosition';

export default angular
  .module('wc.components.MemberProfileOverviewPosition', [])
  // @ts-ignore
  .component(
    'reactWcMemberProfileOverviewPosition',
    react2angular(MemberProfileOverviewPosition, ['member'])
  );
