import RelatedFormController from './RelatedForm.controller';
import RelatedFormTemplateUrl from './RelatedForm.html';

/**
 * relatedForm directive.
 *
 * Take a list of objects as model, and - optionally - a template to render elements of the model.
 *
 * The template can make use of the `relatedFormCtrl` controller that is injected to the scope.
 *
 * Scope:
 * @beforeSaveCallback call just before saving an item. This can be used for validation. If it returns false, saving is
 * cancelled. Optional.
 * @buttonLabels control the save button labels. Defaults to {
      addAnother: 'Add another',
      addNew: 'Add new',
      save: 'Save'
    }.
 * @itemTemplate should evaluate to a string path to a template to render elements of the model. Optional.
 * @model binds to the data model
 * @new binds to the item currently edited. Optional.
 * @removeCallback call with the removed item as a paramter just before it is removed from the model. Optional.
 */
function RelatedForm() {
  return {
    bindToController: true,
    controller: RelatedFormController,
    controllerAs: RelatedFormController.controllerAs,
    restrict: 'E',
    scope: {
      buttonLabels: '<?',
      beforeSaveCallback: '&?',
      itemTemplate: '<?',
      model: '=',
      new: '=?',
      removeCallback: '&?'
    },
    templateUrl: RelatedFormTemplateUrl,
    transclude: true
  };
}
RelatedForm.NAME = 'relatedForm';

export default RelatedForm;
