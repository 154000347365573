import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Switch from '../../../profile/basics/Switch';

export const NotificationSettingsTab = ({ notificationSettings, onChange }) => {
  const [
    enabledNotificationSettings,
    setEnabledNotificationSettings
  ] = useState([]);

  useEffect(() => {
    const enabledNotificationSettingsTmp = [];
    notificationSettings.forEach(notificationSetting => {
      if (notificationSetting.sendEmail) {
        enabledNotificationSettingsTmp.push(notificationSetting.id);
      }
    });
    setEnabledNotificationSettings(enabledNotificationSettingsTmp);
  }, [notificationSettings]);

  const sendEmailChanged = useCallback(
    notificationSetting => {
      onChange({
        ...notificationSetting,
        sendEmail: !notificationSetting.sendEmail
      });
      if (enabledNotificationSettings.includes(notificationSetting.id)) {
        setEnabledNotificationSettings(
          enabledNotificationSettings.filter(
            element => element !== notificationSetting.id
          )
        );
      } else {
        setEnabledNotificationSettings([
          ...enabledNotificationSettings,
          notificationSetting.id
        ]);
      }
    },
    [enabledNotificationSettings]
  );

  return (
    <md-content className="Settings__NotificationTab">
      <p className="SettingsExplanation">
        Receive notification emails for the following events
      </p>
      {notificationSettings &&
        notificationSettings.length > 0 &&
        notificationSettings.map(
          notificationSetting =>
            notificationSetting.display && (
              <div
                className="NotificationSettings__Row"
                key={notificationSetting.id}
              >
                <div>
                  <div className="NotificationSettings__Name">
                    {notificationSetting.notificationType.displayName}
                  </div>
                  <div className="NotificationSettings__Description">
                    {notificationSetting.notificationType.description}
                  </div>
                </div>
                <div className="NotificationSettings__SwitchContainer">
                  <Switch
                    checked={enabledNotificationSettings.includes(
                      notificationSetting.id
                    )}
                    aria-label={
                      notificationSetting.notificationType.description
                    }
                    onChange={() => sendEmailChanged(notificationSetting)}
                  />
                </div>
              </div>
            )
        )}
    </md-content>
  );
};

NotificationSettingsTab.propTypes = {
  notificationSettings: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};
