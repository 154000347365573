import templateUrl from './documents.html';

import './documents.scss';

class DocumentsController {
  /*@ngInject*/
  constructor($rootScope, API) {
    this.API = API;
    this.$rootScope = $rootScope;
    this.loading = true;
  }

  updateFilters(filters) {
    this.$rootScope.$broadcast('documents-filter', filters);
  }
}

export default angular
  .module('wc.components.client-documents', [])
  .component('wcClientDocuments', {
    controller: DocumentsController,
    templateUrl: templateUrl
  });
