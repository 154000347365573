import STATES from '../../../services/States';

class TokenLoginController {
  /*@ngInject*/
  constructor($location, $state, $stateParams, AuthenticationService) {
    this.$location = $location;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.AuthenticationService = AuthenticationService;

    if (!$stateParams.token) {
      $state.go(STATES.LOGIN);
    } else {
      this.AuthenticationService.loginWithOneTimeToken(
        $stateParams,
        STATES.REQUEST_NEW_TOKEN
      ).then(this._onLoginSuccess.bind(this));
    }
  }

  _onLoginSuccess() {
    if (this.$stateParams.redirectTo) {
      this.$location
        .path(this.$stateParams.redirectTo)
        .search({})
        .replace();
    } else {
      this.AuthenticationService.navigateToDefaultState();
    }
  }
}

TokenLoginController.controllerAs = 'login';

export default TokenLoginController;
