import { cloneDeep } from 'lodash';

class ChipsSelect {
  constructor(min = null, max = null) {
    this.min = min;
    this.max = max;

    this.elements = [];
    this.idField = 'id';
  }

  validate() {
    return this.getStatus() === 0;
  }

  getStatus() {
    const selectedCount = this.elements.filter(e => e.isSelected).length;
    const minDiff = Math.min(0, selectedCount - this.min);
    const maxDiff = Math.min(0, this.max - selectedCount);
    const minValid = this.min ? minDiff : 0;
    const maxValid = this.max ? maxDiff : 0;
    return minValid - maxValid;
  }

  getStatusText() {
    const status = this.getStatus();
    if (status === 0) {
      return;
    }
    const addOrRemove = status > 0 ? 'remove' : 'select';
    const absStatus = Math.abs(status);
    let statusText;
    if (status > 0) {
      statusText = absStatus === 1 ? 'an option' : `${absStatus} options`;
    } else {
      statusText =
        absStatus === 1 ? 'one more option' : `${absStatus} more options`;
    }
    const atLeast = this.min !== this.max ? ' at least' : '';

    return `Please ${addOrRemove}${atLeast} ${statusText}`;
  }

  static _toggleSelect(element) {
    element.isSelected = !element.isSelected;
  }

  toggleById(id) {
    this.elements
      .filter(e => e[this.idField] === id)
      .map(ChipsSelect._toggleSelect);
  }

  setIdField(idField) {
    this.idField = idField;
  }

  setElements(elements) {
    this.elements = cloneDeep(elements);
  }

  setMin(min) {
    this.min = min;
  }

  setMax(max) {
    this.max = max;
  }

  setEndpoint(endpoint) {
    this.endpoint = endpoint;
  }

  getModel() {
    return this.elements.filter(e => e.isSelected);
  }

  getInputModel() {
    if (this.validate()) {
      return this.getModel();
    }
  }

  updateElements(model) {
    if (model) {
      this.elements.forEach(e => {
        let match = model.find(m => e[this.idField] === m[this.idField]);
        e.isSelected = !!match;
      });
    }
    return this.elements;
  }
}

export default ChipsSelect;
