import angular from 'angular';

import './LocationChips.scss';
import LocationChipsHtml from './LocationChips.html';
import DEFAULT_MAP_STYLES from '../../../services/DefaultMapStyle.constant';

const MILE_TO_KM = 1.60934;
const DEFAULT_LAT = 51.5072; // London
const DEFAULT_LONG = 0.1275; // London
const DEFAULT_ZOOM = 12;
const DEFAULT_LAT_LONG = { lat: DEFAULT_LAT, lng: DEFAULT_LONG };
const DEFAULT_MAP_CONF = {
  center: DEFAULT_LAT_LONG,
  zoom: DEFAULT_ZOOM,
  scrollwheel: false,
  streetViewControl: false
};

const CIRCLE_STROKE_OPACITY = 0.2;
const CIRCLE_STROKE_WEIGHT = 1;
const CIRCLE_FILL_COLOR = 'rgb(164,148,125)';
const CIRCLE_FILL_OPACITY = 0.4;
const CIRCLE_DEFAULT_RADIUS_MILES = 35;
const CIRCLE_DEFAULT_RADIUS_KM = CIRCLE_DEFAULT_RADIUS_MILES * MILE_TO_KM;

let LocationChipsDirective = (
  GoogleMapService,
  PlaceSearchService,
  $timeout
) => {
  let link = (scope, element) => {
    // Currently, LocationChips is only used in the filters. So far, we don't need to populate the
    // inputs (ie. data flow is always one-way from input -> controller).
    //
    // This probably needs to become more clever in the future.
    scope._model = [];

    function onChange() {
      // Throw away bad values before hitting the public model
      const cleanModel = scope._model.filter(place => {
        try {
          return angular.isObject(place.detail.geometry.location);
        } catch (e) {
          return !(e instanceof TypeError);
        }
      });
      scope.model = cleanModel.slice();
    }

    scope.$watch(() => scope._model, onChange, true);

    let divMapElement = element.find('div-map')[0].parentElement;

    let circle = null;
    let service = null;
    let map = null;

    let updateMap = location => {
      $timeout(() => {
        map.panTo(location);

        circle.setCenter(location);
        circle.setVisible(true);

        // Adjust map to fit the bounds
        map.fitBounds(circle.getBounds());
      }, 300);
    };

    let onChipRemove = () => {
      if (scope._model.length === 0 && circle) {
        circle.setVisible(false);
        circle.setCenter(DEFAULT_LAT_LONG);
        scope.map.show = false;
      }
      onChipSelect(scope._model[scope._model.length - 1]);
    };

    let onChipSelect = place => {
      if (!place) {
        if (circle) {
          circle.setVisible(false);
        }
        return;
      }
      if (map === null) {
        initMap().then(service => {
          PlaceSearchService.getDetailsWithService(
            service,
            place.place_id
          ).then(placeDetail => {
            place.detail = placeDetail;
            updateMap(place.detail.geometry.location);
          });
        });
      } else {
        if (!place.detail) {
          PlaceSearchService.getDetailsWithService(
            service,
            place.place_id
          ).then(placeDetail => {
            place.detail = placeDetail;
            updateMap(place.detail.geometry.location);
          });
        } else {
          updateMap(place.detail.geometry.location);
        }
      }
      place.radius = scope.radius.mileToKm(scope.radius.selected);
    };

    let initMap = () => {
      scope.map.show = true;

      return GoogleMapService.get().then(gmaps => {
        map = new gmaps.maps.Map(divMapElement, DEFAULT_MAP_CONF);

        map.setOptions({ styles: DEFAULT_MAP_STYLES });

        $timeout(() => {
          gmaps.maps.event.trigger(map, 'resize');
        }, 200);

        circle = new gmaps.maps.Circle({
          strokeOpacity: CIRCLE_STROKE_OPACITY,
          strokeWeight: CIRCLE_STROKE_WEIGHT,
          fillColor: CIRCLE_FILL_COLOR,
          fillOpacity: CIRCLE_FILL_OPACITY,
          map: map,
          visible: false,
          center: DEFAULT_LAT_LONG,
          radius:
            (scope.radius.mileToKm(scope.radius.selected) ||
              CIRCLE_DEFAULT_RADIUS_KM) * 1000
        });

        service = new gmaps.maps.places.PlacesService(map);

        return service;
      });
    };

    scope.map = {
      show: false,
      onFocus: () => {
        scope.map.show = true;
        scope.$apply();

        initMap();
      }
    };

    scope.locations = {
      readonly: false,
      requireMatch: true,
      selectedItem: null,
      searchText: null,
      map: null,
      querySearch: query => {
        return PlaceSearchService.autocomplete(query);
      },
      // Triggered when a user selects an item.
      onChipSelect: onChipSelect,
      onChipRemove: onChipRemove,
      selected: scope._model,
      onSelectedItemChange: onChipSelect
    };

    // Hook up initial on focus trigger
    scope.onFocus = () => {
      if (map === null) {
        initMap();
      }
    };

    scope.radius = {
      mileToKm: miles => Math.floor(miles * MILE_TO_KM),
      miles: [10, 20, 35, 50, 75, 100],
      selected: CIRCLE_DEFAULT_RADIUS_MILES
    };

    scope.$watch('radius.selected', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        circle.setRadius(+newVal * MILE_TO_KM * 1000);
        map.fitBounds(circle.getBounds());
        onChange();
        scope._model.forEach(l => (l.radius = scope.radius.mileToKm(newVal)));
      }
    });

    scope.$watchCollection('_model', (newVal, oldVal) => {
      if (angular.isUndefined(newVal)) {
        scope._model = [];
        return;
      }

      // Reset
      if (newVal !== oldVal && newVal.length === 0) {
        if (circle) {
          circle.setCenter(DEFAULT_LAT_LONG);
        }
        onChipRemove();
      }

      scope.map.show = newVal.length > 0;
    });

    scope.$watchCollection('model', () => {
      if (!scope.model) {
        scope._model = [];
      }
    });
  };

  return {
    restrict: 'E',
    templateUrl: LocationChipsHtml,
    scope: {
      model: '='
    },
    link: link
  };
};

LocationChipsDirective.$inject = [
  'GoogleMapService',
  'PlaceSearchService',
  '$timeout'
];
LocationChipsDirective.NAME = 'locationChips';

export default LocationChipsDirective;
