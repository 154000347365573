import './styles.scss';
import templateUrl from './template.html';

const DROPDOWN_MAX_HEIGHT_PX = 250;

function wcDropdownContainer($document) {
  return {
    templateUrl: templateUrl,
    controller: () => {},
    controllerAs: 'wcDropdownContainerCtrl',
    bindToController: true,
    transclude: true,
    scope: {
      isExpanded: '<',
      onCollapse: '&'
    },
    require: 'wcDropdownContainer',
    link: (scope, element, attributes, wcDropdownContainerCtrl) => {
      let boundaryElement = $(
        $document[0].getElementById('WcDropdownBoundary')
      );
      let dropdownElement = $(element[0]).parent();

      scope.$watch(() => dropdownElement.offset(), handleViewportEvent, true);

      scope.$watch(() => boundaryElement.offset(), handleViewportEvent, true);

      scope.$watch(
        () => wcDropdownContainerCtrl.isExpanded,
        (newValue, oldValue) => {
          if (oldValue === true && newValue === false) {
            wcDropdownContainerCtrl.onCollapse();
          }
        }
      );

      function calculateIfEnoughSpace() {
        const boundaryBottom =
          boundaryElement.offset().top + boundaryElement.height();
        const dropdownBottom =
          dropdownElement.offset().top + dropdownElement.height();
        return dropdownBottom + DROPDOWN_MAX_HEIGHT_PX < boundaryBottom;
      }

      function handleViewportEvent() {
        wcDropdownContainerCtrl._hasEnoughSpace = calculateIfEnoughSpace();
      }

      handleViewportEvent();
    }
  };
}

wcDropdownContainer.$inject = ['$document'];
wcDropdownContainer.NAME = 'wcDropdownContainer';
export default wcDropdownContainer;
