import angular from 'angular';
import { react2angular } from 'react2angular';
import { AddToLonglistSwitch } from './AddToLonglistSwitch';

export default angular
  .module('wc.components.react.memberList.addToLonglistSwitch', [])
  // @ts-ignore
  .component(
    'reactAddToLonglistSwitch',
    react2angular(AddToLonglistSwitch, ['user', 'onToggleUser'], ['$scope'])
  );
