import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import InverseButton from '../../../buttons/InverseButton/InverseButton';

export const MemberVisibleSetting = ({ onChange, memberVisible }) => {
  return (
    <div className="MemberVisibleSetting__Row">
      <div className="MemberVisibleSetting__Name">
        <div className="MemberVisibleSetting__Header">
          <div className="MemberVisibleSetting__Label">
            Make my profile visible to other members
          </div>
        </div>
        <div className="MemberVisibleSetting__Description">
          Activate your profile to interact with other members and grow your
          network.
        </div>
      </div>
      <span className="flex" />
      <InverseButton
        className="MemberVisibleSetting__MakeVisible md-primary"
        type="button"
        disabled={memberVisible}
        onClick={() => onChange(true)}
        withBorder={false}
      >
        <span className="MemberVisibleSetting__MakeVisible__Text">
          Activate
        </span>
      </InverseButton>
    </div>
  );
};

MemberVisibleSetting.propTypes = {
  onChange: PropTypes.func,
  memberVisible: PropTypes.bool
};
