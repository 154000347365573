import angular from 'angular';

import './CurrencyRange.scss';
import controller from './CurrencyRange.controller';
import templateUrl from './CurrencyRange.html';

const CurrencyRange = {
  NAME: 'wcCurrencyRange',
  bindings: {
    debounce: '@?',
    currency: '<',
    minLabel: '@',
    maxLabel: '@',
    minModel: '=',
    maxModel: '=',
    title: '@'
  },
  controller: controller,
  templateUrl: templateUrl
};

export default angular
  .module('wc.inputGroups.currencyRange', [])
  .component(CurrencyRange.NAME, CurrencyRange);
