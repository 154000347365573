import angular from 'angular';

const HeavyLabel = {
  NAME: 'wcHeavyLabel',
  template: '<label class="WcHeavyLabel" ng-transclude></label>',
  transclude: true
};

export default angular
  .module('wc.components.typography.heavyLabel', [])
  .component(HeavyLabel.NAME, HeavyLabel);
