import angular from 'angular';

import wcSettings from '../../../../components/settings/Settings.module';
import wcSolidButton from '../../../../components/buttons/solidbutton';
import MemberAccountSettingsTab from '../../../../components/settings/member-account/MemberAccountSettingsTab.module';

import './Settings.scss';
import MemberSettingsController from './Settings.controller';
import Routing from './Settings.routing';

import ReactMemberVisibilitySettingsTab from '../../../../components/react/settings/member-visibility';

const dependencies = [
  wcSettings.name,
  wcSolidButton.name,
  MemberAccountSettingsTab.name,
  MemberSettingsController.name,
  ReactMemberVisibilitySettingsTab.name
];

export default angular
  .module('wc.members.memberArea.settings', dependencies)
  .config(Routing);
