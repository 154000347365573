import './styles.scss';
import templateUrl from './template.html';

class TabController {
  /*@ngInject*/
  constructor($scope) {
    this.$scope = $scope;
  }
}

const Tab = {
  name: 'tab',
  bindings: {
    active: '<'
  },
  controller: TabController,
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.tabs.tab', [])
  .component(Tab.name, Tab);
