import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Radio as BaseRadio } from '@material-ui/core';

const Radio = styled(({ ...props }) => (
  <BaseRadio classes={{ root: 'root' }} {...props} />
))`
  &.root {
    padding: 0;
    margin: 0 9px 0 -2px;

    .MuiSvgIcon-root {
      height: 24px;
      width: 24px;
    }

    &.MuiIconButton-colorSecondary {
      &:hover {
        background-color: ${props =>
          props.theme.palette.extraColors.lighterGrey};
      }
    }
  }
`;
const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LabelText = styled.span`
  font-size: 16px;
`;

const RadioButton = ({ labelText, value }) => {
  return (
    <Label>
      <Radio value={value} />
      <LabelText>{labelText}</LabelText>
    </Label>
  );
};

RadioButton.propTypes = {
  labelText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default RadioButton;
