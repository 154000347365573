import angular from 'angular';
import { react2angular } from 'react2angular';
import { ReactChips } from './ReactChips';

export default angular
  .module('wc.components.ReactChips', [])
  // @ts-ignore
  .component(
    'reactChips',
    react2angular(ReactChips, [
      'title',
      'data',
      'accent',
      'readOnly',
      'onClickFn',
      'titleKey',
      'isSelectedKey'
    ])
  );
