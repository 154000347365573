import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wc.components.search.resultsCount', [])
  .component('wcSearchResultsCount', {
    bindings: {
      count: '<'
    },
    templateUrl: templateUrl
  });
