import STATES from '../../../../services/States';

class GroupsController {
  /*@ngInject*/
  constructor(
    $scope,
    $state,
    $stateParams,
    GroupDialogService,
    GroupsService,
    API,
    $log
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.GroupDialogService = GroupDialogService;
    this.GroupsService = GroupsService;
    this.API = API;
    this.$log = $log;

    // TODO: Get the groups via API, and utilise search
    this.publicDefault = true;

    // Switch to My Groups if navigated here with 'myGroups=true' $stateParam
    this.GroupsService.shouldDisplayAll = !$stateParams.myGroups;

    this._updateGroups();
  }

  showNewGroupDialog = event => {
    this.GroupDialogService.showNewGroupDialog(event, this.publicDefault).then(
      this.createGroup.bind(this),
      this.cancelled.bind(this, 'Adding new group')
    );
  };

  cancelled(action) {
    this.$log.info(`${action} action cancelled`);
  }

  createGroup(data) {
    this.API.Groups.post(data)
      .then(response => {
        this.$state.go(STATES.MEMBER_GROUP, { id: response.id });
      })
      .catch(this.API.handleError('Failed to create group'));
  }

  _toggleAllGroups() {
    this.GroupsService.toggleAllGroups();
    this._updateGroups();
  }

  displayAllGroups = () => {
    if (!this.GroupsService.shouldDisplayAll) {
      this._toggleAllGroups();
    }
  };

  displayMyGroups = () => {
    if (this.GroupsService.shouldDisplayAll) {
      this._toggleAllGroups();
    }
  };

  _updateGroups() {
    this.pending = true;

    if (this.GroupsService.shouldDisplayAll) {
      return this.API.Groups.getList().then(groups => {
        this.allGroups = groups;
        this.pending = false;
      });
    } else {
      return this.API.listMyGroups().then(groups => {
        this.myGroups = groups;
        this.pending = false;
      });
    }
  }
}

GroupsController.controllerAs = '$ctrl';
export default GroupsController;
