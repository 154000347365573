import AnchorNavigation from '../../../components/profile/anchornavigation';
import ProfileLayout from '../../../components/profile/layout';
import { SectionHeader } from '../../../components/typography';

import SettingsModule from './settings/Settings.module';
import GroupsModule from './member-groups/Groups.module';
import GroupModule from './member-group/Group.module';
import MemberAreaRouting from './MemberArea.routing';
import SearchResults from './search-results';

import SearchSidebar from '../../../components/sidebars/membersearchsidebar';
import SidenavNavbar from '../../../components/sidenav/membernavbar';
import SidebarLayout from '../../../components/sidebars/layout';

import ReactSectionHeader from '../../../components/react/typography/section-header';

let MemberAreaModule = angular
  .module('wc.members.memberArea', [
    AnchorNavigation.name,
    ProfileLayout.name,
    SectionHeader.name,
    GroupModule.name,
    GroupsModule.name,
    SettingsModule.name,
    SidebarLayout.name,
    SidenavNavbar.name,
    SearchSidebar.name,
    SearchResults.name,
    ReactSectionHeader.name
  ])
  .config(MemberAreaRouting);

export default MemberAreaModule;
