import angular from 'angular';

import AllowSuggestions from './allowsuggestions/AllowSuggestions.directive';
import ButtonsModule from '../buttons/Buttons.module';
import CheckBox from '../react/inputs/CheckboxQuestion';
import CheckboxQuestion from './checkboxquestion/CheckboxQuestion.directive';
import ChipsSelect from './chipsselect/ChipsSelect.directive';
import ChipsSelectGroup from './chipsselectgroup/ChipsSelectGroup.module';
import CurrencyDropdown from './selects/currency-dropdown';
import Debounce from './debounce';
import DropdownContainer from './expandables/dropdowncontainer';
import LimtedOptionsCurrencyDropdown from './selects/limited-options-currency-dropdown';
import ListCheckboxGroup from './listcheckboxgroup/ListCheckboxGroup.directive';
import ListInput from './listinput/ListInput.directive';
import LocationChips from './locationchips/LocationChips.directive';
import MultiValueTypeahead from './expandables/multivaluetypeahead/MultiValueTypeahead.component';
import NumberInput from './numerical/number/NumberInput.module';
import OptionsRadioGroup from './optionsradiogroup/OptionsRadioGroup.directive';
import PhoneNumberInput from './phonenumber/PhoneNumberInput.directive';
import RequiredDropdownSelect from './requireddropdownselect/RequiredDropdownSelect.directive';
import Services from '../../services/Services.module';
import SingleValueTypeahead from './expandables/singlevaluetypeahead/SingleValueTypeahead.directive';
import ReactSingleValueTypeahead from '../react/inputs/expandables/SingleValueTypeAhead';

const dependencies = [
  'restangular',
  'wcCommon.services.api',
  ButtonsModule.name,
  ChipsSelectGroup.name,
  Debounce.name,
  LimtedOptionsCurrencyDropdown.name,
  NumberInput.name,
  Services.name,
  CheckBox.name,
  ReactSingleValueTypeahead.name
];

let InputsModule = angular
  .module('wc.components.inputs', dependencies)
  .component(MultiValueTypeahead.NAME, MultiValueTypeahead)
  .directive(AllowSuggestions.NAME, AllowSuggestions)
  .directive(CheckboxQuestion.NAME, CheckboxQuestion)
  .directive(ChipsSelect.NAME, ChipsSelect)
  .component(CurrencyDropdown.NAME, CurrencyDropdown)
  .directive(DropdownContainer.NAME, DropdownContainer)
  .directive(ListCheckboxGroup.NAME, ListCheckboxGroup)
  .directive(ListInput.NAME, ListInput)
  .directive(LocationChips.NAME, LocationChips)
  .directive(OptionsRadioGroup.NAME, OptionsRadioGroup)
  .directive(PhoneNumberInput.NAME, PhoneNumberInput)
  .directive(RequiredDropdownSelect.NAME, RequiredDropdownSelect)
  .directive(SingleValueTypeahead.NAME, SingleValueTypeahead);

export default InputsModule;
