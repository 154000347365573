// eslint conflicts with prettier on
import angular from 'angular';
import template from './template.html';
import './styles.scss';

import STATES from '../../../../services/States';
import getFormattedDateString from '../../../../../common/services/DateUtils';

class PositionController {
  /*@ngInject*/
  constructor(
    API,
    $state,
    $mdConstant,
    $timeout,
    $q,
    PositionService,
    CurrencyService,
    AuthenticationService,
    $scope
  ) {
    this.API = API;
    this.$state = $state;
    this.$mdConstant = $mdConstant;
    this.$timeout = $timeout;
    this.$q = $q;
    this.PositionService = PositionService;
    this.CurrencyService = CurrencyService;
    this.AuthenticationService = AuthenticationService;
    this.$scope = $scope;
  }

  $onInit() {
    this.loading = true;

    this.noCompensation = true;
    this.profilePromise = this.AuthenticationService.getProfile().then(
      profile => {
        this.noCompensation = !profile || profile.noCompensation;
      }
    );
    this.currencies = [];
    this.selectedCurrency = {};
    let currenciesPromise = this.API.Currencies.getList().then(currencies => {
      // TODO: check if this is really needed here, or move to component
      this.currencies = this.CurrencyService.currencyListToMap(currencies);
    });

    // init optional fields to allow angular's form validation
    this.remuneration = {
      other: [],
      otherFinancialAmount: null,
      buyoutAmount: null,
      totalCompensationAmount: null
    };
    let remunerationPromise = this.API.retrieveMyRemuneration().then(
      remuneration => {
        if (this.position.url && this.position.current) {
          this.remuneration = remuneration;
        }
        this.retreivedRemuneration = remuneration;
      }
    );

    let currentPositionPromise = this.API.retrieveMyCurrentPosition().then(
      current => {
        this.recentPosition = current;
        if (this.recentPosition) {
          this.recentYear = this.recentPosition.startDate.slice(2, 4);
          this.recentMonth = this.recentPosition.startDate.slice(5, 7);
        }
      }
    );

    let promises = [
      currenciesPromise,
      remunerationPromise,
      currentPositionPromise
    ];

    if (this.position.url) {
      let positionPromise = this.API.restangularize(this.position)
        .get()
        .then(restangularized => (this.position = restangularized));
      promises.push(positionPromise);
    }

    this.$q
      .all(promises)
      .then(this.setView.bind(this))
      .catch(this.handleError.bind(this));

    this.$scope.$watch(
      // TODO: This should live in a service
      this.getTotalCompensation.bind(this),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (this.remuneration) {
            this.remuneration.totalCompensation = newValue;
          }
          if (this.position.current) {
            this.position.totalCompensationAmount = newValue;
          }
        }
      },
      true
    );
  }

  showRemuneration() {
    return !this.noCompensation;
  }

  showRecentEndDate() {
    return (
      this.recentPosition &&
      this.recentPosition.current &&
      this.position.current &&
      this.recentPosition.id !== this.position.id
    );
  }

  setView() {
    if (this.remuneration) {
      this.remuneration.totalCompensation = this.getTotalCompensation();
    }
    // Overwrite the totalCompensationAmount for this position using the calculated value
    if (this.position.current && this.remuneration) {
      this.position.totalCompensationAmount = this.remuneration.totalCompensation;
    }
    this.handleSuggestions();
    this.initCurrency();
    this.setSeparatorKeys();
    this.setCurrencyFields();
    this.forceCurrencySymbolToResize();
    this.loading = false;
  }

  handleSuggestions() {
    if (
      this.position.organizationSuggestions &&
      this.position.organizationSuggestions.length
    ) {
      this.transformOrganizationSuggestions();
    }
  }

  transformOrganizationSuggestions() {
    let suggestions = {};
    this.position.organizationSuggestions.forEach(suggestion => {
      let key = suggestion.organizationType;
      if (!suggestions[key]) {
        suggestions[key] = [];
      }

      suggestions[key].push(suggestion);
    });

    this.position.organizationSuggestions = suggestions;
  }

  initCurrency() {
    // remuneration check is safe to remove once currents' total_comp_fields are populated with migration script
    if (this.remuneration && this.remuneration.totalCompensationCurrency) {
      this.selectedCurrency = this.currencies[
        this.remuneration.totalCompensationCurrency
      ];
      return;
    }
    this.selectedCurrency = this.position.totalCompensationAmount
      ? this.currencies[this.position.totalCompensationCurrency]
      : this.currencies.USD;
  }

  setSeparatorKeys() {
    this.separatorKeys = [
      this.$mdConstant.KEY_CODE.ENTER,
      this.$mdConstant.KEY_CODE.TAB,
      this.$mdConstant.KEY_CODE.COMMA
    ];
  }

  // TODO: This should live in a service
  numberOrZero(numberish) {
    let number = +numberish;
    return isNaN(number) ? 0 : number;
  }

  getTotalCompensation() {
    if (!this.remuneration) {
      return 0;
    }
    let amounts = [
      this.remuneration.salaryAmount,
      this.remuneration.bonusAmount,
      this.remuneration.longTermCashAmount,
      this.remuneration.stockAndOptionsAmount,
      this.remuneration.otherFinancialAmount
    ];
    return amounts.reduce(
      (a, b) => this.numberOrZero(a) + this.numberOrZero(b),
      0
    );
  }

  setCurrencyFields() {
    if (!this.remuneration) {
      return;
    }
    let currencyFields = [
      'bonusCurrency',
      'buyoutCurrency',
      'longTermCashCurrency',
      'stockAndOptionsCurrency',
      'salaryCurrency',
      'otherFinancialCurrency',
      'optionsCurrency',
      'totalCompensationCurrency'
    ];
    currencyFields.forEach(
      cf => (this.remuneration[cf] = this.selectedCurrency.code)
    );
  }

  //TODO: Very hacky
  forceCurrencySymbolToResize() {
    let selectedCurrency = this.selectedCurrency;
    this.selectedCurrency = '';
    this.$timeout(() => (this.selectedCurrency = selectedCurrency), 10);
  }

  submit() {
    this.forceValidMax();
    if (this.form.$valid) {
      this.loading = true;
      this.saveForm();
    }
  }

  updateSummary = str => {
    this.position.roleSummary = str;
  };

  // Ugly fix for monthInput directive's bug:
  // it doesn't cleanup after itself,
  // and changing the position current/not current makes the form waiting for validation
  forceValidMax() {
    if (this.form.$pending) {
      if (this.form.$pending.max) {
        this.form.$pending.max[0].$setValidity('max', true);
      }
    }
  }

  savePosition() {
    if (!this.position.current) {
      this.position.totalCompensationCurrency = this.selectedCurrency.code;
    } else {
      delete this.position.totalCompensationAmount;
      delete this.position.totalCompensationCurrency;
    }
    return (this.positionSavePromise = this.PositionService.savePosition(
      this.position
    ));
  }

  saveRecentEndDate() {
    if (this.showRecentEndDate()) {
      let patchRecent = {
        endDate: getFormattedDateString(this.recentPosition.endDate),
        current: false,
        startDate: this.recentPosition.startDate
      }; // Not changed but required for backend-side validation
      return (this.recentPositionEndDateSavePromise = this.API.restangularize(
        this.recentPosition
      ).patch(patchRecent));
    }
    return (this.recentPositionEndDateSavePromise = true);
  }

  saveForm() {
    this.savePosition();
    this.saveRecentEndDate();
    // Save remuneration after this promises are resolved

    this.$q
      .all([this.positionSavePromise, this.recentPositionEndDateSavePromise])
      .then(this.saveRemuneration.bind(this))
      .catch(this.handleError.bind(this));
  }

  saveRemuneration() {
    if (this.position.current && this.remuneration) {
      this.setCurrencyFields();
      let data = this.remuneration.plain
        ? this.remuneration.plain()
        : this.remuneration;
      // TODO: similar appears in several controllers, refactor
      let danglingOther = $('[name="otherPackageElements"] input').val();
      if (
        danglingOther &&
        this.remuneration.other.indexOf(danglingOther) === -1
      ) {
        this.remuneration.other.push(danglingOther);
      }
      data.other = this.remuneration.other;
      // force overwrite buyoutAmount
      data.buyoutAmount = this.remuneration.buyoutAmount
        ? this.remuneration.buyoutAmount
        : null;
      data.otherFinancialAmount = this.remuneration.otherFinancialAmount
        ? this.remuneration.otherFinancialAmount
        : null;
      // Update if editing existing remuneration or overwrite with new data
      let save =
        this.remuneration && this.remuneration.url
          ? this.API.restangularize(this.remuneration).patch
          : this.retreivedRemuneration && this.retreivedRemuneration.url
          ? this.API.restangularize(this.retreivedRemuneration).patch
          : null;
      if (!save) {
        this.handleNavigation();
        return;
      }
      save(data)
        .then(this.handleNavigation.bind(this))
        .catch(this.handleError.bind(this));
    }
    this.handleNavigation();
  }

  handleNavigation() {
    if (this.position.url) {
      let positionElementId = 'career-' + this.position.id;
      return this.$state.go(STATES.MEMBER_PROFILE, {
        scrollToSection: positionElementId
      });
    }
    this.$state.go(STATES.MEMBER_INLINE_EDIT.EXEC_POSITIONS_LIST);
  }

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

const wcInlineEditPosition = {
  bindings: {
    position: '<'
  },
  controller: PositionController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.position', [])
  .component('wcInlineEditPosition', wcInlineEditPosition);
