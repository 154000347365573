import STATES from '../../services/States';
import UnsupportedDeviceController from './UnsupportedDevice.controller';
import UnsupportedDeviceTemplateUrl from './UnsupportedDevice.html';

export default /*@ngInject*/ function UnsupportedDeviceRouting($stateProvider) {
  $stateProvider.state(STATES.DEVICE_NOT_SUPPORTED, {
    controller: UnsupportedDeviceController,
    controllerAs: UnsupportedDeviceController.controllerAs,
    url: '/unsupportedDevice',
    templateUrl: UnsupportedDeviceTemplateUrl
  });
}
