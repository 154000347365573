import './styles.scss';
import templateUrl from './template.html';

const wcRemoveButton = {
  NAME: 'wcRemoveButton',
  templateUrl: templateUrl,
  controllerAs: 'removeButtonCtrl',
  bindings: {
    onRemove: '&'
  }
};

export default wcRemoveButton;
