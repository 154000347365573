import angular from 'angular';
import { react2angular } from 'react2angular';
import { NewMessageForm } from './NewMessageForm';

export default angular
  .module('wc.components.NewMessageForm', [])
  // @ts-ignore
  .component(
    'reactNewMessageForm',
    react2angular(NewMessageForm, [], ['MessengerService', 'ChatService'])
  );
