import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import AutoSuggest from '../../inputs/selects/AutoSuggest';

class CountryOfOrigin extends React.Component {
  state = {
    suggestions: []
  };

  style = {
    marginLeft: '14px'
  };

  static getDerivedStateFromProps = props => {
    const suggestions = props.countries
      ? props.countries.map(option => ({
          label: option.name,
          value: option.name
        }))
      : [];

    return {
      suggestions
    };
  };

  handleChange = event => {
    const { countries, updateCountry } = this.props;

    let newCountry = null;
    if (event) {
      newCountry = countries.find(country => country.name === event.value);
    }

    updateCountry(newCountry);
  };

  noOptionsMessage = () => 'No matches found.';

  render() {
    const { country, halfWidth = false } = this.props;
    const value = country
      ? { label: country.name, value: country.name }
      : undefined;

    const selectProps = {
      value,
      noOptionsMessage: this.noOptionsMessage,
      options: this.state.suggestions,
      onChange: this.handleChange,
      labelText: 'Country of Origin'
    };

    return (
      <AutoSuggest
        style={this.style}
        halfWidth={halfWidth}
        render={defaultProps => <Select {...defaultProps} {...selectProps} />}
      />
    );
  }
}

CountryOfOrigin.propTypes = {
  countries: PropTypes.array.isRequired,
  country: PropTypes.object,
  updateCountry: PropTypes.func.isRequired,
  halfWidth: PropTypes.bool
};

export default CountryOfOrigin;
