import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import BaseSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { ArrowDropDown } from '@material-ui/icons';

import theme from '../../../../../../common/styles/theme';
import { InputError } from '../../../../../../common/components';
import FormControl from '../../FormControl';
import CancelButton from '../../../buttons/CancelButton';

const StyledLabel = styled.div`
  position: relative;
  bottom: 18px;
`;

const SelectAndButtonDiv = styled.div`
  display: flex;
`;

const StyledSelect = styled(({ ...props }) => (
  <BaseSelect classes={{ icon: 'icon' }} {...props} />
))`
  width: ${props => (props.fullWidth ? '100%' : 'calc(100% - 35px)')};
  &.icon {
    position: relative;
  }
`;

const ButtonContainer = styled.div`
  display: ${props => (props.shouldDisplay ? 'flex' : 'none')};
  margin-left: 5px;
`;

const ErrorWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
class Select extends React.PureComponent {
  // TODO(mihail): figure out how to move these into styled components
  formControlStyles = {
    minWidth: '75px'
  };

  menuItemStyles = {
    fontFamily: "'ProximaNova', sans-serif",
    fontSize: '16px',
    color: 'rgb(44,44,52)'
  };

  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 256,
        minHeight: 48,
        padding: '0px 0px 0px 0px'
      },
      square: true
    },
    MenuListProps: {
      style: {
        padding: '0px 0px 0px 0px'
      }
    }
  };

  constructor(props) {
    super(props);
    const { value, valueToItem } = this.props;

    const selectValue = value && valueToItem(value).value;
    this.state = {
      inputLabelStyle: {
        overflow: 'visible',
        fontSize: '16px',
        transform: selectValue && 'matrix( 0.875, 0, 0, 0.875, 0, -2)',
        letterSpacing: 'normal'
      }
    };
  }

  handleChange = event => {
    const newValue = this.props.options.find(option => {
      const item = this.props.valueToItem(option);
      return item.value === event.target.value;
    });
    this.props.onChange(newValue);
  };

  bodyIsFocused = () => {
    return document.activeElement.className.includes('WcBody');
  };

  handleSelectClick = () => {
    // click is triggered even when the select is not focused
    if (this.bodyIsFocused()) {
      return;
    }
    if (this.props.value) {
      return;
    }
    this.moveLabelUp();
  };

  moveLabelUp = () => {
    this.setState(prevState => ({
      inputLabelStyle: {
        ...prevState.inputLabelStyle,
        transform: 'matrix( 0.875, 0, 0, 0.875, 0, -2)'
      }
    }));
  };

  handleSelectFocus = () => {
    this.moveLabelUp();
  };

  handleSelectBlur = e => {
    this.props.onBlur(e);

    if (this.props.value) {
      return;
    }
    this.moveLabelDown();
  };

  moveLabelDown = () => {
    this.setState(prevState => ({
      inputLabelStyle: {
        ...prevState.inputLabelStyle,
        transform: 'matrix(1, 0, 0, 1, 0, 24)'
      }
    }));
  };

  clearValue = () => {
    this.props.onChange(null);
    this.props.onBlur();
    this.moveLabelDown();
  };

  renderError = () => {
    const { checkForError, hasError, errorMessageLink = true } = this.props;
    if (checkForError && hasError) {
      return (
        <ErrorWrapper>
          <InputError>
            {this.props.errorText}
            {` `}
            {errorMessageLink && (
              <a
                target="_blank"
                href="https://www.wintercircle.co/gdpr.html"
                rel="noopener noreferrer"
              >
                Learn more.
              </a>
            )}
          </InputError>
        </ErrorWrapper>
      );
    }
    return null;
  };

  renderMenuItems = () => {
    const menuItems = this.props.options.map(option => {
      const item = this.props.valueToItem(option);
      return (
        <MenuItem key={item.key} value={item.value} style={this.menuItemStyles}>
          {item.display}
        </MenuItem>
      );
    });
    return menuItems;
  };

  getDropDownIcon = materialUIProps => {
    const { value, fullWidth } = this.props;

    if (value && fullWidth) {
      return <CancelButton onClick={this.clearValue} {...materialUIProps} />;
    }

    return <ArrowDropDown {...materialUIProps} />;
  };

  render() {
    let selectValue = '';
    let inputLabelStyle = { ...this.state.inputLabelStyle };
    const {
      value,
      label,
      hasError,
      checkForError,
      valueToItem,
      fullWidth,
      selectName
    } = this.props;

    if (value) {
      selectValue = valueToItem(value).value;
    }

    return (
      <ThemeProvider theme={theme.palette}>
        <FormControl
          style={this.formControlStyles}
          validationerror={hasError && checkForError}
        >
          <InputLabel style={inputLabelStyle} htmlFor="wc-select-id">
            <StyledLabel>{label}</StyledLabel>
          </InputLabel>
          <SelectAndButtonDiv>
            <StyledSelect
              fullWidth={fullWidth}
              IconComponent={this.getDropDownIcon}
              MenuProps={this.MenuProps}
              value={selectValue}
              onFocus={this.handleSelectFocus}
              onClick={this.handleSelectClick}
              onBlur={this.handleSelectBlur}
              onChange={this.handleChange}
              inputProps={{
                id: 'wc-select-id',
                name: selectName || ''
              }}
            >
              {this.renderMenuItems()}
            </StyledSelect>
            <ButtonContainer shouldDisplay={!fullWidth && !!selectValue}>
              <CancelButton onClick={this.clearValue} />
            </ButtonContainer>
          </SelectAndButtonDiv>
          {this.renderError()}
        </FormControl>
      </ThemeProvider>
    );
  }
}

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  onBlur: PropTypes.func,
  checkForError: PropTypes.bool,
  errorText: PropTypes.string,
  valueToItem: PropTypes.func,
  fullWidth: PropTypes.bool,
  errorMessageLink: PropTypes.bool,
  selectName: PropTypes.string
};

export default Select;
