import states from './services/States';

/*@ngInject*/
export default function AppRouting($stateProvider, $urlRouterProvider) {
  // The "root" state immediately redirects to an appropriate route in the app.
  $stateProvider.state(states.ROOT, {
    url: '/',
    /*@ngInject*/
    controller: function(AuthenticationService) {
      AuthenticationService.navigateToDefaultState();
    },
    controllerAs: '$ctrl'
  });

  $stateProvider.state(states.NOT_FOUND, {
    url: '/404',
    template: '<wc-not-found></wc-not-found>'
  });

  $stateProvider.state(states.SERVER_ERROR, {
    url: '/500',
    template: '<wc-server-error></wc-server-error>'
  });

  // Without this `.when()` rule, the root state will only be matched with an explicit trailing
  // slash in the URL.
  $urlRouterProvider.when('', '/');

  // If nothing else matches, go to root and find a good default state to land on.
  // An alternative solution is to add a 404 page in this case.
  $urlRouterProvider.otherwise('/');
}
