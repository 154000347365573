import angular from 'angular';
import { react2angular } from 'react2angular';
import { GroupList } from './GroupList';

export default angular
  .module('wc.components.ReactGroupList', [])
  // @ts-ignore
  .component(
    'reactGroupList',
    react2angular(GroupList, ['groups'], ['DialogService', 'API'])
  );
