import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileAspirations } from './OwnMemberProfileAspirations';

export default angular
  .module('wc.components.OwnMemberProfileAspirations', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileAspirations',
    react2angular(OwnMemberProfileAspirations, ['member'], ['$state'])
  );
