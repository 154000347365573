import { useCallback } from 'react';
import { Actions as ProfilesActions } from '../../../../common/redux/profiles/actions';
import { Actions as UserActions } from '../../../../common/redux/user/actions';

function useProfileUpdater(
  reduxDispatch,
  reduxState,
  state,
  PlaceSearchService
) {
  const updateUserDetails = useCallback(
    key => payload => {
      UserActions.updateUser(reduxDispatch, { [key]: payload });
    },
    [reduxDispatch, UserActions]
  );

  const updateProfileDetails = useCallback(
    key => payload => {
      ProfilesActions.updateMyProfile(reduxDispatch, { [key]: payload });
    },
    [reduxDispatch, ProfilesActions]
  );

  const updateAddressDetails = useCallback(
    async place_id => {
      const placeDetail = await PlaceSearchService.getDetailsWithService(
        state.mapService,
        place_id
      );

      const countryComponent = placeDetail.address_components.find(
        component => component.types.indexOf('country') > -1
      );
      const newCountry = reduxState.countryOptions.find(
        country => country.name === countryComponent.long_name
      );
      const stateComponent = placeDetail.address_components.find(
        component => component.types.indexOf('administrative_area_level_1') > -1
      );

      updateProfileDetails('address')({
        ...reduxState.address,
        city: placeDetail.name,
        state: stateComponent ? stateComponent.long_name : '',
        country: newCountry,
        coords: {
          lat: placeDetail.geometry.location.lat(),
          lng: placeDetail.geometry.location.lng()
        }
      });
    },
    [PlaceSearchService, state, reduxState, updateProfileDetails]
  );

  return [updateUserDetails, updateProfileDetails, updateAddressDetails];
}

export default useProfileUpdater;
