class GroupDialogController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
  }

  submit() {
    this.form.$submitted = true;
    if (this.form.$valid) {
      this.model = {
        isPrivate: !this.group.public,
        name: this.group.name,
        description: this.group.description
      };
      this.$mdDialog.hide(this.model);
    }
  }
}

export default GroupDialogController;
