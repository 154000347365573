import angular from 'angular';

class GroupsService {
  constructor() {
    this.shouldDisplayAll = true;
  }

  toggleAllGroups() {
    this.shouldDisplayAll = !this.shouldDisplayAll;
  }
}

export default angular
  .module('wc.services.groups', [])
  .service('GroupsService', GroupsService);
