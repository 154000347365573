import './styles.scss';
import template from './template.html';

export default {
  bindings: {
    ariaLabel: '@',
    uiSref: '@'
  },
  NAME: 'wcProfileEditLink',
  templateUrl: template
};
