import React from 'react';
import PropTypes from 'prop-types';

export const DocumentDownloadCell = ({ extra, rowItem }) => {
  return (
    <span
      className="DocumentDownloadCell"
      onClick={() => {
        extra.downloadDocument(rowItem);
      }}
    >
      <span className="zmdi zmdi-download" />
    </span>
  );
};

DocumentDownloadCell.propTypes = {
  extra: PropTypes.any,
  rowItem: PropTypes.any
};
