import './ProgressTabs.scss';
import ProgressTabsTemplateUrl from './ProgressTabs.html';

const inject = ['$log', '$timeout', '$rootScope', '$state'];

let progressTabs = ($log, $timeout, $rootScope, $state) => {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: ProgressTabsTemplateUrl,
    scope: {
      tabs: '=',
      dev: '<?',
      accessAllTabs: '<?'
    },
    link: scope => {
      scope.$state = $state;

      scope.tabsCompleted = scope.accessAllTabs ? scope.tabs.length : 0;
      scope.currentStep = 1;
      scope.allSteps = scope.tabs.length;

      function updateTabsState() {
        let selectedTabIndex = scope.findSelectedTabIndex();
        scope.currentStep = selectedTabIndex + 1;
        scope.tabs.forEach((t, i) => {
          t.disabled = selectedTabIndex < i && !scope.accessAllTabs;
          t.active = i === selectedTabIndex;
          t.done = !(t.disabled || t.active);
        });

        if (selectedTabIndex > scope.tabsCompleted) {
          scope.tabsCompleted = selectedTabIndex;
        }
      }

      scope.findSelectedTabIndex = function findSelectedTabIndex() {
        return scope.tabs.indexOf(
          scope.tabs.find(t =>
            $state.$current.name.startsWith(t.redirectTo.state)
          )
        );
      };

      scope.switchTab = function switchTab(tab) {
        if (!$state.includes(tab.redirectTo.state)) {
          $state.go(tab.redirectTo.state, tab.redirectTo.params);
          $timeout(updateTabsState);
        }
      };

      const stateChangeDestroyer = $rootScope.$on(
        '$stateChangeSuccess',
        updateTabsState
      );
      $rootScope.$on('$destroy', stateChangeDestroyer);
      $timeout(updateTabsState);
    }
  };
};

progressTabs.$inject = inject;
progressTabs.NAME = 'progressTabs';

export default progressTabs;
