import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { HighlightedImageBubble } from '../../../../../highlighted-image-bubble/HighlightedImageBubble';
import { convertToShortDate } from '../../../../../utils/ShortDate';

const MESSAGES_CHANGED_EVENT = 'MESSAGES_CHANGED';

export const ConversationSummary = ({
  conversation,
  $state,
  $scope,
  ChatService,
  MessengerService
}) => {
  const [conversationId] = useState(conversation.sid);
  const [lastMessageDate, setLastMessageDate] = useState(null);
  const [conversationState, setConversationState] = useState();
  const [recipient, setRecipient] = useState();
  const [lastMessage, setLastMessage] = useState('');
  const [lastMessageHasBeenRead, setLastMessageHasBeenRead] = useState(true);

  useEffect(() => {
    readConversationMembers();
    setConversationState(MessengerService.getConversationState());

    refreshLastMessageDate();
    refreshLastMessage();
    refreshLastMessageHasBeenRead();
    $scope.$on(MESSAGES_CHANGED_EVENT, () => {
      refreshLastMessageDate();
      refreshLastMessage();
      refreshLastMessageHasBeenRead();
    });
  }, []);

  const readConversationMembers = async () => {
    const recipient = await MessengerService.getRecipient(conversation);
    if (recipient) {
      setRecipient(recipient.state);
    }
  };

  const refreshLastMessageDate = async () => {
    let lastMessageDate = await ChatService.getLastMessageDate(conversation);
    if (!lastMessageDate) {
      lastMessageDate = conversation.dateCreated;
    }
    setLastMessageDate(lastMessageDate);
  };

  const refreshLastMessage = async () => {
    const lastMessage = await getLastMessage();
    setLastMessage(lastMessage);
  };

  const refreshLastMessageHasBeenRead = async () => {
    const lastMessageHasBeenRead = await fetchLastMessageHasBeenRead();
    setLastMessageHasBeenRead(lastMessageHasBeenRead);
  };

  const getLastMessage = async () => {
    return await ChatService.getLastMessageBody(conversation);
  };

  const isActive = () => {
    return $state.params.id === conversationId;
  };

  const fetchLastMessageHasBeenRead = async () => {
    if (conversation === MessengerService.currentConversation) {
      return true;
    }
    return await ChatService.lastMessageHasBeenRead(conversation);
  };

  return (
    <a
      className={`WcConversationSummary ${
        isActive() ? 'WcConversationSummary--active' : ''
      }`}
      onClick={() =>
        $state.go(conversationState, {
          id: conversationId
        })
      }
    >
      {recipient && (
        <div className="WcConversationSummary__ImageBubble">
          <HighlightedImageBubble src={recipient.attributes.avatar_url} />
        </div>
      )}

      <div className="WcConversationSummary__InfoText">
        {recipient && (
          <h3 className="WcConversationSummary__Username">
            {recipient.attributes.display_name}
          </h3>
        )}
        <p
          className={`WcConversationSummary__LastMessage ${
            !lastMessageHasBeenRead
              ? 'WcConversationSummary__LastMessage--Bold'
              : ''
          }`}
        >
          {lastMessage}
        </p>
      </div>

      <div className="WcConversationSummary__RightColumn">
        <p className="WcConversationSummary__Time">
          {convertToShortDate(lastMessageDate)}
        </p>
        {!lastMessageHasBeenRead && (
          <react-messenger-status-indicator className="WcConversationSummary__StatusIndicator" />
        )}
      </div>
    </a>
  );
};

ConversationSummary.propTypes = {
  conversation: PropTypes.object,
  $state: PropTypes.any,
  $scope: PropTypes.any,
  ChatService: PropTypes.any,
  MessengerService: PropTypes.any
};
