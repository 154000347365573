import angular from 'angular';

class compensationCurrency {
  /*@ngInject*/
  constructor() {
    this.options = [
      { id: 1, label: 'EUR €', code: 'EUR', symbol: '€' },
      { id: 2, label: 'GBP £', code: 'GBP', symbol: '£' },
      { id: 3, label: 'USD $', code: 'USD', symbol: '$' }
    ];
    this.current = this.options[2];
  }
}

export default angular
  .module('wc.services.compensationCurrency', [])
  .service('CompensationCurrency', compensationCurrency);
