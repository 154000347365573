import angular from 'angular';

const FieldsetLegend = {
  NAME: 'wcFieldsetLegend',
  template: '<legend class="WcFieldsetLegend" ng-transclude></legend>',
  transclude: true
};

export default angular
  .module('wc.components.typography.fieldsetLegend', [])
  .component(FieldsetLegend.NAME, FieldsetLegend);
