import angular from 'angular';
import './MultiValueTypeahead.scss';
import controller from './MultiValueTypeahead.controller';
import templateUrl from './MultiValueTypeahead.html';

import Inputs from '../../inputs/Inputs.module'; // for multi-value typeahead
import TimePeriodSelector from '../timeperiodselector';

const MultiValueTypeahead = {
  NAME: 'wcMultiValueTypeaheadWithTimePeriodSelector',
  bindings: {
    endpoint: '=',
    model: '=ngModel',
    timePeriodSelectorOptions: '<wcTimePeriodSelectorOptions',
    title: '@'
  },
  controller: controller,
  templateUrl: templateUrl
};

const dependencies = [Inputs.name, TimePeriodSelector.name];

export default angular
  .module('wc.components.timePeriodInputs.multiValueTypeahead', dependencies)
  .component(MultiValueTypeahead.NAME, MultiValueTypeahead);
