import angular from 'angular';

import SuggestOrganization from './SuggestOrganization.factory';
import './SuggestOrganization.scss';
import SuggestOrganizationDirective from './SuggestOrganization.directive';

let SuggestOrganizationModule = angular
  .module('wc.components.dialogs.suggestOrganization', [])
  .factory(SuggestOrganization.NAME, SuggestOrganization)
  .directive(SuggestOrganizationDirective.NAME, SuggestOrganizationDirective);
export default SuggestOrganizationModule;
