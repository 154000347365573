import angular from 'angular';
import { RETURN } from '../../../../services/Keycodes';

import './SingleValueTypeahead.scss';
import SingleValueTypeaheadTemplateUrl from './SingleValueTypeahead.html';
import TypeaheadUtils from '../../utils/TypeaheadUtils';

const inject = ['$timeout'];

let SingleValueTypeahead = $timeout => {
  return {
    restrict: 'E',
    templateUrl: SingleValueTypeaheadTemplateUrl,
    scope: {
      endpoint: '=',
      form: '=?',
      fieldName: '@?',
      labelField: '@',
      model: '=',
      onChange: '&?',
      onValid: '&?',
      onInvalid: '&?',
      searchField: '@',
      title: '@',
      tooltip: '@?',
      itemDisplayValue: '&?'
    },
    link: (scope, elem, attrs) => {
      scope.labelField = scope.labelField ? scope.labelField : 'name';
      scope.searchField = scope.searchField ? scope.searchField : 'search';
      scope.required = attrs.hasOwnProperty('required');
      scope.element = elem;
      scope.itemDisplayValue = scope.itemDisplayValue
        ? scope.itemDisplayValue()
        : defaultItemDisplayValue;

      function defaultItemDisplayValue(item) {
        return item[scope.labelField];
      }

      if (scope.required && angular.isFunction(scope.onInvalid)) {
        scope.onInvalid();
      }

      function validate() {
        if (!scope.field) {
          return;
        }
        if (scope.required && !scope.model) {
          scope.field.$setValidity('required', false);

          if (angular.isFunction(scope.onInvalid)) {
            scope.onInvalid();
          }
        } else {
          scope.field.$setValidity('required', true);

          if (angular.isFunction(scope.onValid)) {
            scope.onValid();
          }
        }
      }

      $timeout(() => {
        if (scope.form && scope.fieldName) {
          scope.field = scope.form[scope.fieldName];
        }

        elem.find('input').on('blur', validate);
        scope.rendered = true;
      });

      scope.$watch(
        'model',
        () => {
          $timeout(() => {
            if (angular.isFunction(scope.onChange)) {
              scope.onChange();
            }
          });

          $timeout(validate);
        },
        true
      );

      function getOptions(searchText) {
        let searchParams = {};
        searchParams[scope.searchField] = searchText;
        return scope.endpoint
          .getList(searchParams)
          .then(data =>
            TypeaheadUtils.sortResults(data, searchText, scope.labelField)
          );
      }

      scope.getOptions = getOptions;

      // TODO: scope.keyEvent doesn't seem to be used elsewhere, so all we are doing
      //       is storing the event on the scope. Why?
      scope.captureKeyPress = event => {
        if (!scope.keyEvent || scope.keyEvent.keyCode !== event.keyCode) {
          scope.keyEvent = event;
        }
      };

      elem[0].addEventListener('keydown', event => {
        if (event.keyCode === RETURN) {
          event.preventDefault();
        }
      });
    }
  };
};

SingleValueTypeahead.$inject = inject;
SingleValueTypeahead.NAME = 'singleValueTypeahead';

export default SingleValueTypeahead;
