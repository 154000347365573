import STATES from '../../../../../services/States';
import { FORM_ERROR_MESSAGE } from '../../../../../services/FormErrorMessages';
import RegistrationBaseController from '../../RegistrationBase.controller';
import cityDisplayValue from '../../../../../services/CityDisplayValue';
import { tabNames } from '../../../member-area/settings/constants';

class AspirationsController extends RegistrationBaseController {
  /*@ngInject*/
  constructor(
    $mdConstant,
    SuggestOrganization,
    $state,
    API,
    AuthenticationService,
    CropDialogService
  ) {
    super($state, API);
    this.$mdConstant = $mdConstant;
    this.SuggestOrganization = SuggestOrganization;
    this.$state = $state;
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.CropDialogService = CropDialogService;

    this.showCompensation = false;
    this.uploadInProgress = false;

    this.profilePictureCaptions = {
      READY: 'Upload a profile picture',
      PROCESSING: 'Processing...',
      UPLOADING: 'Uploading...',
      UPLOADED: 'Change profile picture'
    };

    this.previousState = STATES.MEMBER_REGISTRATION.REMUNERATION;
    this.nextState = STATES.MEMBER_SETTINGS;

    this.nextStateOptions = { tab: tabNames.COMPENSATION };

    this.cityDisplayValue = cityDisplayValue;

    this.formErrorMessage = FORM_ERROR_MESSAGE;

    this.API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .then(this.getAspirations.bind(this))
      .then(this.setAspirations.bind(this))
      .then(this.setLoading.bind(this, false))
      .catch(this.handleError.bind(this));
  }

  suggestCompany(searchText) {
    this.SuggestOrganization.showSuggestDialog(
      null,
      { name: searchText },
      'Company',
      'company'
    ).then(newModelValue => {
      this.aspirations.companies.push({
        name: newModelValue.name,
        isSuggested: true
      });
      this.API.OrganizationSuggestions.post(newModelValue).catch(
        this.API.handleError()
      );
    });
  }

  showSuggestionSuccessAlert(companyName) {
    this.$mdDialog.show(
      this.$mdDialog
        .alert()
        .clickOutsideToClose(true)
        .title('Thank you!')
        .textContent(`Your suggestion to add ${companyName} has been recorded.`)
        .ariaLabel('Company suggestion success')
        .ok('OK')
    );
  }

  setProfile(profile) {
    this.profile = profile;
    if (!this.profile || this.profile.noCompensation) {
      this.previousState = STATES.MEMBER_REGISTRATION.CURRENT_POSITION;
      this.nextStateOptions = { tab: tabNames.NOTIFICATIONS };
    } else {
      this.showCompensation = true;
    }
    this.currentProfilePicture = this.profile.profilePicture;
    return this.profile;
  }

  getAspirations(profile) {
    if (profile.aspirations) {
      return this.API.restangularizeUrl(profile.aspirations).get();
    }
    return {
      cities: [],
      companies: []
    };
  }

  setAspirations(aspirations) {
    this.aspirations = aspirations;
    return this.aspirations;
  }

  saveAspirations() {
    let aspirationsData = {
      cities: this.API.objectListToIdsSerializerField(this.aspirations.cities),
      companies: this.API.objectListToIdsSerializerField(
        this.aspirations.companies.filter(c => !c.isSuggested)
      )
    };

    let saveAspirationPromise;
    if (this.aspirations.url) {
      saveAspirationPromise = this.API.restangularizeUrl(this.aspirations.url)
        .patch;
    } else {
      saveAspirationPromise = this.API.Aspirations.post;
    }

    return saveAspirationPromise(aspirationsData);
  }

  saveProfile() {
    let profilePatch = {
      interestedInNonExec: this.profile.interestedInNonExec,
      openToRelocate: this.profile.openToRelocate,
      aspirationalComp: this.profile.aspirationalComp
    };
    return this.API.restangularize(this.profile).patch(profilePatch);
  }

  submit() {
    if (this.form.$valid) {
      this.setLoading(true);
      this.saveAspirations()
        .then(this.saveProfile.bind(this))
        .then(() =>
          this.API.restangularize(this.profile)
            .all('complete')
            .post()
        )
        // Refresh the AuthenticationService, because isComplete has changed now
        .then(() => this.AuthenticationService.refresh())
        .then(this.nextTab.bind(this))
        .catch(this.handleError.bind(this));
    }
  }
}

AspirationsController.controllerAs = 'aspirationsCtrl';
AspirationsController.NAME = 'AspirationsController';

export default AspirationsController;
