import angular from 'angular';

import controller from './CheckboxGroup.controller';
import templateUrl from './CheckboxGroup.html';

const CheckboxGroup = {
  NAME: 'wcCheckboxGroup',
  bindings: {
    idField: '@?wcIdField',
    labelField: '@?wcLabelField',
    model: '=ngModel',
    options: '<wcOptions',
    title: '@wcTitle'
  },
  controller: controller,
  templateUrl: templateUrl
};

export default angular
  .module('wc.inputGroups.checkboxGroup', [])
  .component(CheckboxGroup.NAME, CheckboxGroup);
