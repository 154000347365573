export default {
  sortResults(results, searchTerm, field = 'name', priorityField = 'priority') {
    searchTerm = searchTerm ? searchTerm.toLowerCase() : '';
    return results.sort((a, b) => {
      if (a[priorityField] === b[priorityField]) {
        if (searchTerm) {
          const aField = a[field].toLowerCase();
          const bField = b[field].toLowerCase();
          const aStartWithTerm = aField.startsWith(searchTerm);
          const bStartsWithTerm = bField.startsWith(searchTerm);
          if (aStartWithTerm && !bStartsWithTerm) {
            return -1;
          }
          if (bStartsWithTerm && !aStartWithTerm) {
            return 1;
          }
        }
        if (a[field] === b[field]) {
          return 0;
        }
        return a[field] < b[field] ? -1 : 1;
      } else {
        return b[priorityField] - a[priorityField];
      }
    });
  }
};
