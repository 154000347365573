import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import TextField from '@material-ui/core/TextField';

import theme from '../../../../../common/styles/theme';
import { InputError } from '../../../../../common/components';

// eslint-disable-next-line no-unused-vars
const StyledTextField = styled(({ validationerror, ...props }) => (
  <TextField {...props} />
))`
  &.MuiTextField-root {
    min-height: 60px;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: solid 2px
      ${props =>
        props.validationerror ? props.theme.error.A700 : 'rgba(0, 0, 0, 0.05)'};
  }

  .MuiInput-underline:focus:not(.Mui-disabled) {
    border-bottom: solid 2px ${props => props.theme.secondary.A400};
  }
  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
  }

  .MuiInput-input {
    border-bottom: solid 2px transparent;
  }

  .MuiInput-input:focus {
    border-bottom: solid 2px ${props => props.theme.secondary.A400};
    background-color: inherit;
  }

  .MuiFormLabel-root {
    font-family: 'ProximaNova', sans-serif;
    font-size: 16px;
    overflow: visible;
    color: ${props =>
      props.validationerror ? props.theme.error.A700 : '#8c8c8c'};

    &.Mui-focused {
      color: ${props =>
        props.validationerror ? props.theme.error.A700 : '#8c8c8c'};
    }

    &.MuiInputLabel-shrink {
      transform: matrix(0.875, 0, 0, 0.875, 0, 1.5);
    }
  }

  .MuiInputBase-input {
    color: ${props =>
      props.validationerror ? props.theme.error.A700 : 'rgb(44, 44, 52)'};
    font-family: 'ProximaNova', sans-serif;
    font-size: 16px;
    line-height: 19px;
  }
`;

const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.halfWidth ? 50 : 100)}%;
`;

const BaseTextField = ({
  value,
  label,
  onChange,
  onBlur,
  onClick,
  shouldRenderError,
  errorText,
  autocomplete,
  inputProps,
  type = 'text',
  halfWidth = false,
  disabled = false
}) => {
  // TODO (mihail) come up with nicer styling
  const errorWrapperStyles = {
    marginTop: '-15px'
  };

  const renderError = () => {
    if (shouldRenderError) {
      return (
        <div style={errorWrapperStyles}>
          <InputError>{errorText}</InputError>
        </div>
      );
    }
    return null;
  };

  return (
    <ThemeProvider theme={theme.palette}>
      <TextFieldWrapper halfWidth={halfWidth}>
        <StyledTextField
          label={label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          margin="normal"
          validationerror={shouldRenderError}
          inputProps={inputProps}
          type={type}
          autoComplete={autocomplete}
          disabled={disabled}
        />
        {renderError()}
      </TextFieldWrapper>
    </ThemeProvider>
  );
};

BaseTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  shouldRenderError: PropTypes.bool,
  errorText: PropTypes.string,
  autocomplete: PropTypes.string,
  inputProps: PropTypes.object,
  type: PropTypes.string,
  halfWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.value === nextProps.value &&
    prevProps.shouldRenderError === nextProps.shouldRenderError
  );
}

export default React.memo(BaseTextField, areEqual);
