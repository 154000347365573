import { RETURN } from '../../../../services/Keycodes';
import RequestHelper from '../../../../services/RequestHelper';

import './MultiValueTypeahead.scss';
import templateUrl from './MultiValueTypeahead.html';
import TypeaheadUtils from '../../utils/TypeaheadUtils';

/* eslint angular/no-private-call:0 */

class MultiValueTypeaheadController {
  /*@ngInject*/
  constructor($attrs, $document, $element, $scope, $timeout) {
    this.$document = $document;
    this.$element = $element;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$attrs = $attrs;
  }

  $onInit() {
    this.searchField = this.searchField ? this.searchField : 'search';
    this.labelField = this.labelField ? this.labelField : 'name';
    this.filterOptions = this.filterOptions ? this.filterOptions : {};
    this.touched = false;
    this.required = this.$attrs.hasOwnProperty('required');

    this.itemDisplayValue = this.itemDisplayValue
      ? this.itemDisplayValue()
      : this._defaultItemDisplayValue;
    this.chipDisplayValue = this.chipDisplayValue
      ? this.chipDisplayValue()
      : this._defaultItemDisplayValue;

    this.autocompleteMinLength = 1;

    this.$element[0].addEventListener('keydown', event => {
      if (event.keyCode === RETURN) {
        event.preventDefault();
      }
    });

    this.$timeout(() =>
      this.$element.find('input').on('blur', this.onAutocompleteBlur.bind(this))
    );

    this.$scope.$watch(
      () => this.model,
      () => {
        this.setValidity();

        if (angular.isUndefined(this.model)) {
          this.model = [];
        }
      },
      true
    );
  }

  captureKeyPress(event) {
    if (!this.keyEvent || this.keyEvent.keyCode !== event.keyCode) {
      this.keyEvent = event;
    }
  }

  getOptions(searchText) {
    let requestParams = {};
    requestParams[this.searchField] = searchText;
    let filterOptions = RequestHelper.getFilterDict(this.filterOptions);
    Object.assign(requestParams, filterOptions);
    return this.endpoint.getList(requestParams).then(data =>
      TypeaheadUtils.sortResults(data, searchText, this.labelField).filter(
        e => {
          return this.model.map(m => m.id).indexOf(e.id) === -1;
        }
      )
    );
  }

  /*
   * These methods below aren't DRY, but imo it's useful to separate out the handlers for
   * different events. The flow of execution is a bit more obvious to me with these named
   * functions.
   *
   * I also think it's nicer not to tie in setting touched state with setting validity, they are
   * different concerns that coincidentally happen together in this input.
   */

  onChipAdd() {
    this.touched = true;
    this.setValidity();
  }

  onChipRemove() {
    this.touched = true;
    this.setValidity();
  }

  onAutocompleteBlur() {
    if (!this.touched) {
      this.touched = true;
      this.setValidity();
    }
  }

  setValidity() {
    if (this.required) {
      if (!this.model || this.model.length === 0) {
        this.modelCtrl.$setValidity('requiredChips', false);
      } else {
        this.modelCtrl.$setValidity('requiredChips', true);
      }
    } else {
      this.modelCtrl.$setValidity('requiredChips', true);
    }
  }

  shouldDisplayError() {
    if (this.modelCtrl && this.modelCtrl.$error) {
      return (
        Object.keys(this.modelCtrl.$error).length > 0 &&
        (this.touched || this.formCtrl.$submitted)
      );
    } else {
      return false;
    }
  }

  _defaultItemDisplayValue(item) {
    return item[this.labelField];
  }

  _noMatchesFound(searchText) {
    if (this.noMatchesFound) {
      // Welcome to Hack Town! :middle_finger:
      $('md-autocomplete-wrap input')
        .blur()
        .val('');
      this.noMatchesFound({ searchText: searchText });
    }
  }
}

export default {
  NAME: 'multiValueTypeahead',
  bindings: {
    endpoint: '=',
    filterOptions: '=?',
    labelField: '@',
    model: '=ngModel',
    noMatchesFound: '&?',
    noMatchesFoundText: '@?',
    searchField: '@',
    title: '@',
    tooltip: '@?',
    itemDisplayValue: '&?'
  },
  controller: MultiValueTypeaheadController,
  require: {
    modelCtrl: 'ngModel',
    formCtrl: '^^form'
  },
  templateUrl: templateUrl
};
