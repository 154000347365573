import './RemovableChip.scss';
import templateUrl from './RemovableChip.html';

let wcRemovableChip = {
  templateUrl: templateUrl,
  controllerAs: 'removableChipCtrl',
  transclude: true,
  bindings: {
    model: '<',
    onRemove: '&',
    onClick: '&',
    onBlur: '&',
    onFocus: '&'
  }
};

wcRemovableChip.NAME = 'wcRemovableChip';
export default wcRemovableChip;
