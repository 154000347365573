import angular from 'angular';
import './Pages.scss';

const Pages = {
  NAME: 'wcPages',
  template: '<section class="WcPages" ng-transclude></section>',
  transclude: true
};

export default angular
  .module('wc.components.pages', [])
  .component(Pages.NAME, Pages);
