import angular from 'angular';
import { react2angular } from 'react2angular';
import { MessengerContacts } from './MessengerContacts';

export default angular
  .module('wc.components.ReactMessengerContacts', [])
  // @ts-ignore
  .component(
    'reactMessengerContacts',
    react2angular(
      MessengerContacts,
      ['conversations'],
      ['$state', '$scope', 'API', 'ChatService', 'MessengerService']
    )
  );
