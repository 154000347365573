import DisplayFieldTemplateUrl from './DisplayField.html';

const inject = [];

let DisplayField = () => {
  return {
    restrict: 'E',
    templateUrl: DisplayFieldTemplateUrl,
    scope: {
      label: '@',
      field: '@'
    },
    link: scope => {
      scope.$watch('field', () => {
        if (!scope.field) {
          scope.field = '-';
        }
      });
    }
  };
};

DisplayField.$inject = inject;
DisplayField.NAME = 'displayField';

export default DisplayField;
