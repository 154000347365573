import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileOverview } from './MemberProfileOverview';

export default angular
  .module('wc.components.MemberProfileOverview', [])
  // @ts-ignore
  .component(
    'reactMemberProfileOverview',
    react2angular(MemberProfileOverview, ['member'], ['AuthenticationService'])
  );
