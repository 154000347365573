import React from 'react';
import PropTypes from 'prop-types';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import { ReactChips } from '../../../../../ReactChips/ReactChips';
import STATES from '../../../../../../../services/States';

export const OwnMemberProfileRegionalManagement = ({ member, $state }) => {
  const onEdit = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.REGIONS_MANAGED);
  };

  return (
    <article className="Separator">
      <div className="WcEditArticle">
        <MemberProfileEditContainer onEdit={onEdit}>
          <div className="flex-grow">
            <h2 className="WcMemberProfileContent__Subheading">
              Regional Management
            </h2>
            <ReactChips readOnly data={member.regionsManaged} accent={true} />
          </div>
        </MemberProfileEditContainer>
      </div>
    </article>
  );
};

OwnMemberProfileRegionalManagement.propTypes = {
  member: PropTypes.object,
  $state: PropTypes.any
};
