import template from './Settings.html';

const SettingsComponent = {
  NAME: 'wcSettings',
  bindings: {
    loading: '<?',
    profile: '<'
  },
  templateUrl: template,
  transclude: true
};

export default SettingsComponent;
