import './SingleLocation.scss';
import LocationChipsHtml from './SingleLocation.html';
import DEFAULT_MAP_STYLES from '../../services/DefaultMapStyle.constant';

const DEFAULT_LAT = 51.5072; // London
const DEFAULT_LONG = 0.1275; // London
const DEFAULT_ZOOM = 12;
const DEFAULT_LAT_LONG = { lat: DEFAULT_LAT, lng: DEFAULT_LONG };
const DEFAULT_MAP_CONF = {
  center: DEFAULT_LAT_LONG,
  zoom: DEFAULT_ZOOM,
  scrollwheel: false,
  streetViewControl: false
};

/*@ngInject*/
let singleLocationDirective = (
  GoogleMapService,
  PlaceSearchService,
  $timeout,
  $q
) => {
  let link = (scope, element) => {
    let divMapElement = element.find('div-map')[0].parentElement;
    let suggestionsContainer = false;

    let marker = null;
    let service = null;
    let map = null;

    let logoHeight = '86px';

    $timeout(() => {
      if (scope.form && scope.inputName) {
        scope.field = scope.form[scope.inputName];
      }
    });

    let updateMap = location => {
      $timeout(() => {
        map.panTo(location);
        marker.setPosition(location);
        marker.setVisible(true);
        scope.onChange(scope.coords);
      }, 300);
    };

    const setLocation = placeDetail => {
      let location = placeDetail.geometry.location;
      scope.coords = { lat: location.lat(), lng: location.lng() };
      if (scope.showMap) {
        updateMap(location);
      }
      scope.onCitySelect({ details: placeDetail });
    };

    const reverseGeocode = latLng => {
      if (angular.isDefined(google)) {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && scope.showMap) {
            updateMap(latLng);
          }
        });
      }
    };

    const setLocationFromModel = location => {
      let latLng = {
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng)
      };
      if (map !== null) {
        reverseGeocode(latLng);
      } else {
        init().then(() => reverseGeocode(latLng));
      }
    };

    let onLocationChange = place => {
      if (!place) {
        scope.coords = {};
        if (marker) {
          marker.setVisible(false);
        }
        scope.onChange(scope.coords);
        return;
      }
      if (service === null) {
        init().then(service => {
          PlaceSearchService.getDetailsWithService(
            service,
            place.place_id
          ).then(placeDetail => {
            place.detail = placeDetail;
            setLocation(place.detail);
          });
        });
      } else {
        if (!place.detail) {
          PlaceSearchService.getDetailsWithService(
            service,
            place.place_id
          ).then(placeDetail => {
            place.detail = placeDetail;
            setLocation(place.detail);
          });
        } else {
          setLocation(place.detail);
        }
      }
    };

    let init = () => {
      if (!scope.showMap) {
        return GoogleMapService.get().then(gmaps => {
          return new gmaps.maps.places.PlacesService(
            element.find('div-place')[0]
          );
        });
      }

      scope.map.show = true;

      return GoogleMapService.get().then(gmaps => {
        map = new gmaps.maps.Map(divMapElement, DEFAULT_MAP_CONF);

        map.setOptions({ styles: DEFAULT_MAP_STYLES });

        $timeout(() => {
          gmaps.maps.event.trigger(map, 'resize');
        }, 200);

        marker = new gmaps.maps.Marker({
          map: map,
          position: DEFAULT_LAT_LONG,
          visibility: false
        });

        service = new gmaps.maps.places.PlacesService(map);

        return service;
      });
    };

    scope.map = {
      show: false,
      onFocus: () => {
        scope.map.show = true;
        scope.$apply();

        init();
      }
    };

    scope.locations = {
      readonly: false,
      requireMatch: true,
      selectedItem: null,
      map: null,
      querySearch: query => {
        if (!scope.showMap) {
          return addLogoToSuggestions(query);
        }
        return PlaceSearchService.autocomplete(query);
      },
      // Triggered when a user selects an item.
      onSelectedItemChange: onLocationChange
    };

    let addLogoToSuggestions = query => {
      let deferred = $q.defer();
      PlaceSearchService.autocomplete(query)
        .then(response => {
          // Add fake element to suggestions drop-down if map not displayed and has result
          response.push({ description: '' });
          deferred.resolve(response);
        })
        .catch(() => {
          resizeNotFoundElement();
          // Return empty array, otherwise material displays the not-found-template AND the previous suggestions
          let emptyResponse = [];
          deferred.resolve(emptyResponse);
        });
      return deferred.promise;
    };

    let resizeNotFoundElement = () => {
      // Resize container to show properly logo
      if (suggestionsContainer) {
        suggestionsContainer.style.minHeight = logoHeight;
      }
    };

    // Add class to md-virtual-repeat's ul element to display logo by css
    scope.addDisplayLogoClass = () => {
      if (!scope.showMap) {
        return 'display-logo';
      }
    };

    // Hook up initial on focus trigger
    scope.onFocus = () => {
      if (service === null) {
        init();
      }
    };

    scope.$watch('text', () => {
      scope.updateSuggestionContainer();
    });

    scope.updateSuggestionContainer = () => {
      // Find out-of-directive suggestionsContainer after DOM rendered
      if (suggestionsContainer) {
        return;
      }
      let el = getSuggestionElement();
      if (!el) {
        return;
      }
      suggestionsContainer = el;
    };

    let getSuggestionElement = () => {
      if (!element || element.length === 0 || !element[0]) {
        return;
      }
      let suggestionEl = element[0].getElementsByClassName(
        'WcSingleLocation__SuggestionsContainer'
      );
      if (
        !suggestionEl ||
        suggestionEl.length === 0 ||
        !suggestionEl[0] ||
        !suggestionEl[0].parentElement ||
        !suggestionEl[0].parentElement.parentElement ||
        !suggestionEl[0].parentElement.parentElement.parentElement
      ) {
        return;
      }
      return suggestionEl[0].parentElement.parentElement.parentElement;
    };

    scope.$watch(
      'coords',
      (newVal, oldVal) => {
        // Reset
        if (newVal !== oldVal) {
          if (!newVal && marker) {
            marker.setPosition(DEFAULT_LAT_LONG);
          } else {
            setLocationFromModel(newVal);
          }
        }
      },
      true
    );
  };

  return {
    restrict: 'E',
    templateUrl: LocationChipsHtml,
    scope: {
      form: '=',
      floatingLabel: '@',
      inputName: '@',
      onChange: '&',
      required: '<?',
      coords: '=',
      text: '=',
      showMap: '<?',
      onCitySelect: '&'
    },
    link: link
  };
};

singleLocationDirective.NAME = 'singleLocation';

export default angular
  .module('wc.components.singlelocation', [])
  .directive(singleLocationDirective.NAME, singleLocationDirective);
