import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ButtonBase as Button } from '@material-ui/core';
import { Clear as BaseCancelSharp } from '@material-ui/icons';

const CancelSharp = styled(({ ...rest }) => (
  <BaseCancelSharp classes={{ root: 'root' }} {...rest} />
))`
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  &.root {
    position: relative;
    color: ${props => props.theme.common.black};
    padding: 2px;
    height: 16px;
    width: 16px;
  }
`;

const CancelButton = ({ onClick, ...rest }) => {
  return (
    <Button onClick={onClick} disableRipple disableTouchRipple>
      <CancelSharp {...rest} />
    </Button>
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func,
  rest: PropTypes.object
};

export default CancelButton;
