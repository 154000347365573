import angular from 'angular';

import './Navbar.scss';
import sidenavTemplateUrl from './Navbar.html';

const Sidenav = {
  NAME: 'wcSidenavNavbar',
  templateUrl: sidenavTemplateUrl,
  transclude: true
};

export default angular
  .module('wc.components.sidenav.navbar', [])
  .component(Sidenav.NAME, Sidenav);
