import angular from 'angular';
import template from './template.html';
import './styles.scss';

import STATES from '../../../services/States';

class ProfessionalSummaryController {
  /*@ngInject*/
  constructor(API, $state) {
    this.API = API;
    this.$state = $state;

    this.loading = true;

    API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .catch(this.handleError.bind(this));
  }

  setProfile(profile) {
    this.profile = profile;
    this.loading = false;
  }

  backToProfile() {
    this.$state.go(STATES.MEMBER_PROFILE);
  }

  submit() {
    if (this.form.$valid) {
      this.loading = true;
      let patchData = { professionalSummary: this.profile.professionalSummary };
      this.API.restangularize(this.profile)
        .patch(patchData)
        .then(this.backToProfile.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  updateSummary = str => {
    this.profile.professionalSummary = str;
  };

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

const wcInlineEditProfessionalSummary = {
  controller: ProfessionalSummaryController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.professionalSummary', [])
  .component(
    'wcInlineEditProfessionalSummary',
    wcInlineEditProfessionalSummary
  );
