import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const PasswordSettingOption = ({ onEdit }) => {
  return (
    <div className="PasswordSettingOption">
      <div className="PasswordSettingOption__Label">Password</div>
      <button
        className="PasswordSettingOption__Edit"
        onClick={onEdit}
        type="button"
      >
        <span className="zmdi zmdi-edit PasswordSettingOption__EditIcon" />
      </button>
    </div>
  );
};

PasswordSettingOption.propTypes = {
  onEdit: PropTypes.func
};
