import angular from 'angular';

import './ProfilePictureMenu.scss';
import MissingImage from '../../../../common/components/missing-image';
import templateUrl from './ProfilePictureMenu.html';
import { MISSING_PROFILE_PICTURE_URL } from '../../../../common/services/ImageUrls';
import buttonTemplateUrl from './profile-picture-menu-button/template.html';
import './profile-picture-menu-button/style.scss';
import { Actions as ProfilesActions } from '../../../../common/redux/profiles/actions';

const dependencies = [MissingImage.name];

const MapStateToProps = state => ({
  profilePicture: state.profilesReducer.myProfile.profilePicture
});

const MapDispatchToProps = dispatch => ({
  updateMyProfile: myProfile =>
    ProfilesActions.updateMyProfile(dispatch, myProfile)
});

class ProfilePictureMenuCtrl {
  /*@ngInject*/
  constructor(API, $scope, $state, $ngRedux) {
    this.API = API;
    this.$ngRedux = $ngRedux;
    this.missingImage = MISSING_PROFILE_PICTURE_URL;
    this.$state = $state;

    $scope.$watch(
      () => this.uiSref,
      () => {
        this.stateConfig = $state.get(this.uiSref);
      }
    );

    $scope.$watch(
      () => {
        return this.$state.current;
      },
      () => {
        this.isActive = this.checkIsActive();
      }
    );
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(
      MapStateToProps,
      MapDispatchToProps
    )(this);

    this.API.retrieveMyProfile().then(res => {
      if (res.profilePicture) {
        this.updateMyProfile({ profilePicture: res.profilePicture });
      }
    });
  }

  $onDestroy() {
    this.unsubscribe();
  }

  checkIsActive() {
    return this.matchesState() || this.matchesUrl();
  }

  matchesState() {
    if (!this.uiSref) {
      return false;
    }

    return this.$state.includes(this.uiSref);
  }

  matchesUrl() {
    if (!this.uiSref) {
      return false;
    }

    return this.$state.current.url.startsWith(this.stateConfig.url);
  }
}

const ProfilePictureMenu = {
  NAME: 'wcSidenavProfilePictureMenu',
  controller: ProfilePictureMenuCtrl,
  templateUrl: templateUrl,
  bindings: {
    uiSref: '@wcUiSref'
  },
  transclude: true
};

const ProfilePictureMenuButton = {
  NAME: 'wcSidenavProfilePictureMenuButton',
  bindings: {
    iconClass: '@?',
    label: '@',
    onClick: '&'
  },
  templateUrl: buttonTemplateUrl,
  transclude: true
};

export default angular
  .module('wc.components.sidenav.profilepicturemenu', dependencies)
  .component(ProfilePictureMenu.NAME, ProfilePictureMenu)
  .component(ProfilePictureMenuButton.NAME, ProfilePictureMenuButton);
