export const EXPIRED_TOKEN = 'EXPIRED_TOKEN';
export const EXPIRED_SESSION = 'EXPIRED_SESSION';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';

let LOGIN_INFO = {};
LOGIN_INFO[PASSWORD_CHANGE_SUCCESS] =
  'Successfully updated your password. Please log in ' +
  'with your new credentials.';

let LOGIN_ERRORS = {};
LOGIN_ERRORS[EXPIRED_TOKEN] =
  'Your link has expired. Please log in with your credentials or ' +
  'contact support.';
LOGIN_ERRORS[EXPIRED_SESSION] =
  'Your session has expired. Please log in again.';

export default { LOGIN_INFO: LOGIN_INFO, LOGIN_ERRORS: LOGIN_ERRORS };
