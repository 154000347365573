import ClientSearchFilters from '../clientsearchfilters';
import Sidebar from '../sidebar';
import templateUrl from './template.html';

export const LONGLIST_FILTER_SIDEBAR_ID = 'wcLonglistFilterSidebar';

class LonglistFilterSidebarCtrl {
  /*@ngInject*/
  constructor($scope, wcLonglist, wcMemberList, wcSidebar) {
    this.$scope = $scope;
    this.wcLonglist = wcLonglist;
    this.wcMemberList = wcMemberList;
    this.wcSidebar = wcSidebar;

    this.ready = false;

    this.$scope.$watch(
      () => this.wcLonglist.activeLonglist,
      this.switchLonglist.bind(this)
    );
    this.sidebarId = LONGLIST_FILTER_SIDEBAR_ID;
    this.wcSidebar.register(LONGLIST_FILTER_SIDEBAR_ID);
  }

  onClearFilters() {
    this.wcMemberList[this.memberListId].clearFilters();
  }

  switchLonglist() {
    this.ready = !!this.wcLonglist.activeLonglist;
    this.memberListId = this.wcMemberList.getMemberListId(
      this.wcLonglist.activeLonglist
    );
  }
}

const LonglistFilterSidebar = {
  controller: LonglistFilterSidebarCtrl,
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.longlistFilterSidebar', [
    ClientSearchFilters.name,
    Sidebar.name
  ])
  .component('wcLonglistFilterSidebar', LonglistFilterSidebar);
