import RegistrationBaseController from '../../RegistrationBase.controller';
import STATES from '../../../../../services/States';

class UploadsController extends RegistrationBaseController {
  /*@ngInject*/
  constructor($state, $q, API, AuthenticationService, CropDialogService) {
    super($state, API);
    this.$state = $state;
    this.$q = $q;
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.CropDialogService = CropDialogService;

    this.uploadInProgress = false;

    this.profilePictureCaptions = {
      READY: 'Upload a profile picture',
      PROCESSING: 'Processing...',
      UPLOADING: 'Uploading...',
      UPLOADED: 'Change profile picture'
    };

    this.API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .catch(this.API.handleError());
  }

  setProfile(profile) {
    this.profile = profile;
    this.currentProfilePicture = this.profile.profilePicture;
    this.setLoading(false);
  }

  redirect() {
    this.$state.go(STATES.MEMBER_PROFILE);
  }

  back() {
    this.$state.go(STATES.MEMBER_REGISTRATION.PERSONALITY);
  }

  submit() {
    this.API.restangularize(this.profile)
      .all('complete')
      .post()
      // Refresh the AuthenticationService, because isComplete has changed now
      .then(() => this.AuthenticationService.refresh())
      .then(this.redirect.bind(this));
  }
}

UploadsController.NAME = 'UploadsController';
UploadsController.controllerAs = 'uploadsCtrl';
export default UploadsController;
