import angular from 'angular';
import { react2angular } from 'react2angular';
import { EmailPreferences } from './EmailPreferences';

export default angular
  .module('wc.components.ReactEmailPreferences', [])
  // @ts-ignore
  .component(
    'reactEmailPreferences',
    react2angular(EmailPreferences, [
      'emailPreferences',
      'hideUnsubscribe',
      'onChange'
    ])
  );
