import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FixedCard } from '../../../cards/FixedCard';
import { FormInfoMessage } from '../../../typography/FormInfoMessage';
import { FormErrorMessage } from '../../../typography/FormErrorMessage';
import { CookieBar } from '../../../../../../common/components/react/cookie-bar';
import { Loading } from '../../../loading';
import InputError from '../../../../../../common/components/react/inputs/messages/InputError';
import SolidButton from '../../../buttons/SolidButton';
import theme from '../../../../../../common/styles/theme';
// import TextField from './TextField';
import ERROR_MESSAGES from '../../../../../services/ErrorMessages.constant';
import LoginMessages from '../../../../../apps/auth/LoginMessages';
import states from '../../../../../services/States';
import './Login.scss';

// eslint-disable-next-line react/display-name
// const ForwardedTextField = React.forwardRef((props, ref) => <TextField innerRef={ref} {...props} />)

// eslint-disable-next-line no-unused-vars
const CssTextField = withStyles({
  root: {
    margin: '18px 0',
    '& MuiFormLabel-root': {
      color: '#888',
      fontSize: '14px',
    },
    '& label.Mui-focused': {
      color: '#888888',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#a4947d',
      borderWidth: '2px',
    },
    '& .Mui-error.MuiInput-underline:after': {
      borderBottomColor: '#f44336 !important',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.05)',
      borderWidth: '2px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

export const Login = ({ AuthenticationService, $stateParams, $location, $state }) => {
  const { control, handleSubmit } = useForm();

  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setInfoMessage(LoginMessages.LOGIN_INFO[$stateParams.status]);
    setErrorMessage(LoginMessages.LOGIN_ERRORS[$stateParams.status]);
  }, []);

  const [loading, setLoading] = useState(false);

  const login = (data) => {
    const { email = '', password = '' } = data;

    setErrorMessage('');
    setLoading(true);

    AuthenticationService.login(email, password).then(
      () => {
        setLoading(false);
        if ($stateParams.next) {
          _redirectToNextState();
        } else {
          AuthenticationService.navigateToDefaultState();
        }
      },
      response => {
        setLoading(false);
        // Cloud front return 403, but DRF returns 429. Handle both.
        // Failed login is 400
        if (response.status === 429 || response.status === 403) {
          setErrorMessage(ERROR_MESSAGES.TOO_MANY_LOGIN_ATTEMPTS);
        } else {
          setErrorMessage(ERROR_MESSAGES.BAD_LOGIN);
        }
      }
    );
  }

  const _redirectToNextState = () => {
    const nextLocation = decodeURIComponent($stateParams.next);
    const [nextPath, nextSearch] = nextLocation.split('?');
    $location.path(nextPath).search(nextSearch || {});
  }

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <h1 className="WcViewHeader">Sign In</h1>
        <FixedCard>
          <Loading loading={loading}>
              <div className="Form">
                {(infoMessage && !errorMessage) && <FormInfoMessage
                  className="Form__InfoMessage">
                  {infoMessage}
                </FormInfoMessage>}

                {errorMessage && <FormErrorMessage
                  className="Form__InfoMessage">
                  {errorMessage}
                </FormErrorMessage>}

                <Controller
                  name='email'
                  control={control}
                  rules={{ required: true, pattern: /\S+@\S+\.\S+/ }}
                  defaultValue=''
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <React.Fragment>
                      <CssTextField
                        type='email'
                        label="Email"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                      {error && <InputError>
                        {error.type === "required" && (
                          <span>This field is required</span>
                        )}
                        {error.type === "pattern" && (
                          <span>Please provide a valid email address</span>
                        )}
                      </InputError>}
                    </React.Fragment>
                  )}
                />

                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  defaultValue=''
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <React.Fragment>
                    <CssTextField
                      type='password'
                      label='Password'
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                      {error && (
                        <InputError>
                          {error.type === "required" && (
                            <span>This field is required</span>
                          )}
                        </InputError>
                      )}
                    </React.Fragment>
                  )}
                />

                <div className="Form__SubmitButton space-between center">
                  <SolidButton type="submit" onClick={handleSubmit(login)}>
                    Sign In
                  </SolidButton>

                  <div className="Form__ButtonRowText">
                    <label>
                      Need help with{' '}
                      <a href={$state.href(states.FORGOTTEN_PASSWORD)}>password</a>?
                    </label>
                  </div>
                </div>
              </div>
          </Loading>
        </FixedCard>
        <CookieBar />
      </React.Fragment>
    </ThemeProvider>
  );
};

Login.propTypes = {
  AuthenticationService: PropTypes.any,
  $stateParams: PropTypes.any,
  $location: PropTypes.any,
  $state: PropTypes.any
};
