import angular from 'angular';

import DrawerContainerModule from '../drawercontainer';
import MessagesModule from '../../../../../common/components/inputs/messages';
import SuggestOrganizationModule from '../../../dialogs/suggestorganization/SuggestOrganization.module';
import OnDisabledModule from '../../behaviours/OnDisabled.module';

import ChipsDrawer from '../chipsdrawer/ChipsDrawer.directive';
import SuggestibleChipsDrawer from '../chipsdrawer/SuggestibleChipsDrawer.directive';

const dependencies = [
  DrawerContainerModule.name,
  MessagesModule.name,
  SuggestOrganizationModule.name,
  OnDisabledModule.name
];

let ChipsDrawerModule = angular
  .module('wc.components.inputs.chipsdrawer', dependencies)
  .directive(ChipsDrawer.NAME, ChipsDrawer)
  .directive(SuggestibleChipsDrawer.NAME, SuggestibleChipsDrawer);

export default ChipsDrawerModule;
