import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../inputs/TextField';

const NameField = ({
  fieldName = '',
  label = '',
  autocomplete = '',
  initialValue,
  updateValue,
  updateError,
  updateTouched,
  touched,
  formSubmitted,
  halfWidth = false
}) => {
  const errorHandler = value => {
    if (!value) {
      return {
        hasError: true,
        errorText: 'This field is required'
      };
    }
    return {
      hasError: false,
      errorText: ''
    };
  };

  const hasError = value => errorHandler(value).hasError;

  const errorText = value => errorHandler(value).errorText;

  return (
    <TextField
      fieldName={fieldName}
      label={label}
      initialValue={initialValue}
      updateValue={updateValue}
      hasError={hasError}
      updateError={updateError}
      errorText={errorText}
      updateTouched={updateTouched}
      touched={touched}
      formSubmitted={formSubmitted}
      autocomplete={autocomplete}
      halfWidth={halfWidth}
    />
  );
};

NameField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autocomplete: PropTypes.string,
  initialValue: PropTypes.string,
  updateValue: PropTypes.func,
  updateError: PropTypes.func,
  updateTouched: PropTypes.func,
  touched: PropTypes.bool,
  formSubmitted: PropTypes.bool,
  halfWidth: PropTypes.bool
};

export default NameField;
