import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

const ActionBar = {
  NAME: 'wcActionBar',
  bindings: {
    isInverse: '<'
  },
  templateUrl: templateUrl,
  transclude: true,
  require: {
    opaqueActionBarViewController: '?^^wcOpaqueActionBarView'
  }
};

export default angular
  .module('wc.components.actionBar.toolbar', [])
  .component(ActionBar.NAME, ActionBar);
