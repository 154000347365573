import angular from 'angular';

import main from './main';
import opaqueActionBarScrollContainer from './opaqueActionBarScrollContainer';
import opaqueActionBarView from './opaqueActionBarView';
import './tableContainer';

export default angular.module('wc.components.actionBarView', [
  main.name,
  opaqueActionBarScrollContainer.name,
  opaqueActionBarView.name
]);
