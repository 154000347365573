import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wc.components.actionBar.opaqueActionBarScrollContainer', [])
  .component('wcOpaqueActionBarScrollContainer', {
    bindings: {
      extraClasses: '@'
    },
    templateUrl: templateUrl,
    transclude: true
  });
