import angular from 'angular';
import { react2angular } from 'react2angular';
import { ParagraphSeparator } from './ParagraphSeparator';

export default angular
  .module('wc.components.separators.paragraphSeparator', [])
  // @ts-ignore
  .component(
    'reactParagraphSeparator',
    react2angular(ParagraphSeparator, ['className'], [])
  );
