import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { isEqual } from 'lodash';

import AutoSuggest from '../../inputs/selects/AutoSuggest';
import poweredByGoogleImage from '../../../../../../assets/img/powered_by_google_on_white.png';

class LocationSelect extends React.Component {
  selectStyles = {
    menu: () => ({
      maxHeight: '288px',
      zIndex: '2'
    }),
    menuList: () => ({
      maxHeight: '288px'
    }),
    menuPortal: () => ({
      maxHeight: '288px'
    }),
    option: (_, { data, isFocused }) => {
      let background = '';
      let backgroundPosition = '';
      if (data.poweredByGoogle) {
        background = `white url(${poweredByGoogleImage}) no-repeat right`;
        backgroundPosition = 'calc(100% - 10px) calc(100% - 10px)';
      } else if (isFocused && !data.noOptionsMessage) {
        background = '#f7f7f7';
      } else {
        background = 'transparent';
      }
      return {
        overflowY: 'hidden',
        height: '48px',
        background,
        backgroundPosition
      };
    }
  };

  noOptionsMessage = {
    label: 'No matches found.',
    value: 'no-matches',
    isDisabled: true,
    noOptionsMessage: true
  };

  poweredByGoogleSuggestion = {
    label: '   ',
    value: '',
    isDisabled: true,
    poweredByGoogle: true
  };

  mounted = false;

  fieldName = 'address';

  state = {
    value: undefined,
    suggestions: [this.noOptionsMessage, this.poweredByGoogleSuggestion],
    error: true,
    touched: false
  };

  componentDidMount() {
    this.mounted = true;
    this.initialCity = undefined;
    const { address } = this.props;

    if (address) {
      this.updateStateFromAddress(address);
      this.initialCity = address.city;
    }

    this.updateError(this.hasError(address));
    this.updateTouched(false);
  }

  componentDidUpdate(prevProps) {
    const { address } = this.props;
    if (!isEqual(prevProps.address, address)) {
      this.initialCity = address ? address.city : undefined;
      this.updateStateFromAddress(address);
      this.updateError(this.hasError(address));
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mySetState = state => {
    /**
     * For some weird (angularjs) reason the component gets
     * re-mounted and unmounted back really fast during
     * registration. The unmounts happens before the API fetch
     * finishes so this is a cheap hacky guard against it.
     *
     * TODO(mihail): this should be removed once we fully migrated
     * to react.
     * An alternative to this would be cancelable promises
     */
    if (this.mounted) {
      this.setState(state);
    }
  };

  updateStateFromAddress = address => {
    this.updateValueFromAddress(address);
    this.updateSuggestionsFromAddress(address);
  };

  updateValueFromAddress = address => {
    const value = address
      ? { label: address.city, value: address.city }
      : undefined;

    this.mySetState({
      value
    });
  };

  updateValueFromCity = city => {
    this.updateValueFromAddress({ city });
  };

  updateSuggestionsFromAddress = address => {
    if (!address) {
      this.mySetState({
        suggestions: [this.noOptionsMessage, this.poweredByGoogleSuggestion]
      });
      return;
    }

    if (!address.city) {
      this.mySetState({
        suggestions: [this.noOptionsMessage, this.poweredByGoogleSuggestion]
      });
      return;
    }

    this.props
      .queryFunction(address.city)
      .then(results => {
        const suggestions = results
          ? results.map(res => {
              return {
                label: res.description,
                value: res.description,
                place_id: res.place_id
              };
            })
          : [this.noOptionsMessage];

        suggestions.push(this.poweredByGoogleSuggestion);
        this.mySetState({
          suggestions
        });
      })
      .catch(() => {
        /* no results */
        this.mySetState({
          suggestions: [this.noOptionsMessage, this.poweredByGoogleSuggestion]
        });
      });
  };

  updateSuggestionsFromCity = city => {
    this.updateSuggestionsFromAddress({ city });
  };

  handleInputChange = value => {
    const newValue = value ? value : this.initialCity;
    this.updateSuggestionsFromCity(newValue);
  };

  handleChange = event => {
    this.props.updateAddress(event.place_id);

    // this is just to avoid showing city + country for a brief second
    const newCity = this.convertDescriptionToCity(event.value);
    this.updateValueFromCity(newCity);
  };

  convertDescriptionToCity = description => {
    const words = description.split(', ');
    return words[0];
  };

  updateError = error => {
    this.props.updateError(this.fieldName, error);
    this.mySetState({ error });
  };

  hasError = value => !value;

  checkForError = () => {
    return this.props.formSubmitted || this.state.touched;
  };

  shouldRenderError = () => {
    return this.checkForError() && this.state.error;
  };

  updateTouched = touched => {
    this.props.updateTouched(this.fieldName, touched);
    this.mySetState({ touched });
  };

  handleBlur = () => this.updateTouched(true);

  render() {
    const selectProps = {
      value: this.state.value,
      filterOption: () => true,
      options: this.state.suggestions,
      onChange: this.handleChange,
      onInputChange: this.handleInputChange,
      onBlur: this.handleBlur,
      validationError: this.shouldRenderError(),
      errorMessage: 'This field is required',
      labelText: 'Town / City*'
    };

    return (
      <AutoSuggest
        selectStyles={this.selectStyles}
        render={defaultProps => <Select {...defaultProps} {...selectProps} />}
      />
    );
  }
}

LocationSelect.propTypes = {
  address: PropTypes.object,
  queryFunction: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  updateTouched: PropTypes.func.isRequired,
  updateError: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired
};

export default LocationSelect;
