import angular from 'angular';

import STATES from '../../../services/States';
import './styles.scss';
import template from './template.html';
import { MISSING_GROUP_PICTURE_BASE64 } from '../../../../common/services/ImageUrls';

class groupInviteNotificationController {
  /*@ngInject*/
  constructor() {
    this.GROUP_STATE = STATES.MEMBER_GROUP;
    this.MEMBER_PROFILE_STATE = STATES.OTHER_MEMBER_PROFILE;
    this.PLACEHOLDER_URL = MISSING_GROUP_PICTURE_BASE64;
  }
}

const groupInviteNotifcationComponent = {
  bindings: {
    notification: '<',
    onAccept: '&',
    onIgnore: '&'
  },
  controller: groupInviteNotificationController,
  templateUrl: template
};

export default angular
  .module('wc.components.notifications.groupInvite', [])
  .component('wcGroupInviteNotification', groupInviteNotifcationComponent);
