import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import { sortPositions } from '../../../../../services/Positions';
import { PositionOverview } from '../../../position/position-overview/PositionOverview';
import { MinimalPositionOverview } from '../../../position/minimal-position-overview/MinimalPositionOverview';

export const MemberProfileCareerHistory = ({
  member,
  isClient,
  CurrencyService,
  CompensationsBlockedService
}) => {
  const [positionsExpanded, setPositionsExpanded] = useState(false);
  const [memberState, setMemberState] = useState(member);

  useEffect(() => {
    const memberTmp = { ...member };
    memberTmp.positions = sortPositions([...memberTmp.positions]);
    setMemberState(memberTmp);
  }, [member]);

  const getRemuneration = position => {
    if (position.current) {
      return memberState.remuneration;
    }
    if (position.totalCompensationCurrency) {
      return position;
    }
  };

  const toggleExpandPositions = () => {
    setPositionsExpanded(state => !state);
  };

  return memberState.positions.length !== 0 ? (
    <article className={`WcMemberProfileContent__WorkHistory`}>
      <h2 className="WcMemberProfileContent__Subheading">Career History</h2>
      <div>
        {memberState.positions.map((position, index) =>
          index === 0 || positionsExpanded ? (
            <div
              key={position.id}
              className="WcMemberProfileContent__PositionOverview"
            >
              <PositionOverview
                position={position}
                profile={member}
                remuneration={getRemuneration(position)}
                isClient={isClient}
                CurrencyService={CurrencyService}
                CompensationsBlockedService={CompensationsBlockedService}
              />
            </div>
          ) : (
            <React.Fragment key={index} />
          )
        )}
        {memberState.positions.length > 1 && (
          <div
            className="WcMemberProfileContent__PositionOverview--Expandable"
            onClick={toggleExpandPositions}
          >
            {!positionsExpanded && (
              <div className="layout-row">
                <MinimalPositionOverview position={memberState.positions[1]} />
              </div>
            )}
            <div
              className={`WcMemberProfileContent__ExpandPositions ${
                !positionsExpanded
                  ? 'WcMemberProfileContent__ExpandPositions--closed'
                  : ''
              }`}
            >
              <div className="WcMemberProfileContent__ExpandPositionsLeftPush" />
              <div className="WcMemberProfileContent__ExpandPositionsButton" />
              <div className="WcMemberProfileContent__ExpandPositionsRightPush" />
            </div>
          </div>
        )}
      </div>
    </article>
  ) : (
    <React.Fragment />
  );
};

MemberProfileCareerHistory.propTypes = {
  member: PropTypes.object,
  isClient: PropTypes.bool,
  CurrencyService: PropTypes.any,
  CompensationsBlockedService: PropTypes.any
};
