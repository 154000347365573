import React from 'react';
import PropTypes from 'prop-types';

import { BooleanChip } from './../../../chips/BooleanChip';
import { ReactChips } from '../../../../../../desktop/components/react/ReactChips/ReactChips';
import { WidthExpander } from '../../../../../../desktop/components/react/width-expander/WidthExpander';

export const MemberProfileAspirations = ({ member }) => {
  return (
    <React.Fragment>
      <article className="Separator">
        {member.aspirations && member.aspirations.goals > 0 && (
          <div>
            <h2 className="WcMemberProfileContent__Subheading">Summary</h2>
            <p className="WcFormattedParagraph">{member.aspirations.goals}</p>
          </div>
        )}

        {member.aspirationalComp && (
          <div>
            <h2 className="WcMemberProfileContent__Subheading">
              Compensation Expectations
            </h2>
            <p>{member.aspirationalComp}</p>
          </div>
        )}

        <div>
          <h2 className="WcMemberProfileContent__Subheading">Relocation</h2>
          <BooleanChip
            title="Would relocate for the right role"
            active={member.openToRelocate}
            falseTitle="Not open to relocate"
          />
        </div>

        <div>
          <h2 className="WcMemberProfileContent__Subheading">Non-Executive</h2>
          <BooleanChip
            title="Interested in Non-Executive opportunities"
            active={member.interestedInNonExec}
            falseTitle="Not interested in Non-Executive opportunities"
          />
        </div>
      </article>

      {member.aspirations &&
        member.aspirations.companies &&
        member.aspirations.companies.length > 0 && (
          <article className="Separator">
            <div>
              <h2 className="WcMemberProfileContent__Subheading">Companies</h2>
              <ReactChips readOnly data={member.aspirations.companies} accent />
            </div>
          </article>
        )}

      {member.aspirations &&
        member.aspirations.cities &&
        member.aspirations.cities.length > 0 && (
          <article className="Separator">
            <div>
              <h2 className="WcMemberProfileContent__Subheading">Cities</h2>
              <ReactChips readOnly data={member.aspirations.cities} accent />
            </div>
          </article>
        )}

      {member.aspirations &&
        member.aspirations.industryStandardJobTitles &&
        member.aspirations.industryStandardJobTitles.length > 0 && (
          <article className="Separator">
            <div>
              <h2 className="WcMemberProfileContent__Subheading">Job Roles</h2>
              <ReactChips
                readOnly
                data={member.aspirations.industryStandardJobTitles}
                accent
              />
            </div>
          </article>
        )}

      {member.aspirations &&
        member.aspirations.industries &&
        member.aspirations.industries.length > 0 && (
          <article className="Separator">
            <div>
              <h2 className="WcMemberProfileContent__Subheading">Industries</h2>
              <ReactChips
                readOnly
                data={member.aspirations.industries}
                accent
              />
            </div>
          </article>
        )}

      <WidthExpander />
    </React.Fragment>
  );
};

MemberProfileAspirations.propTypes = {
  member: PropTypes.object
};
