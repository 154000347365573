import angular from 'angular';

import SidenavIconButton from '../iconbuttons';
import templateUrl from './template.html';

class MessagesLinkController {
  /*@ngInject*/
  constructor(MessengerService) {
    this.MessengerService = MessengerService;
  }
}

const MessagesLink = {
  bindings: {
    uiSref: '@wcUiSref',
    label: '@'
  },
  controller: MessagesLinkController,
  templateUrl: templateUrl
};

export default angular
  .module('wc.components.sidenav.messagesLink', [SidenavIconButton.name])
  .component('wcSidenavMessagesLink', MessagesLink);
