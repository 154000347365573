import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.secondary.A200};
  height: 68px;
`;

const H4 = styled.h4`
  color: ${props => props.theme.palette.common.white};
  font: normal 500 18px/24px ProximaNova, sans-serif;
`;

const PageHeader = ({ heading }) => {
  return (
    <HeadingContainer>
      <H4>{heading}</H4>
    </HeadingContainer>
  );
};

PageHeader.propTypes = {
  heading: PropTypes.string.isRequired
};

export default PageHeader;
