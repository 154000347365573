import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import moment from 'moment';

export const MessageBubbleUser = ({ isLast, message, mergeBottom, sentAt }) => {
  const [sentAtFormatted, setSentAtFormatted] = useState('');

  useEffect(() => {
    if (sentAt) {
      const sentAtFormattedTmp = moment(sentAt).calendar(null, {
        sameDay: 'LT',
        lastDay: '[Yesterday] LT',
        lastWeek: '[Last] dddd LT',
        sameElse: 'DD/MM/YYYY'
      });
      setSentAtFormatted(sentAtFormattedTmp);
    }
  }, [sentAt]);

  return (
    <div className="WcMessageBubbleUserContainer">
      <div
        className={`WcMessageBubbleUser__MessageContainer ${
          !mergeBottom && !isLast
            ? 'WcMessageBubbleUser__MessageContainer__LastMessageInBlurb'
            : ''
        }`}
      >
        <div
          className={`WcMessageBubbleUser ${
            mergeBottom
              ? 'WcMessageBubbleUser--MergeTop'
              : 'WcMessageBubbleUser--MergeBottom'
          }`}
          title={sentAt}
        >
          <p className="WcMessageBubbleUser__Text">{message.body}</p>
        </div>
        {!mergeBottom && (
          <div className="WcMessageBubbleUserTimeStamp--Separator" />
        )}
        {!mergeBottom && (
          <time
            className="WcMessageBubbleUserTimeStamp"
            dateTime={sentAt}
            title={sentAt}
          >
            {sentAtFormatted}
          </time>
        )}
      </div>
    </div>
  );
};

MessageBubbleUser.propTypes = {
  isLast: PropTypes.bool,
  message: PropTypes.object,
  mergeBottom: PropTypes.bool,
  sentAt: PropTypes.any
};
