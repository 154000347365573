/* eslint angular/no-private-call:0 */

class ProgressLoaderService {
  /*@ngInject*/
  constructor($timeout) {
    this.timers = [];
    this.$timeout = $timeout;
    this.activeRequests = 0;
  }

  addRequest() {
    this.timers.push(
      this.$timeout(() => {
        this.activeRequests++;
      }, 400)
    );
  }

  resolveRequest() {
    if (this.timers.length > 0) {
      let timer = this.timers[0];
      if (timer.$$state.status === 1) {
        this.activeRequests--;
      } else {
        this.$timeout.cancel(timer);
      }
      this.timers.splice(0, 1);
    } else {
      this.activeRequests = 0;
    }
  }
}

ProgressLoaderService.NAME = 'ProgressLoaderService';

export default ProgressLoaderService;
