import angular from 'angular';

import SidenavIconButton from '../iconbuttons';
import templateUrl from './template.html';

class NotificationsLinkController {
  /*@ngInject*/
  constructor(NotificationService) {
    this.NotificationService = NotificationService;
  }

  getNotificationsCount() {
    return this.NotificationService.getNotificationsCount();
  }
}

const NotificationsLink = {
  bindings: {
    uiSref: '@wcUiSref',
    label: '@'
  },
  controller: NotificationsLinkController,
  templateUrl: templateUrl
};

export default angular
  .module('wc.components.sidenav.NotificationsLink', [SidenavIconButton.name])
  .component('wcSidenavNotificationsLink', NotificationsLink);
