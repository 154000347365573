import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileCareerHistory } from './OwnMemberProfileCareerHistory';

export default angular
  .module('wc.components.OwnMemberProfileCareerHistory', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileCareerHistory',
    react2angular(
      OwnMemberProfileCareerHistory,
      ['member'],
      [
        'CurrencyService',
        'CompensationsBlockedService',
        'DialogService',
        'API',
        '$state'
      ]
    )
  );
