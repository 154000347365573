import STATES from '../../../services/States';
import { FORM_ERROR_MESSAGE } from '../../../services/FormErrorMessages';

class UploadController {
  /*@ngInject*/
  constructor($scope, $state, API, AuthenticationService, CropDialogService) {
    this.$scope = $scope;
    this.$state = $state;
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.CropDialogService = CropDialogService;
    this.formErrorMessage = FORM_ERROR_MESSAGE;

    this.profilePictureCaptions = {
      READY: 'Upload a profile picture',
      PROCESSING: 'Processing...',
      UPLOADING: 'Uploading...',
      UPLOADED: 'Change profile picture'
    };

    this.API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .catch(this.API.handleError());
  }

  setProfile(profile) {
    this.profile = profile;
    this.currentProfilePicture = this.profile.profilePicture;
  }

  submit() {
    if (this.form.$valid) {
      let patchData = {
        aboutMe: this.profile.aboutMe
      };

      this.API.restangularize(this.profile)
        .patch(patchData)
        // Refresh the AuthenticationService, because isComplete has changed now.
        .then(() => this.AuthenticationService.refresh())
        .then(this.nextState.bind(this))
        .catch(this.API.handleError());
    }
  }

  nextState() {
    this.$state.go(STATES.CLIENT_PROFILE);
  }

  previousState() {
    this.$state.go(STATES.CLIENT_EDIT_PROFILE);
  }

  isCurrentProfilePictureSet() {
    return (
      this.AuthenticationService.isProfileComplete() &&
      this.currentProfilePicture
    );
  }
}

UploadController.controllerAs = 'uploadCtrl';
export default UploadController;
