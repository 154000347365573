export default class NotificationService {
  /*@ngInject*/
  constructor(API, AuthenticationService, $q, $rootScope) {
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$rootScope.$on(
      '$stateChangeSuccess',
      this.getNotifications.bind(this)
    );
    this.notifications = [];
    this.isReadyDeferred = this.$q.defer();
    this.isReady = this.isReadyDeferred.promise;
    this.getNotifications();
  }

  getNotifications() {
    if (this.inProgress === true) {
      return;
    }
    if (!this.AuthenticationService.isLoggedIn()) {
      return;
    }
    if (this.AuthenticationService.isClient()) {
      return;
    }
    this.inProgress = true;
    this.API.Notifications.getList()
      .then(this.setNotifications.bind(this))
      .catch(this.API.handleError());
  }

  setNotifications(notifications) {
    this.notifications = notifications;
    this.inProgress = false;
    this.isReadyDeferred.resolve();
  }

  optimisticRemoveNotification(notification) {
    let notificationIndex = this.notifications.indexOf(notification);
    this.notifications.splice(notificationIndex, 1);
  }

  listNotifications() {
    return this.notifications;
  }

  deleteGroupNotification(groupId, notificationType) {
    let groupNotifications = this.listNotifications().filter(
      x =>
        x.metadata.group.id === groupId &&
        x.notificationType === notificationType
    );
    // Nothing to do if empty
    if (groupNotifications.length === 0) {
      return;
    }
    // Otherwise delete them all and remove them from the notification bar
    groupNotifications.map(this.deleteAndRemoveNotification.bind(this));
  }

  getNotificationsCount() {
    if (this.notifications) {
      return this.notifications.length;
    }
  }

  deleteNotification(notification) {
    this.API.Notifications.one(notification.id.toString())
      .remove()
      .catch(this.handleError.bind(this));
  }

  deleteAndRemoveNotification(notification) {
    this.deleteNotification(notification);
    this.optimisticRemoveNotification(notification);
  }

  handleError(error) {
    this.API.handleError()(error);
  }
}

NotificationService.NAME = 'NotificationService';
