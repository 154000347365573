import angular from 'angular';

/* @ngInject */
function onRequired() {
  return (attributes, onRequired, onNotRequired) => {
    function callRequiredHandlers() {
      if ('required' in attributes) {
        if (angular.isFunction(onRequired)) {
          onRequired();
        }
      } else {
        if (angular.isFunction(onNotRequired)) {
          onNotRequired();
        }
      }
    }

    attributes.$observe('required', callRequiredHandlers);
  };
}

onRequired.NAME = 'dfOnRequired';

export default angular
  .module('dfComponents.inputs.behaviours.dfOnRequired', [])
  .factory(onRequired.NAME, onRequired);
