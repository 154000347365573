import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

const EditButton = {
  NAME: 'wcEditButton',
  bindings: {
    ariaLabel: '@ariaLabel',
    onClick: '&wcOnClick'
  },
  templateUrl: templateUrl
};

export default angular
  .module('wc.components.buttons.editbutton', [])
  .component(EditButton.NAME, EditButton);
