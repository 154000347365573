import angular from 'angular';

const MiniLabel = {
  NAME: 'wcMiniLabel',
  template: '<label class="WcMiniLabel" ng-transclude></label>',
  transclude: true
};

export default angular
  .module('wc.components.typography.miniLabel', [])
  .component(MiniLabel.NAME, MiniLabel);
