import angular from 'angular';

import AspirationsModule from './tabs/AboutYou/AboutYou.module';
import ChipsDrawer from '../../../components/inputs/expandables/chipsdrawer/ChipsDrawer.module';
import ExperienceModule from './tabs/Experience/Experience.module';
import FormsModule from '../../../components/forms';
import MembersRegistrationController from './Registration.controller';
import MembersRegistrationRouting from './Registration.routing';
import previousButtonWithConfirmation from '../../../components/buttons/previousbuttonwithconfirmation';
import WcChips from '../../../components/inputs/wc-chips';

const moduleDependencies = [
  AspirationsModule.name,
  ChipsDrawer.name,
  ExperienceModule.name,
  FormsModule.name,
  previousButtonWithConfirmation.name,
  WcChips.name
];

let MembersRegistrationModule = angular
  .module('wc.members.registration', moduleDependencies)
  .controller(MembersRegistrationController.NAME, MembersRegistrationController)
  .config(MembersRegistrationRouting);

export default MembersRegistrationModule;
