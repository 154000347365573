class ClientCompensationDialog {
  /*@ngInject*/
  constructor(DialogService) {
    this.DialogService = DialogService;
  }

  showDisableCompensationConfirmDialog() {
    let locals = {
      ariaLabel: 'Hide Compensation',
      content:
        'Hiding compensation data means you will not be able to view these details, even if the user has chosen to share them with you. To reverse this action, you will need to contact Winter Circle directly.<br/><br/> Do you want to continue?',
      okAction: 'Continue',
      title: 'Hide Compensation'
    };

    return this.DialogService.showConfirmDialog(locals);
  }

  showEnableCompensationConfirmDialog() {
    const locals = {
      title: 'Display Compensation',
      content:
        'Compensation data is currently hidden. To change this setting, please contact Winter Circle.',
      okAction: 'OK'
    };
    return this.DialogService.showAlertDialog(locals);
  }
}

ClientCompensationDialog.NAME = 'ClientCompensationDialogService';

export default ClientCompensationDialog;
