import React from 'react';
import PropTypes from 'prop-types';
import BaseSwitch from '@material-ui/core/Switch';
import styled, { ThemeProvider } from 'styled-components';

import theme from '../../../../../common/styles/theme';

// eslint-disable-next-line no-unused-vars
const StyledSwitch = styled(({ loading, ...props }) => (
  <BaseSwitch {...props} />
))`
  opacity: ${props => (props.loading ? '.5' : '1')};

  .PrivateSwitchBase-input-15 {
    cursor: ${props => (props.loading ? 'wait' : 'grab')};
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${props => props.theme.extraColors.switch};
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.extraColors.switch};
  }

  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: ${props => props.theme.extraColors.switchTransparent};
  }
`;

const Switch = ({ checked, onChange, loading = false, disabled = false }) => {
  return (
    <ThemeProvider theme={theme.palette}>
      <StyledSwitch
        loading={loading}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        inputProps={{ 'aria-label': 'checkbox' }}
      />
    </ThemeProvider>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Switch;
