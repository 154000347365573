import styled from 'styled-components';

const FormError = styled.div`
  color: ${props => props.theme.palette.error.A700};
  font-size: 14px;
  text-align: right;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: opacity 0.5s ease-in;
  line-height: 1.3;
  ${props =>
    props.show &&
    `
    height: auto;
    visibility: visible;
    opacity: 1;
  `}
`;

export default FormError;
