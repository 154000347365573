import angular from 'angular';
import templateUrl from './template.html';

const Container = {
  templateUrl: templateUrl,
  transclude: {
    tabs: 'wcAnchorNavigationTabsSlot',
    content: 'wcAnchorNavigationContentSlot'
  }
};

export default angular
  .module('wc.components.anchornavigation.container', [])
  .component('wcAnchorNavigationContainer', Container);
