import TimePeriodGroups from '../TimePeriodGroups';

export default class TimePeriodSelectorCtrl {
  constructor() {
    this._initializeMenuItems();
  }

  _initializeMenuItems() {
    if (this.timePeriodSelectorOptions) {
      this.menuItems = this.timePeriodSelectorOptions.toJS();
    } else {
      this.menuItems = TimePeriodGroups.LISTS.get('ALL').toJS();
    }
  }
}
