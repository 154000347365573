class GroupDialogController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
    this.selectedGroups = [];
  }

  noSelectedGroups() {
    return !this.selectedGroups.filter(group => group !== null).length;
  }

  submit() {
    const selectedGroupIds = this.selectedGroups.filter(
      groupId => groupId !== null
    );
    // Return the actual group objects; Using the group objects in the ng-model does not work, as there is a
    // hidden copy operation somewhere that removes restangular methods.
    const selectedGroups = this.groups.filter(
      group => selectedGroupIds.indexOf(group.id) > -1
    );
    this.$mdDialog.hide(selectedGroups);
  }
}

GroupDialogController.controllerAs = '$ctrl';

export default GroupDialogController;
