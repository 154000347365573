import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Switch from '../../profile/basics/Switch';

export const EmailPreferences = ({
  emailPreferences,
  hideUnsubscribe,
  onChange
}) => {
  const [allSubscribed, setAllSubscribed] = useState(false);
  const [preferences, setPreferences] = useState(emailPreferences);
  const [isOneOrMoreSubsribed, setIsOneOrMoreSubsribed] = useState(false);

  useEffect(() => {
    setAllSubscribed(
      preferences.interests.every(preference => preference.status === true)
    );
    setIsOneOrMoreSubsribed(
      preferences.interests.some(preference => preference.status === true)
    );
  }, [preferences]);

  const subscribeAll = () => {
    const interestsTmp = preferences.interests.map(interest => ({
      ...interest,
      status: true
    }));
    const preferencesTmp = { ...emailPreferences, interests: interestsTmp };
    setPreferences(preferencesTmp);
    onChange({ emailPreferences: preferencesTmp });
  };

  const changePreferenceStatus = name => {
    const interestsTmp = preferences.interests.map(interest => {
      if (interest.name === name) {
        interest.status = !interest.status;
      }
      return interest;
    });
    const preferencesTmp = { ...emailPreferences, interests: interestsTmp };
    setPreferences(preferencesTmp);
    onChange({ emailPreferences: preferencesTmp });
  };

  const unsubscribe = () => {
    const interestsTmp = preferences.interests.map(interest => ({
      ...interest,
      status: false
    }));
    const preferencesTmp = { ...emailPreferences, interests: interestsTmp };
    setPreferences(preferencesTmp);
    onChange({ emailPreferences: preferencesTmp });
  };

  return (
    <div className="Settings__NotificationTab">
      <p className="SettingsExplanation">
        Please choose which updates you would like to receive from us so we can
        tailor your content to your preferences.
      </p>
      {!hideUnsubscribe && (
        <div className="NotificationSettings__Row">
          <div className="NotificationSettings__Name EmailPreferences__SubscribeAllTitle">
            Subscribe to all Winter Circle Updates
          </div>
          <span className="flex" />
          <Switch
            disabled={allSubscribed}
            checked={allSubscribed}
            aria-label="Subscribe to all Winter Circle Updates"
            onChange={subscribeAll}
          />
        </div>
      )}
      {preferences &&
        preferences.interests &&
        preferences.interests.map(preference => (
          <div className="NotificationSettings__Row" key={preference.name}>
            <div>
              <div className="NotificationSettings__Name">
                {preference.name}
              </div>
              {preference.description && (
                <div className="NotificationSettings__Description">
                  {preference.description}
                </div>
              )}
            </div>
            <span className="flex" />
            <Switch
              checked={preference.status}
              aria-label={preference.name}
              onChange={() => changePreferenceStatus(preference.name)}
            />
          </div>
        ))}
      {!hideUnsubscribe && (
        <div className="NotificationSettings__Row">
          <div className="NotificationSettings__Name EmailPreferences__UnsubscribeTitle">
            Opt-out of all Winter Circle Updates
          </div>
          <span className="flex" />

          <Switch
            checked={isOneOrMoreSubsribed}
            aria-label="Opt-out of all Winter Circle Updates"
            onChange={unsubscribe}
          />
        </div>
      )}
    </div>
  );
};

EmailPreferences.propTypes = {
  emailPreferences: PropTypes.object,
  hideUnsubscribe: PropTypes.bool,
  onChange: PropTypes.func
};
