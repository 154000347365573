import backButton from './BackButton.html';

export default angular
  .module('wc.components.buttons.backbutton', [])
  .component('backbutton', {
    templateUrl: backButton,
    bindings: {
      toState: '@'
    }
  });
