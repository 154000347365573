import angular from 'angular';
import template from './template.html';
import { Actions as ProfilesActions } from '../../../../common/redux/profiles/actions';

import STATES from '../../../services/States';

const MapStateToProps = state => ({
  profile: state.profilesReducer.myProfile
});

const MapDispatchToProps = dispatch => ({
  updateMyProfile: myProfile =>
    ProfilesActions.updateMyProfile(dispatch, myProfile)
});

class RegionsManagedController {
  /*@ngInject*/
  constructor(API, $state, $ngRedux) {
    this.API = API;
    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.toResolve = 1;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(
      MapStateToProps,
      MapDispatchToProps
    )(this);

    this.API.retrieveMyProfile()
      .then(profile => {
        this.updateMyProfile(profile);
        this.toResolve--;
      })
      .catch(this.handleError.bind(this));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  cancel() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'regional-management'
    });
  }

  submit() {
    if (this.form.$valid) {
      this.toResolve++;
      let regionsPatch = {
        regionsManaged: this.profile.regionsManaged.map(region => region.id)
      };
      this.updateMyProfile({
        regionsManaged: this.regionsManaged
      });
      this.API.restangularize(this.profile)
        .patch(regionsPatch)
        .then(this.handleSucces.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleSucces() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'regional-management'
    });
  }

  handleError(error) {
    this.toResolve = 0;
    this.API.handleError()(error);
  }

  isLoaded() {
    return this.toResolve === 0;
  }
}

const wcInlineEditRegionsManaged = {
  controller: RegionsManagedController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.regionsManaged', [])
  .component('wcInlineEditRegionsManaged', wcInlineEditRegionsManaged);
