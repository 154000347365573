import angular from 'angular';

import './styles.scss';

import reactOwnMemberProfileActions from './../../react/profile/profile-actions/own-member-profile-actions';
import reactClientMemberProfileActions from './../../react/profile/profile-actions/client-member-profile-actions';
import reactOtherMemberProfileActions from './../../react/profile/profile-actions/other-member-profile-actions';

export default angular.module('wc.components.profile.profileActions', [
  reactOwnMemberProfileActions.name,
  reactClientMemberProfileActions.name,
  reactOtherMemberProfileActions.name
]);
