import angular from 'angular';

import TimePeriodModel from '../components/timeperiodinputs/TimePeriodModel';
import apiModule from '../../common/services/api/API.module';

const PAGINATION_LIMIT = 50;
const API_KEYWORDS = ['limit', 'offset', 'ordering'];

export const initialSearchOrdering = { field: 'relevance', reverse: false };

/*@ngInject*/
function MemberListFactory($log, $rootScope, $q, API) {
  class MemberList {
    /*@ngInject*/
    constructor(endpoint) {
      this.$rootScope = $rootScope;
      this.$q = $q;

      this.activeFilterCount = 0;
      this.endpoint = endpoint;
      this.filters = {};
      this.isPending = true;
      this.members = [];
      this.ordering = Object.assign({}, initialSearchOrdering);

      // Use null to differentiate "no results" from "not loaded yet"
      this.filteredResultsCount = null;
      this.totalResultsCount = null;

      // Bind these so that they can be made public API without `this` issues arising
      this.clearFilters = this.clearFilters.bind(this);
      this.flipOrdering = this.flipOrdering.bind(this);
      this.hasNoMatchForFilters = this.hasNoMatchForFilters.bind(this);
      this.isAlwaysEmpty = this.isAlwaysEmpty.bind(this);
      this.isEmpty = this.isEmpty.bind(this);
      this.loadNextMembers = this.loadNextMembers.bind(this);
      this.setOrderingField = this.setOrderingField.bind(this);
      this.setOrdering = this.setOrdering.bind(this);

      // Do not trigger changes on $digests during initial loading
      this.$rootScope.$watch(
        () => this.filters,
        (newValue, oldValue) => {
          if (!angular.equals({}, oldValue) && newValue !== oldValue) {
            if (
              !angular.equals(
                this._getAPIFilters(oldValue),
                this._getAPIFilters(newValue)
              )
            ) {
              this._onFilterUpdate();
            }
          }
        },
        true
      );

      this._previousFilters = null;
      this._outstandingSearches = {};
      this._nextOutstandingSearchID = 1;
    }

    /* Public API */

    /**
     *
     * @returns True if the current set of filters does not match any member
     *
     * @memberOf MemberList
     */
    hasNoMatchForFilters() {
      return this.filteredResultsCount === 0 && this.activeFilterCount > 0;
    }

    /**
     *
     * Could be used to detect a fresh Longlist, or other cases where there are no members (even before filtering).
     *
     * @returns `true` if member list is always empty, regardless of filters.
     *
     * @memberOf MemberList
     */
    isAlwaysEmpty() {
      return this.totalResultsCount === 0;
    }

    clearFilters() {
      this.filters = {};
    }

    flipOrdering() {
      this.ordering.reverse = !this.ordering.reverse;
      this._updateOrderingFilter();
    }

    /**
     *
     * @returns `true` if list of members is currently empty for any reason, whether due to filtering or having no
     * members in the first place.
     *
     * @memberOf MemberList
     */
    isEmpty() {
      return this.members.length === 0;
    }

    /*
     * Request the next "page" of Members from the API.
     */
    loadNextMembers() {
      if (
        this.members.length >= this.filteredResultsCount ||
        this.nextPageIsLoading
      ) {
        // Nothing else to load, or next page is still loading
        return;
      }

      this.nextPageIsLoading = true;

      return this._loadSearchResultsFrom(this.members.length)
        .then(this._onLoadNextPageSuccess.bind(this))
        .catch(() => {});
    }

    /**
     Currently it is possible to update the members in another way than this service.
     In that case, we need to be able to update the contents of the members in the service
     to be up to date (e.g. members were added to a longlist from search, and we navigate to
     the longlist)
     **/
    refresh() {
      return this._loadSearchResultsFrom(0, true)
        .then(this._onLoadResultsSuccess.bind(this))
        .catch(() => {});
    }

    setOrderingField(field) {
      this.ordering.field = field;
      this._updateOrderingFilter();
    }

    setOrderingReverse(bool) {
      this.ordering.reverse = bool;
      this._updateOrderingFilter();
    }

    setOrdering(ordering) {
      this.ordering = ordering;
      this._updateOrderingFilter();
    }

    addMembers(members, listId) {
      this.isPending = true;

      let promises = [];
      members.forEach(member => {
        promises.push(
          API.LonglistMember(listId, member.userId)
            .put()
            .catch(API.handleError())
        );
      });

      return this.$q.all(promises).then(() => {
        this.isPending = false;
      });
    }

    removeMembers(userIds) {
      this.isPending = true;

      let promises = [];
      userIds.forEach(userId => {
        promises.push(
          API.LonglistMember(this.longlistId, userId)
            .remove()
            .catch(API.handleError())
        );
      });

      this.$q.all(promises).then(() => {
        const previousLength = this.members.length;

        this.members = this.members.filter(member => {
          return !userIds.includes(member.userId);
        });

        // mathz
        const numRemovedMembers = previousLength - this.members.length;
        this.filteredResultsCount =
          this.filteredResultsCount - numRemovedMembers;
        this.totalResultsCount = this.totalResultsCount - numRemovedMembers;

        this.isPending = false;
        this.$rootScope.$broadcast('membersUpdated');
      });
    }

    /* Private methods */

    _loadSearchResultsFrom(offset = 0, forceRefresh = false) {
      // To avoid race conditions, we only allow one outstanding search promise at a time.
      //
      // This is a bit dodgy, and an excellent use case for RxJS instead of the current combo of
      // $rootScope.$watch + low-level imperative bs

      Object.keys(this._outstandingSearches).forEach(key => {
        this._outstandingSearches[key].cancelled = true;
        this._outstandingSearches[key].timeout.resolve();
      });

      const allRequestsCancelled = Object.keys(this._outstandingSearches).every(
        key => {
          return this._outstandingSearches[key].cancelled;
        }
      );

      const filters = this._getAPIFilters(this.filters);

      const outstandingSearch = {
        cancelled: false,
        timeout: this.$q.defer(),
        deferred: this.$q.defer(),
        filters: Object.assign({}, filters)
      };

      const id = this._nextOutstandingSearchID;
      this._outstandingSearches[id] = outstandingSearch;
      this._nextOutstandingSearchID++;

      // De-dupe requests
      // I think this could be solved with an RxJS one-liner! ;)
      // http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html#instance-method-distinctUntilChanged

      if (
        !forceRefresh &&
        this._previousFilters !== null &&
        angular.equals(this._previousFilters, filters) &&
        !allRequestsCancelled
      ) {
        return outstandingSearch.deferred.promise;
      }

      this._previousFilters = angular.copy(filters);
      const paginationOptions = { limit: PAGINATION_LIMIT, offset: offset };

      this.endpoint
        .withHttpConfig({ timeout: outstandingSearch.timeout.promise })
        .getList(Object.assign(filters, paginationOptions))
        .then(members => {
          if (outstandingSearch.cancelled) {
            outstandingSearch.deferred.reject();
          } else {
            outstandingSearch.deferred.resolve(members);
          }
          delete this._outstandingSearches[id];
        })
        .catch(() => {});

      return outstandingSearch.deferred.promise;
    }

    _onFilterUpdate() {
      this.isPending = true;
      this._loadSearchResultsFrom(0)
        .then(members => {
          this._onLoadResultsSuccess(members);
        })
        .catch(() => {});
    }

    _onLoadResultsSuccess(members) {
      this.members = members.data;
      this._onUpdateMembers(members);
      return members;
    }

    _onLoadNextPageSuccess(members) {
      this.nextPageIsLoading = false;
      this.members.push(...members.data);
      this._onUpdateMembers(members);
    }

    _onUpdateMembers(members) {
      const resultsCount = parseInt(members.headers('count'), 10);

      if (this.activeFilterCount === 0) {
        this.totalResultsCount = resultsCount;
      }

      this.filteredResultsCount = resultsCount;
      this.isPending = false;
      this.$rootScope.$broadcast('membersUpdated');
    }

    _updateOrderingFilter() {
      this.filters.ordering = this.ordering.reverse
        ? `-${this.ordering.field}`
        : this.ordering.field;
    }

    /*
     * Set this.activeFilterCount to the number of filters with a valid value. This involves throwing away
     * non-filter-related query params like `ordering` or `offset`.
     */
    _updateFilterCount(processedFilters) {
      let filters = Object.assign({}, processedFilters);
      API_KEYWORDS.forEach(keyword => delete filters[keyword]);
      this.activeFilterCount = Object.keys(filters).length;
    }

    /*
     * Private functions to process filters object. This is a pretty ugly pipeline, could use work.
     */

    _getAPIFilters(filters) {
      filters = angular.copy(filters);
      filters = this._convertTimePeriodFilters(filters);
      filters = this._convertMultiValueFilters(filters);
      filters = this._pruneFilters(filters);

      this._updateFilterCount(filters);

      return filters;
    }

    /*
     * Beware: mutates input filters! Only intended to be called by _getAPIFilters.
     */
    _convertTimePeriodFilters(filters) {
      angular.forEach(filters, (filter, key) => {
        if (TimePeriodModel.isTimePeriodModel(filter)) {
          const transformedQueryParams = this._convertTimePeriodModel(
            key,
            filter
          );
          delete filters[key];
          Object.assign(filters, transformedQueryParams);
        }
      });

      return filters;
    }

    _convertMultiValueFilters(filters) {
      angular.forEach(filters, (filterValue, key) => {
        // Hacky type inference
        //
        // If the filter value is an array of objects, assume it came from a
        // multi-value typeahead
        if (angular.isArray(filterValue) && angular.isObject(filterValue[0])) {
          if (
            filterValue.length &&
            filterValue[0].detail &&
            filterValue[0].detail.geometry
          ) {
            // Hacky type inference part 2...
            //
            // If the multi-value filter objects have a detail.geometry property,
            // assume it's a location filter! Extract coords.

            filters[key] = filterValue.map(obj => {
              const lat = obj.detail.geometry.location.lat();
              const lng = obj.detail.geometry.location.lng();
              const radius = obj.radius;

              return `${lat},${lng},${radius}`;
            });
          } else if (filterValue[0].id && Number.isInteger(filterValue[0].id)) {
            // If the filter values are objects with integer IDs, assume the
            // values are objects from our own DB. Extract the IDs.

            filters[key] = filterValue.map(obj => obj.id);
          } else {
            // If the filter value looks different, assume it's dodgy and throw it
            // away to prevent making bad requests.

            delete filters[key];
          }
        }
      });

      return filters;
    }

    /*
     * Beware: mutates input filters! Only intended to be called by _getAPIFilters.
     */
    _pruneFilters(filters) {
      angular.forEach(filters, (filter, key) => {
        if (angular.isUndefined(filter) || filter.length === 0) {
          delete filters[key];
        }
      });

      return filters;
    }

    _convertTimePeriodModel(key, timePeriodModel) {
      /*
       * eg. key             = 'internal_job_titles'
       *     timePeriodModel = {value: 'svp', timePeriods: ['past', 'current']}
       *
       *     returns {'current_past_internal_job_titles': 'svp'}
       */

      let filter = {};
      const timePeriod = timePeriodModel.stringifyTimePeriod();

      let value;
      if (
        angular.isArray(timePeriodModel.value) &&
        timePeriodModel.value[0] &&
        !timePeriodModel.value[0].detail
      ) {
        value = timePeriodModel.value.map(x => x.id);
      } else {
        value = timePeriodModel.value;
      }

      filter[`${timePeriod}_${key}`] = value;
      return filter;
    }
  }

  return function createMemberList(...args) {
    return new MemberList(...args);
  };
}

export default angular
  .module('wc.services.memberLists.memberList', [apiModule.name])
  .factory('createMemberList', MemberListFactory);
