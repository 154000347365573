import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileEducation } from './OwnMemberProfileEducation';

export default angular
  .module('wc.components.OwnMemberProfileEducation', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileEducation',
    react2angular(OwnMemberProfileEducation, ['member'], ['$state'])
  );
