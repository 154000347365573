import WcButtonNextTemplateUrl from './WcButtonNext.html';

let WcButtonNext = () => {
  return {
    restrict: 'E',
    templateUrl: WcButtonNextTemplateUrl,
    scope: {
      label: '@?',
      ngDisabled: '='
    }
  };
};

WcButtonNext.NAME = 'wcButtonNext';

export default WcButtonNext;
