import STATES from '../../../services/States';

import SearchTemplateUrl from './Search.html';
import SearchController from './Search.controller';

let SearchRouting = $stateProvider => {
  $stateProvider.state(STATES.SEARCH, {
    url: '/search',
    templateUrl: SearchTemplateUrl,
    controller: SearchController,
    controllerAs: SearchController.controllerAs,
    params: {
      longlistId: null
    }
  });
};

SearchRouting.$inject = ['$stateProvider'];

export default SearchRouting;
