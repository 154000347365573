import React from 'react';
import PropTypes from 'prop-types';
import STATES from '../../../../../../../services/States';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import { WidthExpander } from '../../../../../width-expander/WidthExpander';
import { QAndA } from '../../../../../../../../common/components/react/q-and-a/QAndA';
import { ImageBubbleList } from '../../../../../../../../common/components/react/image-bubble-list/ImageBubbleList';

export const OwnMemberProfileInsights = ({ member, $state }) => {
  const editInsightsHobbies = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.INSIGHTS.HOBBIES);
  };

  const editInsightsQA = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.INSIGHTS.QA);
  };

  return (
    <React.Fragment>
      <article className="Separator">
        <div className="WcEditArticle" id="insights-q-and-a">
          <MemberProfileEditContainer onEdit={editInsightsQA}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">
                Questions & Answers
              </h2>
              <div>
                {member.answers.map((answer, index) =>
                  answer.text && answer.text.length ? (
                    <QAndA
                      key={answer.id}
                      question={answer.questionText}
                      answer={answer.text}
                      separator={index !== 0}
                    />
                  ) : (
                    <React.Fragment key={index} />
                  )
                )}
              </div>
            </div>
          </MemberProfileEditContainer>
        </div>
      </article>
      <article className="Separator">
        <div className="WcEditArticle" id="insights-hobbies">
          <MemberProfileEditContainer onEdit={editInsightsHobbies}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">
                Hobbies &amp; Interests
              </h2>
              <ImageBubbleList
                data={member.hobbies}
                sourceField="image"
                captionField="name"
              />
            </div>
          </MemberProfileEditContainer>
        </div>
      </article>
      <WidthExpander />
    </React.Fragment>
  );
};

OwnMemberProfileInsights.propTypes = {
  member: PropTypes.object,
  $state: PropTypes.any
};
