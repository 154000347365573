import angular from 'angular';

import './styles.scss';

class TabsController {
  /*@ngInject*/
  constructor($attrs, $element, $document, $rootScope, $timeout, $window) {
    this.$element = $element;
    this.$document = $document;
    this.$timeout = $timeout;
    this.$window = $window;

    let resizeTimer;
    $($window).resize(() => {
      clearTimeout(resizeTimer);
      resizeTimer = $timeout(this.resizeToolbar.bind(this), 100);
    });

    $timeout(this.resizeToolbar.bind(this));

    this.navigationItems = [];
    this.$rootScope = $rootScope; // Ugly $rootScope here, but AnchorNavigationItem.$scope is not a child of AnchorNavigation.$scope
  }

  $onInit() {
    this.containerCtrl.registerListener(this.resizeToolbar.bind(this));
  }

  resizeToolbar(containerHeight, containerScrollTop) {
    const profileBoundingBox = $(this.$document).find('#profileBoundingBox')[0];

    if (angular.isUndefined(profileBoundingBox)) {
      return;
    }

    const targetBoundingRect = profileBoundingBox.getBoundingClientRect();

    if (!this.isSticky) {
      this.offsetHeight = this.$element[0].offsetTop;
    }

    if (containerScrollTop >= this.offsetHeight) {
      if (!this.isSticky) {
        const dummyElement = `<div id="stickyToolbarDummy" style="height: ${$(
          this.$element
        )
          .children()
          .eq(0)
          .outerHeight(true)}px;"></div>`;
        this.isSticky = true;
        this.$element.after(dummyElement);
      }

      $(this.$element).css({
        position: 'absolute',
        top: '0',
        width: `${targetBoundingRect.width}px`
      });
      $(this.$element).addClass('WcAnchorNavSticky');
    } else {
      if (!this.isSticky) {
        return;
      }

      $('#stickyToolbarDummy').detach();
      this.isSticky = false;
      $(this.$element).css({
        left: '',
        position: '',
        top: '',
        width: ''
      });
      $(this.$element).removeClass('WcAnchorNavSticky');
    }
  }

  setActiveItem(activeItem) {
    this.activeItem = activeItem;
    this.$rootScope.$broadcast('activeItemChanged', this.activeItem);
  }

  getItemById(id) {
    return this.navigationItems.find(n => n.id === id);
  }

  removeItemById(id) {
    let existingItem = this.getItemById(id);
    if (existingItem) {
      this.navigationItems.splice(
        this.navigationItems.indexOf(existingItem),
        1
      );
    }
  }

  registerNavigationItem(id) {
    // Ensure no duplicates
    this.removeItemById(id);

    let element = $(`#${id}`);
    this.navigationItems.push({
      id: id,
      element: element
    });
  }
}

const Tabs = {
  name: 'tabs',
  controller: TabsController,
  require: {
    containerCtrl: '^^wcStickyElementContainer'
  },
  template: `
    <div class="WcTabs"><div class="WcTabs__Container" ng-transclude></div></div>
  `,
  transclude: true
};

export default angular
  .module('wc.components.tabs', [])
  .component(Tabs.name, Tabs);
