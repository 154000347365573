import React from 'react';
import PropTypes from 'prop-types';
import { ActionBarSeparator } from '../../../action-bar/separator/Separator';
import './styles.scss';

export const OrderingDropdown = ({
  sortOptionDefaultValue,
  sortOptions,
  sortOptionChange,
  reversible,
  reverseOrdering,
  onFlipOrdering,
  $scope
}) => {
  return (
    <div className="wc-member-list-ordering-dropdown">
      <div className="md-input-container">
        <select
          className="WcOrderingDropdown"
          onChange={event => {
            sortOptionChange(event.target.value);
            $scope.$apply();
          }}
          defaultValue={
            sortOptionDefaultValue && sortOptionDefaultValue.sortingKey
          }
        >
          {sortOptions &&
            sortOptions.length > 0 &&
            sortOptions.map((sortOption, sortOptionIndex) => {
              return (
                <option
                  key={sortOptionIndex}
                  className="WcActionBarDropdown__Option"
                  value={sortOption.sortingKey}
                >
                  Sort by {sortOption.name.toLowerCase()}
                </option>
              );
            })}
        </select>
      </div>
      {reversible && (
        <React.Fragment>
          <ActionBarSeparator />
          <button
            className="md-icon-button WcOrderingDropdown__ToggleButtons"
            onClick={() => {
              onFlipOrdering();
              $scope.$apply();
            }}
            aria-label="Ordering Direction"
          >
            <span
              className={`WcOrderingDropdown__ToggleIcon zmdi zmdi-caret-up zmdi-hc-2x ${
                reverseOrdering
                  ? 'WcOrderingDropdown__ToggleIcon--Disabled'
                  : ''
              }`}
              title="Ascending"
              aria-label="Ascending"
            />
            <span
              className={`WcOrderingDropdown__ToggleIcon zmdi zmdi-caret-down zmdi-hc-2x ${
                !reverseOrdering
                  ? 'WcOrderingDropdown__ToggleIcon--Disabled'
                  : ''
              }`}
              aria-label="Descending"
              title="Descending"
            />
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

OrderingDropdown.propTypes = {
  sortOptionDefaultValue: PropTypes.object,
  sortOptions: PropTypes.array,
  sortOptionChange: PropTypes.func,
  reversible: PropTypes.bool,
  reverseOrdering: PropTypes.bool,
  onFlipOrdering: PropTypes.func,
  $scope: PropTypes.object
};
