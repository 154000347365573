import React from 'react';
import './styles.scss';
import { Icon } from '../../../icon/Icon';

export const MessengerSendButton = () => {
  return (
    <button className="WcMessengerSendButton" type="submit">
      <Icon icon="send" />
    </button>
  );
};
