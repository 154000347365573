import angular from 'angular';

import RelatedFormDirective from './RelatedForm.directive';

const moduleDependencies = [];

let RelatedFormModule = angular
  .module('wc.components.relatedForm', moduleDependencies)
  .directive(RelatedFormDirective.NAME, RelatedFormDirective);
export default RelatedFormModule;
