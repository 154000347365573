import angular from 'angular';
import template from './template.html';
import './styles.scss';

import STATES from '../../../services/States';
import {
  sortPositions,
  sortNonExecPositions
} from '../../../../common/services/Positions';

class PositionsListController {
  /*@ngInject*/
  constructor(API, $state, DialogService) {
    this.API = API;
    this.$state = $state;
    this.DialogService = DialogService;
    this.loading = true;
  }

  $onInit() {
    this.headerTitle = (this.nonExec ? 'Non-' : '') + 'Executive Positions';
    this.addButtonText =
      'Another' + (this.nonExec ? ' Non-' : ' ') + 'Executive Position';
    this.scrollToSection = this.nonExec ? 'non-exec-positions' : 'career';

    this.getPositions();
  }

  getPositions() {
    if (!this.nonExec) {
      this.API.Positions.getList()
        .then(this.setPositions.bind(this))
        .catch(this.handleError.bind(this));
    } else {
      this.API.NonExecPositions.getList()
        .then(this.setPositions.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  setPositions(positions) {
    if (!this.nonExec) {
      this.positions = sortPositions(positions);
    } else {
      this.positions = sortNonExecPositions(positions);
    }
    this.loading = false;
  }

  addPosition() {
    if (this.nonExec) {
      return this.$state.go(STATES.MEMBER_INLINE_EDIT.NON_EXEC_POSITION, {
        position: {}
      }); // send empty position object to overwrite the previous stateparam. Silly ui-router!
    }
    this.$state.go(STATES.MEMBER_INLINE_EDIT.EXEC_POSITION, { position: {} });
  }

  editPosition(position) {
    if (this.nonExec) {
      return this.$state.go(STATES.MEMBER_INLINE_EDIT.NON_EXEC_POSITION, {
        position: position
      });
    }
    this.$state.go(STATES.MEMBER_INLINE_EDIT.EXEC_POSITION, {
      position: position
    });
  }

  deletePosition(position) {
    const locals = {
      title: 'Are you sure?',
      content:
        `Deleting this position cannot be undone.<br>` +
        `Do you want to proceed?`,
      okAction: 'Delete',
      cancelAction: 'Cancel'
    };

    this.DialogService.showConfirmDialog(locals).then(() => {
      this.loading = true;
      let method;
      if (this.nonExec) {
        method = this.API.NonExecPosition(position.id);
      } else {
        method = this.API.Position(position.id);
      }
      method
        .remove()
        .then(this.handleRemove.bind(this, position))
        .catch(this.handleError.bind(this));
    });
  }

  handleRemove(position) {
    let positionIndex = this.positions.indexOf(position);
    this.positions.splice(positionIndex, 1);
    this.loading = false;
  }

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

const wcInlineEditPositionsList = {
  controller: PositionsListController,
  templateUrl: template,
  bindings: {
    nonExec: '<?'
  }
};

export default angular
  .module('wc.components.inlineEdit.positionsList', [])
  .component('wcInlineEditPositionsList', wcInlineEditPositionsList);
