import angular from 'angular';
import { react2angular } from 'react2angular';

import { ConversationPaneToolbar } from './ConversationPaneToolbar';

export default angular
  .module('wc.components.ReactConversationPaneToolbar', [])
  .component(
    'reactConversationPaneToolbar',
    react2angular(ConversationPaneToolbar, [], ['$state', 'MessengerService'])
  );
