import angular from 'angular';

import './Fieldset.scss';

const Fieldset = {
  NAME: 'wcFieldset',
  template: '<fieldset class="WcFieldset" ng-transclude></fieldset>',
  transclude: true
};

export default angular
  .module('wc.components.form.fieldset', [])
  .component(Fieldset.NAME, Fieldset);
