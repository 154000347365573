import angular from 'angular';
import { react2angular } from 'react2angular';
import { SensitiveSettingOption } from './SensitiveSettingOption';

export default angular
  .module('wc.components.ReactSensitiveSettingOption', [])
  // @ts-ignore
  .component(
    'reactSensitiveSettingOption',
    react2angular(SensitiveSettingOption, ['label', 'name', 'onEdit', 'value'])
  );
