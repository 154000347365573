import angular from 'angular';

import apiModule from '../../../common/services/api/API.module';
import templateUrl from './template.html';
import './styles.scss';

class MemberReferralFormController {
  /*@ngInject*/
  constructor(API, ngToast, $state) {
    this.API = API;
    this.inProgress = false;
    this.ngToast = ngToast;
    this.$state = $state;
  }

  onSubmit() {
    if (this.form.$valid && !this.inProgress) {
      this.inProgress = true;
      return this.API.MemberReferrals.post(this.formData)
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleSuccess() {
    this.ngToast.success('Member Referral Submitted');
    this.inProgress = false;
    this.$state.reload();
  }

  handleError(error) {
    this.inProgress = false;
    this.API.handleError()(error);
  }
}

export default angular
  .module('wc.components.memberReferralForm', [apiModule.name])
  .component('wcMemberReferralForm', {
    controller: MemberReferralFormController,
    templateUrl: templateUrl
  });
