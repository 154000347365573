import STATES from '../../../services/States';

import EditClientProfileCtrl from './ClientEditProfile.controller';
import EditClientProfileTemplateUrl from '../registration/Registration.html';

import ClientUploadCtrl from '../registration/Upload.controller';
import ClientUploadTemplateUrl from '../registration/Upload.html';

/*@ngInject*/
function ClientEditProfileRouter($stateProvider, $urlRouterProvider) {
  $stateProvider
    .state(STATES.CLIENT_EDIT, {
      url: '/clients/edit',
      abstract: true,
      template: '<ui-view class="MainView"></ui-view>',
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService, $sessionStorage) {
          // Only clients with complete profiles can access Client Edit routes.
          if (!$sessionStorage.hasSeenLegislationAlert) {
            return RedirectService.redirectIfNotCompleteClientOrNotAcceptedLegislation();
          }
        }
      }
    })

    .state(STATES.CLIENT_EDIT_PROFILE, {
      url: '/profile',
      templateUrl: EditClientProfileTemplateUrl,
      controller: EditClientProfileCtrl,
      controllerAs: EditClientProfileCtrl.controllerAs
    })

    .state(STATES.CLIENT_EDIT_UPLOAD, {
      url: '/upload',
      templateUrl: ClientUploadTemplateUrl,
      controller: ClientUploadCtrl,
      controllerAs: ClientUploadCtrl.controllerAs
    });

  $urlRouterProvider.when('/clients/edit', '/clients/edit/profile');
}

export default ClientEditProfileRouter;
