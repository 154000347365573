/* State can define a property redirectTo which will
 * automatically trigger a redirect to the specified state
 *
 * Useful for going straight into a particular child state
 * But not wanting to declare parent abstract
 */
let RoutingRedirects = ($rootScope, $state) => {
  let _ = $rootScope.$on('$stateChangeStart', function(evt, to, params) {
    if (to.redirectTo) {
      evt.preventDefault();
      $state.go(to.redirectTo, params);
    }
  });
};

RoutingRedirects.$inject = ['$rootScope', '$state'];

export default RoutingRedirects;
