import angular from 'angular';

import OwnEdit from './own-edit';

import './styles.scss';
import templateUrl from './template.html';

class MemberProfileContentController {
  /*@ngInject*/
  constructor(DetectDeviceService, $stateParams, API) {
    this.DetectDeviceService = DetectDeviceService;
    this.API = API;
    this.$stateParams = $stateParams;
  }

  $onInit() {
    if (this.isClient && this.$stateParams.lid) {
      this.loadCandidateDocuments(this.$stateParams.lid, this.$stateParams.id);
      if (this.member && this.member.userId) {
        this.loadCandidateSummary(this.$stateParams.lid, this.member.userId);
      }
      this.loadLonglist(this.$stateParams.lid);
    }

    if (!this.member || !this.member.answers || !this.member.answers.length) {
      return;
    }

    this.member.answers = this.member.answers.filter(x => {
      return !!x.text;
    });
  }

  hasAspirations() {
    return (
      (this.member.aspirations &&
        (this.member.aspirations.goals ||
          (this.member.aspirations.industryStandardJobTitles &&
            this.member.aspirations.industryStandardJobTitles.length) ||
          (this.member.aspirations.industries &&
            this.member.aspirations.industries.length) ||
          (this.member.aspirations.cities &&
            this.member.aspirations.cities.length) ||
          (this.member.aspirations.companies &&
            this.member.aspirations.companies.length))) ||
      this.member.openToRelocate ||
      this.member.aspirationalComp ||
      this.member.interestedInNonExec
    );
  }

  showAspirations() {
    return this.isOwn || (this.isClient && this.hasAspirations());
  }

  areAspirationsFetched() {
    return typeof this.member.aspirations !== 'string';
  }

  showInsights() {
    return (
      this.isOwn ||
      (this.member.hobbies && this.member.hobbies.length) ||
      (this.member.answers && this.member.answers.length)
    );
  }

  showDocuments() {
    return (
      this.isClient &&
      this.candidateDocuments &&
      this.candidateDocuments.length !== 0
    );
  }

  isDesktop() {
    return this.DetectDeviceService.isDesktop();
  }

  loadCandidateDocuments(longlistId, memberId) {
    this.API.LonglistCandidateDocuments(longlistId, memberId)
      .getList()
      .then(this.setCandidateDocuments.bind(this));
  }

  setCandidateDocuments(documents) {
    this.candidateDocuments = documents;
  }

  loadCandidateSummary(longlistId, userId) {
    this.API.LonglistMember(longlistId, userId)
      .get()
      .then(this.setCandidateSummary.bind(this));
  }

  setCandidateSummary(response) {
    this.member.candidateSummary = response ? response.summary : null;
  }

  loadLonglist(longlistId) {
    this.API.Longlist(longlistId)
      .get()
      .then(this.setLonglist.bind(this));
  }

  setLonglist(longlist) {
    this.longlist = longlist;
  }
}

export default angular
  .module('wc.components.profile.content', [OwnEdit.name])
  .component('wcMemberProfileContent', {
    bindings: {
      member: '<',
      user: '<',
      isOwn: '@?',
      isClient: '<?',
      groups: '<?'
    },
    controller: MemberProfileContentController,
    templateUrl: templateUrl
  });
