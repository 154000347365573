class PreviousButtonWithConfirmationController {
  /*@ngInject*/
  constructor(DialogService) {
    this.DialogService = DialogService;
  }

  showConfirmationDialog() {
    if (!this.skipConfirmationIf) {
      const locals = {
        title: 'You will lose data if you go back',
        content:
          'Filled in data will be lost if navigating away without submitting.',
        okAction: 'Discard changes',
        cancelAction: 'Keep changes'
      };
      this.DialogService.showConfirmDialog(locals).then(
        this.handleReturn.bind(this)
      );
    } else {
      this.handleReturn();
    }
  }

  handleReturn() {
    this.onConfirm();
  }
}

export default PreviousButtonWithConfirmationController;
