import templateUrl from './MemberNavbar.html';
import STATES from '../../../services/States';

import { MEMBER_SEARCH_SIDEBAR_ID } from '../../sidebars/membersearchsidebar';

class MemberNavbarCtrl {
  /*@ngInject*/
  constructor($state, AuthenticationService, featureFlags, $rootScope) {
    this.STATES = STATES;
    this.$state = $state;
    this.AuthenticationService = AuthenticationService;
    this.memberSearchFeatureIsOn = featureFlags.isOn('memberSearch');
    this.searchSidebarId = MEMBER_SEARCH_SIDEBAR_ID;
    this.memberIsPublic = AuthenticationService.isMemberPublic();
    $rootScope.$on('memberVisibilityChanged', this.updateVisibility.bind(this));
  }

  updateVisibility() {
    this.memberIsPublic = this.AuthenticationService.isMemberPublic();
  }

  goToClientProfile() {
    this.$state.go(this.STATES.MEMBER_PROFILE);
  }

  goToMemberSettings() {
    this.$state.go(this.STATES.MEMBER_SETTINGS);
  }

  signOut() {
    this.AuthenticationService.showLogoutDialog();
  }
}

const MemberNavbar = {
  NAME: 'wcSidenavMemberNavbar',
  controller: MemberNavbarCtrl,
  templateUrl: templateUrl
};

export default MemberNavbar;
