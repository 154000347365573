import angular from 'angular';
import { react2angular } from 'react2angular';
import { ActiveFilterCount } from './ActiveFilterCount';

export default angular
  .module('wc.components.react.memberList.activeFilterCount', [])
  // @ts-ignore
  .component(
    'reactMemberListActiveFilterCount',
    react2angular(ActiveFilterCount, ['count'], [])
  );
