let scroll = /*@ngInject*/ $timeout => {
  return {
    restrict: 'A',
    scope: {
      wcScroll: '&'
    },
    link: {
      post: (scope, element) => {
        $timeout(() => {
          $timeout(() => {
            $(element).on('scroll', event => {
              scope.$apply(() => scope.wcScroll({ event: event }));
            });
          });
        });
      }
    }
  };
};

scroll.NAME = 'wcScroll';

let scrollModule = angular
  .module('wc.components.scroll', [])
  .directive(scroll.NAME, scroll);

export default scrollModule;
