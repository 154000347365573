import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu } from './../../../../dropdown-menu/DropdownMenu';
import { validate } from '../../../../../../../common/components/inputs/fileUploads/document/validation';
import STATES from '../../../../../../services/States';

export const OwnMemberProfileActions = ({ member, $state, Resume, API }) => {
  const addExecPosition = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.EXEC_POSITION, { position: {} });
  };

  const addNonExecPosition = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.NON_EXEC_POSITION, {
      position: {}
    });
  };

  const downloadResume = () => {
    if (member.resume) {
      Resume.download(member.resume, member.fullName);
    }
  };

  const validateFile = file => {
    let validationInfo = validate(file);

    if (!validationInfo.isValid) {
      return handleError(validationInfo.error);
    }

    return validationInfo.isValid;
  };

  const onUpload = files => {
    if (!files || files.length === 0) {
      return;
    }

    const isValid = validateFile(files[0]);

    if (isValid) {
      uploadResume({ file: files[0] });
    }
  };

  const uploadResume = file => {
    const fd = new FormData();
    fd.append('file', file.file);

    return API.Resumes.withHttpConfig({
      transformRequest: angular.identity
    })
      .customPOST(fd, '', {}, { 'Content-Type': undefined })
      .then(() => updateCVUrl(file.file.name))
      .catch(handleError);
  };

  const updateCVUrl = (fileName, response) => {
    API.handleSuccess(`${fileName} uploaded successfully`);
    member.resume = response.url;
  };

  const handleError = error => {
    API.handleError('Upload failed')(error);
  };

  const positionMenu = {
    button: {
      name: 'Another Role',
      icon: 'AddIcon'
    },
    menu: [
      {
        name: 'Executive Position',
        callback: addExecPosition
      },
      {
        name: 'Non-Executive Position',
        callback: addNonExecPosition
      }
    ]
  };

  const resumeMenu = {
    button: {
      name: 'CV / Resume',
      icon: 'FileText'
    },
    menu: [
      {
        name: 'View',
        icon: 'zmdi-download',
        callback: downloadResume
      },
      {
        name: 'Upload',
        icon: 'zmdi-upload',
        clickInput: '#resume-upload'
      }
    ]
  };

  const resumeEmptyMenu = {
    button: {
      name: 'CV / Resume',
      icon: 'zmdi-file-text'
    },
    menu: [
      {
        name: 'No CV/Resume'
      },
      {
        name: 'Upload',
        icon: 'zmdi-upload',
        clickInput: '#resume-upload'
      }
    ]
  };

  return (
    <React.Fragment>
      <div className="WcMemberProfileContent__ActionButtons">
        <DropdownMenu menuObject={positionMenu} />

        <DropdownMenu
          menuObject={member.resume.length ? resumeMenu : resumeEmptyMenu}
        />
      </div>

      <input
        type="file"
        id="resume-upload"
        className="ng-hide"
        onChange={e => onUpload(e.target.files)}
        accept="application/pdf"
      />
    </React.Fragment>
  );
};

OwnMemberProfileActions.propTypes = {
  member: PropTypes.object,
  $state: PropTypes.any,
  Resume: PropTypes.any,
  API: PropTypes.any
};
