import React from 'react';
import PropTypes from 'prop-types';
import styled, {
  ThemeProvider as StyledThemeProvider
} from 'styled-components';
import { ThemeProvider as MaterialUIStylesProvider } from '@material-ui/styles';
import { Checkbox as BaseCheckbox } from '@material-ui/core';
import { CheckBox as CheckBoxIcon } from '@material-ui/icons';
import angular from 'angular';
import { react2angular } from 'react2angular';

import theme from '../../../../../common/styles/theme';

const Checkbox = styled(({ ...props }) => (
  <BaseCheckbox classes={{ root: 'root' }} {...props} />
))`
  &.root {
    padding: 0;
    margin: 0 9px 0 -3px;

    .MuiSvgIcon-root {
      height: 26px;
      width: 26px;
    }

    &.MuiIconButton-colorSecondary {
      &:hover {
        background-color: ${props =>
          props.theme.palette.extraColors.lighterGrey};
      }
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const LabelText = styled.span`
  cursor: pointer;
  font-size: 16px;
  user-select: text;
  whitespace: normal;
`;

export const CheckboxQuestion = ({
  labelText,
  checked,
  onChange,
  onBlur,
  className,
  fieldName
}) => {
  return (
    <div className={className}>
      <Label>
        <Checkbox
          onBlur={onBlur}
          checkedIcon={<CheckBoxIcon />}
          color="secondary"
          checked={checked}
          onChange={onChange}
          inputProps={{
            name: fieldName
          }}
        />
        <LabelText>{labelText}</LabelText>
      </Label>
    </div>
  );
};

CheckboxQuestion.propTypes = {
  labelText: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  fieldName: PropTypes.string
};

const Wrapper = ({ labelText, checked, onChange, fieldName }) => {
  return (
    <StyledThemeProvider theme={theme}>
      <MaterialUIStylesProvider theme={theme}>
        <CheckboxQuestion
          labelText={labelText}
          checked={checked}
          onChange={onChange}
          fieldName={fieldName}
        />
      </MaterialUIStylesProvider>
    </StyledThemeProvider>
  );
};

Wrapper.propTypes = {
  labelText: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string
};

export default angular
  .module('wc.components.react.checkboxQuestion', [])
  .component(
    'reactCheckboxQuestion',
    react2angular(
      Wrapper,
      ['labelText', 'checked', 'onChange', 'fieldName'],
      []
    )
  );
