import React from 'react';
import PropTypes from 'prop-types';
import angular from 'angular';
import { react2angular } from 'react2angular';
import { parse, format, isValidNumber } from 'libphonenumber-js';

import TextField from '../../inputs/TextField';

export const PhoneNumberField = ({
  initialValue,
  updateValue,
  updateError,
  updateTouched,
  touched,
  formSubmitted,
  label,
  halfWidth = false
}) => {
  const convertValue = (value, fmt) => {
    if (isValidNumber(value)) {
      return format(parse(value), fmt);
    }
    return value;
  };

  const convertToDisplayValue = value => {
    return convertValue(value, 'International');
  };

  const errorHandler = value => {
    if (!value) {
      return {
        hasError: true,
        errorText: 'This field is required'
      };
    }

    if (!isValidNumber(value) || value[0] !== '+') {
      return {
        hasError: true,
        errorText:
          'Please enter your full contact number, including country code, e.g. +14156231234'
      };
    }

    return {
      hasError: false,
      errorText: ''
    };
  };

  const hasError = value => errorHandler(value).hasError;

  const errorText = value => errorHandler(value).errorText;

  return (
    <TextField
      fieldName="mobileNumber"
      label={label}
      initialValue={convertToDisplayValue(initialValue)}
      updateValue={updateValue}
      hasError={hasError}
      updateError={updateError}
      errorText={errorText}
      updateTouched={updateTouched}
      touched={touched}
      formSubmitted={formSubmitted}
      autocomplete="tel"
      formatInput={convertToDisplayValue}
      halfWidth={halfWidth}
    />
  );
};

PhoneNumberField.propTypes = {
  initialValue: PropTypes.string,
  updateValue: PropTypes.func.isRequired,
  updateError: PropTypes.func.isRequired,
  updateTouched: PropTypes.func,
  touched: PropTypes.bool,
  formSubmitted: PropTypes.bool.isRequired,
  label: PropTypes.string,
  halfWidth: PropTypes.bool
};

PhoneNumberField.defaultProps = {
  label: 'Phone Number'
};

export default angular
  .module('wc.components.PhoneNumberField', [])
  .component(
    'reactPhoneNumberField',
    react2angular(PhoneNumberField, [
      'initialValue',
      'updateValue',
      'updateError',
      'updateTouched',
      'touched',
      'formSubmitted',
      'label'
    ])
  );
