import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import AnchorNavigation from '../../AnchorNavigation';

export const AnchorNavigationItem = ({
  target,
  label,
  activeByDefault = false
}) => {
  const [active, setActive] = useState(activeByDefault);

  useEffect(() => {
    const onScroll = event => {
      setActive(
        AnchorNavigation.checkIfItemActive(target, event.target.scrollTop)
      );
    };

    document
      .querySelector('#profileScrollContainer')
      .addEventListener('scroll', onScroll);

    return () => {
      document
        .querySelector('#profileScrollContainer')
        .removeEventListener('scroll', onScroll);
    };
  }, []);

  const scrollTo = () => {
    AnchorNavigation.scrollTo(target);
  };

  return (
    <div className="WcAnchorNavigationItem">
      <div className="WcTab" onClick={scrollTo}>
        <div className={active ? 'WcAnchorNavigationItemActive' : ''}>
          {label}
        </div>
      </div>
    </div>
  );
};

AnchorNavigationItem.propTypes = {
  target: PropTypes.string,
  label: PropTypes.string,
  activeByDefault: PropTypes.bool
};
