import STATES from '../../../../services/States';
import log from 'loglevel';
import pluralize from 'pluralize';
import {
  checkAnySelected,
  deselectAll
} from '../../../../services/MultipleSelection';

import './LonglistDetail.scss';
import OverflowMenuCell from '../../../../components/table/cells/OverflowMenuCell.html';
import DateCell from '../../../../components/table/cells/DateCell.html';
import FavouriteCell from '../../../../components/table/cells/FavouriteCell.html'; // eslint-disable-line no-unused-vars
import StatusCell from '../../../../components/table/cells/StatusCell.html'; // eslint-disable-line no-unused-vars
import NoteCell from '../../../../components/table/cells/NoteCell.html';
import { LONGLIST_FILTER_SIDEBAR_ID } from '../../../../components/sidebars/longlistfiltersidebar';

class LonglistDetailController {
  /*@ngInject*/
  constructor(
    $state,
    $stateParams,
    $localStorage,
    $window,
    API,
    LonglistDialogService,
    MemberListUIService,
    AuthenticationService,
    wcLonglist,
    wcMemberList,
    NotesDialogService,
    MemberStatusDialogService,
    Resume
  ) {
    this.$state = $state;
    this.$window = $window;
    this.Resume = Resume;
    this.$stateParams = $stateParams;
    this.$localStorage = $localStorage;
    this.API = API;
    this.LonglistDialogService = LonglistDialogService;
    this.MemberListUIService = MemberListUIService;
    this.wcLonglist = wcLonglist;
    this.wcMemberList = wcMemberList;
    this.NotesDialogService = NotesDialogService;
    this.MemberStatusDialogService = MemberStatusDialogService;

    this.statusChoices = [];
    this.longlistId = this.$stateParams.lid;
    this.currentLonglistEndpoint = this.API.Longlist(this.longlistId);
    this.documentsList = this.API.LonglistDocuments(this.longlistId).getList;

    this.memberListId = this.wcMemberList.getMemberListId(this.longlistId);
    if (this.$state.current.name === STATES.LONGLIST_DETAIL) {
      this.userProfileState = STATES.LONGLIST_DETAIL_MEMBER;
      this.membersSearchState = STATES.SEARCH;
    } else {
      this.userProfileState = STATES.STANDALONE_PREVIEW_MEMBER;
      this.noSidenavOffset = true;
    }
    this.sidebarId = LONGLIST_FILTER_SIDEBAR_ID;

    this.loading = true;
    this.documentsLoading = true;

    this.wcLonglist.activeLonglist = this.longlistId;
    this.wcMemberList.registerLonglist(this.longlistId);
    this.memberList = this.wcMemberList[this.memberListId];
    this.memberList.refresh().then(() => {
      this.loading = false;
    });
    this.AuthenticationService = AuthenticationService;

    this.selectedTab = 'members';

    this.MemberListUIService.isReady
      .then(this.setMemberListOptions.bind(this))
      .then(this.setLonglistData.bind(this));

    this.contacts = [];
  }

  getSymbolImage(company) {
    return require('../../dashboard/img/' +
      company.toLowerCase() +
      '-symbol.png');
  }

  setLonglistData() {
    this.getData();
    this.API.retrieveMyLonglistsEndpoint()
      .then(this.setLonglistEndpoints.bind(this))
      .then(this.getLonglistsData.bind(this), this.API.handleError());
  }

  toggleFavourite(member) {
    let index = this.memberList.members.indexOf(member);

    member.isFavourite = !member.isFavourite;

    this.API.Longlist(this.longlistId)
      .one('members', member.userId)
      .patch({ isFavourite: member.isFavourite })
      .then(() => {
        this.API.handleSuccess(`${member.fullName} toggled favourite`);
        let updatedMember = member;
        this.memberList.members[index] = updatedMember;
      }, this.API.handleError(`Could not toggle favourite on ${member.fullName}. Please try again.`));
  }

  setMemberListOptions() {
    this.memberListUIResolved = true;
    this.tableHeaders = this.MemberListUIService.getDefaultTableHeaders();
    this.setOverflowMenus();
    this.MemberListUIService.updateSortOptions(this.tableHeaders);
    this.sortOptions = this.MemberListUIService.getDefaultSortOptions();
    this.setCurrentOrderingField();
  }

  setOverflowMenus() {
    this.extraTableData = {
      lid: this.longlistId,
      toggleFavourite: this.toggleFavourite.bind(this),
      updateStatus: this.updateStatus.bind(this),
      getChoiceName: this.getChoiceName.bind(this),
      overflowMenu: [
        {
          text: 'Change status',
          callback: this.showStatusMemberDialog.bind(this),
          icon: 'zmdi-account-o'
        },
        {
          text: 'Copy to another project',
          callback: this.showCopyMemberDialog.bind(this),
          icon: 'zmdi-copy'
        },
        {
          text: 'Delete',
          callback: this.showRemoveMemberDialog.bind(this),
          icon: 'zmdi-delete'
        }
      ],
      showAddNote: false,
      addNote: this.showNotes.bind(this)
    };
    this.tableHeaders.push({
      name: 'Date',
      include: DateCell,
      sortable: true,
      sortingKey: 'date_created',
      classes: ['LightFontCell', 'DateCell'],
      priority: 1
    });
    this.tableHeaders.push({
      name: 'Status',
      key: 'status',
      sortable: true,
      sortingKey: 'status',
      include: StatusCell,
      classes: ['LightFontCell', 'StatusCell'],
      priority: 1
    });
    this.tableHeaders.push({
      classes: ['FavouriteCell', 'LightFontCell'],
      name: 'Favourite',
      hideName: true,
      sortable: true,
      sortingKey: 'is_favourite',
      include: FavouriteCell,
      priority: 0
    });
    this.AuthenticationService.getProfile().then(profile => {
      if (profile) {
        this.extraTableData.overflowMenu.unshift({
          text: 'View/Add notes',
          callback: this.showNotesMemberDialog.bind(this),
          icon: 'zmdi-file'
        });
        this.extraTableData.showAddNote = true;
        this.tableHeaders.push({
          include: NoteCell,
          sortable: false,
          classes: ['NoteCell', 'LightFontCell'],
          priority: 0
        });
      }
    });
    this.tableHeaders.push({
      include: OverflowMenuCell,
      sortable: false,
      classes: ['MenuCell']
    });
  }

  setLonglistEndpoints(longlistsEndpoint) {
    this.longlistsEndpoint = longlistsEndpoint;
    return this.longlistsEndpoint;
  }

  getData() {
    this.getLonglist();
    this.setUpMemberOptions();
    this.getContacts();
  }

  getLonglistsData() {
    this.setUpOptions();
    this.getAllLonglists();
  }

  getContacts() {
    this.API.LonglistContacts(this.longlistId)
      .getList()
      .then(contacts => {
        this.contacts = contacts;
      });
  }

  getSelectedMembers() {
    return this.memberList.members.filter(member => {
      return member.isSelected;
    });
  }

  areAnyMembersSelected() {
    return checkAnySelected(this.memberList.members);
  }

  deselectAll = () => {
    return deselectAll(this.memberList.members);
  };

  goToSearchState = () => {
    return this.$state.go(this.membersSearchState, {
      longlistId: this.longlistId
    });
  };

  getSearchStateString() {
    return `${this.membersSearchState}({longlistId: ${this.longlistId}})`;
  }

  getAllLonglists() {
    if (this.longlistsEndpoint) {
      this.longlistsEndpoint
        .getList()
        .then(this.setAllLonglists.bind(this), this.API.handleError());
    }
  }

  /* API methods */
  setUpOptions() {
    if (this.longlistsEndpoint) {
      this.longlistsEndpoint
        .options()
        .then(this.setOptions.bind(this), this.API.handleError());
    }
  }

  setUpMemberOptions() {
    this.API.LonglistMembers(this.longlistId)
      .options()
      .then(this.setMemberOptions.bind(this), this.API.handleError());
  }

  getLonglist() {
    this.currentLonglistEndpoint
      .get()
      .then(this.setLonglist.bind(this), this.API.handleError());
  }

  copyMembersToLonglist(members, longlist) {
    let usersString = pluralize('User', members.length);

    this.memberList
      .addMembers(members, longlist.id)
      .then(
        this.API.handleSuccess(`${usersString} copied to ${longlist.name}`),
        this.API.handleError()
      );
  }

  copyMembers(longlistIds, members) {
    longlistIds.map(id =>
      this.API.Longlist(id)
        .get()
        .then(this.copyMembersToLonglist.bind(this, members))
    );
  }

  updateLonglist(longlist) {
    return this.API.Longlist(longlist.id).patch(longlist);
  }

  deleteLonglist() {
    this.API.Longlist(this.longlist.id)
      .remove()
      .then(this.redirectAfterDelete.bind(this), this.API.handleError());
  }

  downloadCVs = () => {
    const members = this.getSelectedMembers();
    members.forEach(member => {
      if (member.resume) {
        this.Resume.download(member.resume, member.fullName);
      }
    });
  };

  downloadPDFs = () => {
    const members = this.getSelectedMembers();
    members.forEach(member => {
      if (member.pdfDownload) {
        this.Resume.triggerDownloadJS(member.pdfDownload, member.fullName);
      }
    });
  };

  /* Dialog methods */
  showCopyMembersDialog = event => {
    const members = this.getSelectedMembers();
    this.LonglistDialogService.showCopyToLonglistDialog(
      event,
      this.longlists,
      this.longlist
    ).then(
      destinationLonglists => this.copyMembers(destinationLonglists, members),
      this.cancelled.bind(this, 'Copying members')
    );
  };

  showCopyMemberDialog(event, currentMember) {
    this.showCopyMembersDialog(event, [currentMember]);
  }

  updateOrdering = fieldName => {
    this.memberList.setOrderingField(fieldName);
    this.setCurrentOrderingField();
    this.saveOrdering();
  };

  setCurrentOrderingField() {
    const key = this.memberList.ordering.field;
    this.currentOrderingField = this.sortOptions.find(
      option => option.sortingKey === key
    );
  }

  setOrderingReverse(bool) {
    this.memberList.setOrderingReverse(bool);
    this.saveOrdering();
  }

  toggleOrdering = () => {
    this.memberList.setOrderingReverse(!this.memberList.ordering.reverse);
    this.saveOrdering();
  };

  saveOrdering() {
    this.$localStorage.longlistOrdering[
      this.longlistId
    ] = this.memberList.ordering;
  }

  showRemoveMemberDialog(event, deletedMember) {
    this.LonglistDialogService.showRemoveMemberDialog(
      event,
      deletedMember
    ).then(() => {
      this.memberList.removeMembers([deletedMember.userId]);
    }, this.cancelled.bind(this, 'Removing member'));
  }

  showRemoveSelectedMembersDialog = event => {
    this.LonglistDialogService.showRemoveSelectedMembersDialog(event)
      .then(() => {
        const selectedUserIds = this.getSelectedMembers().map(member => {
          return member.userId;
        });

        this.memberList.removeMembers(selectedUserIds);
      }, this.cancelled.bind(this, 'Removing members from project'))
      .catch(this.API.handleError());
  };

  saveNote(member, note) {
    let memberId = member.id;
    this.API.Notes(memberId).post({ text: note });
    member.notesCount++;
  }

  showNotesMemberDialog(event, member) {
    this.showNotes(member);
  }

  showNotes(member) {
    let memberId = member.id;
    this.API.Notes(memberId)
      .getList()
      .then(this.showNotesDialog.bind(this))
      .then(this.saveNote.bind(this, member))
      .catch(this.API.handleError.bind(this));
  }

  showNotesDialog(notes) {
    return this.NotesDialogService.showMemberNotesDialog(notes);
  }

  showStatusMemberDialog(event, member) {
    return this.MemberStatusDialogService.showEditMemberStatusDialog(
      event,
      member,
      this.memberStatusChoices
    ).then(status => {
      member.status = status;
      member.statusDisplay = this.getChoiceName(status);
      this.updateStatus(member);
    }, this.cancelled.bind(this, 'Changing member status'));
  }

  toggleLayout() {
    this.MemberListUIService.toggleLayout();
  }

  updateStatus(member) {
    let index = this.memberList.members.indexOf(member);

    this.API.Longlist(this.longlistId)
      .one('members', member.userId)
      .patch({ status: member.status })
      .then(() => {
        this.API.handleSuccess(`${member.fullName} changed status`);
        let updatedMember = member;
        this.memberList.members[index] = updatedMember;
      }, this.API.handleError(`Could not change status on ${member.fullName}. Please try again.`));
  }

  onInfiniteScroll() {
    this.memberList.loadNextMembers();
  }

  /* Promise handlers */
  cancelled(action) {
    log.info(`${action} action cancelled`);
  }

  setLonglist(longlist) {
    this.longlist = longlist;
  }

  setAllLonglists(longlists) {
    this.longlists = longlists;
  }

  setOptions(options) {
    this.statusChoices = options.actions.POST.status.choices;
  }

  getChoiceName(val) {
    let vals = this.memberStatusChoices.filter(c => {
      if (c.value === val) {
        return true;
      }
      return false;
    });

    if (vals.length === 0) {
      return val;
    }

    return vals[0].displayName;
  }

  setMemberOptions(options) {
    this.memberStatusChoices = options.actions.PUT.status.choices;
    this.extraTableData.memberStatusChoices = this.memberStatusChoices;
  }

  redirectAfterDelete() {
    this.$state.go(STATES.LONGLISTS);
  }

  emailLink(contact) {
    return (
      'mailto:' +
      contact.user.firstName +
      ' ' +
      contact.user.lastName +
      '<' +
      contact.user.email +
      '>' +
      (this.longlist ? '?subject=' + this.longlist.name : '')
    );
  }

  mobileLink(contact) {
    return 'tel:' + contact.user.mobileNumber;
  }
}

LonglistDetailController.controllerAs = '$ctrl';

export default LonglistDetailController;
