export default class CurrencyRangeController {
  /*@ngInject*/
  constructor($scope) {
    this.minModel = '';
    this.maxModel = '';

    $scope.$watch(
      () => this._minModel,
      () => {
        this.minModel = this.transformCurrencyModel(this._minModel);
      }
    );

    $scope.$watch(
      () => this._maxModel,
      () => {
        this.maxModel = this.transformCurrencyModel(this._maxModel);
      }
    );

    $scope.$watch(
      () => this.currency,
      () => {
        this.maxModel = this.transformCurrencyModel(this._maxModel);
        this.minModel = this.transformCurrencyModel(this._minModel);
      }
    );

    $scope.$watch(
      () => this.minModel,
      this.updateInternalModel.bind(this, 'minModel')
    );

    $scope.$watch(
      () => this.maxModel,
      this.updateInternalModel.bind(this, 'maxModel')
    );
  }

  updateInternalModel(modelProperty) {
    if (!this[modelProperty]) {
      this['_' + modelProperty] = '';
    }
  }

  transformCurrencyModel(amount) {
    if (amount && this.currency) {
      return this.stringifyCurrencyValue(amount);
    } else {
      return '';
    }
  }

  stringifyCurrencyValue(amount) {
    return `${amount}_${this.currency.code}`;
  }
}
