import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MemberProfileOverviewPersonal = ({ member }) => {
  return (
    <div className="WcCommonMemberProfileOverviewPersonal">
      <h3 className="WcOtherMemberProfileOverview__Name">{member.fullName}</h3>

      {member.address.city && (
        <div>
          <p className="WcOtherMemberProfileOverview__InfoText">
            {member.address.city}
            {member.address.state && <span>, {member.address.state}</span>}
            {member.address.country && (
              <span>, {member.address.countryName}</span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

MemberProfileOverviewPersonal.propTypes = {
  member: PropTypes.object
};
