import STATES from '../../services/States';

class PasswordCreationController {
  /*@ngInject*/
  constructor($state, API, AuthenticationService) {
    this.$state = $state;
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.STATES = STATES;

    this.userType = this.AuthenticationService.getUserTypeDisplay();
  }

  getTermsHref() {
    if (this.AuthenticationService.isClient()) {
      return 'https://www.wintercircle.co/client-terms.html';
    }

    if (this.AuthenticationService.isMember()) {
      return 'https://www.wintercircle.co/member-terms.html';
    }
  }

  signUp() {
    if (this.form.$valid) {
      this.formErrorMessage = null;
      this.API.User.patch(this.user)
        // Refresh the AuthenticationService, because hasChosenPassword might have changed now.
        .then(() => this.AuthenticationService.refresh())
        .then(() => this.AuthenticationService.navigateToDefaultState())
        .catch(this.API.handleError());
    }
  }
}

PasswordCreationController.controllerAs = 'passwordCreationCtrl';
export default PasswordCreationController;
