import angular from 'angular';
import template from './template.html';
import { Actions as ProfilesActions } from '../../../../common/redux/profiles/actions';

import STATES from '../../../services/States';

const MapStateToProps = state => ({
  profile: state.profilesReducer.myProfile
});

const MapDispatchToProps = dispatch => ({
  updateMyProfile: myProfile =>
    ProfilesActions.updateMyProfile(dispatch, myProfile)
});

class FinancePeopleController {
  /*@ngInject*/
  constructor(
    API,
    $state,
    CurrencyService,
    PreferredCurrencyService,
    $q,
    $timeout,
    $ngRedux
  ) {
    this.API = API;
    this.$state = $state;
    this.$q = $q;
    this.$ngRedux = $ngRedux;
    this.CurrencyService = CurrencyService;
    this.PreferredCurrencyService = PreferredCurrencyService;
    this.$timeout = $timeout;
    this.loading = true;
    this.currencies = [];
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(
      MapStateToProps,
      MapDispatchToProps
    )(this);

    let currenciesPromise = this.API.Currencies.getList().then(currencies => {
      this.currencies = this.CurrencyService.currencyListToMap(currencies);
      this.selectedCurrency = this.PreferredCurrencyService.getPreferredCurrency(
        currencies
      );
    });

    let profilePromise = this.API.retrieveMyProfile().then(profile => {
      this.updateMyProfile(profile);
    });

    this.$q
      .all([currenciesPromise, profilePromise])
      .then(() => {
        if (this.opexCurrency) {
          this.selectedCurrency = this.currencies[this.opexCurrency];
        }
        this.forceCurrencySymbolToResize();
        this.loading = false;
      })
      .catch(this.handleError.bind(this));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  //TODO: Very hacky -- only works in fast browsers
  forceCurrencySymbolToResize() {
    this.selectedCurrency.symbol += ' ';
    this.$timeout(() => {
      this.selectedCurrency.symbol = this.selectedCurrency.symbol.trim();
    });
  }

  shouldDisplayFieldError(fieldName) {
    if (!this.form || !this.form[fieldName]) {
      return false;
    }

    return (
      Object.keys(this.form[fieldName].$error).length > 0 &&
      (this.form[fieldName].$touched || this.form.$submitted)
    );
  }

  cancel() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'finance-people'
    });
  }

  submit() {
    if (this.form.$valid) {
      this.loading = true;
      const profilePatch = {
        opexAmount: this.profile.opexAmount ? this.profile.opexAmount : null,
        opexCurrency: this.selectedCurrency.code,
        capexAmount: this.profile.capexAmount ? this.profile.capexAmount : null,
        capexCurrency: this.selectedCurrency.code,
        profitAndLossAmount: this.profile.profitAndLossAmount
          ? this.profile.profitAndLossAmount
          : null,
        profitAndLossCurrency: this.selectedCurrency.code,
        revenueTargetAmount: this.profile.revenueTargetAmount
          ? this.profile.revenueTargetAmount
          : null,
        revenueTargetCurrency: this.selectedCurrency.code,
        fullTimeEmployees: this.profile.fullTimeEmployees
          ? this.profile.fullTimeEmployees
          : null,
        extendedTeamSize: this.profile.extendedTeamSize
          ? this.profile.extendedTeamSize
          : null,
        directReports: this.profile.directReports
          ? this.profile.directReports
          : null
      };
      this.updateMyProfile({
        ...profilePatch
      });
      this.API.restangularize(this.profile)
        .patch(profilePatch)
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleSuccess() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'finance-people'
    });
  }

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

const wcInlineEditFinancePeople = {
  controller: FinancePeopleController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.financePeople', [])
  .component('wcInlineEditFinancePeople', wcInlineEditFinancePeople);
