import angular from 'angular';

import inverseButton from './inversebutton';
import ReactInverseButton from '../../../desktop/components/react/buttons/InverseButton';
import ReactMessengerSendButton from '../react/buttons/messenger-send';

export default angular.module('wcCommon.components.buttons', [
  inverseButton.name,
  ReactInverseButton.name,
  ReactMessengerSendButton.name
]);
