import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu } from '../../../dropdown-menu/DropdownMenu';

export const OverflowMenuCell = ({ extra, rowItem }) => {
  const getOverflowMenu = () => {
    let menu = [];

    if (!rowItem.isOwner && extra.editOverflowMenu) {
      menu = extra.editOverflowMenu;
    } else if (
      (!rowItem.isOwner && !extra.editOverflowMenu) ||
      rowItem.isOwner
    ) {
      menu = extra.overflowMenu;
    }

    const menuItem = menu.map(overflowMenuItem => {
      return {
        ...overflowMenuItem,
        callback: overflowMenuItem.callback.bind(this, null, rowItem)
      };
    });

    const positionMenu = {
      className: 'sortable-overflow-menu',
      button: {
        icon: 'MoreVertIcon'
      },
      menu: menuItem
    };

    return positionMenu;
  };

  return <DropdownMenu menuObject={getOverflowMenu()} />;
};

OverflowMenuCell.propTypes = {
  extra: PropTypes.any,
  rowItem: PropTypes.any
};
