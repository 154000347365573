import angular from 'angular';

import commonServices from '../../common/services';
import compensationCurrencyService from './CompensationCurrency.service';
import config from '../config/Config.module';
import DetectDeviceService from './DetectDevice.service';
import groupsService from './Groups.service';
import LonglistService from './Longlist.service';
import memberListFactoryModule from './MemberList.factory';
import memberListsServiceModule from './MemberLists.service';
import MemberListUIService from './MemberListUI.service';
import NotificationService from './NotificationService';
import PositionService from './Position.service';

let ServicesModule = angular
  .module('wc.services', [
    'ngStorage',
    commonServices.name,
    compensationCurrencyService.name,
    config.name,
    groupsService.name,
    memberListFactoryModule.name,
    memberListsServiceModule.name
  ])
  .service(DetectDeviceService.NAME, DetectDeviceService)
  .service(PositionService.NAME, PositionService)
  .service(LonglistService.NAME, LonglistService)
  .service(MemberListUIService.NAME, MemberListUIService)
  .service(NotificationService.NAME, NotificationService);

export default ServicesModule;
