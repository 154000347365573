import { MISSING_GROUP_PICTURE_BASE64 } from '../../../../../common/services/ImageUrls';
import STATES from '../../../../services/States';

const dataStateOptions = {
  NOT_FOUND: 'NOT_FOUND',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS'
};

class GroupController {
  /*@ngInject*/
  constructor(
    $q,
    $scope,
    $stateParams,
    API,
    GroupDialogService,
    NotificationService,
    $state
  ) {
    this.$scope = $scope;
    this.API = API;
    this.groupId = $stateParams.id;
    this.GroupDialogService = GroupDialogService;
    this.NotificationService = NotificationService;
    this.$state = $state;

    this.dataStateOptions = dataStateOptions;
    this.TAB_FEED = 'feed';
    this.TAB_MEMBERS = 'members';
    this.tab = this.TAB_FEED;
    this.dataState = dataStateOptions.PENDING;
    this.postContent = '';

    this.userProfileState = STATES.OTHER_MEMBER_PROFILE;
    this.groupsState = STATES.MEMBER_GROUPS;
    this.PLACEHOLDER_URL = MISSING_GROUP_PICTURE_BASE64;

    const profilePromise = API.retrieveMyProfile().then(profile => {
      this.profile = profile;
    });

    const groupPromise = this.getGroup();
    const groupMembersPromise = this.getMembers();

    $q.all([profilePromise, groupPromise, groupMembersPromise]).then(
      () => {
        this.dataState = dataStateOptions.SUCCESS;
      },
      () => {
        this.dataState = dataStateOptions.NOT_FOUND;
      }
    );

    //
    this.clearGroupActivityNotifications();
  }

  getMembers() {
    return this.API.GroupMembers(this.groupId)
      .getList()
      .then(this.setGroupMembers.bind(this));
  }

  getGroup() {
    return this.API.Groups.get(this.groupId).then(response => {
      this.group = response;
    });
  }

  newPost() {
    this.API.GroupPosts(this.groupId)
      .post({
        content: this.postContent
      })
      .then(response => {
        this.group.posts.unshift(response);
        this.postContent = '';
      });
  }

  joinGroup() {
    this.API.GroupMembers(this.groupId)
      .post()
      .then(this.getMembers.bind(this))
      .then(this.getGroup.bind(this));
  }

  setGroupMembers(groupMembers) {
    this.groupMembers = groupMembers;
  }

  shouldShowLeaveGroupButton() {
    return this.group.isMember && !this.group.isOwner;
  }

  showLeaveGroupDialog() {
    this.GroupDialogService.leaveGroupDialog(this.group).then(
      this.leaveGroup.bind(this)
    );
  }

  leaveGroup() {
    this.API.GroupMember(this.groupId, this.profile.id)
      .remove()
      .then(this._leaveGroup.bind(this));
  }

  _leaveGroup() {
    if (this.group.isPrivate) {
      this.$state.go(this.groupsState);
      return;
    }
    this.getMembers().then(this.getGroup.bind(this));
  }

  clearGroupActivityNotifications() {
    // Delete any Group Activity notification for this group
    this.NotificationService.isReady.then(
      this.NotificationService.deleteGroupNotification.bind(
        this.NotificationService,
        parseInt(this.groupId),
        'GACT'
      )
    );
  }
}

GroupController.controllerAs = '$ctrl';
export default GroupController;
