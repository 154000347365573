import template from './NumberInput.html';
import './NumberInput.scss';
import {
  MIN_INTEGER,
  MAX_INTEGER
} from '../../../../../common/components/validators/NumericalLimits';

class $ctrl {
  setIsRequired(bool) {
    this.isRequired = bool;
  }

  setIsDisabled(bool) {
    this.isDisabled = bool;
  }
}

function watchMin(attributes, $ctrl) {
  if (!attributes.min) {
    $ctrl.min = MIN_INTEGER;
  }

  attributes.$observe('min', () => {
    if (!attributes.min) {
      $ctrl.min = MIN_INTEGER;
    }
  });
}

function watchMax(attributes, $ctrl) {
  if (!attributes.max) {
    $ctrl.max = MAX_INTEGER;
  }

  attributes.$observe('max', () => {
    if (!attributes.max) {
      $ctrl.max = MAX_INTEGER;
    }
  });
}

/*@ngInject*/
function dfNumberInput(dfOnDisabled, dfOnRequired) {
  return {
    bindToController: true,
    controller: $ctrl,
    controllerAs: '$ctrl',
    require: 'dfNumberInput',
    templateUrl: template,
    scope: {
      ngModel: '=',
      name: '@',
      min: '@',
      max: '@'
    },
    link: (scope, element, attributes, $ctrl) => {
      dfOnDisabled(
        attributes,
        $ctrl.setIsDisabled.bind($ctrl, true),
        $ctrl.setIsDisabled.bind($ctrl, false)
      );

      dfOnRequired(
        attributes,
        $ctrl.setIsRequired.bind($ctrl, true),
        $ctrl.setIsRequired.bind($ctrl, false)
      );

      watchMin(attributes, $ctrl);
      watchMax(attributes, $ctrl);
    }
  };
}

dfNumberInput.NAME = 'dfNumberInput';

export default dfNumberInput;
