import angular from 'angular';
import { react2angular } from 'react2angular';
import { AnchorNavigationTabsSlot } from './AnchorNavigationTabsSlot';

export default angular
  .module('wc.components.AnchorNavigationTabsSlot', [])
  // @ts-ignore
  .component(
    'reactAnchorNavigationTabsSlot',
    react2angular(AnchorNavigationTabsSlot, [], [])
  );
