import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileFinancePeople } from './OwnMemberProfileFinancePeople';

export default angular
  .module('wc.components.OwnMemberProfileFinancePeople', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileFinancePeople',
    react2angular(
      OwnMemberProfileFinancePeople,
      ['member'],
      ['$state', 'CurrencyService']
    )
  );
