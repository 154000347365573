import './SuggestedChip.scss';
import templateUrl from './SuggestedChip.html';

export default {
  NAME: 'wcSuggestedChip',
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    model: '<',
    onRemove: '&',
    onClick: '&',
    onBlur: '&',
    onFocus: '&'
  }
};
