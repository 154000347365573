import angular from 'angular';

import input from './input';
import locationchips from './locationchips';
import multivaluetypeahead from './multivaluetypeahead';
import timeperiodselector from './timeperiodselector';

export default angular.module('wc.components.timeperiodinputs', [
  input.name,
  locationchips.name,
  multivaluetypeahead.name,
  timeperiodselector.name
]);
