import angular from 'angular';

import ScrollTransfer from '../../../components/scroll-transfer';

import './Search.scss';
import SearchRouting from './Search.routing';
import SearchController from './Search.controller';

let moduleDependencies = [ScrollTransfer.name];

let search = angular
  .module('wc.clients.search', moduleDependencies)
  .controller(SearchController.name, SearchController)
  .config(SearchRouting);

export default search;
