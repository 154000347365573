import React from 'react';
import PropTypes from 'prop-types';

export const MyComponent = ({ fooBar, baz }) => {
  return (
    <div>
      <span>FooBar: {fooBar}</span>
      <span>Baz: {baz}</span>
    </div>
  );
};

MyComponent.propTypes = {
  fooBar: PropTypes.number,
  baz: PropTypes.string
};
