import angular from 'angular';

import ClientAccountSettingsTab from '../../../components/settings/client-account/ClientAccountSettingsTab.module';
import SettingsModule from '../../../components/settings/Settings.module';

import Routing from './Settings.routing';

const dependencies = [ClientAccountSettingsTab.name, SettingsModule.name];

export default angular
  .module('wc.clients.settings', dependencies)
  .config(Routing);
