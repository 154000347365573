import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sortNonExecPositions } from '../../../../../../../../common/services/Positions';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import STATES from '../../../../../../../services/States';
import { NonExecPositionOverview } from '../../../../../../../../common/components/react/position/non-exec-position-overview/NonExecPositionOverview';

const DELETE_POSITION_LOCALS = {
  title: 'Are you sure?',
  content:
    `Deleting this position cannot be undone.<br>` + `Do you want to proceed?`,
  okAction: 'Delete',
  cancelAction: 'Cancel'
};

export const OwnMemberProfileNonExecPositions = ({
  member,
  API,
  $state,
  DialogService
}) => {
  const [showNonExecAlert, setShowNonExecAlert] = useState(false);
  const [memberState, setMemberState] = useState();

  useEffect(() => {
    member.nonExecPositions.forEach(position => {
      if (!position.startDate) {
        setShowNonExecAlert(true);
        return;
      }
    });

    const memberTmp = { ...member };
    memberTmp.nonExecPositions = sortNonExecPositions([
      ...memberTmp.nonExecPositions
    ]);
    setMemberState(memberTmp);
  }, [member]);

  const editNonExecPosition = position => {
    $state.go(STATES.MEMBER_INLINE_EDIT.NON_EXEC_POSITION, {
      position: position
    });
  };

  const deleteNonExecPosition = position => {
    DialogService.showConfirmDialog(DELETE_POSITION_LOCALS).then(() => {
      API.restangularize(position)
        .remove()
        .then(handleRemoveNonExec(position))
        .catch(API.handleError.bind(this));
    });
  };

  const handleRemoveNonExec = position => {
    let positionIndex = memberState.nonExecPositions.indexOf(position);
    const memberTmp = { ...memberState };
    memberTmp.nonExecPositions.splice(positionIndex, 1);

    setMemberState(memberTmp);
  };

  return memberState && memberState.nonExecPositions.length ? (
    <article className="WcMemberProfileContent__WorkHistory Separator">
      <h2 className="WcMemberProfileContent__Subheading">
        Non-Executive Positions
      </h2>
      {showNonExecAlert && (
        <div className="WcMemberProfileContent__NonExecAlert">
          Now you can add more details, including start and end dates, as well
          as position titles. Your non-executive experience will be visible on
          your profile to both members and clients.
        </div>
      )}
      <div>
        {memberState.nonExecPositions.map(position => (
          <div
            className="WcEditArticle"
            key={position.id}
            id={`"non-exec-"${position.id}`}
          >
            <MemberProfileEditContainer
              onEdit={() => editNonExecPosition(position)}
              onDelete={() => deleteNonExecPosition(position)}
            >
              <div className="flex-grow">
                <NonExecPositionOverview position={position} isOwn />
              </div>
            </MemberProfileEditContainer>
          </div>
        ))}
      </div>
    </article>
  ) : (
    <React.Fragment />
  );
};

OwnMemberProfileNonExecPositions.propTypes = {
  member: PropTypes.object,
  DialogService: PropTypes.any,
  API: PropTypes.any,
  $state: PropTypes.any
};
