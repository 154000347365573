import angular from 'angular';
import { react2angular } from 'react2angular';
import { AnchorNavigationItem } from './AnchorNavigationItem';

export default angular
  .module('wc.components.AnchorNavigationItem', [])
  // @ts-ignore
  .component(
    'reactAnchorNavigationItem',
    react2angular(AnchorNavigationItem, ['target', 'label', 'activeByDefault'])
  );
