import GroupedChips from './GroupedChips';

export default class ChipsSelectGroupController {
  /* @ngInject */
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.model = this.model || [];

    this.groupedChips = new GroupedChips(
      this.model,
      this.groupProperty,
      this.nameProperty,
      'Other',
      this.minSelection,
      this.maxSelection
    );

    this.$scope.$watch(() => {
      return this.options;
    }, this._updateSelectedOptions.bind(this));

    this.$scope.$watchCollection(
      () => {
        return this.model;
      },
      () => {
        this._updateSelectedOptions();
        this._validate();
      }
    );

    // Set default property names
    this.groupProperty = this.groupProperty || 'group';
    this.nameProperty = this.nameProperty || 'name';
    this.idProperty = this.idProperty || 'id';
  }

  isGrouped() {
    return Object.keys(this.groupedChips.groups).length > 1;
  }

  addChip(chip) {
    this.model.push(chip);
  }

  removeChip(chip) {
    this.model.splice(this.model.indexOf(chip), 1);
  }

  onBlur() {
    this.isTouched = true;
  }

  onChipToggle(chip) {
    chip.isSelected = !chip.isSelected;

    if (chip.isSelected) {
      this.addChip(chip);
    } else {
      this.removeChip(chip);
    }

    this._validate();
  }

  onRequired() {
    if (!this.groupedChips.minSelection) {
      this.groupedChips.setMinSelection(1);
    }
  }

  _validate() {
    this.isValid = this.groupedChips.checkIsValid();
    this.statusText = this.groupedChips.getStatusText();
  }

  _updateSelectedOptions() {
    this.options.forEach(option => {
      if (option.isSelected && this.model.indexOf(option) === -1) {
        option.isSelected = false;
      }
    });

    this.model.forEach(selectedOption => {
      let option = this.options.filter(
        option => option.id === selectedOption.id
      )[0];
      if (option) {
        option.isSelected = true;
      } else {
        this.removeChip(selectedOption);
      }
    });

    this.groupedChips.setChips(this.options);
  }

  noop() {
    // Used as an ng-click handler to make the container focusable
  }
}
