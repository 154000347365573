import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MemberProfileOverviewPosition = ({ member }) => {
  return (
    <div className="WcCommonMemberProfileOverviewPersonal">
      <h4 className="WcOtherMemberProfileOverview__Company">
        {member.currentPosition.internalJobTitle} at{' '}
        {member.currentPosition.company.name}
      </h4>
    </div>
  );
};

MemberProfileOverviewPosition.propTypes = {
  member: PropTypes.object
};
