import React from 'react';
import PropTypes from 'prop-types';
import { OwnMemberProfileCareerHistory } from '../../career-history/OwnMemberProfileCareerHistory';
import { OwnMemberProfileFinancePeople } from '../../finance-people/OwnMemberProfileFinancePeople';
import { OwnMemberProfileNonExecPositions } from '../../non-exec-positions/OwnMemberProfileNonExecPositions';
import { OwnMemberProfileRegionalManagement } from '../../regional-management/OwnMemberProfileRegionalManagement';
import { OwnMemberProfileWorkEligibility } from '../../work-eligibility/OwnMemberProfileWorkEligibility';
import { OwnMemberProfileEducation } from '../../education/OwnMemberProfileEducation';
import { OwnMemberProfilePersonal } from '../../personal/OwnMemberProfilePersonal';
import { OwnMemberProfileContacts } from '../../contact-details/OwnMemberProfileContacts';
import { WidthExpander } from '../../../../../width-expander/WidthExpander';

export const OwnMemberProfileExperience = ({
  member,
  user,
  CurrencyService,
  CompensationsBlockedService,
  DialogService,
  API,
  $state,
  AuthenticationService
}) => {
  return (
    <React.Fragment>
      <div id="career">
        <OwnMemberProfileCareerHistory
          member={member}
          CurrencyService={CurrencyService}
          CompensationsBlockedService={CompensationsBlockedService}
          DialogService={DialogService}
          API={API}
          $state={$state}
        />
      </div>
      {member.nonExecPositions.length > 0 && (
        <div id="non-exec-positions">
          <OwnMemberProfileNonExecPositions
            member={member}
            DialogService={DialogService}
            API={API}
            $state={$state}
          />
        </div>
      )}
      <div id="finance-people">
        <OwnMemberProfileFinancePeople
          member={member}
          $state={$state}
          CurrencyService={CurrencyService}
        />
      </div>
      <div id="regional-management">
        <OwnMemberProfileRegionalManagement member={member} $state={$state} />
      </div>
      <div id="work-eligibility">
        <OwnMemberProfileWorkEligibility member={member} $state={$state} />
      </div>
      <div id="education">
        <OwnMemberProfileEducation member={member} $state={$state} />
      </div>
      <div id="personal">
        <OwnMemberProfilePersonal member={member} $state={$state} />
      </div>
      <div id="contacts">
        <OwnMemberProfileContacts
          user={user}
          $state={$state}
          API={API}
          AuthenticationService={AuthenticationService}
        />
      </div>
      <WidthExpander />
    </React.Fragment>
  );
};

OwnMemberProfileExperience.propTypes = {
  member: PropTypes.object,
  user: PropTypes.object,
  CurrencyService: PropTypes.any,
  CompensationsBlockedService: PropTypes.any,
  DialogService: PropTypes.any,
  API: PropTypes.any,
  $state: PropTypes.any,
  AuthenticationService: PropTypes.any
};
