import angular from 'angular';

import templateUrl from './template.html';

class RequestNewTokenController {
  /*@ngInject*/
  constructor(API) {
    this.API = API;
  }

  requestToken() {
    this.API.RequestToken.post({
      email: this.email
    }).then(() => {
      this.success = true;
    });
  }
}

export default angular
  .module('wc.auth.requestNewToken', [])
  .component('wcRequestNewToken', {
    templateUrl: templateUrl,
    controller: RequestNewTokenController
  });
