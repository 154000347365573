import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import moment from 'moment';
import { MessageBubbleUser } from '../../message-bubble-user/MessageBubbleUser';
import { MessageBubblePartner } from '../../message-bubble-partner/MessageBubblePartner';

export const ConversationDetail = ({ conversation, messages, ChatService }) => {
  const [currentUserSid, setCurrentUserSid] = useState();

  useEffect(() => {
    conversation
      .getMemberByIdentity(ChatService.userId)
      .then(member => setCurrentUserSid(member.sid));
  }, [conversation]);

  const isUserMessage = message => {
    return currentUserSid === message.memberSid;
  };

  const shouldMergeBottom = (message, nextMessage) => {
    if (!nextMessage) {
      return false;
    }
    if (message.memberSid !== nextMessage.memberSid) {
      return false;
    }
    return moment(nextMessage.timestamp).diff(message.timestamp, 'days') < 1;
  };

  const shouldMergeTop = (previousMessage, message) => {
    if (!previousMessage) {
      return false;
    }
    if (previousMessage.memberSid !== message.memberSid) {
      return false;
    }
    return (
      moment(message.timestamp).diff(previousMessage.timestamp, 'days') < 1
    );
  };

  if (messages && messages.length) {
    return (
      <div className="wcConversationDetail">
        {messages.map((message, index) =>
          isUserMessage(message) ? (
            <MessageBubbleUser
              key={index}
              isLast={index === messages.length - 1}
              message={message}
              mergeBottom={shouldMergeBottom(message, messages[index + 1])}
              sentAt={message.timestamp}
            />
          ) : (
            <MessageBubblePartner
              key={index}
              isLast={index === messages.length - 1}
              mergeTop={shouldMergeTop(messages[index - 1], message)}
              message={message}
              mergeBottom={shouldMergeBottom(message, messages[index + 1])}
              sentAt={message.timestamp}
            />
          )
        )}
      </div>
    );
  } else {
    return <div className="wcConversationDetail" />;
  }
};

ConversationDetail.propTypes = {
  conversation: PropTypes.object,
  messages: PropTypes.array,
  ChatService: PropTypes.any
};
