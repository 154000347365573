import angular from 'angular';

import commonConfig from '../../common/services/config';
import AuthenticationConfig from './Authentication.constant';
import GoogleMapsConfig from '../../common/config/GoogleMaps.config';
import ThemeConfig from '../../common/services/config/Theme.config';
import apiInterceptors from './ApiInterceptors';
import ENV_CONFIG from './EnvConfig.constant';
import store from '../../common/redux/store';

// angular-feature-flags expects 'angular' to be available on global scope
// Use Webpack's imports-loader to pass the local `angular` variable
// See: https://webpack.github.io/docs/shimming-modules.html
require('imports-loader?angular=angular!angular-feature-flags');

const enableDebug = ENV_CONFIG.configName === 'local';

export default angular
  .module('wc.config', [apiInterceptors.name, commonConfig.name])
  .constant('AuthenticationConfig', AuthenticationConfig)
  .config(ThemeConfig)
  .constant(GoogleMapsConfig.NAME, GoogleMapsConfig)
  .config(
    /*@ngInject*/ function AppConfig(EnvConfigProvider) {
      EnvConfigProvider.setEnvConfig(ENV_CONFIG);
    }
  )
  // Disable debug mode in non-local envs
  .config(
    /*@ngInject*/ function($compileProvider, $logProvider) {
      $logProvider.debugEnabled(enableDebug);
      $compileProvider.debugInfoEnabled(enableDebug);
    }
  )
  .config(
    /*@ngInject*/ $ngReduxProvider => {
      $ngReduxProvider.provideStore(store);
    }
  );
