import { ESC } from '../../../../../services/Keycodes';

export default function collapseOnEsc(scope, element, controller) {
  element.addEventListener('keyup', event => {
    if (event.keyCode === ESC) {
      controller.collapse();
      scope.$apply();
    }
  });
}
