import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileInsights } from './OwnMemberProfileInsights';

export default angular
  .module('wc.components.OwnMemberProfileInsights', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileInsights',
    react2angular(OwnMemberProfileInsights, ['member'], ['$state'])
  );
