import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-grow: 1;
`;

const EducationInfo = styled.p`
  margin: 0;
`;

const EducationSchool = styled(EducationInfo)`
  font-size: 16px;
`;

const SavedItem = ({ education }) => {
  return (
    <Wrapper>
      <EducationSchool>
        <b>{education.school}</b>
      </EducationSchool>
      <EducationInfo>
        {education.qualification.name || education.qualificationName}
      </EducationInfo>
      <EducationInfo>{education.fieldOfStudy}</EducationInfo>
    </Wrapper>
  );
};

SavedItem.propTypes = {
  education: PropTypes.object.isRequired
};

export default SavedItem;
