import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactModal from 'react-modal';

import InverseButton from '../buttons/InverseButton/InverseButton';
import { default as BaseSolidButton } from '../buttons/SolidButton';

const StyledReactModal = styled(({ className, ...props }) => (
  <ReactModal
    portalClassName={className}
    className={{
      base: 'content',
      afterOpen: 'content--after-open',
      beforeClose: 'content--before-close'
    }}
    overlayClassName={{
      base: 'overlay',
      afterOpen: 'overlay--after-open',
      beforeClose: 'overlay--before-close'
    }}
    {...props}
  />
))`
  z-index: 3;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(33, 33, 33, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  .content {
    background: ${({ theme }) => theme.palette.common.white};
    overflow: auto;
    border-radius: 3px;
    outline: none;
    padding: 20px;
    max-width: 80%;
    min-width: 240px;
    max-height: 80%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    opacity: 0;
    transform: scale(0);
    transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);

    &--after-open {
      opacity: 1;
      transform: scale(1);
    }

    &--before-close {
      opacity: 0;
      transform: scale(0);
    }

    @media screen and (min-width: 768px) {
      width: 500px;
    }
  }
`;

const ModalText = styled.div`
  padding: 0 16px;
`;

const Heading = styled.h1`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin: 16px 0 32px 0;
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;

  p {
    font-size: 16px;
    line-height: 20px;
  }

  & > * {
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  ${SolidButton} {
    margin-left: 20px;
  }
`;

const SolidButton = styled(({ ...props }) => <BaseSolidButton {...props} />)`
  &&.root {
    margin-left: 20px;
  }
`;

const Modal = ({
  isOpen,
  confirmAction,
  cancelAction,
  heading,
  contentLabel,
  children
}) => {
  ReactModal.setAppElement('body');

  return (
    <StyledReactModal
      isOpen={isOpen}
      contentLabel={contentLabel || 'Modal'}
      onRequestClose={cancelAction.onClick}
      closeTimeoutMS={800}
    >
      <ModalText>
        <Heading>{heading}</Heading>
        <Content>{children}</Content>
      </ModalText>
      <ButtonContainer>
        <InverseButton
          onClick={cancelAction.onClick}
          transitionDuration="3"
          pullFocus
        >
          {cancelAction.text}
        </InverseButton>
        <SolidButton onClick={confirmAction.onClick}>
          {confirmAction.text}
        </SolidButton>
      </ButtonContainer>
    </StyledReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirmAction: PropTypes.object.isRequired,
  cancelAction: PropTypes.object.isRequired,
  heading: PropTypes.string,
  contentLabel: PropTypes.string,
  children: PropTypes.any
};

export default Modal;
