import { snakeCase } from 'lodash';
import angular from 'angular';

import DocumentNameCell from '../table/cells/DocumentNameCell.html';
import DocumentDownloadCell from '../table/cells/DocumentDownloadCell.html';

import './list.scss';
import templateUrl from './list.html';

class DocumentsListController {
  /*@ngInject*/
  constructor(API, Restangular, DocumentDownloadService, $timeout, $scope) {
    this.API = API;
    this.Restangular = Restangular;
    this.DocumentDownloadService = DocumentDownloadService;
    this.$timeout = $timeout;
    this.documents = [];

    $scope.$on('documents-filter', (event, filters) => {
      this.updateFilters(filters);
    });

    this.headers = [
      {
        name: 'Name',
        include: DocumentNameCell,
        key: 'name',
        sortable: true,
        sortingKey: 'name',
        classes: ['BoldCell', 'Documents__NameCell']
      },
      {
        name: 'Date Added',
        key: 'dateCreated',
        sortable: true,
        sortingKey: 'dateCreated',
        filter: { type: 'date', args: ['MMM d, yyyy'] },
        classes: ['Documents__DateCell']
      },
      {
        sortable: false,
        include: DocumentDownloadCell,
        classes: ['DocumentDownloadCell'],
        priority: 0
      }
    ];

    if (this.showLonglist) {
      this.headers.splice(1, 0, {
        name: 'Project',
        key: 'longlistName',
        sortingKey: 'longlist',
        sortable: true,
        classes: ['DocumentLonglistNameCell']
      });
    }

    this.ordering = {
      field: 'dateCreated',
      reverse: true
    };

    this.extra = {
      downloadDocument: this.downloadDocument.bind(this)
    };

    this.filters = {
      search: ''
    };

    this.loading = true;
  }

  $onInit() {
    this.getDocuments();
  }

  getDocuments() {
    this.endpoint()(this.filters)
      .then(this.setDocuments.bind(this))
      .catch(this.handleError.bind(this));
  }

  downloadDocument(row) {
    this.DocumentDownloadService.download(row, 'documents');
  }

  onInfiniteScroll() {
    return true;
  }

  setDocuments(documents) {
    this.documents = documents;
    this.loading = false;
  }

  updateOrdering(field) {
    this.ordering.field = field;
    let reverseText = this.ordering.reverse ? '-' : '';
    let snake_case = snakeCase(field);
    this.filters.ordering = reverseText + snake_case;
  }

  updateOrderingReverse(bool) {
    this.ordering.reverse = bool;
    this.updateOrdering(this.ordering.field);
    this.getDocuments();
  }

  updateFilters(filters) {
    this.$timeout(() => {
      angular.forEach(filters, (value, key) => {
        this.filters[key] = value;
      });
      this.getDocuments();
    }, 250);
  }

  noDocuments() {
    return !this.documents.length && !this.filters.search;
  }

  noResults() {
    return !this.documents.length && this.filters.search;
  }

  handleError(error) {
    this.downloadInProgress = false;
    this.API.handleError()(error);
  }
}

export default angular
  .module('wc.components.client-documents-list', [])
  .component('wcClientDocumentsList', {
    controller: DocumentsListController,
    templateUrl: templateUrl,
    bindings: {
      loading: '=',
      showLonglist: '<',
      endpoint: '&'
    }
  });
