import angular from 'angular';
import { react2angular } from 'react2angular';
import { ActionBarSeparator } from './Separator';

export default angular
  .module('wc.components.react.actionbar.separator', [])
  // @ts-ignore
  .component(
    'reactActionBarSeparator',
    react2angular(ActionBarSeparator, ['className'], [])
  );
