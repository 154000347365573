import angular from 'angular';

import LonglistDialogsModule from '../../../components/dialogs/longlist/Longlist.module';

import LonglistsRouting from './Longlists.routing';

let moduleDependencies = [LonglistDialogsModule.name];

let LonglistsModule = angular
  .module('wc.clients.longlists', moduleDependencies)
  .config(LonglistsRouting);

export default LonglistsModule;
