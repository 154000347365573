import STATES from '../../services/States';

import templateUrl from './template.html';

export default /*@ngInject*/ function PreviewRouting($stateProvider) {
  $stateProvider.state(STATES.STANDALONE, {
    abstract: true,
    templateUrl: templateUrl
  });

  $stateProvider.state(STATES.STANDALONE_PREVIEW_MEMBER, {
    url: '/preview/:id',
    template: `<wc-member-profile is-client="true" id="profileScrollContainer" wc-sticky-element-container></wc-member-profile>`,
    resolve: {
      /*@ngInject*/
      redirect: function(RedirectService) {
        return RedirectService.redirectIfNoPreviewAccess();
      }
    }
  });
}
