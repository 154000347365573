import './ListCheckboxGroup.scss';
import ListCheckboxGroupTemplateUrl from './ListCheckboxGroup.html';

const inject = ['$log'];

let ListCheckboxGroup = $log => {
  return {
    restrict: 'E',
    scope: {
      title: '@',
      endpoint: '=',
      model: '=',
      labelField: '@?'
    },
    templateUrl: ListCheckboxGroupTemplateUrl,
    link: scope => {
      if (!scope.labelField) {
        scope.labelField = 'name';
      }

      scope.options = [];

      scope.isInModel = option => {
        return scope.model.filter(m => m.id === option.id).length > 0;
      };

      scope.toggle = option => {
        let index = scope.model.indexOf(option);
        if (index !== -1) {
          scope.model.splice(index, 1);
        } else {
          scope.model.push(option);
        }
      };

      scope.getOptions = () => {
        scope.endpoint
          .getList()
          .then(scope.handleSuccess.bind(scope), scope.handleError.bind(scope));
      };

      scope.handleSuccess = options => {
        scope.options = options;
        if (options.length === 0) {
          $log.warn(`Empty list returned`);
        }
      };

      scope.handleError = error => {
        if (
          error &&
          error.status &&
          error.statusText &&
          error.data &&
          error.data.detail &&
          error.config &&
          error.config.url
        ) {
          $log.error(
            `API error: ${error.data.detail} (${error.config.url} ${
              error.status
            } ${error.statusText})`
          );
        } else if (error && error.status && error.statusText) {
          $log.error(`API error: ${error.status} ${error.statusText}`);
        } else {
          $log.error(`Unexpected API error ${error}`, error);
        }
      };

      scope.getOptions();
    }
  };
};

ListCheckboxGroup.NAME = 'listCheckboxGroup';
ListCheckboxGroup.$inject = inject;

export default ListCheckboxGroup;
