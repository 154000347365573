import { tabNames } from './constants';

class SettingsController {
  /*@ngInject*/
  constructor(
    $stateParams,
    API,
    ClientCompensationDialogService,
    CompensationsBlockedService,
    featureFlags
  ) {
    this.API = API;
    this.ClientCompensationDialogService = ClientCompensationDialogService;
    this.CompensationsBlockedService = CompensationsBlockedService;
    this.featureFlags = featureFlags;

    this.userLoading = true;
    this.profileLoading = true;
    this.notificationSettingsLoading = true;
    this.compensationSettingsLoading = true;
    this.shouldShowSettingsTab = false;

    this.updateLoading();

    API.User.get().then(this.setUser.bind(this), API.handleError());
    API.retrieveMyProfile().then(this.setProfile.bind(this), API.handleError());
    API.NotificationSettings.getList().then(
      this.setNotificationSettings.bind(this)
    );

    this.CompensationsBlockedService.isReady.then(() => {
      this.setCompensationSetting(
        !this.CompensationsBlockedService.isBlocked()
      );
    });

    this.goToTab($stateParams.tab);
  }

  goToTab(tabParam) {
    const tabValues = Object.keys(tabNames).map(tabName => {
      return tabNames[tabName];
    });

    if (!this.featureFlags.isOn('messaging')) {
      let messagingFlag = tabValues.indexOf('notifications');
      tabValues.splice(messagingFlag, 1);
    }

    let tabIndex = tabValues.indexOf(tabParam);
    if (tabIndex === -1) {
      tabIndex = 0;
    }

    this.selectedTab = tabIndex;
  }

  showDisableCompensationConfirmDialog() {
    this.ClientCompensationDialogService.showDisableCompensationConfirmDialog().then(
      this.disableCompensation.bind(this)
    );
  }

  showEnableCompensationConfirmDialog() {
    this.ClientCompensationDialogService.showEnableCompensationConfirmDialog();
  }

  setUser(user) {
    this.user = user;
    this.userLoading = false;
    this.updateLoading();
  }

  setProfile(profile) {
    this.profile = profile;
    this.profileLoading = false;
    this.updateLoading();
  }

  setNotificationSettings(notificationSettings) {
    this.notificationSettings = notificationSettings;

    this.notificationSettings.forEach(setting => (setting.display = true));

    this.notificationSettings
      .filter(setting => setting.notificationType.type === 'NMSG')
      .forEach(
        setting =>
          (setting.display =
            setting.display && this.featureFlags.isOn('messaging'))
      );

    this.notificationSettingsLoading = false;
    this.updateLoading();

    this.shouldShowSettingsTab =
      this.notificationSettings.filter(setting => setting.display).length !== 0;
  }

  setCompensationSetting(compensationSetting) {
    this.compensationSetting = compensationSetting;
    this.compensationSettingsLoading = false;
    this.updateLoading();
  }

  onNotificationSettingChange = notificationSetting => {
    this.API.Notifications.one('settings', notificationSetting.id)
      .patch(notificationSetting)
      .catch(this.API.handleError());
  };

  compensationSettingChange = () => {
    if (!this.compensationSetting) {
      this.showEnableCompensationConfirmDialog();
    } else {
      this.showDisableCompensationConfirmDialog();
    }
  };

  disableCompensation() {
    this.compensationSettingsLoading = true;
    this.CompensationsBlockedService.setBlocked().then(() => {
      this.setCompensationSetting(false);
    });
  }

  updateLoading() {
    this.loading =
      this.userLoading ||
      this.profileLoading ||
      this.notificationSettingsLoading ||
      this.compensationSettingsLoading;
  }
}

SettingsController.controllerAs = 'settingsCtrl';
export default SettingsController;
