const STATES = {
  DEVICE_NOT_SUPPORTED: 'deviceNotSupported',
  ROOT: 'root',
  AUTH: 'auth',
  AUTH_REDIRECT: 'authRedirect',
  LOGIN: 'authRedirect.login',
  TOKEN_LEGACY: 'auth.token',
  TOKEN_REDIRECT: 'auth.tokenRedirect',
  TOKEN_LOGIN: 'auth.tokenLogin',
  TOKEN_REGISTER: 'auth.tokenRegister',
  CHANGE_PASSWORD: 'auth.changePassword',
  FORGOTTEN_PASSWORD: 'auth.forgottenPassword',
  NOT_FOUND: 'notFound',
  SERVER_ERROR: 'serverError',
  STANDALONE: 'standalone',
  STANDALONE_PREVIEW_MEMBER: 'standalone.previewMember',
  STANDALONE_PREVIEW_LONGLIST: 'standalone.previewLonglist',
  CLIENTS: 'clients',
  CLIENT_ACCEPT_LEGISLATION: 'clientsAcceptLegislation',
  CLIENT_DASHBOARD: 'clients.dashboard',
  CLIENT_DOCUMENTS: 'clients.documents',
  CLIENT_DOCUMENT_DOWNLOAD: 'clients.documents.download',
  CLIENT_EDIT: 'clientsEdit',
  CLIENT_EDIT_PROFILE: 'clientsEdit.profile',
  CLIENT_EDIT_UPLOAD: 'clientsEdit.upload',
  CLIENT_MESSAGES: 'clients.messages',
  CLIENT_CONVERSATION: 'clients.messages.conversation',
  CLIENT_REGISTRATION: 'clientsRegistration',
  CLIENT_REGISTRATION_PROFILE: 'clientsRegistration.profile',
  CLIENT_REGISTRATION_UPLOAD: 'clientsRegistration.upload',
  CLIENT_PROFILE: 'clients.clientProfile',
  CLIENT_SETTINGS: 'clients.settings',
  SEARCH: 'clients.search',
  LONGLISTS: 'clients.longlistList',
  LONGLIST_DETAIL: 'clients.longlistDetail',
  LONGLIST_DETAIL_MEMBER: 'clients.longlistMember',
  CLIENTS_MEMBER_PROFILE: 'clients.memberProfile',
  CLIENT_CONTRACT: 'clientsContract',
  MEMBERS: 'members',
  MEMBERS_CLIENT: 'members.client',
  MEMBER_REGISTRATION: {
    ROOT: 'members.registration',
    PROFILE: 'members.registration.profile',
    EXPERIENCE: 'members.registration.experience',
    CURRENT_POSITION: 'members.registration.experience.currentPosition',
    REMUNERATION: 'members.registration.remuneration',
    ABOUT_YOU: 'members.registration.aboutYou',
    ASPIRATIONS: 'members.registration.aboutYou.aspirations',
    PERSONALITY: 'members.registration.aboutYou.personality',
    UPLOADS: 'members.registration.aboutYou.uploads'
  },
  MEMBER_AREA: 'members.memberArea',
  MEMBER_GROUP: 'members.memberArea.group',
  MEMBER_GROUPS: 'members.memberArea.groups',
  MEMBER_PROFILE: 'members.memberArea.profile',
  MEMBER_SEARCH_RESULTS: 'members.memberArea.searchResults',
  MEMBER_SETTINGS: 'members.memberArea.settings',
  MEMBER_MESSAGES: 'members.memberArea.messages',
  MEMBER_CONVERSATION: 'members.memberArea.messages.conversation',
  MEMBER_NOTIFICATIONS: 'members.memberArea.notifications',
  OTHER_MEMBER_PROFILE: 'members.memberArea.otherMemberProfile',
  MEMBER_REFERRALS: 'members.memberArea.memberReferrals',
  PASSWORD_CREATION: 'passwordCreation',
  PLAYGROUND: 'playground',
  PLAYGROUND_INPUTS: 'playgroundInputs',
  REQUEST_NEW_TOKEN: 'auth.requestNewToken',
  MEMBER_INLINE_EDIT: {
    ROOT: 'members.inlineEdit',
    PROFILE: 'members.inlineEdit.profile',
    PROFESSIONAL_SUMMARY: 'members.inlineEdit.professionalSummary',
    EXEC_POSITION: 'members.inlineEdit.position',
    EXEC_POSITIONS_LIST: 'members.inlineEdit.positionsList',
    NON_EXEC_POSITION: 'members.inlineEdit.nonExecPosition',
    NON_EXEC_POSITIONS_LIST: 'members.inlineEdit.nonExecPositionsList',
    ELIGIBLE_REGIONS: 'members.inlineEdit.eligibleRegions',
    REGIONS_MANAGED: 'members.inlineEdit.regionsManaged',
    FINANCE_PEOPLE: 'members.inlineEdit.financePeople',
    ASPIRATIONS: {
      ROOT: 'members.inlineEdit.aspirations',
      SUMMARY: 'members.inlineEdit.aspirations.summary',
      NON_EXEC: 'members.inlineEdit.aspirations.nonExec',
      RELOCATION: 'members.inlineEdit.aspirations.relocation',
      JOB_ROLES: 'members.inlineEdit.aspirations.jobRoles',
      INDUSTRIES: 'members.inlineEdit.aspirations.industries',
      CITIES: 'members.inlineEdit.aspirations.cities',
      COMPANIES: 'members.inlineEdit.aspirations.companies',
      COMPENSATION: 'members.inlineEdit.aspirations.compensation'
    },
    INSIGHTS: {
      ROOT: 'members.inlineEdit.insights',
      HOBBIES: 'members.inlineEdit.insights.hobbies',
      QA: 'members.inlineEdit.insights.qA'
    },
    EDUCATION: 'members.inlineEdit.education'
  }
};

export default STATES;
