import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PositionOverview } from '../../../../../../../../common/components/react/position/position-overview/PositionOverview';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import STATES from '../../../../../../../services/States';
import { sortPositions } from '../../../../../../../../common/services/Positions';

const DELETE_POSITION_LOCALS = {
  title: 'Are you sure?',
  content:
    `Deleting this position cannot be undone.<br>` + `Do you want to proceed?`,
  okAction: 'Delete',
  cancelAction: 'Cancel'
};

export const OwnMemberProfileCareerHistory = ({
  member,
  CurrencyService,
  CompensationsBlockedService,
  DialogService,
  API,
  $state
}) => {
  const [memberState, setMemberState] = useState();

  useEffect(() => {
    const memberTmp = { ...member };
    memberTmp.positions = sortPositions(member.positions);
    setMemberState(memberTmp);
  }, [member]);

  const getRemuneration = position => {
    if (position.current) {
      return member.remuneration;
    }
    if (position.totalCompensationCurrency) {
      return position;
    }
  };

  const editPosition = position => {
    $state.go(STATES.MEMBER_INLINE_EDIT.EXEC_POSITION, {
      position: position
    });
  };

  const deletePosition = position => {
    DialogService.showConfirmDialog(DELETE_POSITION_LOCALS).then(() => {
      API.restangularize(position)
        .remove()
        .then(handleRemove(position))
        .catch(API.handleError.bind(this));
    });
  };

  const handleRemove = position => {
    let positionIndex = memberState.positions.indexOf(position);
    const memberTmp = { ...memberState };
    memberTmp.positions.splice(positionIndex, 1);
    setMemberState(memberTmp);
  };

  return member.positions.length !== 0 ? (
    <article className="WcMemberProfileContent__WorkHistory">
      <h2 className="WcMemberProfileContent__Subheading">Career History</h2>
      <div>
        {memberState &&
          memberState.positions.length > 0 &&
          memberState.positions.map(position => (
            <div
              className="WcMemberProfileContent__PositionOverview WcEditArticle"
              key={position.id}
              id={`career-${position.id}`}
            >
              <MemberProfileEditContainer
                onEdit={() => editPosition(position)}
                onDelete={() => deletePosition(position)}
              >
                <div className="flex-grow">
                  <PositionOverview
                    position={position}
                    profile={memberState}
                    remuneration={getRemuneration(position)}
                    showEmpty
                    CurrencyService={CurrencyService}
                    CompensationsBlockedService={CompensationsBlockedService}
                  />
                </div>
              </MemberProfileEditContainer>
            </div>
          ))}
      </div>
    </article>
  ) : (
    <React.Fragment />
  );
};

OwnMemberProfileCareerHistory.propTypes = {
  member: PropTypes.object,
  CurrencyService: PropTypes.any,
  CompensationsBlockedService: PropTypes.any,
  DialogService: PropTypes.any,
  API: PropTypes.any,
  $state: PropTypes.any
};
