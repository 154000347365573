import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { CardList } from '../../../card-list/CardList';
import { Card } from '../../../card/Card';
import { CardContent } from '../../card-content/CardContent';

const NOT_YET_REQUESTED_DIALOG_TEMPLATE = `
      <p class="WcDialog__Message">
        The group owner chose to set this group as private.
      </p>
      <p class="WcDialog__Message">
        You will need to request access from the owner in order to view or participate in the group.
      </p>
    `;

const ALREADY_REQUESTED_DIALOG_TEMPLATE = `
      <p class="WcDialog__Message">
        You have already requested to join this group.
        The owner of the group has been notified and will review your request.
      </p>
    `;

export const GroupList = ({ groups, DialogService, API }) => {
  const shouldNavigateToGroupDetail = group => {
    return !group.isPrivate || group.invited || group.isMember;
  };

  const onPrivateGroupClick = group => {
    if (group.requestedJoin) {
      DialogService.showAlertDialog({
        content: ALREADY_REQUESTED_DIALOG_TEMPLATE,
        title: 'Request pending',
        okAction: 'OK'
      });
    } else {
      DialogService.showConfirmDialog({
        content: NOT_YET_REQUESTED_DIALOG_TEMPLATE,
        title: 'This group is private',
        okAction: 'Request Access'
      })
        .then(() => {
          return API.GroupJoinRequests(group.id).post();
        })
        .then(
          // Success
          () => {
            DialogService.showAlertDialog({
              content: `
            <p class="WcDialog__Message">
              Your request to join has been submitted to the group owner for review
            </p>
          `,
              title: 'Thank you',
              okAction: 'OK'
            });
          },
          // Error
          response => {
            if (typeof response === 'undefined') {
              // cancelled is pressed
              return;
            }

            DialogService.showAlertDialog({
              content: `
            <p class="WcDialog__Message">
              There was an error making the request.
            </p>
          `,
              title: 'Error',
              okAction: 'OK'
            });
          }
        );
    }
  };

  return (
    <CardList>
      <React.Fragment>
        {groups &&
          groups.map(group => (
            <Card key={group.id}>
              {shouldNavigateToGroupDetail(group) ? (
                <a
                  className="WcGroupList__Link"
                  href={`members/group/${group.id}`}
                >
                  <CardContent group={group} />
                </a>
              ) : (
                <button
                  className="WcGroupList__Button"
                  onClick={() => onPrivateGroupClick(group)}
                >
                  <CardContent group={group} />
                </button>
              )}
            </Card>
          ))}
      </React.Fragment>
    </CardList>
  );
};

GroupList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object),
  DialogService: PropTypes.any,
  API: PropTypes.any
};
