import STATES from '../../../services/States';
import ClientProfileCtrl from './ClientProfile.controller';
import ClientProfileTemplate from './ClientProfile.html';

/*@ngInject*/
function ClientProfileRouter($stateProvider) {
  $stateProvider.state(STATES.CLIENT_PROFILE, {
    url: '/profile',
    templateUrl: ClientProfileTemplate,
    controller: ClientProfileCtrl,
    controllerAs: ClientProfileCtrl.controllerAs
  });
}

export default ClientProfileRouter;
