import angular from 'angular';
import { react2angular } from 'react2angular';
import { Alert } from './Alert';

export default angular
  .module('wc.components.ReactAlert', [])
  // @ts-ignore
  .component(
    'reactAlert',
    react2angular(Alert, ['iconClass', 'className', 'message'])
  );
