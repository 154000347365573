import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const Alert = ({ iconClass, className, message }) => {
  return (
    <div className={`WcAlert ${className}`}>
      <span className={`WcAlert__Icon ${iconClass}`} />
      <p className="WcAlert__Message">{message}</p>
    </div>
  );
};

Alert.propTypes = {
  iconClass: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string
};
