import angular from 'angular';
import { react2angular } from 'react2angular';
import { HighlightedImageBubble } from './HighlightedImageBubble';

export default angular
  .module('wc.components.HighlightedImageBubble', [])
  // @ts-ignore
  .component(
    'reactHighlightedImageBubble',
    react2angular(HighlightedImageBubble, ['src', 'label', 'missingImageUrl'])
  );
