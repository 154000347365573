import angular from 'angular';
import 'angular-hotkeys';
import Sidebar from '../../../services/Sidebar.service';

import IconToggleButton from '../../buttons/icontogglebutton';
import templateUrl from '../searchbutton/SearchButton.html';

class SearchButtonCtrl {
  /*@ngInject*/
  constructor($rootScope, hotkeys, wcSidebar, $scope, $state) {
    this.$rootScope = $rootScope;
    this.hotkeys = hotkeys;
    this.wcSidebar = wcSidebar;
    this.$state = $state;

    $scope.$watch(
      () => this.uiSref,
      () => {
        this.stateConfig = $state.get(this.uiSref);
      }
    );

    $scope.$watch(
      () => {
        return this.$state.current;
      },
      () => {
        this.isActive = this.checkIsActive();
      }
    );
  }

  $onInit() {
    this.bindHotkeys();
  }

  bindHotkeys() {
    this.hotkeys.add({
      combo: 'ctrl+s',
      description: 'Search',
      callback: event => {
        event.preventDefault();
        this.toggleSearch();
      }
    });
  }

  checkIsActive() {
    return this.matchesState() || this.matchesUrl();
  }

  matchesState() {
    if (!this.uiSref) {
      return false;
    }

    return this.$state.includes(this.uiSref);
  }

  matchesUrl() {
    if (!this.uiSref) {
      return false;
    }

    return this.$state.current.url.startsWith(this.stateConfig.url);
  }

  toggleSearch() {
    this.wcSidebar.toggle(this.searchSidebarId);
  }
}

const SearchButton = {
  NAME: 'wcSidenavSearchButton',
  bindings: {
    searchSidebarId: '@',
    label: '@',
    uiSref: '@wcUiSref'
  },
  controller: SearchButtonCtrl,
  templateUrl: templateUrl
};

export default angular
  .module('wc.components.sidenav.searchButton', [
    'cfp.hotkeys',
    IconToggleButton.name,
    Sidebar.name
  ])
  .component(SearchButton.NAME, SearchButton);
