export default class LonglistService {
  constructor() {
    this.activeLonglist = null;
  }

  setActiveLonglist(id) {
    this.activeLonglist = id;
  }
}

LonglistService.NAME = 'wcLonglist';
