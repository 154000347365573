import './Card.scss';
import templateUrl from './Card.html';

/*@ngInject*/
const Card = {
  NAME: 'card',
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.card', [])
  .component(Card.NAME, Card);
