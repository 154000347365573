import angular from 'angular';
import template from './template.html';

import STATES from '../../../../services/States';

class AspirationsIndustriesController {
  /*@ngInject*/
  constructor(API, $state) {
    this.API = API;
    this.$state = $state;
    this.toResolve = 1;

    API.retrieveMyAspirations()
      .then(this.setAspirations.bind(this))
      .catch(this.handleError.bind(this));
  }

  setAspirations(aspirations) {
    this.aspirations = aspirations;
    this.toResolve--;
  }

  cancel() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'aspiration-industries'
    });
  }

  sendData(aspirationsData) {
    let saveAspirationPromise;
    if (this.aspirations.url) {
      saveAspirationPromise = this.API.restangularizeUrl(this.aspirations.url)
        .patch;
    } else {
      saveAspirationPromise = this.API.Aspirations.post;
    }

    return saveAspirationPromise(aspirationsData);
  }

  submit() {
    if (this.form.$valid) {
      this.toResolve++;
      let aspirationsPatch = {
        industries: this.API.objectListToIdsSerializerField(
          this.aspirations.industries
        )
      };

      this.sendData(aspirationsPatch)
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
    }
  }

  handleSuccess() {
    this.$state.go(STATES.MEMBER_PROFILE, {
      scrollToSection: 'aspiration-industries'
    });
  }

  handleError(error) {
    this.toResolve = 0;
    this.API.handleError()(error);
  }

  isLoaded() {
    return this.toResolve === 0;
  }
}

const wcInlineEditAspirationsIndustries = {
  controller: AspirationsIndustriesController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.aspirations.industries', [])
  .component(
    'wcInlineEditAspirationsIndustries',
    wcInlineEditAspirationsIndustries
  );
