import angular from 'angular';
import template from './template.html';
import { Actions as ProfilesActions } from '../../../../common/redux/profiles/actions';

const MapDispatchToProps = dispatch => ({
  updateEducations: educations => {
    ProfilesActions.updateEducations(dispatch, educations);
  }
});

class EducationController {
  /*@ngInject*/
  constructor(API, $state, $q, $ngRedux) {
    this.API = API;
    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.$q = $q;
    this.loading = true;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(() => ({}), MapDispatchToProps)(
      this
    );

    let profilePromise = this.API.retrieveMyProfile().then(
      profile => profile.educations
    );

    let qualificationsPromise = this.API.Qualifications.getList().then(
      qualifications => (this.qualifications = qualifications)
    );

    this.$q
      .all([profilePromise, qualificationsPromise])
      .then(([educations, qualifications]) => {
        educations.forEach(education => {
          education.qualification = qualifications.find(
            qualification => qualification.url === education.qualification
          );
        });
        this.updateEducations(educations);
        this.setLoaded();
      })
      .catch(this.handleError.bind(this));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  setLoaded() {
    /**
     * TODO(mihail): this should be set only after we have this.educations
     * in react: check for this.props.educations for this flag
     */
    this.loading = false;
  }

  handleError(error) {
    this.loading = false;
    this.API.handleError()(error);
  }
}

const wcInlineEditEducation = {
  controller: EducationController,
  templateUrl: template
};

export default angular
  .module('wc.components.inlineEdit.education', [])
  .component('wcInlineEditEducation', wcInlineEditEducation);
