import React from 'react';
import PropTypes from 'prop-types';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import { ContactDetails } from '../../../../../../../../common/components/react/member-profile/contact-details/ContactDetails';
import STATES from '../../../../../../../services/States';
import { tabNames } from '../../../../../../../apps/members/member-area/settings/constants';

export const OwnMemberProfileContacts = ({
  user,
  $state,
  API,
  AuthenticationService
}) => {
  const onEdit = () => {
    $state.go(STATES.MEMBER_SETTINGS, { tab: tabNames.ACCOUNT });
  };

  return (
    <div className="WcEditArticle">
      <MemberProfileEditContainer onEdit={onEdit}>
        <div className="flex-grow">
          <ContactDetails
            user={user}
            API={API}
            AuthenticationService={AuthenticationService}
          />
        </div>
      </MemberProfileEditContainer>
    </div>
  );
};

OwnMemberProfileContacts.propTypes = {
  user: PropTypes.object,
  $state: PropTypes.any,
  API: PropTypes.any,
  AuthenticationService: PropTypes.any
};
