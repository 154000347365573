import angular from 'angular';

import template from './template.html';
import './styles.scss';

class NotesDialogController {
  /*@ngInject*/
  constructor($mdDialog, API) {
    this.$mdDialog = $mdDialog;
    this.API = API;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  saveNote = () => {
    this.$mdDialog.hide(this.newNote);
  };

  updateNote = str => {
    this.newNote = str;
  };
}

class NotesDialogService {
  /*@ngInject*/
  constructor(DialogService) {
    this.DialogService = DialogService;
  }

  showMemberNotesDialog(notes) {
    return this.DialogService.show({
      locals: {
        notes: notes
      },
      templateUrl: template,
      controller: NotesDialogController,
      controllerAs: '$ctrl'
    });
  }
}

export default angular
  .module('wc.components.dialogs.notesDialog', [])
  .service('NotesDialogService', NotesDialogService);
