class EditMemberStatusDialogController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
    this.status = this.member.status;
  }

  validate() {
    this.dialogForm.$submitted = true;
    if (this.dialogForm.$valid) {
      this.$mdDialog.hide(this.status);
    }
  }
}

export default EditMemberStatusDialogController;
