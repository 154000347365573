import angular from 'angular';
import { react2angular } from 'react2angular';
import { ClientCompensationSettingsTab } from './ClientCompensationSettingsTab';

export default angular
  .module('wc.components.ReactClientCompensationSettingsTab', [])
  // @ts-ignore
  .component(
    'reactClientCompensationSettingsTab',
    react2angular(ClientCompensationSettingsTab, [
      'compensationSetting',
      'onChange'
    ])
  );
