import angular from 'angular';
import { react2angular } from 'react2angular';
import { MemberProfileContent } from './MemberProfileContent';

export default angular
  .module('wc.components.MemberProfileContent', [])
  // @ts-ignore
  .component(
    'reactMemberProfileContent',
    react2angular(
      MemberProfileContent,
      ['member', 'user', 'groups', 'isOwn', 'isClient'],
      [
        '$state',
        'Resume',
        'API',
        'MessengerService',
        'AuthenticationService',
        'CurrencyService',
        'CompensationsBlockedService',
        'DialogService',
        'GroupInvitesService',
        'GroupDialogService',
        '$q',
        '$stateParams',
        'DetectDeviceService',
        'DocumentDownloadService'
      ]
    )
  );
