import SortableTableTemplateUrl from './SortableTable.html';
import moment from 'moment/moment';

// https://stackoverflow.com/a/41923815
const style = require('sass-extract-loader!./../../styles/site/Sizes.scss');

/*
 Makes scope data available to directives that require sortable-table eg. sortable-table-checkboxes
 */
class SortableTableController {
  /*@ngInject*/
  constructor($scope, CurrencyService, AuthenticationService) {
    this.headers = $scope.headers;
    this.data = $scope.data;
    this.extra = $scope.extra;
    this.ordering = $scope.ordering;

    $scope.scrollTopOffset = style.global['$view-toolbar-height'].value;
    if (!$scope.noSidenavOffset) {
      $scope.scrollTopOffset += style.global['$wc-sidenav-height'].value;
    }
    $scope.canContact = false;
    AuthenticationService.getProfile().then(profile => {
      $scope.canContact = profile && !profile.hideContact ? true : false;
    });

    $scope.$watch('data', () => {
      this.data = $scope.data;
      if (!this.data || !this.data.length) {
        return;
      }
      this.data.forEach(row => {
        if (row.totalCompensationCurrency) {
          CurrencyService.isReady.then(() => {
            row.totalCompensationSymbol = CurrencyService.getCurrencySymbol(
              row.totalCompensationCurrency
            );
          });
        }
      });
    });
  }
}

/*@ngInject*/
let SortableTableDirective = () => {
  return {
    restrict: 'E',
    scope: {
      addNew: '&?',
      showAdd: '=?',
      headers: '=',
      data: '=',
      filter: '=',
      pkColumn: '=?',
      ordering: '<',
      noSidenavOffset: '<',
      extra: '=', // Object containing extra data required by subcomponents eg. dropdown options,
      onInfiniteScroll: '&',
      onUpdateOrderingField: '&',
      onUpdateOrderingReverse: '&',
      showLonglistActions: '=',
      userProfileState: '@'
    },
    replace: true,
    templateUrl: SortableTableTemplateUrl,
    link: scope => {
      if (!scope.pkColumn) {
        scope.pkColumn = 'id';
      }
      scope.moment = moment;
    },
    controller: /*@ngInject*/ SortableTableController
  };
};

SortableTableDirective.NAME = 'sortableTable';

export default SortableTableDirective;
