import angular from 'angular';

class RelatedFieldsGroup {
  constructor(relatedFieldsMap) {
    this.relatedFieldsMap = relatedFieldsMap;
    this.transformFieldsMap();
  }

  setModel(model) {
    this.model = model;
  }

  setSuggestionsModel(suggestionsModel) {
    this.suggestionsModel = suggestionsModel;
  }

  transformFieldsMap() {
    Object.keys(this.relatedFieldsMap).forEach(key => {
      this.relatedFieldsMap[key].relatedFields = this.relatedFieldsMap[key];
      this.relatedFieldsMap[key].isDisabled = false;
    });
  }

  getRelatedFields(fieldName) {
    let relatedFields = [];
    const directlyRelatedFields = this.relatedFieldsMap[fieldName];

    directlyRelatedFields.forEach(directlyRelatedField => {
      relatedFields.push(directlyRelatedField);

      this.getRelatedFields(directlyRelatedField).forEach(
        indirectlyRelatedField => {
          relatedFields.push(indirectlyRelatedField);
        }
      );
    });

    return relatedFields;
  }

  setDisabled(fieldName, isDisabled) {
    this.relatedFieldsMap[fieldName].isDisabled = isDisabled;
  }

  isDisabled(fieldName) {
    return this.relatedFieldsMap[fieldName].isDisabled;
  }

  setValid(fieldName, isValid) {
    this.relatedFieldsMap[fieldName].isValid = isValid;
  }

  hasValidDependentFields(fieldName) {
    let keys = Object.keys(this.relatedFieldsMap);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const field = this.relatedFieldsMap[key];

      if (field.relatedFields.indexOf(fieldName) > -1 && !field.isValid) {
        return false;
      }
    }

    return true;
  }

  disableRelatedFields(fieldName) {
    const fieldsToDisable = this.getRelatedFields(fieldName);

    fieldsToDisable.forEach(field => {
      this.setDisabled(field, true);
    });
  }

  onSuggest(fieldName) {
    const fieldsToDisable = this.getRelatedFields(fieldName);

    fieldsToDisable.forEach(field => {
      if (this.suggestionsModel[field] && this.suggestionsModel[field].length) {
        return;
      }

      this.setDisabled(field, true);
      this.model[field] = [];
      this.suggestionsModel[field] = [];
    });
  }

  onClearSuggestions(fieldName) {
    if (!this.model[fieldName] || !this.model[fieldName].length) {
      this.setValid(fieldName, false);
    }

    const fieldsToEnable = this.getRelatedFields(fieldName);

    fieldsToEnable.forEach(field => {
      if (this.hasValidDependentFields(field)) {
        this.setDisabled(field, false);
      }
    });
  }

  onValid = (fieldName, hasSuggestion) => {
    if (hasSuggestion) {
      return;
    }

    const fieldsToEnable = this.relatedFieldsMap[fieldName];

    this.setValid(fieldName, true);

    fieldsToEnable.forEach(field => {
      this.setDisabled(field, false);
    });
  };

  onInvalid = fieldName => {
    this.setValid(fieldName, false);
    this.disableRelatedFields(fieldName);
  };
}

function RelatedFieldsGroupFactory() {
  return function(relatedFieldsMap) {
    return new RelatedFieldsGroup(relatedFieldsMap);
  };
}

RelatedFieldsGroupFactory.NAME = 'RelatedFieldsGroup';

export default angular
  .module('wc.members.editProfile.relatedFieldsGroup', [])
  .factory(RelatedFieldsGroupFactory.NAME, RelatedFieldsGroupFactory);
