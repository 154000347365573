export default class ChangeContactDialogController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
  }

  submit() {
    this.form.$submitted = true;
    if (this.form.$valid && this.reactFormValid()) {
      this.$mdDialog.hide({
        password: this.password,
        currentPassword: this.password
      });
    }
  }
}
