import angular from 'angular';

import NotInPathWellBlacklist from './notinpathwellblacklist/NotInPathWellBlacklist.directive';
import RequiredChips from './chips/RequiredChips.directive';
import RequireDigits from './requiredigits/RequireDigits.directive';
import RequireLowercaseCharacter from './requirelowercasecharacters/RequireLowercaseCharacters.directive';
import RequireUppercaseCharacter from './requireuppercasecharacters/RequireUppercaseCharacters.directive';
import RequireSpecialCharacter from './requirespecialcharacters/RequireSpecialCharacters.directive';
import StrongPassword from './strongpassword/StrongPassword.directive';

let InputsModule = angular
  .module('wc.components.validators', [])
  .directive(NotInPathWellBlacklist.NAME, NotInPathWellBlacklist)
  .directive(RequiredChips.NAME, RequiredChips)
  .directive(RequireDigits.NAME, RequireDigits)
  .directive(RequireLowercaseCharacter.NAME, RequireLowercaseCharacter)
  .directive(RequireUppercaseCharacter.NAME, RequireUppercaseCharacter)
  .directive(RequireSpecialCharacter.NAME, RequireSpecialCharacter)
  .directive(StrongPassword.NAME, StrongPassword);

export default InputsModule;
