import angular from 'angular';
import { react2angular } from 'react2angular';
import { MessageBubbleUser } from './MessageBubbleUser';

export default angular
  .module('wc.components.ReactMessageBubbleUser', [])
  // @ts-ignore
  .component(
    'reactMessageBubbleUser',
    react2angular(MessageBubbleUser, [
      'isLast',
      'message',
      'mergeBottom',
      'sentAt'
    ])
  );
