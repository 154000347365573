import './UnsupportedDevice.scss';
import UnsupportedDeviceRouting from './UnsupportedDevice.routing';

const dependencies = [];

let UnsupportedDeviceModule = angular
  .module('wc.unsupportedDevice', dependencies)
  .config(UnsupportedDeviceRouting);

export default UnsupportedDeviceModule;
