export default class ChangeConvisibilityDialogController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
  }

  submit() {
    this.form.$submitted = true;
    if (this.form.$valid) {
      let patchData = {
        covisibility: this.covisibility
      };

      this.onChange(patchData);

      this.$mdDialog.hide(this.user);
    }
  }
}
