import React from 'react';
import PropTypes from 'prop-types';
import angular from 'angular';
import { react2angular } from 'react2angular';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import EducationAccordion from '../../EducationsAccordion';
import theme from '../../../../../common/styles/theme';

// Fran: This component needs to be deleted once profile component is in react
const EducationAccordionWrapper = ({
  qualifications,
  showBackToProfile = false,
  profileSection = '',
  $state,
  $ngRedux,
  API,
  $scope
}) => {
  return (
    <Provider store={$ngRedux}>
      <ThemeProvider theme={theme}>
        <EducationAccordion
          qualifications={qualifications}
          showBackToProfile={showBackToProfile}
          profileSection={profileSection}
          $state={$state}
          API={API}
          $scope={$scope}
        />
      </ThemeProvider>
    </Provider>
  );
};

EducationAccordionWrapper.propTypes = {
  qualifications: PropTypes.array.isRequired,
  showBackToProfile: PropTypes.bool,
  profileSection: PropTypes.string,
  $state: PropTypes.object.isRequired,
  $ngRedux: PropTypes.object.isRequired,
  API: PropTypes.object.isRequired,
  $scope: PropTypes.object.isRequired
};

export default angular
  .module('dfComponents.reactEducationAccordion', [])
  .component(
    'reactEducationAccordion',
    react2angular(
      EducationAccordionWrapper,
      ['qualifications', 'showBackToProfile', 'profileSection'],
      ['$state', '$ngRedux', 'API', '$scope']
    )
  );
