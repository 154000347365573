import './ChipsDrawer.scss';
import controller from './ChipsDrawer.controller';
import templateUrl from './ChipsDrawer.html';
import link from './ChipsDrawer.link';

import bindValidationBehaviour from './behaviours/ChipsDropdownValidation';
import collapseOnEsc from './behaviours/CollapseOnEsc';

/*@ngInject*/
function wcChipsDrawer($document, $timeout) {
  return {
    restrict: 'E',
    templateUrl: templateUrl,
    controller: controller,
    controllerAs: controller.controllerAs,
    bindToController: true,
    require: {
      wcChipsDrawerController: 'wcChipsDrawer',
      ngModelController: 'ngModel',
      formController: '^form'
    },
    scope: {
      model: '=ngModel',
      endpoint: '&',
      label: '@',
      labelProperty: '@?',
      groupLabelProperty: '@?',
      min: '@',
      max: '@',
      tooltip: '@',
      filterOptions: '<',
      onChange: '&'
    },
    link: (scope, element, attributes, controllers) => {
      const wcChipsDrawerCtrl = controllers.wcChipsDrawerController;
      const ngModelCtrl = controllers.ngModelController;
      const formCtrl = controllers.formController;

      wcChipsDrawerCtrl.ngModelController = ngModelCtrl;

      bindValidationBehaviour(
        scope,
        attributes,
        wcChipsDrawerCtrl,
        ngModelCtrl,
        formCtrl
      );
      collapseOnEsc(scope, element[0], wcChipsDrawerCtrl);
      return link(
        scope,
        element,
        attributes,
        wcChipsDrawerCtrl,
        $document,
        $timeout
      );
    }
  };
}

wcChipsDrawer.NAME = 'wcChipsDrawer';
export default wcChipsDrawer;
