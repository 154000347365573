import STATES from '../../../../services/States';

import SearchResultsController from './SearchResults.controller';
import SearchResults from './SearchResults.html';

/*@ngInject*/
function routeMemberSearchResults($stateProvider) {
  $stateProvider.state(STATES.MEMBER_SEARCH_RESULTS, {
    url: '/search',
    templateUrl: SearchResults,
    controller: SearchResultsController,
    controllerAs: SearchResultsController.controllerAs,
    resolve: {
      /*@ngInject*/
      redirect: function(RedirectService) {
        // Only Members should be allowed to access Member Search.
        //
        // Probably this should be narrowed down to complete Members, as incomplete Members are
        // supposed to be redirected to Registration.
        //
        // Refactoring first, can tighten up later.
        return RedirectService.redirectIfNotMemberPublic();
      }
    }
  });
}

export default angular
  .module('wc.members.memberArea.searchResults', [])
  .config(routeMemberSearchResults);
