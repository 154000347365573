import angular from 'angular';

import STATES from '../../services/States';
import './styles.scss';
import templateUrl from './template.html';

class PostController {
  /*@ngInject*/
  constructor($element, $timeout, API, $state) {
    this.$element = $element;
    this.$timeout = $timeout;
    this.API = API;
    this.$state = $state;

    this.displayCommentBoxOverride = false;
    this.memberProfileState = STATES.OTHER_MEMBER_PROFILE;
  }

  onClickCommentButton() {
    this.displayCommentBoxOverride = true;
    this.$timeout(() => {
      $(this.$element)
        .find('textarea')
        .focus();
    });
  }

  submitComment = () => {
    if (!this.newCommentText) {
      return;
    }

    this.API.PostComments(this.group.id, this.post.id)
      .post({
        content: this.newCommentText
      })
      .then(response => {
        this.post.comments.push(response);
        this.newCommentText = '';
      });
  };

  updateComment = str => {
    this.newCommentText = str;
  };

  shouldDisplayNewCommentBox() {
    return (
      this.group.isMember &&
      (this.post.comments.length > 0 || this.displayCommentBoxOverride)
    );
  }
}

export default angular.module('wc.components.post', []).component('wcPost', {
  bindings: {
    group: '<',
    canComment: '<',
    post: '<',
    member: '<'
  },
  controller: PostController,
  templateUrl: templateUrl
});
