import angular from 'angular';
import { react2angular } from 'react2angular';
import { CompanyPrivacySettings } from './CompanyPrivacySettings';

export default angular
  .module('wc.components.CompanyPrivacySettings', [])
  // @ts-ignore
  .component(
    'reactCompanyPrivacySettings',
    react2angular(CompanyPrivacySettings, [
      'onChange',
      'onDefaultChanged',
      'remunerationBlockedDefault',
      'companiesWithCompensationBlocked',
      'companies'
    ])
  );
