import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const DateCell = ({ date, format }) => {
  return <span>{moment(date).format(format)}</span>;
};

DateCell.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string
};
