import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import { BooleanChip } from '../../../../../../../../common/components/react/chips/BooleanChip';
import { ReactChips } from '../../../../../ReactChips/ReactChips';
import STATES from '../../../../../../../services/States';

export const OwnMemberProfileAspirations = ({ member, $state }) => {
  const editAspirationsCompensation = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.COMPENSATION);
  };

  const editAspirationsSummary = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.SUMMARY);
  };

  const editAspirationsNonExec = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.NON_EXEC);
  };

  const editAspirationsRelocation = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.RELOCATION);
  };

  const editAspirationsJobRoles = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.JOB_ROLES);
  };

  const editAspirationsIndustries = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.INDUSTRIES);
  };

  const editAspirationsCities = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.CITIES);
  };

  const editAspirationsCompanies = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.ASPIRATIONS.COMPANIES);
  };

  return (
    <React.Fragment>
      <article className="Separator">
        <div className="WcEditArticle" id="aspiration-goals">
          <MemberProfileEditContainer onEdit={editAspirationsSummary}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">Summary</h2>
              {member.aspirations && member.aspirations.goals && (
                <p className="WcFormattedParagraph">
                  {member.aspirations.goals}
                </p>
              )}
            </div>
          </MemberProfileEditContainer>
        </div>

        <div className="WcEditArticle" id="aspiration-compensation">
          <MemberProfileEditContainer onEdit={editAspirationsCompensation}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">
                Compensation Expectations
              </h2>
              {member.aspirationalComp ? (
                <p>{member.aspirationalComp}</p>
              ) : (
                <p className="WcMemberProfileContent__AspirationCompensation__Empty">
                  Please provide details on what you would expect your total
                  compensation to be in your next role. Adding expectations to
                  your profile will help ensure you are seen for the most
                  relevant roles. This data is not visible to other members.
                </p>
              )}
            </div>
          </MemberProfileEditContainer>
        </div>

        <div className="WcEditArticle" id="aspiration-relocation">
          <MemberProfileEditContainer onEdit={editAspirationsRelocation}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">Relocation</h2>
              <BooleanChip
                title="Would relocate for the right role"
                active={member.openToRelocate}
                falseTitle="Not open to relocate"
              />
            </div>
          </MemberProfileEditContainer>
        </div>

        <div className="WcEditArticle" id="aspiration-non-execs">
          <MemberProfileEditContainer onEdit={editAspirationsNonExec}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">
                Non-Executive
              </h2>
              <BooleanChip
                title="Interested in Non-Executive opportunities"
                active={member.interestedInNonExec}
                falseTitle="Not interested in Non-Executive opportunities"
              />
            </div>
          </MemberProfileEditContainer>
        </div>
      </article>

      <article className="Separator">
        <div className="WcEditArticle" id="aspiration-companies">
          <MemberProfileEditContainer onEdit={editAspirationsCompanies}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">Companies</h2>
              <ReactChips
                readOnly={true}
                data={member.aspirations.companies}
                accent={true}
              />
            </div>
          </MemberProfileEditContainer>
        </div>
      </article>

      <article className="Separator">
        <div className="WcEditArticle" id="aspiration-cities">
          <MemberProfileEditContainer onEdit={editAspirationsCities}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">Cities</h2>
              <ReactChips
                readOnly={true}
                data={member.aspirations.cities}
                accent={true}
              />
            </div>
          </MemberProfileEditContainer>
        </div>
      </article>

      <article className="Separator">
        <div className="WcEditArticle" id="aspiration-job-roles">
          <MemberProfileEditContainer onEdit={editAspirationsJobRoles}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">Job Roles</h2>
              <ReactChips
                readOnly={true}
                data={member.aspirations.industryStandardJobTitles}
                accent={true}
              />
            </div>
          </MemberProfileEditContainer>
        </div>
      </article>

      <article className="Separator">
        <div className="WcEditArticle" id="aspiration-industries">
          <MemberProfileEditContainer onEdit={editAspirationsIndustries}>
            <div className="flex-grow">
              <h2 className="WcMemberProfileContent__Subheading">Industries</h2>
              <ReactChips
                readOnly={true}
                data={member.aspirations.industries}
                accent={true}
              />
            </div>
          </MemberProfileEditContainer>
        </div>
      </article>

      <df-width-expander />
    </React.Fragment>
  );
};

OwnMemberProfileAspirations.propTypes = {
  member: PropTypes.object,
  $state: PropTypes.any
};
