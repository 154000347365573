import log from 'loglevel';

const logger = log.getLogger('Dropdown supercontroller');

/*
 * Controller superclass for custom dropdown inputs.
 */
class DropdownController {
  constructor() {
    this.collapse();
    this.wasClicked = false;
  }

  _onFocus() {
    logger.debug('focus');
    this.expand();
  }

  _onBlur() {
    logger.debug('blur');
    this.collapse();
    this.wasClicked = false;
  }

  _onClick() {
    logger.debug('click');
    this.toggleExpand();
  }

  collapse() {
    this._isExpanded = false;
  }

  expand() {
    this._isExpanded = true;
  }

  toggleExpand() {
    if (!this.wasClicked) {
      this.wasClicked = true;
      return;
    }

    this._isExpanded = !this._isExpanded;
  }

  onCollapse() {
    this.isTouched = true;
  }
}

DropdownController.$inject = [];
export default DropdownController;
