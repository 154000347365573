import CheckboxQuestionTemplate from './CheckboxQuestion.html';

function checkboxQuestion() {
  return {
    restrict: 'E',
    scope: {
      fieldName: '@',
      model: '=',
      tooltip: '@?'
    },
    transclude: true,
    templateUrl: CheckboxQuestionTemplate
  };
}

checkboxQuestion.NAME = 'checkboxQuestion';

export default checkboxQuestion;
