import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const OpaqueActionBarScrollContainer = ({ children, className }) => {
  return (
    <div className="wc-opaque-action-bar-view">
      <div className={`WcOpaqueActionBarScrollContainer ${className}`}>
        {children}
      </div>
    </div>
  );
};

OpaqueActionBarScrollContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};
