import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MemberProfileEditContainer } from './../../../../edit-container/MemberProfileEditContainer';

export const MemberProfileOverviewProfessionalSummary = ({
  member,
  onEdit,
  stickyEdit
}) => {
  const [
    professionalSummaryExpanded,
    setProfessionalSummaryExpanded
  ] = useState(false);
  return (
    <div
      className={`WcCommonMemberProfileOverview__ProfessionalSummaryContainer
               ${
                 !member.professionalSummary
                   ? 'WcCommonMemberProfileOverview__ProfessionalSummaryContainer--noExpandButton'
                   : ''
               }`}
    >
      <MemberProfileEditContainer
        member={member}
        onEdit={onEdit}
        stickyEdit={stickyEdit}
      >
        <div
          className="WcCommonMemberProfileOverviewPersonal"
          onClick={() => setProfessionalSummaryExpanded(state => !state)}
        >
          {member.professionalSummary && (
            <div
              className={`WcCommonMemberProfileOverview__ProfessionalSummary ${
                !professionalSummaryExpanded
                  ? 'WcCommonMemberProfileOverview__ProfessionalSummary--clamped'
                  : ''
              }`}
            >
              {professionalSummaryExpanded ? (
                <div className="WcCommonMemberProfileOverview__ProfessionalSummaryText">
                  {member.professionalSummary}
                </div>
              ) : (
                <div
                  className="WcCommonMemberProfileOverview__ProfessionalSummaryText"
                  // eslint-disable-next-line
                  clamp="2"
                >
                  {member.professionalSummary}
                </div>
              )}
            </div>
          )}
          {!member.professionalSummary && (
            <div className="WcCommonMemberProfileOverview__ProfessionalSummary--emptySummary">
              Boost your visibility in client search results by writing a
              professional summary. Other members will also be able to read your
              summary to learn more about your career.
            </div>
          )}
        </div>
      </MemberProfileEditContainer>
      {member.professionalSummary && (
        <div
          className="WcCommonMemberProfileOverview__ProfessionalSummaryMoreButton"
          onClick={() => setProfessionalSummaryExpanded(state => !state)}
        >
          {professionalSummaryExpanded ? (
            <span className="zmdi zmdi-chevron-up" />
          ) : (
            <span className="zmdi zmdi-chevron-down" />
          )}
        </div>
      )}
    </div>
  );
};

MemberProfileOverviewProfessionalSummary.propTypes = {
  member: PropTypes.object,
  onEdit: PropTypes.func,
  stickyEdit: PropTypes.bool
};
