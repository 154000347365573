import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const LayoutToggleSwitch = ({ MemberListUIService, $scope }) => {
  const [gridLayout, setGridLayout] = useState(
    MemberListUIService.viewOptions.gridLayout
  );

  const toggle = () => {
    MemberListUIService.toggleLayout();
    setGridLayout(MemberListUIService.viewOptions.gridLayout);
    $scope.$apply();
  };

  return (
    <button
      className="md-icon-button WcMemberListLayoutToggleSwitch"
      onClick={toggle}
      aria-label="Toggle grid layout"
    >
      <div className="WcMemberListLayoutToggleSwitch__IconsContainer">
        <span
          className={`WcMemberListLayoutToggleSwitch__Icon zmdi zmdi-apps zmdi-hc-lg ${
            !gridLayout ? 'WcMemberListLayoutToggleSwitch__Icon--Disabled' : ''
          }`}
        />
        <span
          className={`WcMemberListLayoutToggleSwitch__Icon zmdi zmdi-storage zmdi-hc-lg ${
            gridLayout ? 'WcMemberListLayoutToggleSwitch__Icon--Disabled' : ''
          }`}
        />
      </div>
    </button>
  );
};

LayoutToggleSwitch.propTypes = {
  MemberListUIService: PropTypes.object.isRequired,
  $scope: PropTypes.object.isRequired
};
