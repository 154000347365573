import MembersRegistrationController from './Registration.controller';
import MembersRegistrationTemplateUrl from './Registration.html';
import RemunerationController from './tabs/Remuneration.controller';
import RemunerationTemplate from './tabs/Remuneration.html';
import STATES from '../../../services/States';

/*@ngInject*/
function MembersRegistrationRouting($stateProvider, $urlRouterProvider) {
  $stateProvider
    .state(STATES.MEMBER_REGISTRATION.ROOT, {
      url: '/registration',
      templateUrl: MembersRegistrationTemplateUrl,
      controller: MembersRegistrationController,
      controllerAs: MembersRegistrationController.controllerAs,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfNotMemberOrAlreadyComplete();
        }
      }
    })

    .state(STATES.MEMBER_REGISTRATION.PROFILE, {
      url: '/profile',
      template: `<react-registration-profile></react-registration-profile>`
    })

    .state(STATES.MEMBER_REGISTRATION.REMUNERATION, {
      url: '/remuneration',
      templateUrl: RemunerationTemplate,
      controller: RemunerationController,
      controllerAs: RemunerationController.controllerAs
    });

  $urlRouterProvider.when(
    '/members/registration',
    '/members/registration/profile'
  );
  $urlRouterProvider.when(
    '/members/registration/about-you',
    '/members/registration/about-you/aspirations'
  );
}

export default MembersRegistrationRouting;
