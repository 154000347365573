import states from '../../../services/States';

class DashboardController {
  /*@ngInject*/
  constructor(API) {
    this.API = API;

    this.states = states;
    this.items = [
      {
        id: 'EXEC_SEARCH',
        symbol: 'mh',
        name: 'Executive Search',
        content:
          'Build the best senior teams using our flagship service - Marlin Hawk Executive Search. Bespoke to each client’s demands, we conduct thorough research, full candidate assessment and create an exclusive shortlist for the role, managing the candidate through the entire process and beyond.'
      },
      {
        id: 'PIPELINING',
        symbol: 'mh',
        name: 'Succession Planning',
        content:
          'Create solid succession plans, diversity and talent pipelines for your senior teams. Marlin Hawk will proactively support your strategy to develop an external pool of leaders who will fit into your organisation and help drive growth. These executives will be fully assessed, actively engaged and ready to build relationships with your teams.'
      },
      {
        id: 'ORG',
        symbol: 'mh',
        name: 'Strategic & Organisational Analysis',
        content:
          'Elevate your talent planning with our Strategic & Organisational Analysis service. Consulting on your strategy and growth plans, we span multiple industries and geographies to provide detailed insights on competitors’ structure and talent strategies. We analyse key data from across the market to identify the best talent plans for the future of your organisation.'
      },
      {
        id: 'RESEARCH',
        symbol: 'wc',
        name: 'Executive Research & Talent Pipelining',
        content:
          'Executive research, delivered digitally. The pioneering Winter Circle offering enables direct executive search in organisations that wish to do their own assessment and candidate management. We deliver a longlist of engaged global executives for your open roles, succession planning, and talent and diversity pipelines.'
      },
      {
        id: 'INTERIM',
        symbol: 'mh',
        name: 'Interim Management',
        content:
          'Access an unparalleled global network of referenced and vetted senior executives through our Interim Management consultancy. Find the best leaders for your team flexibly and efficiently, in a way that works for your organisation. Respond quickly to sudden changes or opportunities with the right leaders, at the right time.'
      }
    ];

    this.API.listMyLonglists().then(this.setLonglists.bind(this));
  }

  setLonglists(longlists) {
    this.longlists = longlists;

    this.longlistsPerCategory = {};
    this.items = this.items.map(item => {
      this.longlistsPerCategory[item.id] = this.getLonglistsInTab(
        this.longlists,
        item.id
      );
      item.length = this.longlistsPerCategory[item.id].length;
      return item;
    });
  }

  getLonglistsInTab(longlists, tab) {
    let filtered = longlists.filter(item => {
      if (tab) {
        return item.category === tab;
      }
      return !item.category;
    });
    return filtered;
  }

  getSymbolImage(symbol) {
    return require('./img/' + symbol + '-symbol.png');
  }
}

DashboardController.NAME = 'DashboardController';
DashboardController.controllerAs = 'dashboard';

export default DashboardController;
