import routing from './ClientProfile.routing';

import ProfileEditLink from '../../../components/profile/profileeditlink';
import EditButton from '../../../components/buttons/editbutton';
import ProfileLayout from '../../../components/profile/layout';
import OverviewCard from '../../../../common/components/overviewcard';
import ParagraphSeparator from '../../../components/separators/paragraphseparator';

import './ClientProfile.scss';

import { SectionHeader, WeakParagraph } from '../../../components/typography';
import ReactSectionHeader from '../../../components/react/typography/section-header';

const dependencies = [
  EditButton.name,
  OverviewCard.name,
  ParagraphSeparator.name,
  ProfileLayout.name,
  SectionHeader.name,
  WeakParagraph.name,
  ReactSectionHeader.name
];

export default angular
  .module('wc.clients.clientProfile', dependencies)
  .component(ProfileEditLink.NAME, ProfileEditLink) // Now it's needed only here
  .config(routing);
