import OverflowMenuTemplate from './OverflowMenu.html';
import './OverflowMenu.scss';

let overflowMenu = () => {
  return {
    scope: {
      menuItems: '=',
      menuItemKey: '@?',
      element: '=',
      isSquare: '='
    },
    restrict: 'E',
    templateUrl: OverflowMenuTemplate,
    link(scope) {
      scope.menuItemKey = scope.menuItemKey ? scope.menuItemKey : 'text';
    }
  };
};

overflowMenu.NAME = 'overflowMenu';

export default overflowMenu;
