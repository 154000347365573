import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

function drawerContainer() {
  return {
    templateUrl: templateUrl,
    transclude: true,
    bindToController: true,
    controller: () => {},
    controllerAs: '$ctrl',
    scope: {
      isExpanded: '<',
      onCollapse: '&'
    },
    link: (scope, element, attributes, ctrl) => {
      scope.$watch(
        () => ctrl.isExpanded,
        (newValue, oldValue) => {
          if (oldValue === true && newValue === false) {
            ctrl.onCollapse();
          }
        }
      );
    }
  };
}

const dependencies = [];

export default angular
  .module('dfComponents.inputs.drawerContainer', dependencies)
  .directive('wcDrawerContainer', drawerContainer);
