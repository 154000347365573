import { USER_TYPE_DISPLAY } from '../../../common/services/api/APIConstants';
import commonAuthenticationService from '../../../common/services/CommonAuthentication';
import dialogsModule from '../../components/dialogs/Dialogs.module';
import memberListsModule from '../../services/MemberLists.service';
import states from '../../services/States';

class AuthenticationService {
  /*@ngInject*/
  constructor(
    $log,
    $state,
    CommonAuthenticationService,
    SignoutDialogService,
    API
  ) {
    this.$log = $log;
    this.API = API;
    this.$state = $state;
    this.CommonAuthenticationService = CommonAuthenticationService;
    this.SignoutDialogService = SignoutDialogService;
  }

  // Hide the fact that user is actually stored in CommonAuthenticationService
  get user() {
    return this.CommonAuthenticationService.user;
  }

  canLogout() {
    return this.CommonAuthenticationService.canLogout();
  }

  /**
   * .getAuthenticatedUser() must be called before checking any auth-related state (eg. .isMember())
   * to ensure that the AuthenticationService has up-to-date info.
   *
   * @returns A Promise that resolves with the logged in user.
   */
  getAuthenticatedUser() {
    return this.CommonAuthenticationService.getAuthenticatedUser();
  }

  getProfile() {
    return this.CommonAuthenticationService.getProfile();
  }

  getUserTypeDisplay() {
    if (!this.user || !this.user.type) {
      return null;
    }
    return USER_TYPE_DISPLAY[this.user.type];
  }

  hasChosenPassword() {
    return this.CommonAuthenticationService.hasChosenPassword();
  }

  hasSession() {
    return this.CommonAuthenticationService.hasSession();
  }

  hasUser() {
    return this.CommonAuthenticationService.hasUser();
  }

  isClient() {
    return this.CommonAuthenticationService.isClient();
  }

  isLimitedClient() {
    return this.CommonAuthenticationService.isLimitedClient();
  }

  isMessagingDisabled() {
    return this.CommonAuthenticationService.isMessagingDisabled();
  }

  isLoggedIn() {
    return this.CommonAuthenticationService.isLoggedIn();
  }

  isMember() {
    return this.CommonAuthenticationService.isMember();
  }

  isMemberPublic() {
    return this.CommonAuthenticationService.isMemberPublic();
  }

  isProfileComplete() {
    return this.CommonAuthenticationService.isProfileComplete();
  }

  isOwnMemberProfile(id) {
    return this.CommonAuthenticationService.isOwnMemberProfile(id);
  }

  hasAcceptedLegislation() {
    return this.CommonAuthenticationService.hasAcceptedLegislation();
  }

  shouldAcceptTerms() {
    return this.CommonAuthenticationService.shouldAcceptTerms();
  }

  login(email, password) {
    // Note: an empty .catch() isn't generally good practice. It swallows all rejected promises that
    // might happen in the chain. Since exceptions count as rejections in a promise chain, we're
    // silently swallowing all exceptions right now.
    //
    // Usually it's better to let the error hit the UI layer, then unhandled errors are easy to spot
    // during development.
    //
    // Not changing today out of fear of introducing new bugs but worth noting imo :)
    return this.CommonAuthenticationService.login(email, password).catch();
  }

  loginWithOneTimeToken($stateParams, nextState) {
    return this.CommonAuthenticationService.loginWithOneTimeToken(
      $stateParams
    ).catch(error => {
      this.$state.go(nextState);
      throw error; // Re-throw so that consumers can add their own error handling.
    });
  }

  logout(stateParams = null) {
    this.CommonAuthenticationService.logout().then(
      () => this.$state.go(states.LOGIN, stateParams),
      error => {
        this.API.handleError('Failed to logout, please try again.')(error);
        throw error;
      }
    );
  }

  navigateToDefaultState() {
    return this.CommonAuthenticationService.getAuthenticatedUser().then(
      this._doNavigation.bind(this)
    );
  }

  redirectToLogin(extraParams = {}) {
    this.CommonAuthenticationService.redirectWithNextLocationParam(
      states.LOGIN,
      extraParams
    );
  }

  redirectToLegislationAcceptPage() {
    this.$state.go(states.CLIENT_ACCEPT_LEGISLATION);
  }

  refresh() {
    return this.CommonAuthenticationService.refresh();
  }

  showLogoutDialog() {
    this.SignoutDialogService.showLogoutDialog().then(this.logout.bind(this));
  }

  cleanSessionData() {
    this.CommonAuthenticationService.cleanSessionData();
  }

  _doNavigation() {
    // Anonymous users need to log in first.
    if (!this.hasSession()) {
      this.redirectToLogin();
      return;
    }

    if (!this.isClient() && !this.isMember()) {
      this.logout();
      return;
    }

    // Users without passwords need to create one before doing anything else.
    if (this.isLoggedIn() && !this.hasChosenPassword()) {
      this.CommonAuthenticationService.redirectToState(
        states.PASSWORD_CREATION
      );
      return;
    }

    // Complete Members should see their own profile.
    if (this.isMember() && this.isProfileComplete()) {
      this.CommonAuthenticationService.redirectToState(states.MEMBER_PROFILE);
      return;
    }

    // Incomplete Members should complete registration first.
    if (this.isMember() && !this.isProfileComplete()) {
      this.CommonAuthenticationService.redirectToState(
        states.MEMBER_REGISTRATION.ROOT
      );
      return;
    }

    // Complete Clients should see their own profile.
    if (this.isClient() && this.isProfileComplete()) {
      if (this.shouldAcceptTerms()) {
        this.CommonAuthenticationService.redirectToState(
          states.CLIENT_CONTRACT
        );
        return;
      }
      if (this.isLimitedClient()) {
        this.CommonAuthenticationService.redirectToState(
          states.CLIENT_DASHBOARD
        );
      } else {
        this.CommonAuthenticationService.redirectToState(states.SEARCH);
      }
      return;
    }

    // Incomplete Clients should complete registration first.
    if (this.isClient() && !this.isProfileComplete()) {
      this.CommonAuthenticationService.redirectToState(
        states.CLIENT_REGISTRATION_PROFILE
      );
      return;
    }

    this.$log.error(
      'navigateToDefaultState could not find an appropriate redirect!'
    );
    this.$log.error('User: ', this.user);
    this.$log.error('Profile: ', this.profile);
  }
}

export default angular
  .module('wc.auth.authService', [
    commonAuthenticationService.name,
    dialogsModule.name,
    memberListsModule.name
  ])
  .service('AuthenticationService', AuthenticationService);
