import angular from 'angular';
import { react2angular } from 'react2angular';

import { MessengerConversationPane } from './MessengerConversationPane';

export default angular
  .module('wc.components.MessengerConversationPane', [])
  .component(
    'reactMessengerConversationPane',
    react2angular(
      MessengerConversationPane,
      [],
      ['$state', 'MessengerService', 'ChatService', '$scope']
    )
  );
