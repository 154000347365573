import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const ActionBar = ({ className, children }) => {
  //TODO DOGUCAN
  return (
    <div className="wc-action-bar">
      <div className={`md-toolbar WcActionBar ${className}`}>{children}</div>
    </div>
  );
};

ActionBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};
