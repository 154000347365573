import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import angular from 'angular';
import { react2angular } from 'react2angular';
import AsyncSelect from 'react-select/async';
import Highlighter from 'react-highlight-words';

import AutoSuggest from '../../selects/AutoSuggest';
import typeahead from '../../../utils/typeahead';
import theme from '../../../../../../common/styles/theme';

const SingleValueTypeahead = ({
  endpoint,
  onChange,
  updateFormFieldError,
  error,
  onValid,
  onInvalid,
  formSubmitted,
  value,
  inputFieldName,
  itemLabelField = 'name',
  required = false,
  title = ''
}) => {
  const [touched, setTouched] = useState(false);
  // this is needed to stop running the function updateFormFieldError on inital render as it messes with the angular digest cycle. once the registration/tabs/experience/position.controller.js is in react this can be removed.
  const doNotUpdateReactFormError = useRef(true);
  const getOptions = searchText => {
    return endpoint
      .getList({ search: searchText })
      .then(data => typeahead.sortResults(data, searchText, itemLabelField));
  };

  const validate = () => {
    if (required && !value) {
      onInvalid(inputFieldName);
      if (doNotUpdateReactFormError.current) {
        return;
      }
      updateFormFieldError(inputFieldName, true);
    } else {
      onValid(inputFieldName);
      if (doNotUpdateReactFormError.current) {
        return;
      }
      updateFormFieldError(inputFieldName, false);
    }
  };

  const handleBlur = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  const shouldRenderError = () => {
    return error && (formSubmitted || touched) && !value;
  };

  const formatOptionLabel = (value = {}, { inputValue }) => {
    return (
      <Highlighter
        highlightStyle={{ fontWeight: 'bold', background: 'none' }}
        searchWords={[inputValue]}
        textToHighlight={value[itemLabelField]}
      />
    );
  };

  const selectProps = {
    name: inputFieldName,
    value,
    cacheOptions: true,
    defaultOptions: true,
    loadOptions: getOptions,
    getOptionValue: option => option[itemLabelField],
    formatOptionLabel,
    noOptionsMessage: () => 'No matches found.',
    labelText: title,
    onChange,
    onBlur: handleBlur,
    validationError: shouldRenderError(),
    errorMessage: 'This field is required',
    openMenuOnFocus: true,
    tabSelectsValue: false
  };

  useEffect(() => {
    validate();
    if (doNotUpdateReactFormError.current) {
      doNotUpdateReactFormError.current = false;
    }
  }, [value]);

  return (
    <AutoSuggest
      render={defaultProps => (
        <AsyncSelect {...defaultProps} {...selectProps} />
      )}
    />
  );
};

SingleValueTypeahead.propTypes = {
  endpoint: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  updateFormFieldError: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  onValid: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  value: PropTypes.object,
  inputFieldName: PropTypes.string,
  itemLabelField: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string
};

const Wrapper = ({
  endpoint,
  onChange,
  updateFormFieldError,
  error,
  onValid,
  onInvalid,
  formSubmitted,
  value,
  inputFieldName,
  itemLabelField = 'name',
  required = false,
  title = ''
}) => {
  return (
    <ThemeProvider theme={theme}>
      <SingleValueTypeahead
        endpoint={endpoint}
        onChange={onChange}
        updateFormFieldError={updateFormFieldError}
        error={error}
        onValid={onValid}
        onInvalid={onInvalid}
        formSubmitted={formSubmitted}
        value={value}
        inputFieldName={inputFieldName}
        itemLabelField={itemLabelField}
        required={required}
        title={title}
      />
    </ThemeProvider>
  );
};

Wrapper.propTypes = {
  endpoint: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  updateFormFieldError: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  onValid: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  value: PropTypes.object,
  inputFieldName: PropTypes.string,
  itemLabelField: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string
};

export default angular
  .module('wc.components.react.radioSingleValueTypeahead', [])
  .component(
    'reactSingleValueTypeahead',
    react2angular(
      Wrapper,
      [
        'endpoint',
        'onChange',
        'updateFormFieldError',
        'error',
        'onValid',
        'onInvalid',
        'formSubmitted',
        'value',
        'inputFieldName',
        'itemLabelField',
        'required',
        'title'
      ],
      []
    )
  );
