import angular from 'angular';

import MessagesLink from '../messagesLink';
import NotificationsLink from '../notificationsLink';
import Navbar from '../navbar';
import ProfilePictureMenu from '../profilepicturemenu';
import IconButtons from '../iconbuttons';
import SearchButton from '../searchbutton';

import MemberNavbar from '../membernavbar/MemberNavbar.controller';

import './MemberNavbar.scss';

const dependencies = [
  MessagesLink.name,
  NotificationsLink.name,
  Navbar.name,
  IconButtons.name,
  ProfilePictureMenu.name,
  SearchButton.name
];

export default angular
  .module('wc.components.sidenav.memberNavbar', dependencies)
  .component(MemberNavbar.NAME, MemberNavbar);
