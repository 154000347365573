import angular from 'angular';
import { react2angular } from 'react2angular';
import CreateButton from './CreateButton';

export default angular
  .module('wc.components.ReactCreateButton', [])
  // @ts-ignore
  .component(
    'reactCreateButton',
    react2angular(CreateButton, ['ariaLabel', 'onClick'])
  );
