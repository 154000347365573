import './UserCardList.scss';
import templateUrl from './UserCardList.html';

class UserCardListController {
  /*@ngInject*/
  constructor($state, CurrencyService, AuthenticationService) {
    this.$state = $state;
    this.CurrencyService = CurrencyService;
    this.CurrencyService.isReady.then(this.setReady.bind(this));
    this.AuthenticationService = AuthenticationService;
    this.canContact = false;
    this.AuthenticationService.getProfile().then(profile => {
      this.canContact = profile && !profile.hideContact;
    });
  }

  setReady() {
    this.currencyServiceReady = true;
  }

  getCurrencySymbol(currencyCode) {
    return this.CurrencyService.getCurrencySymbol(currencyCode);
  }

  shouldDisplayCompensation(user) {
    return (
      this.currencyServiceReady &&
      user.totalCompensationAmount !== null &&
      angular.isDefined(user.totalCompensationAmount)
    );
  }
}

/*@ngInject*/
export default {
  NAME: 'userCardList',
  bindings: {
    users: '<',
    userProfileState: '@',
    lid: '<',
    menuItems: '<',
    infiniteScrollContainer: '@',
    isLonglist: '<',
    onToggleUserCheckbox: '&',
    onToggleUser: '&',
    onInfiniteScroll: '&',
    showLonglistActions: '<'
  },
  controller: UserCardListController,
  templateUrl: templateUrl
};
