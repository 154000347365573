import React from 'react';
import PropTypes from 'prop-types';

export const LocationCell = ({ city, country }) => {
  return (
    <span>
      {city}{' '}
      {city && country && (
        <React.Fragment>
          <span>,&#32;</span> {country}
        </React.Fragment>
      )}
    </span>
  );
};

LocationCell.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string
};
