import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

import STATES from '../../../services/States';
import { tabNames } from '../../../apps/members/member-area/settings/constants';

class NotificationBarController {
  /*@ngInject*/
  constructor($state, CommonAuthenticationService) {
    this.$state = $state;
    this.CommonAuthenticationService = CommonAuthenticationService;
    this.showVisibilityBanner = false; //this.CommonAuthenticationService.isMember() && !this.CommonAuthenticationService.isMemberPublic();
  }

  activate() {
    this.$state.go(STATES.MEMBER_SETTINGS, { tab: tabNames.VISIBILITY });
  }
}

export default angular
  .module('wc.components.inline-edit.notification-bar', [])
  .component('wcInlineEditProfileNotificationBar', {
    templateUrl: templateUrl,
    controller: NotificationBarController
  });
