import ProgressLoaderTemplateUrl from './ProgressLoader.html';
import './ProgressLoader.scss';

let progressLoader = ProgressLoaderService => {
  return {
    restrict: 'E',
    templateUrl: ProgressLoaderTemplateUrl,
    link: scope => {
      scope.ProgressLoaderService = ProgressLoaderService;
      scope.$on(
        'addActiveRequest',
        ProgressLoaderService.addRequest.bind(ProgressLoaderService)
      );
      scope.$on(
        'resolveActiveRequest',
        ProgressLoaderService.resolveRequest.bind(ProgressLoaderService)
      );
    }
  };
};

progressLoader.$inject = ['ProgressLoaderService'];
progressLoader.NAME = 'progressLoader';

export default progressLoader;
