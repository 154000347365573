import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { MessengerSendButton } from '../../../buttons/messenger-send/MessengerSendButton';
import { ExpandableTextarea } from '../../../expandable-textarea/ExpandableTextarea';

export const NewMessageForm = ({ MessengerService, ChatService }) => {
  const [message, setMessage] = useState('');

  const sendMessage = event => {
    if (event) {
      event.preventDefault();
    }
    ChatService.sendSimpleMessage(
      MessengerService.currentConversation,
      message
    );
    setMessage('');
  };

  return (
    <form className="WcNewMessageForm" onSubmit={event => sendMessage(event)}>
      <ExpandableTextarea
        className="WcNewMessageForm__Textarea"
        model={message}
        sendOnEnter={true}
        focus={true}
        placeholder="Type a message..."
        onSubmit={() => sendMessage()}
        onChange={val => setMessage(val)}
      />
      <MessengerSendButton />
    </form>
  );
};

NewMessageForm.propTypes = {
  MessengerService: PropTypes.any,
  ChatService: PropTypes.any
};
