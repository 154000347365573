import { PathWell } from 'pathwell';

let RequireSpecialCharacters = () => {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.requireSpecialCharacters = function(
        modelValue,
        viewValue
      ) {
        const classCount = PathWell.getClassCounts(viewValue);
        const expected = parseInt(attrs.requireSpecialCharacters) || 0;
        return expected <= classCount.s;
      };
    }
  };
};

RequireSpecialCharacters.NAME = 'requireSpecialCharacters';

export default RequireSpecialCharacters;
