import angular from 'angular';

import Hobbies from './hobbies';
import QA from './questions-answers';

const dependencies = [Hobbies.name, QA.name];

export default angular.module(
  'wc.components.inlineEdit.insights',
  dependencies
);
