import angular from 'angular';

import './Layout.scss';
import templateUrl from './Layout.html';

const Layout = {
  NAME: 'wcProfileLayout',
  templateUrl: templateUrl,
  transclude: {
    overview: 'wcProfileOverviewSlot',
    content: 'wcProfileContentSlot'
  }
};

export default angular
  .module('wc.components.profile.layout', [])
  .component(Layout.NAME, Layout);
