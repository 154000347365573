import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import { AnchorNavigationContainer } from '../../../profile/profile-content/navigation/anchor-navigation-container/AnchorNavigationContainer';
import { AnchorNavigationTabsSlot } from '../../../profile/profile-content/navigation/anchor-navigation-tabs-slot/AnchorNavigationTabsSlot';
import { AnchorNavigationItem } from '../../../profile/anchornavigation/navItem/AnchorNavigationItem';
import PageContainer from '../../../PageContainer';
import Page from '../../../Page';
import { OwnMemberProfileActions } from '../../../profile/profile-actions/own-member-profile-actions/OwnMemberProfileActions';
import { ClientMemberProfileActions } from '../../../profile/profile-actions/client-member-profile-actions/ClientMemberProfileActions';
import { OwnMemberProfileExperience } from '../../../profile/profile-content/own-edit/experience/OwnMemberProfileExperience';
import { MemberProfileExperienceFull } from '../../../../../../common/components/react/member-profile/experience-full/MemberProfileExperienceFull';
import { OtherMemberProfileActions } from '../../../profile/profile-actions/other-member-profile-actions/OtherMemberProfileActions';
import { MemberProfileExperienceLimited } from '../../../../../../common/components/react/member-profile/experience-limited/MemberProfileExperienceLimited';
import { SectionHeader } from './../../../typography/section-header/SectionHeader';
import { OwnMemberProfileAspirations } from './../../profile-content/own-edit/aspirations/OwnMemberProfileAspirations';
import { MemberProfileAspirations } from '../../../../../../common/components/react/member-profile/aspirations/MemberProfileAspirations';
import { OwnMemberProfileInsights } from './../../profile-content/own-edit/insights/OwnMemberProfileInsights';
import { MemberProfileInsights } from '../../../../../../common/components/react/member-profile/insights/MemberProfileInsights';
import { MemberProfileDocuments } from '../../../../../../common/components/react/member-profile/documents/MemberProfileDocuments';

/*eslint complexity: ["error", 30]*/
export const MemberProfileContent = ({
  member,
  user,
  groups,
  isOwn,
  isClient,
  $state,
  Resume,
  API,
  MessengerService,
  AuthenticationService,
  CurrencyService,
  CompensationsBlockedService,
  DialogService,
  GroupInvitesService,
  GroupDialogService,
  $q,
  $stateParams,
  DetectDeviceService,
  DocumentDownloadService
}) => {
  const [modifiedMember, setModifiedMember] = useState(member);
  const [candidateDocuments, setCandidateDocuments] = useState([]);
  const [longlist, setLonglist] = useState();
  const [candidateSummary, setCandidateSummary] = useState('');

  useEffect(() => {
    let candidateSummary = null;
    if (isClient && $stateParams.lid) {
      loadCandidateDocuments($stateParams.lid, $stateParams.id);
      if (member && member.userId) {
        API.LonglistMember($stateParams.lid, member.userId)
          .get()
          .then(response => {
            setCandidateSummary(response ? response.summary : '');
          });
      }
      loadLonglist($stateParams.lid);
    }

    setModifiedMember({
      ...member,
      answers: member.answers.filter(x => {
        return !!x.text;
      }),
      candidateSummary: candidateSummary
    });
  }, [isClient, member]);

  const hasAspirations = () => {
    return (
      (modifiedMember.aspirations &&
        (modifiedMember.aspirations.goals ||
          (modifiedMember.aspirations.industryStandardJobTitles &&
            modifiedMember.aspirations.industryStandardJobTitles.length > 0) ||
          (modifiedMember.aspirations.industries &&
            modifiedMember.aspirations.industries.length > 0) ||
          (modifiedMember.aspirations.cities &&
            modifiedMember.aspirations.cities.length > 0) ||
          (modifiedMember.aspirations.companies &&
            modifiedMember.aspirations.companies.length > 0))) ||
      modifiedMember.openToRelocate ||
      modifiedMember.aspirationalComp ||
      modifiedMember.interestedInNonExec
    );
  };

  const showAspirations = () => {
    return isOwn || (isClient && hasAspirations());
  };

  const showInsights = () => {
    return (
      isOwn ||
      (modifiedMember.hobbies && modifiedMember.hobbies.length > 0) ||
      (modifiedMember.answers && modifiedMember.answers.length > 0)
    );
  };

  const showDocuments = () => {
    return isClient && candidateDocuments && candidateDocuments.length !== 0;
  };

  const isDesktop = () => {
    return DetectDeviceService.isDesktop();
  };

  const loadCandidateDocuments = (longlistId, memberId) => {
    API.LonglistCandidateDocuments(longlistId, memberId)
      .getList()
      .then(documents => setCandidateDocuments(documents));
  };

  const loadLonglist = longlistId => {
    API.Longlist(longlistId)
      .get()
      .then(longlist => setLonglist(longlist));
  };

  const anchorNavigationsTabs = (
    <AnchorNavigationTabsSlot>
      {isDesktop() && (
        <div style={{ display: 'flex' }}>
          <AnchorNavigationItem
            target="experience"
            label="Experience"
            activeByDefault={true}
          />
          {showAspirations() && (
            <AnchorNavigationItem target="aspirations" label="Aspirations" />
          )}
          {showInsights() && (
            <AnchorNavigationItem target="insights" label="Insights" />
          )}
          {showDocuments() && (
            <AnchorNavigationItem target="documents" label="Documents" />
          )}
        </div>
      )}
    </AnchorNavigationTabsSlot>
  );

  return (
    <AnchorNavigationContainer tabs={anchorNavigationsTabs}>
      <PageContainer>
        <Page noTopPadding={true}>
          <div id="experience" className="anchorPage">
            {isOwn && !isClient && (
              <OwnMemberProfileActions
                member={modifiedMember}
                $state={$state}
                Resume={Resume}
                API={API}
              />
            )}
            {!isOwn && isClient && (
              <ClientMemberProfileActions
                member={modifiedMember}
                Resume={Resume}
                MessengerService={MessengerService}
                AuthenticationService={AuthenticationService}
              />
            )}
            {isOwn && !isClient && (
              <OwnMemberProfileExperience
                member={modifiedMember}
                user={user}
                CurrencyService={CurrencyService}
                CompensationsBlockedService={CompensationsBlockedService}
                DialogService={DialogService}
                API={API}
                $state={$state}
                AuthenticationService={AuthenticationService}
              />
            )}
            {!isOwn && isClient && (
              <MemberProfileExperienceFull
                member={{
                  ...modifiedMember,
                  candidateSummary
                }}
                user={user}
                isClient={isClient}
                CurrencyService={CurrencyService}
                API={API}
                AuthenticationService={AuthenticationService}
                CompensationsBlockedService={CompensationsBlockedService}
              />
            )}
            {!isOwn && !isClient && (
              <OtherMemberProfileActions
                member={modifiedMember}
                groups={groups}
                API={API}
                MessengerService={MessengerService}
                GroupInvitesService={GroupInvitesService}
                GroupDialogService={GroupDialogService}
                $q={$q}
              />
            )}
            {!isOwn && !isClient && (
              <MemberProfileExperienceLimited
                member={modifiedMember}
                CurrencyService={CurrencyService}
                CompensationsBlockedService={CompensationsBlockedService}
              />
            )}
          </div>
        </Page>

        {showAspirations() && (
          <Page>
            <div id="aspirations" className="anchorPage">
              <SectionHeader value="Aspirations" />
              {isOwn && (
                <OwnMemberProfileAspirations
                  member={modifiedMember}
                  $state={$state}
                />
              )}
              {isClient && <MemberProfileAspirations member={modifiedMember} />}
            </div>
          </Page>
        )}

        {showInsights() && (
          <Page>
            <div id="insights" className="anchorPage">
              <SectionHeader value="Insights" />
              {isOwn && (
                <OwnMemberProfileInsights
                  member={modifiedMember}
                  $state={$state}
                />
              )}
              {!isOwn && <MemberProfileInsights member={modifiedMember} />}
            </div>
          </Page>
        )}

        {showDocuments() && (
          <Page>
            <div id="documents" className="anchorPage">
              <SectionHeader value="Documents" />
              {!isOwn && isClient && (
                <MemberProfileDocuments
                  longlist={longlist}
                  documents={candidateDocuments}
                  DocumentDownloadService={DocumentDownloadService}
                />
              )}
            </div>
          </Page>
        )}
      </PageContainer>
    </AnchorNavigationContainer>
  );
};

MemberProfileContent.propTypes = {
  member: PropTypes.object,
  user: PropTypes.object,
  groups: PropTypes.any,
  isOwn: PropTypes.bool,
  isClient: PropTypes.bool,
  $state: PropTypes.any,
  Resume: PropTypes.any,
  API: PropTypes.any,
  MessengerService: PropTypes.any,
  AuthenticationService: PropTypes.any,
  CurrencyService: PropTypes.any,
  CompensationsBlockedService: PropTypes.any,
  DialogService: PropTypes.any,
  GroupInvitesService: PropTypes.any,
  GroupDialogService: PropTypes.any,
  $q: PropTypes.any,
  $stateParams: PropTypes.any,
  DetectDeviceService: PropTypes.any,
  DocumentDownloadService: PropTypes.any
};
