import React from 'react';
import PropTypes from 'prop-types';

export const AnchorNavigationTabsSlot = ({ children }) => {
  return <div className="WcTabs">{children}</div>;
};

AnchorNavigationTabsSlot.propTypes = {
  children: PropTypes.node
};
