import angular from 'angular';
import { react2angular } from 'react2angular';
import { Post } from './Post';

export default angular
  .module('wc.components.reactPost', [])
  // @ts-ignore
  .component(
    'reactWcPost',
    react2angular(
      Post,
      ['group', 'canComment', 'post', 'member'],
      ['API', '$state']
    )
  );
