import states from '../../services/States';

import ChangePasswordController from './change-password/ChangePassword.controller';
import ChangePasswordTemplate from './change-password/ChangePassword.html';
import ForgottenPasswordController from './forgotten-password/ForgottenPassword.controller';
import ForgottenPasswordTemplate from './forgotten-password/ForgottenPassword.html';
// import LoginController from './login/Login.controller';
import LoginTemplate from './login/Login.html';
import TokenLoginController from './token/TokenLogin.controller';
import TokenLoginTemplate from './token/TokenLogin.html';
import BaseTemplate from './template.html';

/*@ngInject*/
export default function configureRoutes($stateProvider) {
  $stateProvider
    .state(states.AUTH, {
      abstract: true,
      templateUrl: BaseTemplate
    })

    .state(states.AUTH_REDIRECT, {
      abstract: true,
      templateUrl: BaseTemplate,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfAlreadyLoggedIn();
        }
      }
    })

    .state(states.LOGIN, {
      url: '/login?status&next&reload',
      templateUrl: LoginTemplate,
      // controller: LoginController,
      // controllerAs: LoginController.controllerAs
    })

    .state(states.TOKEN_LEGACY, {
      url: '/token/:token?redirectTo&changedBy',
      templateUrl: TokenLoginTemplate,
      controller: TokenLoginController,
      controllerAs: TokenLoginController.controllerAs
    })

    .state(states.TOKEN_REGISTER, {
      url: '/token-register/:token?redirectTo&changedBy',
      templateUrl: TokenLoginTemplate,
      controller: TokenLoginController,
      controllerAs: TokenLoginController.controllerAs
    })

    .state(states.TOKEN_REDIRECT, {
      url: '/token-redirect/:token?redirectTo&changedBy',
      templateUrl: TokenLoginTemplate,
      controller: TokenLoginController,
      controllerAs: TokenLoginController.controllerAs
    })

    .state(states.TOKEN_LOGIN, {
      url: '/token-login/:token?redirectTo&changedBy',
      templateUrl: TokenLoginTemplate,
      controller: TokenLoginController,
      controllerAs: TokenLoginController.controllerAs
    })

    .state(states.CHANGE_PASSWORD, {
      url: '/change-password/:token',
      templateUrl: ChangePasswordTemplate,
      controller: ChangePasswordController,
      controllerAs: ChangePasswordController.controllerAs
    })

    .state(states.FORGOTTEN_PASSWORD, {
      url: '/forgotten-password',
      templateUrl: ForgottenPasswordTemplate,
      controller: ForgottenPasswordController,
      controllerAs: ForgottenPasswordController.controllerAs
    })

    .state(states.REQUEST_NEW_TOKEN, {
      url: '/request-new-token',
      template: '<wc-request-new-token></wc-request-new-token>'
    });
}
