class StickyElementContainerController {
  /*@ngInject*/
  constructor($element, $timeout) {
    this.$element = $element;
    this.scrollPositionListeners = [];

    this.$element.parent().css('position', 'relative');
    $(this.$element).scroll(this.notifyListeners.bind(this));

    let resizeTimer;
    $(window).resize(() => {
      clearTimeout(resizeTimer);
      resizeTimer = $timeout(this.notifyListeners.bind(this), 100);
    });

    $timeout(this.notifyListeners.bind(this));
  }

  registerListener(callback) {
    this.scrollPositionListeners.push(callback);
  }

  notifyListeners() {
    this.scrollPositionListeners.forEach(listener => {
      listener(this.$element[0].clientHeight, this.$element[0].scrollTop);
    });
  }
}

export default function StickyElementContainer() {
  return {
    bindToController: true,
    controller: StickyElementContainerController,
    restrict: 'A'
  };
}

StickyElementContainer.NAME = 'wcStickyElementContainer';
