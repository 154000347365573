import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileContacts } from './OwnMemberProfileContacts';

export default angular
  .module('wc.components.OwnMemberProfileContacts', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileContacts',
    react2angular(
      OwnMemberProfileContacts,
      ['user'],
      ['$state', 'API', 'AuthenticationService']
    )
  );
