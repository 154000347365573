import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Switch from '../../../profile/basics/Switch';

export const ClientCompensationSettingsTab = ({
  compensationSetting,
  onChange
}) => {
  return (
    <md-content>
      <p className="SettingsExplanation">
        Control visibility of compensation data
      </p>
      <div className="NotificationSettings__Row">
        <div>
          <div className="NotificationSettings__Name">
            Display compensation data
          </div>
        </div>
        <div className="ClientCompensationSettings__SwitchContainer">
          <span onClick={() => onChange(compensationSetting)}>
            {/* Wrapped in span, because disabled switch can't handle onChange */}
            <Switch
              disabled
              checked={compensationSetting}
              aria-label="Display compensation data"
            />
          </span>
        </div>
      </div>
      <div className="NotificationSettings__Description">
        If for any reason you do not wish to see these details, please toggle
        &apos;off&apos; here.
      </div>
    </md-content>
  );
};

ClientCompensationSettingsTab.propTypes = {
  compensationSetting: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};
