import angular from 'angular';

const DEFAULT_SIDEBAR_CONFIG = { isOpen: false };

class SidebarService {
  constructor() {
    this.sidebars = {};

    this.register = this.register.bind(this);
    this.isOpen = this.isOpen.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  register(id) {
    this.sidebars[id] = angular.copy(DEFAULT_SIDEBAR_CONFIG);
  }

  isOpen(id) {
    return this.sidebars[id].isOpen;
  }

  toggle(id) {
    this.closeOthers(id);
    this.sidebars[id].isOpen = !this.sidebars[id].isOpen;
  }

  open(id) {
    this.sidebars[id].isOpen = true;
  }

  close(id) {
    this.sidebars[id].isOpen = false;
  }

  // TODO: would be nicer to refactor the service to register with an id once, save that id, and not require an id in
  // every method. Then remove this method.
  closeAny() {
    Object.keys(this.sidebars).map(id => this.close(id));
  }

  closeOthers(id) {
    Object.keys(this.sidebars)
      .filter(sidebarId => sidebarId !== id)
      .map(id => this.close(id));
  }
}

SidebarService.NAME = 'wcSidebar';

export default angular
  .module('wc.services.sidebar', [])
  .service(SidebarService.NAME, SidebarService);
