import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wc.components.forms.form', [])
  .component('wcForm', {
    bindings: {
      name: '=',
      onSubmit: '&'
    },
    templateUrl: templateUrl,
    transclude: true
  });
