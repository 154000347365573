import STATES from '../../../../services/States';
import { MEMBER_SEARCH_SIDEBAR_ID } from '../../../../components/sidebars/membersearchsidebar';
import { MAIN_SEARCH_ID } from '../../../../services/MemberLists.service';

class SearchController {
  /*@ngInject*/
  constructor($scope, $state, MemberListUIService, wcMemberList) {
    this.$scope = $scope;
    this.$state = $state;

    // This legacy service is only kept around to set up ordering widget + table headers.
    // See this.setUpMemberList()
    //
    // Should be replaced by componentizing table + ordering widget, as the concern here is UI
    // reuse.
    //
    // Also provides grid/list functionality
    this.MemberListUIService = MemberListUIService;

    // This is the "real" member list service which handles filtering etc.
    this.wcMemberList = wcMemberList;

    this.searchSidebarId = MEMBER_SEARCH_SIDEBAR_ID;
    this.userProfileState = STATES.OTHER_MEMBER_PROFILE;

    this.MemberListUIService.isReady
      .then(this._setUpMemberListUIService.bind(this))
      .then(this._setUpMemberList.bind(this));
  }

  onFlipOrdering = () => {
    this.memberList.flipOrdering();
  };

  _setCurrentSortOption(orderingField) {
    if (!this.sortOptions) {
      return;
    }

    this.currentSortOption = this.sortOptions.filter(
      opt => opt.sortingKey === orderingField
    )[0];
  }

  _setUpMemberList() {
    this.wcMemberList.isReady.then(() => {
      this.memberList = this.wcMemberList[MAIN_SEARCH_ID];
      this.memberList.refresh();
      this.$scope.$watch(
        () => this.memberList.ordering.field,
        this._setCurrentSortOption.bind(this)
      );
    });
  }

  _setUpMemberListUIService() {
    this.tableHeaders = this.MemberListUIService.getDefaultTableHeaders();
    this.MemberListUIService.updateSortOptions(this.tableHeaders);
    this.sortOptions = this.MemberListUIService.getDefaultSortOptions();

    // Hack stuff: add a Relevance sorting option, and remove Compensation
    // MemberListUIService is a bad abstraction
    this.tableHeaders = this.tableHeaders.filter(
      header => header.name !== 'Compensation'
    );
    this.sortOptions = this.sortOptions.filter(
      header => header.name !== 'Compensation'
    );
    this.sortOptions.unshift({ name: 'Relevance' });
  }
}

SearchController.controllerAs = '$ctrl';
export default SearchController;
