import angular from 'angular';
import controller from './SensitiveSettings.controller';
import template from './SensitiveSettings.html';
import Dialogs from '../../dialogs/Dialogs.module';
import Forms from '../../forms/Forms.module';

const dependencies = [Dialogs.name, Forms.name];

const SensitiveSettings = {
  NAME: 'wcSensitiveSettings',
  bindings: {
    onUserChange: '&',
    showEmailSettingOption: '<',
    onSharingSettingChange: '&',
    sharingSettingHanging: '<',
    user: '<'
  },
  controller: controller,
  templateUrl: template
};

export default angular
  .module('wc.settings.sensitive', dependencies)
  .component(SensitiveSettings.NAME, SensitiveSettings);
