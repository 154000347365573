import './styles.scss';
import templateUrl from './template.html';

const longlistDropdown = {
  bindings: {
    ngModel: '=',
    options: '<wcOptions'
  },
  templateUrl: templateUrl
};

export default angular
  .module('wc.components.addToLonglistDropdown', [])
  .component('wcAddToLonglistDropdown', longlistDropdown);
