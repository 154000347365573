import angular from 'angular';

/*@ngInject*/
function registerApiErrorHandlers(API, ngToast) {
  API.onError(function displayToastOnApiError(message) {
    ngToast.warning(message);
  });

  API.onSuccess(function displayToastOnApiError(message) {
    ngToast.success(message);
  });
}

export default angular
  .module('wc.apiErrorHandlers', [])
  .run(registerApiErrorHandlers);
