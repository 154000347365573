import angular from 'angular';

class ClientDocumentDownloadController {
  /*@ngInject*/
  constructor(
    API,
    $stateParams,
    DocumentDownloadService,
    DownloadDialogService,
    $q
  ) {
    this.API = API;
    this.DocumentDownloadService = DocumentDownloadService;
    this.deferredDocument = $q.defer();

    DownloadDialogService.show(this.deferredDocument.promise).then(
      this.downloadDocument.bind(this)
    );

    API.Documents.get($stateParams.id)
      .then(this.setDocument.bind(this))
      .catch(this.handleError.bind(this));
  }

  setDocument(clientDocument) {
    this.deferredDocument.resolve(clientDocument);
    this.document = clientDocument;
  }

  downloadDocument() {
    this.DocumentDownloadService.download(this.document, 'documents');
  }

  handleError(error) {
    this.deferredDocument.reject();
    this.API.handleError()(error);
  }
}

export default angular
  .module('wc.components.clientDocumentDownload', [])
  .component('wcClientDocumentDownload', {
    controller: ClientDocumentDownloadController
  });
