import STATES from '../../services/States';
import MembersController from './Members.controller';
import MembersTemplateUrl from './Members.html';

/*@ngInject*/
function MembersRouting($stateProvider) {
  $stateProvider
    .state(STATES.MEMBERS, {
      url: '/members',
      controller: MembersController,
      controllerAs: MembersController.controllerAs,
      templateUrl: MembersTemplateUrl,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          // Only Members should be allowed to access the Member Area.
          //
          // Probably this should be narrowed down to complete Members, as incomplete Members are
          // supposed to be redirected to Registration.
          //
          // Refactoring first, can tighten up later.
          return RedirectService.redirectIfNotMember();
        }
      }
    })

    .state(STATES.MEMBER_PROFILE, {
      url: '/profile',
      template: `<wc-member-profile is-own="true" id="profileScrollContainer"
                        wc-sticky-element-container></wc-member-profile>`,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfNotCompleteMember();
        }
      },
      params: { scrollToSection: false }
    })

    .state(STATES.OTHER_MEMBER_PROFILE, {
      url: '/members/:id',
      template: `<wc-member-profile id="profileScrollContainer" wc-sticky-element-container>
      </wc-member-profile>`,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService, $stateParams) {
          // Redirect to own profile if needed
          return RedirectService.redirectIfNotCompleteMemberOrOwnProfile(
            $stateParams.id
          );
        }
      }
    })

    .state(STATES.MEMBER_MESSAGES, {
      url: '/messages',
      template: `<main class="WcMessengerView">
        <wc-messenger></wc-messenger>
      </main>`
    })

    .state(STATES.MEMBER_NOTIFICATIONS, {
      url: '/notifications',
      template: `<main>
        <wc-notifications-view>
        </wc-notifications-view>
      </main>`,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          // Only Members should be allowed to access the Member Area.
          //
          // Probably this should be narrowed down to complete Members, as incomplete Members are
          // supposed to be redirected to Registration.
          //
          // Refactoring first, can tighten up later.
          return RedirectService.redirectIfNotMemberPublic();
        }
      }
    })

    .state(STATES.MEMBER_CONVERSATION, {
      url: '/:id',
      template:
        '<react-messenger-conversation-pane></react-messenger-conversation-pane>'
    })

    .state(STATES.MEMBER_REFERRALS, {
      url: '/referrals',
      template: '<wc-member-referral-form></wc-member-referral-form>'
    });
}

export default MembersRouting;
