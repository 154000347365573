import angular from 'angular';

import Summary from './summary';
import NonExec from './non-exec';
import Relocation from './relocation';
import JobRoles from './job-roles';
import Industries from './industries';
import Cities from './cities';
import Companies from './companies';
import Compensation from './compensation';

const dependencies = [
  Summary.name,
  NonExec.name,
  Relocation.name,
  JobRoles.name,
  Industries.name,
  Cities.name,
  Companies.name,
  Compensation.name
];

export default angular.module(
  'wc.components.inlineEdit.aspirations',
  dependencies
);
