import uiRouter from 'angular-ui-router';

import config from '../../../../config/Config.module';
import authService from '../../../auth/Authentication.service';
import { tabNames } from './constants';
import STATES from '../../../../services/States';

class SettingsController {
  /*@ngInject*/
  constructor(
    $q,
    $rootScope,
    $scope,
    $state,
    ngToast,
    AuthenticationConfig,
    AuthenticationService,
    API,
    CommonAuthenticationService,
    DialogService,
    featureFlags
  ) {
    this.$q = $q;
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.CommonAuthenticationService = CommonAuthenticationService;
    this.DialogService = DialogService;
    this.featureFlags = featureFlags;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.ngToast = ngToast;

    this.companiesLoading = true;
    this.profileLoading = true;
    this.notificationSettingsLoading = true;
    this.visibilityLoading = true;
    this.covisibilityLoading = true;
    this.sharingSettingHanging = false;
    this.visibleToCurrentCompanyHanging = false;
    this.visibleToMembersHanging = false;
    this.covisibilityHanging = false;

    this.isMember = this.AuthenticationService.isMember();

    this.showCompensation = false;

    this.shouldShowEmailPreferences = false;
    this.shouldShowSettingsTab = false;
    this.hideUnsubscribe = true; // Hide unsubscribe / subscribe all options to allow backward compatibility with API

    this.updateLoading();

    this.getUser();

    API.retrieveMyProfile()
      .then(this.setProfile.bind(this))
      .then(this.getEmailPreferences.bind(this))
      .then(this.getVisibility.bind(this))
      .then(this.setVisibility.bind(this))
      .then(this.getCovisibility.bind(this))
      .then(this.setCovisibility.bind(this))
      .catch(API.handleError.bind(this));

    API.retrieveLiveCompanies()
      .then(this.setLiveCompanies.bind(this), this.API.handleError())
      .then(() => this.goToTab(this.$state.params));

    API.NotificationSettings.getList().then(
      this.setNotificationSettings.bind(this)
    );

    this.profileReadyPromiseDeferred = $q.defer();
    this.profileReadyPromise = this.profileReadyPromiseDeferred.promise;

    this.passwordStrengthOptions =
      AuthenticationConfig.PASSWORD_STRENGTH_OPTIONS;
    this.goToTab(this.$state.params);
  }

  getVisibility(profile) {
    this.visibilityLoading = true;
    return this.API.retrieveMyVisibility(profile);
  }

  setVisibility(visibility) {
    this.visibilityLoading = false;
    this.updateLoading();
    this.visibility = visibility;
  }

  getUser() {
    return this.API.User.get()
      .then(this.setUser.bind(this))
      .catch(this.API.handleError.bind(this));
  }

  getEmailPreferences(member) {
    this.API.EmailPreferences(member.id)
      .then(this.setEmailPreferences.bind(this))
      .catch(this.API.handleError());
    return member;
  }

  getCovisibility() {
    this.covisibilityLoading = true;
    return this.API.retrieveMyCovisibility();
  }

  setCovisibility(covisibility) {
    this.covisibilityLoading = false;
    this.updateLoading();
    // Consider more robust ways of handling this (see also this.updateCovisibilitySetting)
    if (covisibility) {
      this.covisibility = covisibility;
      this.covisibilityExists = true;
    } else {
      this.covisibility = {
        company: []
      };
      this.covisibilityExists = false;
    }
    return this.covisibility;
  }

  setEmailPreferences(preferences) {
    if (Array.isArray(preferences)) {
      this.emailPreferences = {
        interests: preferences
      };
    } else {
      this.emailPreferences = preferences;
      this.hideUnsubscribe = false; // API supports unsubscribe
    }

    this.shouldShowEmailPreferences =
      this.emailPreferences.interests.length !== 0;
    this.updateShowSettingsTab();
  }

  updateShowSettingsTab() {
    this.shouldShowSettingsTab =
      this.notificationSettings.filter(setting => setting.display).length !==
        0 || this.shouldShowEmailPreferences;
  }

  goToTab(stateParams) {
    // Because Object.values is not supported in all browsers :(
    const tabValues = Object.keys(tabNames).map(tabName => {
      return tabNames[tabName];
    });

    let tabIndex = tabValues.indexOf(stateParams.tab);
    if (tabIndex === -1) {
      tabIndex = 0;
    } else if (stateParams.tab === tabNames.REFERRALS) {
      this.$state.go(STATES.MEMBER_REFERRALS);
    }

    this.selectedTab = tabIndex;
  }

  setUser(user) {
    this.user = user;
  }

  setProfile(profile) {
    this.profile = profile;
    this.profileLoading = false;
    this.updateLoading();
    this.profileReadyPromiseDeferred.resolve();
    this.showCompensation = this.profile && !this.profile.noCompensation;
    return profile;
  }

  setLiveCompanies(liveCompanies) {
    return this.profileReadyPromise.then(() => {
      let liveCompaniesWithCompensationBlocked = liveCompanies.filter(
        company => company.compensationsBlocked
      );
      let liveCompaniesWithCompensationVisible = liveCompanies.filter(
        company => !company.compensationsBlocked
      );
      this.liveCompanies = liveCompaniesWithCompensationVisible;
      this.liveCompanies.map(company => {
        company.allowed =
          this.profile.remunerationBlocked.indexOf(company.url) === -1;
        return company;
      });
      this.liveCompaniesWithCompensationBlocked = liveCompaniesWithCompensationBlocked;
      this.companiesLoading = false;
      this.updateLoading();
    });
  }

  setNotificationSettings(notificationSettings) {
    this.notificationSettings = notificationSettings;

    this.notificationSettings.forEach(setting => (setting.display = true));

    this.notificationSettings
      .filter(setting => setting.notificationType.type === 'GACT')
      .forEach(
        setting =>
          (setting.display =
            this.AuthenticationService.isMemberPublic() &&
            this.featureFlags.isOn('memberGroups'))
      );

    this.notificationSettings
      .filter(setting => setting.notificationType.type === 'NMSG')
      .forEach(
        setting =>
          (setting.display =
            setting.display && this.featureFlags.isOn('messaging'))
      );

    this.notificationSettingsLoading = false;
    this.updateLoading();
    this.updateShowSettingsTab();
  }

  onNotificationSettingChange = notificationSetting => {
    this.API.Notifications.one('settings', notificationSetting.id)
      .patch(notificationSetting)
      .catch(this.API.handleError());
  };

  onEmailPreferencesChange(emailPreferences) {
    this.API.EmailPreferencesList(this.profile.id).post(
      emailPreferences.interests
    );
  }

  updateLoading() {
    this.loading =
      this.companiesLoading ||
      this.notificationSettingsLoading ||
      this.profileLoading ||
      this.visibilityLoading ||
      this.covisibilityLoading;
  }

  onCompanyPermissionChange = companies => {
    let blockedCompanyIds = companies
      .filter(company => !company.allowed)
      .map(company => company.id);
    this.API.restangularize(this.profile).patch({
      remunerationBlocked: blockedCompanyIds
    });
  };

  onBlockedDefaultRemunerationChange = newDefault => {
    this.API.restangularize(this.profile).patch({
      remunerationBlockedDefault: newDefault
    });
  };

  deleteAccount(currentPassword) {
    this.user
      .remove(null, null, { currentPassword: currentPassword })
      .then(this.AuthenticationService.logout.bind(this))
      .catch(this.setErrorMessage.bind(this));
  }

  updateSharingSetting(sharingSetting) {
    if (!this.sharingSettingHanging) {
      this.updateSharingSettingStatus(true);
      this.API.User.patch({ contactSharing: sharingSetting })
        .then(this.handleSharingSettingSuccess.bind(this))
        .catch(this.handleSharingSettingError.bind(this));
    }
  }

  handleSharingSettingSuccess() {
    this.ngToast.success('Contact Sharing Settings Updated');
    this.updateSharingSettingStatus(false);
  }

  handleSharingSettingError(error) {
    this.API.handleError()(error);
    this.getUser().then(this.updateSharingSettingStatus.bind(this, false));
  }

  updateSharingSettingStatus(bool) {
    this.sharingSettingHanging = bool;
  }

  updateSameCompanySetting(sameCompanySetting) {
    if (!this.visibleToCurrentCompanyHanging) {
      this.updateSameCompanySettingStatus(true);
      let saveMethod;
      if (this.visibility) {
        saveMethod = this.API.restangularize(this.visibility).patch;
      } else {
        saveMethod = this.API.Visibility.post;
      }
      saveMethod({ toSamecompany: sameCompanySetting })
        .then(this.handleSameCompanySettingSuccess.bind(this))
        .catch(this.handleSameCompanySettingError.bind(this));
    }
  }

  handleSameCompanySettingSuccess(visibility) {
    this.visibility = visibility;
    this.ngToast.success('Company Visibility Settings Updated');
    this.updateSameCompanySettingStatus(false);
  }

  handleSameCompanySettingError(error) {
    this.API.handleError()(error);
    this.getVisibility(this.profile)
      .then(this.setVisibility.bind(this))
      .then(this.updateSameCompanySettingStatus.bind(this, false));
  }

  updateSameCompanySettingStatus(bool) {
    this.visibleToCurrentCompanyHanging = bool;
  }

  updateMemberVisibleSettingStatus(bool) {
    this.visibleToMembersHanging = bool;
  }

  handleMemberVisibleSettingSuccess(visibility) {
    this.visibility = visibility;
    this.CommonAuthenticationService._storeVisibility(visibility);
    this.$rootScope.$broadcast('memberVisibilityChanged');
    if (visibility.toMembers) {
      this.ngToast.success('Account activated');
    } else {
      this.ngToast.success('Member Visibility Settings Updated');
    }
    this.updateMemberVisibleSettingStatus(false);
  }

  handleMemberVisibleSettingError(error) {
    this.API.handleError()(error);
    this.getVisibility(this.profile)
      .then(this.setVisibility.bind(this))
      .then(this.updateMemberVisibleSettingStatus.bind(this, false));
  }

  updateMemberVisibleSetting(publicMemberSetting) {
    if (!this.visibleToMembersHanging) {
      const locals = {
        title: 'Join the network',
        content:
          `Your profile must be visible in order for you to interact with other members.<br><br>` +
          `Do you want to activate your profile and join the network?`,
        okAction: 'Continue',
        cancelAction: 'Cancel'
      };

      this.DialogService.showConfirmDialog(locals).then(() => {
        this.updateMemberVisibleSettingStatus(true);
        let saveMethod;
        if (this.visibility) {
          saveMethod = this.API.restangularize(this.visibility).patch;
        } else {
          saveMethod = this.API.Visibility.post;
        }
        saveMethod({ toMembers: publicMemberSetting })
          .then(this.handleMemberVisibleSettingSuccess.bind(this))
          .catch(this.handleMemberVisibleSettingError.bind(this));
      });
    }
  }

  updateCovisibilitySetting(covisibilitySetting) {
    if (this.covisibilityHanging) {
      return;
    }
    this.updateCovisibilitySettingStatus(true);

    let saveMethod;
    let companies;

    // Consider more robust ways of handling this (see also this.getCovisibility)
    if (this.covisibilityExists) {
      saveMethod = this.API.restangularize(this.covisibility).patch;
    } else {
      saveMethod = this.API.Covisibility.post;
    }

    companies = covisibilitySetting.company.map(company => company.url);

    saveMethod({ company: companies })
      .then(this.handleCovisibilitySettingSuccess.bind(this))
      .catch(this.handleCovisibilitySettingError.bind(this));
  }

  handleCovisibilitySettingSuccess() {
    this.ngToast.success('Additional Company Visibility Settings Updated');

    if (!this.covisibilityExists) {
      this.covisibilityExists = true;
    }

    this.refreshCovisibilitySetting();
  }

  handleCovisibilitySettingError(error) {
    this.API.handleError()(error);
    this.refreshCovisibilitySetting();
  }

  refreshCovisibilitySetting() {
    this.updateCovisibilitySettingStatus(true);
    this.getCovisibility()
      .then(this.setCovisibility.bind(this))
      .then(this.updateCovisibilitySettingStatus.bind(this, false))
      .catch(this.API.handleError());
  }

  updateCovisibilitySettingStatus(bool) {
    this.covisibilityHanging = bool;
  }
}

export default angular
  .module('wc.members.settings.settingsController', [
    authService.name,
    config.name,
    uiRouter
  ])
  .controller('MemberSettingsController', SettingsController);
