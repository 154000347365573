import angular from 'angular';
import { react2angular } from 'react2angular';
import { ActionBarIconButton } from './IconButton';

export default angular
  .module('wc.components.ReactActionBarIconButton', [])
  // @ts-ignore
  .component(
    'reactActionBarIconButton',
    react2angular(ActionBarIconButton, [
      'ariaLabel',
      'onClick',
      'title',
      'textButton',
      'text',
      'fontSet',
      'className'
    ])
  );
