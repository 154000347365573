import angular from 'angular';

import templateUrl from './template.html';

export default angular
  .module('wc.components.conditional-service-link', [])
  .component('wcConditionalServiceLink', {
    bindings: {
      wcCondition: '<',
      tabId: '<',
      stateView: '<',
      wcClass: '@?'
    },
    templateUrl: templateUrl,
    replace: true,
    transclude: true
  });
