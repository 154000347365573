/*
 * Superclasses all controllers. Sets up useful logging defaults.
 */
class RegistrationBaseController {
  constructor($state, API) {
    this.API = API;
    this.$state = $state;
    this.setLoading(true);
  }

  setLoading(loading) {
    this.loading = !!loading;
  }

  handleError(error) {
    this.setLoading(false);
    this.API.handleError()(error);
  }

  nextTab() {
    this.$state.go(this.nextState, this.nextStateOptions);
  }

  previousTab() {
    this.$state.go(this.previousState, this.previousStateOptions);
  }
}

RegistrationBaseController.$inject = ['$scope', 'API'];
export default RegistrationBaseController;
