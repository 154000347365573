export default class CropDialogController {
  /*@ngInject*/
  constructor($mdDialog, $timeout, CropperService) {
    this.$mdDialog = $mdDialog;
    this.CropperService = CropperService;
    $timeout(() => {
      CropperService.init('#CroppableImage');
    });
  }

  done() {
    this.$mdDialog.hide(this.CropperService.getCroppedJpeg());
  }
}

CropDialogController.NAME = 'CropDialogController';
