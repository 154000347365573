import STATES from '../../services/States';

import ClientsTemplateUrl from './Clients.html';
import DashboardTemplateUrl from './dashboard/Dashboard.html';
import ClientsController from './Clients.controller';

import DashboardController from './dashboard/Dashboard.controller';

import AcceptTemplate from './accept-legislation/Accept.html';
import AcceptController from './accept-legislation/Accept.controller';

import AcceptContractTemplate from './accept-contract/Accept.html';
import AcceptContractController from './accept-contract/Accept.controller';

/*@ngInject*/
function ClientsRouting($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/clients', '/clients/search');

  $stateProvider
    .state(STATES.CLIENTS, {
      url: '/clients',
      templateUrl: ClientsTemplateUrl,
      controller: ClientsController,
      controllerAs: ClientsController.controllerAs,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectClientIfNeeded();
        }
      }
    })

    .state(STATES.CLIENT_ACCEPT_LEGISLATION, {
      url: '/accept',
      templateUrl: AcceptTemplate,
      params: {
        next: null
      },
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfNotCompleteClientOrAcceptedLegislation();
        }
      },
      controller: AcceptController,
      controllerAs: AcceptController.controllerAs
    })

    .state(STATES.CLIENT_DASHBOARD, {
      url: '/services',
      templateUrl: DashboardTemplateUrl,
      controller: DashboardController,
      controllerAs: DashboardController.controllerAs
    })

    .state(STATES.CLIENT_DOCUMENTS, {
      url: '/documents',
      template: `<wc-client-documents></<wc-client-documents>`
    })

    .state(STATES.CLIENT_DOCUMENT_DOWNLOAD, {
      url: '/:id',
      template: `<wc-client-document-download></<wc-client-document-download>`
    })

    .state(STATES.CLIENTS_MEMBER_PROFILE, {
      url: '/members/:id',
      template: `<wc-member-profile is-client="true" id="profileScrollContainer" wc-sticky-element-container>
      </wc-member-profile>`
    })

    .state(STATES.CLIENT_MESSAGES, {
      url: '/messages',
      template: `<main class="WcMessengerView"><wc-messenger></wc-messenger></main>`,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfMessagingDisabled();
        }
      }
    })

    .state(STATES.CLIENT_CONVERSATION, {
      url: '/:id',
      // this component is already migrated: src/desktop/components/react/messenger/conversation-pane/index.js
      template:
        '<wc-messenger-conversation-pane></wc-messenger-conversation-pane>'
    })

    .state(STATES.CLIENT_CONTRACT, {
      url: '/contract',
      templateUrl: AcceptContractTemplate,
      params: {
        next: null
      },
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfShouldntAcceptTerms();
        }
      },
      controller: AcceptContractController,
      controllerAs: AcceptContractController.controllerAs
    });
}

export default ClientsRouting;
