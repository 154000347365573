import { BACKSPACE } from '../../../../services/Keycodes';

function isInternalBlur(event, rootElement, activeElement) {
  // Chip removal is a special case, because the activeElement (the chip's remove button) is
  // removed from the DOM just after it receives the focus!
  const firedByChipRemoval =
    event.target.className.indexOf('RemoveButton') > -1;

  return rootElement.contains(activeElement) || firedByChipRemoval;
}

function wcChipsDropdownLink(
  scope,
  element,
  attributes,
  wcChipsDrawerCtrl,
  $document,
  $timeout
) {
  // TODO: not DRY
  wcChipsDrawerCtrl.required = attributes.hasOwnProperty('required');

  scope.$watch(
    () => wcChipsDrawerCtrl.filterOptions,
    wcChipsDrawerCtrl._fetchOptions.bind(wcChipsDrawerCtrl),
    true
  );

  /* Event bindings */

  const childElement = element[0];

  // Handle all 'focus' events within wcChipsDropdown
  childElement.addEventListener(
    'focus',
    event => {
      // Don't expand if we gained focus by removing a chip
      if (event.target.className.indexOf('RemoveButton') === -1) {
        wcChipsDrawerCtrl._onFocus();
        scope.$apply();
      }
    },
    true
  );

  // Handle all 'blur' events within wcChipsDropdown
  childElement.addEventListener(
    'blur',
    event => {
      // We need to wait a browser tick before document.activeElement is assigned the correct
      // value.
      $timeout(() => {
        if (isInternalBlur(event, childElement, $document[0].activeElement)) {
          return;
        }

        wcChipsDrawerCtrl.touched = true;
        wcChipsDrawerCtrl._onBlur();
        scope.$apply();
      });
    },
    true
  );

  childElement.addEventListener('keydown', event => {
    if (event.keyCode === BACKSPACE) {
      event.preventDefault();
    }
  });

  wcChipsDrawerCtrl._onChipBlur = function _onChipBlur() {
    $timeout(() => {
      $(childElement)
        .find('.ChipsDropdown__ChipsWrap')
        .trigger('focus');
    });
  };
}

export default wcChipsDropdownLink;
