import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const OpaqueActionBarView = ({ className, children }) => {
  const [isOpaque, setIsOpaque] = useState(false);

  useEffect(() => {
    const bodyScrollContainer = document.getElementById(
      'wc-body-scroll-container'
    );

    const onScroll = async event => {
      if (event.target.scrollTop > 0) {
        setIsOpaque(true);
      } else {
        setIsOpaque(false);
      }
    };

    bodyScrollContainer.addEventListener('scroll', onScroll);

    return () => {
      bodyScrollContainer.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div
      className={`wc-opaque-action-bar-view ${className} ${
        isOpaque ? 'WcActionBar--Opaque' : ''
      }`}
    >
      {children}
    </div>
  );
};

OpaqueActionBarView.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};
