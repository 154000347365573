import angular from 'angular';
import './ChipsDrawer.scss';
import controller from './SuggestibleChipsDrawer.controller';
import templateUrl from './SuggestibleChipsDrawer.html';
import link from './ChipsDrawer.link';

import bindValidationBehaviour from './behaviours/SuggestibleChipsDrawerValidation';
import bindDisabledBehaviour from '../../../behaviours/Disabled';
import collapseOnEsc from './behaviours/CollapseOnEsc';

/*@ngInject*/
function wcSuggestibleChipsDrawer($document, $timeout, dfOnDisabled) {
  return {
    restrict: 'E',
    templateUrl: templateUrl,
    controller: controller,
    controllerAs: controller.controllerAs,
    bindToController: true,
    require: {
      wcSuggestibleChipsDrawerController: 'wcSuggestibleChipsDrawer',
      ngModelController: 'ngModel',
      formController: '^form'
    },
    scope: {
      endpoint: '&',
      filterOptions: '<',
      groupLabelProperty: '@?',
      label: '@',
      labelProperty: '@?',
      max: '@',
      min: '@',
      model: '=ngModel',
      name: '@?',
      onChange: '&',
      onSuggest: '&',
      onClearSuggestions: '&',
      onValid: '&',
      onInvalid: '&',
      organizationType: '@',
      tooltip: '@',
      suggestedChipsModel: '='
    },
    link: (scope, element, attributes, controllers) => {
      const ctrl = controllers.wcSuggestibleChipsDrawerController;

      bindValidationBehaviour(scope, attributes, controllers);
      bindDisabledBehaviour(attributes, ctrl);
      dfOnDisabled(attributes, ctrl.validate, ctrl.validate);
      collapseOnEsc(scope, element[0], ctrl);

      // TODO: separate this responsibility
      ctrl.ngModelController = controllers.ngModelController;

      scope.$watch(
        () => ctrl.suggestedChipsModel,
        (newVal, oldVal) => {
          if (oldVal !== newVal) {
            ctrl.onSuggestedChipsModelChange();
          }
        },
        true
      );

      scope.$watch(
        () => ctrl.filterOptions,
        (newVal, oldVal) => {
          if (angular.isDefined(newVal) && newVal !== {} && oldVal !== newVal) {
            ctrl._fetchOptions.bind(ctrl);
          }
        }
      );

      return link(scope, element, attributes, ctrl, $document, $timeout);
    }
  };
}

wcSuggestibleChipsDrawer.NAME = 'wcSuggestibleChipsDrawer';
export default wcSuggestibleChipsDrawer;
