import './ChipsSelectGroup.scss';
import controller from './ChipsSelectGroup.controller';
import templateUrl from './ChipsSelectGroup.html';

const NAME = 'dfChipsSelectGroup';

/*
 * # dfChipsSelectGroup
 *
 * A group of chipsSelect components that share a common model, split up by some group identifier.
 *
 * ## Attributes
 *
 * ### Two-way bindings
 *
 * - df-model          => The model. A list of selected options.
 * - df-is-valid       => Exposes validity to parent component.
 * - df-is-touched     => Exposes touched status to parent component.
 * - df-status         => Exposes human-readable status text about component validity.
 *
 * ### Data
 *
 * - df-options        => The list of options. One chip is rendered per option.
 *
 * ### Config
 *
 * - df-min-selection  => The minimum number of chips required to make the component valid.
 * - df-max-selection  => The maximum number of chips required to make the component valid.
 * - df-group-property => The property that defines which group each option belongs to.
 *                        Defaults to "group".
 * - df-title-property => The property that contains the human-readable chip text for each option.
 *                        Defaults to "name".
 * - df-id-property    => The property that contains the ID for each option.
 *                        Defaults to "id".
 *
 * ### Callbacks
 *
 * - df-on-change      => Called on model change.
 */
function ChipsSelectGroup(dfOnBlur, dfOnRequired) {
  'ngInject'; // The @ngInject comment syntax did not work here for some reason.

  function link(scope, element, attributes, controller) {
    const onBlur = controller.onBlur.bind(controller);
    dfOnBlur(scope, element[0], onBlur);

    const onRequired = controller.onRequired.bind(controller);
    dfOnRequired(attributes, onRequired);
  }

  return {
    templateUrl: templateUrl,
    controller: controller,
    controllerAs: '$ctrl',
    bindToController: true,
    require: NAME,
    link: link,
    scope: {
      model: '=dfModel',
      isValid: '=?dfIsValid',
      isTouched: '=?dfIsTouched',
      statusText: '=?dfStatusText',

      /* Inputs: data */
      options: '<?dfOptions',

      /* Inputs: config */
      minSelection: '@?dfMinSelection',
      maxSelection: '@?dfMaxSelection',
      groupProperty: '@dfGroupProperty',
      nameProperty: '@?dfNameProperty',
      idProperty: '@?dfIdProperty'
    }
  };
}

ChipsSelectGroup.NAME = NAME;
export default ChipsSelectGroup;
