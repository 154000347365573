import angular from 'angular';
import { react2angular } from 'react2angular';
import { ConditionalProfileLink } from './ConditionalProfileLink';

export default angular
  .module('wc.components.react-conditional-profile-link', [])
  // @ts-ignore
  .component(
    'reactWcConditionalProfileLink',
    react2angular(
      ConditionalProfileLink,
      [
        'wcCondition',
        'profileId',
        'lid',
        'userProfileState',
        'url',
        'wcClass',
        'children'
      ],
      ['$state']
    )
  );
