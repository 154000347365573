export default class CheckboxGroupController {
  /*@ngInject*/
  constructor($scope) {
    this.labelField = this.labelField || 'name';
    this.idField = this.idField || 'id';

    $scope.$watch(
      () => this.model,
      () => {
        if (!this.model && this.options) {
          this.options.map(option => {
            option.isSelected = false;
            return option;
          });
        }
      }
    );
  }

  updateModel() {
    this.model = this.options
      .filter(option => option.isSelected)
      .map(option => option[this.idField]);
  }
}
