import STATES from '../../../../../services/States';
import { FORM_ERROR_MESSAGE } from '../../../../../services/FormErrorMessages';
import RegistrationBaseController from '../../RegistrationBase.controller';

class PersonalityController extends RegistrationBaseController {
  constructor($log, $state, $mdConstant, API) {
    super($state, API);

    this.$log = $log;
    this.$state = $state;
    this.$mdConstant = $mdConstant;
    this.API = API;
    this.profile = {};
    this.setSeparatorKeys();

    this.previousState = STATES.MEMBER_REGISTRATION.ASPIRATIONS;
    this.nextState = STATES.MEMBER_REGISTRATION.UPLOADS;

    this.formErrorMessage = FORM_ERROR_MESSAGE;

    this.API.retrieveMyProfile()
      .then(this.handleProfile.bind(this))
      .then(this.setLoading.bind(this, false))
      .catch(this.API.handleError());
  }

  handleProfile(profile) {
    this.profile = profile;
    this.lifeValues = this.profile.lifeValues;
  }

  setSeparatorKeys() {
    this.separatorKeys = [
      this.$mdConstant.KEY_CODE.ENTER,
      this.$mdConstant.KEY_CODE.TAB,
      this.$mdConstant.KEY_CODE.COMMA
    ];
  }

  submit() {
    if (this.form.$valid) {
      this.setLoading(true);
      let patchData = {
        lifeValues: this.lifeValues,
        leadershipStyles: this.API.objectListToIdsSerializerField(
          this.profile.leadershipStyles
        ),
        influencingApproaches: this.API.objectListToIdsSerializerField(
          this.profile.influencingApproaches
        ),
        positiveWorkEnvironments: this.API.objectListToIdsSerializerField(
          this.profile.positiveWorkEnvironments
        ),
        negativeWorkEnvironments: this.API.objectListToIdsSerializerField(
          this.profile.negativeWorkEnvironments
        )
      };

      this.API.restangularize(this.profile)
        .patch(patchData)
        .then(this.nextTab.bind(this))
        .catch(this.handleError.bind(this));
    }
  }
}

PersonalityController.$inject = ['$log', '$state', '$mdConstant', 'API'];
PersonalityController.NAME = 'PersonalityController';
PersonalityController.controllerAs = 'personalityCtrl';
export default PersonalityController;
