import 'ng-toast';

/*@ngInject*/
export default function ToastConfig(ngToastProvider) {
  ngToastProvider.configure({
    additionalClasses: 'ToastMessage',
    animation: 'fade',
    combineDuplications: true,
    dismissButton: true,
    dismissButtonHtml: '<button class="ToastMessage__Dismiss">Dismiss</button>',
    dismissOnClick: false,
    horizontalPosition: 'right',
    timeout: 6000,
    verticalPosition: 'bottom'
  });
}
