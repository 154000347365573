import React from 'react';
import PropTypes from 'prop-types';
import { MemberProfileEditContainer } from '../../../../edit-container/MemberProfileEditContainer';
import STATES from '../../../../../../../services/States';

const getMemberGenderName = memberGender => {
  switch (memberGender) {
    case 'F':
      return 'Female';
    case 'M':
      return 'Male';
    case 'O':
      return 'Other';
    default:
      return 'N/A';
  }
};

export const OwnMemberProfilePersonal = ({ member, $state }) => {
  const onEdit = () => {
    $state.go(STATES.MEMBER_INLINE_EDIT.PROFILE, { section: 'personal' });
  };

  return (
    <article className="Separator WcMemberProfilePersonal">
      <div className="WcEditArticle">
        <MemberProfileEditContainer onEdit={onEdit}>
          <div className="flex-grow">
            <div className="flex layout-row">
              <div className="flex">
                <h2 className="WcMemberProfileContent__Subheading">Gender</h2>
                <div>
                  <p>{getMemberGenderName(member.gender)}</p>
                </div>
              </div>
              <div className="flex">
                <h2 className="WcMemberProfileContent__Subheading">
                  Ethnicity
                </h2>
                <p>{member.ethnicity ? member.ethnicity.name : 'N/A'}</p>
              </div>
            </div>

            <h2 className="WcMemberProfileContent__Subheading">
              Country of Origin
            </h2>
            <p>
              {member.countryOfOrigin ? member.countryOfOrigin.name : 'N/A'}
            </p>
          </div>
        </MemberProfileEditContainer>
      </div>
    </article>
  );
};

OwnMemberProfilePersonal.propTypes = {
  member: PropTypes.object,
  $state: PropTypes.any
};
