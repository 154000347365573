import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

// TO DO: change value to children or add 'link' prop - to display in the header text + link
export const SectionHeader = ({ value }) => (
  <h2 className="WcSectionHeader">{value}</h2>
);

SectionHeader.propTypes = {
  value: PropTypes.string
};
