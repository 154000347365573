import MembersRouting from './Members.routing';

import InlineEdit from './inline-edit/';
import PasswordCreationModule from '../password-creation/PasswordCreation.module';
import RegistrationModule from './registration/Registration.module';
import MemberArea from './member-area/MemberArea.module';

let MembersModule = angular
  .module('wc.members', [
    InlineEdit.name,
    PasswordCreationModule.name,
    MemberArea.name,
    RegistrationModule.name
  ])
  .config(MembersRouting);

export default MembersModule;
