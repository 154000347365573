import SuggestOrganizationTemplateUrl from './SuggestOrganization.html';

let SuggestOrganization = SuggestOrganization => {
  return {
    restrict: 'E',
    templateUrl: SuggestOrganizationTemplateUrl,
    scope: {
      model: '=',
      readableOrganizationType: '@',
      organizationType: '@',
      onSuggest: '&'
    },
    link: scope => {
      scope.showSuggestDialog = (
        $event,
        model,
        readableOrganizationType,
        organizationType
      ) => {
        SuggestOrganization.showSuggestDialog(
          $event,
          model,
          readableOrganizationType,
          organizationType
        ).then(newModelValue => {
          scope.model = newModelValue;
          scope.onSuggest({ suggestion: newModelValue });
        });
      };
    }
  };
};

SuggestOrganization.$inject = ['SuggestOrganization'];
SuggestOrganization.NAME = 'suggestOrganization';

export default SuggestOrganization;
