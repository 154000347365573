import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import moment from 'moment';
import { HighlightedImageBubble } from '../../../highlighted-image-bubble/HighlightedImageBubble';

export const MessageBubblePartner = ({
  isLast,
  message,
  mergeTop,
  mergeBottom,
  sentAt
}) => {
  const [sentAtFormatted, setSentAtFormatted] = useState('');
  const [avatarSrc, setAvatarSrc] = useState('');

  useEffect(() => {
    if (message) {
      setAvatar();
    }
    if (sentAt) {
      const sentAtFormattedTmp = moment(sentAt).calendar(null, {
        sameDay: 'LT',
        lastDay: '[Yesterday] LT',
        lastWeek: '[Last] dddd LT',
        sameElse: 'DD/MM/YYYY'
      });
      setSentAtFormatted(sentAtFormattedTmp);
    }
  }, [sentAt, message]);

  const setAvatar = async message => {
    if (message) {
      const author = await getMessageAuthor(message);
      if (author) {
        const avatarSrcTmp = author.attributes.avatar_url;
        setAvatarSrc(avatarSrcTmp);
      }
    }
  };

  const getMessageAuthor = async message => {
    const member = await message.getMember();
    return await member.getUser();
  };

  return (
    <div className="WcMessageBubblePartnerContainer">
      <div className="WcMessagePartnerProfilePicture">
        {!mergeTop ? (
          <div className="WcMessagePartnerProfilePicture__ImageBubble">
            <HighlightedImageBubble src={avatarSrc} />
          </div>
        ) : (
          <div className="WcMessagePartnerProfilePicture__ImageBubble--MessagePadding" />
        )}
      </div>

      {!mergeTop && <div className="WcMessageBubblePartner__Arrow" />}

      <div
        className={`WcMessageBubblePartner__MessageContainer ${
          !mergeBottom && !isLast
            ? 'WcMessageBubblePartner__MessageContainer__LastMessageInBlurb'
            : ''
        }`}
      >
        <div
          className={`WcMessageBubblePartner ${
            mergeBottom ? 'WcMessageBubblePartner--MergeBottom' : ''
          } ${mergeTop ? 'WcMessageBubblePartner--MergeTop' : ''}`}
          title={sentAt}
        >
          <p className="WcMessageBubblePartner__Text">{message.body}</p>
        </div>
        {!mergeBottom && (
          <div className="WcMessageBubblePartnerTimeStamp--Separator" />
        )}
        {mergeBottom && (
          <time
            className="WcMessageBubblePartnerTimeStamp"
            dateTime={sentAt}
            title={sentAt}
          >
            {sentAtFormatted}
          </time>
        )}
      </div>
    </div>
  );
};

MessageBubblePartner.propTypes = {
  isLast: PropTypes.bool,
  message: PropTypes.object,
  mergeBottom: PropTypes.bool,
  mergeTop: PropTypes.bool,
  sentAt: PropTypes.any
};
