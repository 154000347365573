import angular from 'angular';
import { react2angular } from 'react2angular';
import { OwnMemberProfileExperience } from './OwnMemberProfileExperience';

export default angular
  .module('wc.components.OwnMemberProfileExperience', [])
  // @ts-ignore
  .component(
    'reactOwnMemberProfileExperience',
    react2angular(
      OwnMemberProfileExperience,
      ['member', 'user'],
      [
        'CurrencyService',
        'CompensationsBlockedService',
        'DialogService',
        'API',
        '$state',
        'AuthenticationService'
      ]
    )
  );
