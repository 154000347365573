import angular from 'angular';

import ParagraphSeparator from '../separators/paragraphseparator';
import Page from '../page';
import Pages from '../pagecontainer';
import 'ng-file-upload';

import MemberProfile from './member-profile';
import ProfileContent from './profile-content';
import ProfileActions from './profile-actions';
import ProfileEditContainer from './edit-container';
import StickyElementContainer from './stickyelementcontainer';
import StickySidebar from './stickysidebar';

import ReactWidthExpander from './../react/width-expander';
import ReactMemberProfileOverview from './../react/profile/profile-overview';
import ReactProfileContent from './../react/profile/profile-content';

const dependencies = [
  'ngFileUpload',
  MemberProfile.name,
  ProfileContent.name,
  ProfileActions.name,
  ProfileEditContainer.name,
  ParagraphSeparator.name,
  Page.name,
  Pages.name,
  ReactWidthExpander.name,
  ReactMemberProfileOverview.name,
  ReactProfileContent.name
];

export default angular
  .module('wc.components.profile', dependencies)
  .directive(StickyElementContainer.NAME, StickyElementContainer)
  .directive(StickySidebar.NAME, StickySidebar);
