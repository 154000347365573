import getFormattedDateString from '../../common/services/DateUtils';

class PositionService {
  /*@ngInject*/
  constructor(API) {
    this.API = API;
  }

  savePosition(position) {
    const queryParams = this._getQueryParams(position);
    const saveMethod = this._getSaveMethod(position);
    return saveMethod(this._positionToJson(position), queryParams).then(
      this._saveSuggestions.bind(this, position)
    );
  }

  /* Private helper methods */

  _positionToJson(position) {
    let jsonModel = {
      positionType: position.positionType.code,
      company: position.company ? position.company.id : null,
      startDate: getFormattedDateString(position.startDate),
      endDate: position.current
        ? null
        : getFormattedDateString(position.endDate),
      internalJobTitle: position.internalJobTitle,
      directReportingLine: position.directReportingLine,
      noticePeriod: position.noticePeriod,
      current: position.current,
      roleSummary: position.roleSummary,
      businessLines: this.API.objectListToIdsSerializerField(
        position.businessLines
      ),
      departments: this.API.objectListToIdsSerializerField(
        position.departments
      ),
      functions: this.API.objectListToIdsSerializerField(position.functions),
      industryStandardJobTitles: this.API.objectListToIdsSerializerField(
        position.industryStandardJobTitles
      ),
      areasOfResponsibility: this.API.objectListToIdsSerializerField(
        position.areasOfResponsibility
      ),
      roleObjectives: this.API.objectListToIdsSerializerField(
        position.roleObjectives
      ),
      totalCompensationAmount: position.totalCompensationAmount
        ? position.totalCompensationAmount
        : null,
      totalCompensationCurrency: position.totalCompensationCurrency
        ? position.totalCompensationCurrency
        : null,
      city: position.city ? position.city : null,
      cityCoords: position.cityCoords ? position.cityCoords : null
    };

    let changedToJson = {};
    Object.keys(position).map(key => {
      changedToJson[key] = jsonModel[key];
    });
    return changedToJson;
  }

  _getQueryParams(position) {
    if (this._hasSuggestions(position)) {
      return { suggestion: true };
    }
  }

  _hasSuggestions(position) {
    if (!position.organizationSuggestions) {
      return false;
    }

    const keys = Object.keys(position.organizationSuggestions);
    for (let i = 0; i < keys.length; i++) {
      const suggestion = position.organizationSuggestions[keys[i]];

      if (suggestion && suggestion.length > 0) {
        return true;
      }
    }

    return false;
  }

  _saveSuggestions(position, fetchedPosition) {
    if (!position.organizationSuggestions) {
      return;
    }

    if (fetchedPosition.organizationSuggestions) {
      fetchedPosition.organizationSuggestions.forEach(fetchedSuggestion => {
        let searchBucket =
          position.organizationSuggestions[fetchedSuggestion.organizationType];

        if (searchBucket) {
          for (let i = 0; i < searchBucket.length; i++) {
            if (searchBucket[i].name === fetchedSuggestion.name) {
              return;
            }
          }

          this.API.restangularize(fetchedSuggestion)
            .remove()
            .catch(this.API.handleError());
        }
      });
    }

    Object.keys(position.organizationSuggestions).forEach(key => {
      position.organizationSuggestions[key].forEach(suggestion => {
        let duplicate = false;

        for (
          let i = 0;
          i < fetchedPosition.organizationSuggestions.length;
          i++
        ) {
          if (
            fetchedPosition.organizationSuggestions[i].name === suggestion.name
          ) {
            duplicate = true;
          }
        }

        if (!duplicate) {
          let saveData = {
            readableOrganizationType: suggestion.readableOrganizationType,
            organizationType: suggestion.organizationType,
            position: fetchedPosition.id,
            name: suggestion.name
          };

          this.API.OrganizationSuggestions.post(saveData).catch(
            this.API.handleError()
          );
        }
      });
    });
  }

  _getSaveMethod(position) {
    return position.url
      ? this.API.restangularize(position).patch
      : this.API.Positions.post;
  }
}

PositionService.NAME = 'PositionService';
export default PositionService;
