import angular from 'angular';
import STATES from '../../../services/States';

import { CLIENT_SEARCH_SIDEBAR_ID } from '../../sidebars/clientsearchsidebar';
import Navbar from '../navbar';
import IconButtons from '../iconbuttons';
import MessagesLink from '../messagesLink';
import SearchButton from '../searchbutton';
import ProfilePictureMenu from '../profilepicturemenu';

import '../clientnavbar/ClientNavbar.scss';
import templateUrl from '../clientnavbar/ClientNavbar.html';

class ClientNavbarCtrl {
  /*@ngInject*/
  constructor($state, AuthenticationService, featureFlags) {
    this.STATES = STATES;
    this.$state = $state;
    this.AuthenticationService = AuthenticationService;

    this.searchSidebarId = CLIENT_SEARCH_SIDEBAR_ID;
    this.messagingEnabled = false;
    this.AuthenticationService.getProfile().then(profile => {
      this.messagingEnabled = profile && !profile.disableMessaging;
    });
    this.messagingFeatureIsOn = featureFlags.isOn('messaging');
    this.clientSearchFeatureIsOn = featureFlags.isOn('clientSearch');
  }

  goToClientProfile() {
    this.$state.go(this.STATES.CLIENT_PROFILE);
  }

  goToClientSettings() {
    this.$state.go(this.STATES.CLIENT_SETTINGS);
  }

  signOut() {
    this.AuthenticationService.showLogoutDialog();
  }
}

const ClientNavbar = {
  NAME: 'wcSidenavClientNavbar',
  controller: ClientNavbarCtrl,
  templateUrl: templateUrl
};

const dependencies = [
  Navbar.name,
  IconButtons.name,
  MessagesLink.name,
  SearchButton.name,
  ProfilePictureMenu.name
];

export default angular
  .module('wc.components.sidenav.clientNavbar', dependencies)
  .component(ClientNavbar.NAME, ClientNavbar);
