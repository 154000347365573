import ChipsDrawerController from './ChipsDrawer.controller';

class SuggestibleChipsDrawerController extends ChipsDrawerController {
  /*@ngInject*/
  constructor($scope, $timeout, $q, API) {
    super($scope, $timeout, $q, API);
  }

  _initializeModel() {
    this.model = this.model || [];
    this.suggestedChipsModel = this.suggestedChipsModel || [];
  }

  _onSuggestedChipRemove(event, chip) {
    this._catchEvent(event);
    this.removeSuggestedChip(chip);

    if (this._isExpanded) {
      this._onChipBlur(event);
    }
  }

  _onSuggest(suggestion) {
    if (!this.suggestedChipsModel) {
      this.suggestedChipsModel = [];
    }

    this.suggestedChipsModel.push(suggestion);
    this.onSuggest({ suggestion: suggestion });
  }

  checkIsValid() {
    return super.checkIsValid() || this.hasSuggestion();
  }

  onSuggestedChipsModelChange() {
    if (this.suggestedChipsModel && this.suggestedChipsModel.length) {
      this.suggestedChipsModel.forEach(suggestion => {
        this.onSuggest({ suggestion: suggestion });
      });
    }
    if (!this.isDisabled && !this.hasSuggestion()) {
      this.onClearSuggestions();
    }
  }

  hasSuggestion() {
    return this.suggestedChipsModel && this.suggestedChipsModel.length > 0;
  }

  removeSuggestedChip(chip) {
    this.suggestedChipsModel.splice(this.suggestedChipsModel.indexOf(chip), 1);
  }
}

SuggestibleChipsDrawerController.controllerAs = 'chipsDrawerCtrl';
export default SuggestibleChipsDrawerController;
