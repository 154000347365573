import React from 'react';
import PropTypes from 'prop-types';
import angular from 'angular';
import { react2angular } from 'react2angular';
import './WcLoading.scss';

export const Loading = ({ children, loading, centered, transparent, hide }) => {
  let className = '';
  className += centered ? 'WcLoading__Centered' : '';
  className += transparent ? 'WcLoading__Transparent' : '';

  return (
    <React.Fragment>
      {loading ? (
        <div className={className}>
          <div className='WcLoading__Container'>
            <div className='DfSpinner'></div>
          </div>
        </div>
      ) : (hide ? null : children)}
    </React.Fragment>
  )
}

Loading.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  centered: PropTypes.bool,
  transparent: PropTypes.bool,
  hide: PropTypes.bool
};

export default angular
  .module('wc.components.reactWcLoading', [])
  // @ts-ignore
  .component('reactWcLoading', react2angular(Loading, ['children', 'loading', 'centered', 'transparent', 'hide']));
