import ScrollableTemplateUrl from './Scrollable.html';

let scrollable = $timeout => {
  return {
    restrict: 'A',
    transclude: true,
    templateUrl: ScrollableTemplateUrl,
    scope: {},
    link: {
      post: (scope, element) => {
        scope.id = 'scrollable' + Math.round(Math.random() * 100000);
        const scrollableId = `#${scope.id}`;
        scope.scrolledToTop = true;
        element.addClass('Scrollable');
        $timeout(() => {
          let scrollable = $(scrollableId);
          scope.scrolledToTop = scrollable.scrollTop() === 0;
          $(scrollableId).on('scroll', event => {
            let elem = event.target;
            let scrollRatio =
              (elem.scrollTop + elem.offsetHeight) / elem.scrollHeight;
            scope.scrolledToBottom = scrollRatio === 1;
            scope.scrolledToTop = elem.scrollTop === 0;
            scope.$apply();
          });
        });
      }
    }
  };
};

scrollable.$inject = ['$timeout'];
scrollable.NAME = 'scrollable';

export default scrollable;
