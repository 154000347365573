import angular from 'angular';

import states from '../../../services/States';
import { MAIN_SEARCH_ID } from '../../../services/MemberLists.service';
import CheckboxGroup from '../../inputgroups/checkboxgroup';
import ClientSearchFilters from '../clientsearchfilters';
import CurrencyRange from '../../inputgroups/currencyrange';
import Debounce from '../../inputs/debounce';
import Fieldset from '../../forms/fieldset';
import InputContainer from '../../inputcontainer';
import Inputs from '../../inputs/Inputs.module';
import InputWithTimePeriodSelector from '../../timeperiodinputs/input';
import IntegerRange from '../../inputgroups/integerrange';
import MultiValueTypeaheadWithTimePeriodSelector from '../../timeperiodinputs/multivaluetypeahead';
import RestrictedCurrencyRange from '../../inputgroups/restrictedcurrencyrange';
import SearchSidebar from '../searchsidebar';
import { FieldsetLegend } from '../../typography';

import './styles.scss';
import templateUrl from './template.html';

export const CLIENT_SEARCH_SIDEBAR_ID = 'wcClientSearchSidebar';

class ClientSearchSidebarCtrl {
  /*@ngInject*/
  constructor($state, API, wcMemberList, wcSidebar) {
    this.$state = $state;
    this.API = API;
    this.wcMemberList = wcMemberList;
    this.wcSidebar = wcSidebar;

    this.searchSidebarId = CLIENT_SEARCH_SIDEBAR_ID;
    this.memberListId = MAIN_SEARCH_ID;

    this.wcMemberList.initMainSearch();
  }

  onInit() {
    this.wcSidebar.register(CLIENT_SEARCH_SIDEBAR_ID);
  }

  onSearch() {
    this.$state.go(states.SEARCH);
  }
}

const ClientSearchSidebar = {
  NAME: 'wcClientSearchSidebar',
  controller: ClientSearchSidebarCtrl,
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wc.components.clientSearchSidebar', [
    'ngMaterial',
    CheckboxGroup.name,
    ClientSearchFilters.name,
    CurrencyRange.name,
    Debounce.name,
    Fieldset.name,
    FieldsetLegend.name,
    InputContainer.name,
    Inputs.name,
    InputWithTimePeriodSelector.name,
    IntegerRange.name,
    MultiValueTypeaheadWithTimePeriodSelector.name,
    RestrictedCurrencyRange.name,
    SearchSidebar.name
  ])
  .component(ClientSearchSidebar.NAME, ClientSearchSidebar);
