import angular from 'angular';
import { react2angular } from 'react2angular';
import { NotificationSettingsTab } from './NotificationSettingsTab';

export default angular
  .module('wc.components.ReactNotificationSettingsTab', [])
  // @ts-ignore
  .component(
    'reactNotificationSettingsTab',
    react2angular(NotificationSettingsTab, ['notificationSettings', 'onChange'])
  );
