import angular from 'angular';
import { react2angular } from 'react2angular';
import { PasswordSettingOption } from './PasswordSettingOption';

export default angular
  .module('wc.components.ReactPasswordSettingOption', [])
  // @ts-ignore
  .component(
    'reactPasswordSettingOption',
    react2angular(PasswordSettingOption, ['onEdit'])
  );
