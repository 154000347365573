import STATES from './services/States';

/*@ngInject*/
export default function run(
  $log,
  $rootScope,
  $timeout,
  wcSidebar,
  MessengerService
) {
  $rootScope.$on('$stateChangeSuccess', () => {
    $('input').blur(); // This helps hiding the soft keyboard on mobile devices.
    $timeout($('main').scrollTop(0));
    wcSidebar.closeAny(); // Close the sidebar
  });

  $rootScope.$on('$stateChangeSuccess', function(
    event,
    toState,
    toParams,
    fromState
  ) {
    if (
      (fromState.name === STATES.MEMBER_CONVERSATION &&
        toState.name !== STATES.MEMBER_CONVERSATION) ||
      (fromState.name === STATES.CLIENT_CONVERSATION &&
        toState.name !== STATES.CLIENT_CONVERSATION)
    ) {
      MessengerService.leaveConversations();
    }
  });

  // Uncomment these to debug state transition errors :) / :(

  // $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState) {
  //   console.log('$stateChangeStart from', fromState.name, 'to', toState.name);
  // });

  // $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState) {
  //   console.log('$stateChangeSuccess from', fromState.name, 'to', toState.name);
  // });

  $rootScope.$on('$stateChangeError', function(
    event,
    toState,
    toParams,
    fromState,
    fromParams,
    error
  ) {
    $log.error('$stateChangeError', error);
  });
}
